import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  ${'' /* color: #636363; */}
  font-family: Montserrat;
  font-size: ${(props) => props?.fontSize};
  font-weight: 500;
  display: flex;
  gap: 10px;

  display: flex;
  align-items: center;
  // justify-content: center;
`;

const InfoWrapper = styled.div`
  & .infoIcon {
    margintop: 2px;
    color: #222;
    border-radius: 50%;
    border: 2.5px solid #222;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;

    cursor: pointer;
    position: relative;

    &:hover {
      & .infoText {
        display: unset;
      }
    }

    & .infoText {
      display: none;
      color: #636363;
      position: absolute;
      left: 35px;
      top: -12px;
      min-width: 205px;
      max-width: 300px;
      min-height: 30px;
      background: #d0ccff;
      padding: 10px;
      border-radius: 8px;
      z-index: 100;

      font-family: Open Sans;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;

      box-shadow: 0px 4px 4px 0px #00000040;

      &:before {
        content: '';
        position: absolute;

        left: -14px;
        top: 12px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 15px solid #d0ccff;
        z-index: 11;
      }
      & > ul {
        & > li {
          white-space: nowrap;
          line-height: 15px;
          margin-left: -25px;
        }
      }
    }
  }
`;

const TitleWithInfo = ({
  showSwitchTab,
  title = '',
  infoText = '',
  position = 'right',
  fontSize='15px'
}) => {
  return (
    <Title fontSize={fontSize}>
      {title}
      {infoText && (
        <InfoWrapper>
          <div className='infoIcon'>
            i
            {typeof infoText === 'string' ? (
              <div className='infoText'>{infoText}</div>
            ) : (
              <div className='infoText'>
                <ul>
                  {infoText.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </InfoWrapper>
      )}
    </Title>
  );
};

export default TitleWithInfo;
