import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: Open Sans;
  width: 80%;

  //change this to your own
  margin: 50px 0px 20px 0px; 
  align-items: left;
`;

const BarsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 8% 80% 5%;
  gap: 5px;
  margin-left: 45px;
  padding-top:5px;

  color: #636363;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

const Bar = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;


const FilledBar = styled.div`
  // flex: ${(props) => props.percentage};
  // height: 27px;
  background-color: ${(props) => props.barColor || '#EAAABD'};
  border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'};
  margin: 8px 0px 0px 0px;

  height: 32px;
    // background: rgb(218, 217, 219);
    // width: 0%;
    // min-width: 1%;
    // border-radius: 3px 0px 0px 3px;
`;


const GenderBasedRating = ({ data, gender }) => {

  /* const minwidth = '1px'; */
  const getBarColor = () => {
    switch (gender) {
      case 'male':
        // return '#6640B8';
        return 'rgba(13, 135, 246, 1)';
      case 'female':
        // return '#EAAABD';
        return 'rgba(246, 79, 129, 1)';
      case 'unknown':
        // return '#D2D2D4';
        return 'rgba(218, 217, 219, 1)';
      default:
        return 'rgba(246, 79, 129, 1)'; // Default color if gender is not recognized
    }
  };

  return (
    <Wrapper>
      {data?.rating.map((percentage, index) => (
        <BarsWrapper key={index}>
          <div>{5 - index} Star</div>
          <Bar>
            <FilledBar data-testid="filled-bar" percentage={percentage} style={{ width: `${percentage + 1}%` }} barColor={getBarColor()}></FilledBar>
            <div style={{ marginLeft: '5px' }}>{`${percentage}%`}</div>
          </Bar>
          {/* <PercentageLabel>{`${percentage}%`}</PercentageLabel> */}

        </BarsWrapper>
      ))}
    </Wrapper>
  );
};

export default GenderBasedRating;
