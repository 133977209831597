import React, { useState } from 'react';
import TopicBuilder from '../QueryBuilder/TopicBuilder';
import PopUp from '../../../../Common/PopUp/PopUp';
import styled from 'styled-components';
import AiTopicBuilder from '../QueryBuilder/AiTopicBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { setPopUpActive, topicPreviewEmpty } from '../../../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 30px;
  & > div {
    width: 35%;
    color: #2a2929;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
  }
`;

const SVG_Wrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  & > :not(:nth-child(2)) {
    margin-top: 30px;
  }
`;

const Button_Wrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  & > * {
    width: 161px;
    height: 54px;
    padding: 7px 21px;

    border-radius: 100px;
    border: 1px solid #353cf7;
    color: #353cf7;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
  }
`;

const AddNewTopic = () => {
  // const [popUpActive, setPopUpActive] = useState(true);
  const [aiActive, setAiActive] = useState(false);
  const popUpActive = useSelector((state) => state.topicBuilder.popUpActive);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleButtonClick = () => {
    dispatch(topicPreviewEmpty())
    setAiActive(true);
    dispatch(setPopUpActive(false))
    
  };

  const handleActiveManual  = ( ) => {
    
    dispatch(topicPreviewEmpty())
    setAiActive(false);
    dispatch(setPopUpActive(false))
  }
  const handleClosePopup = () => {
    
    navigate('/topic/home');
  }
 
  return (
    <>
      {popUpActive && (
        <PopUp onClose={() => handleClosePopup()}>
          <Wrapper>
            <TextWrapper>
              <div>For crafting queries by self, select</div>
              <div>For AI-assisted query, select</div>
            </TextWrapper>
            <SVG_Wrapper>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='47'
                viewBox='0 0 43 47'
                fill='none'
              >
                <path
                  d='M41.1504 3.02532C40.7526 -0.156802 30.475 4.98824 29.0526 6.03634C26.6739 7.78905 24.8708 10.5945 22.7617 12.7036C19.924 15.5412 19.5501 19.0065 17.3849 21.7904C16.1055 23.4353 16.2032 23.9586 15.503 26.1994C14.8288 28.3569 12.4657 28.9914 12.1156 31.0923C11.8244 32.8394 7.67055 40.0896 6.30859 40.7706'
                  stroke='black'
                  stroke-width='3'
                  stroke-linecap='round'
                />
                <path
                  d='M2.43943 30.123C2.43943 33.645 1.181 40.0525 2.92335 43.1887C5.39737 47.642 15.0451 42.209 17.9247 40.7692'
                  stroke='black'
                  stroke-width='3'
                  stroke-linecap='round'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='82'
                height='82'
                viewBox='0 0 82 82'
                fill='none'
              >
                <path
                  d='M28.1875 43.5625C30.3103 43.5625 32.0312 41.8416 32.0312 39.7188C32.0312 37.5959 30.3103 35.875 28.1875 35.875C26.0647 35.875 24.3438 37.5959 24.3438 39.7188C24.3438 41.8416 26.0647 43.5625 28.1875 43.5625Z'
                  fill='black'
                />
                <path
                  d='M30.75 30.75H25.625V20.5H30.75C32.1092 20.5 33.4128 19.96 34.3739 18.9989C35.335 18.0378 35.875 16.7342 35.875 15.375C35.875 14.0158 35.335 12.7122 34.3739 11.7511C33.4128 10.79 32.1092 10.25 30.75 10.25H25.625C24.2662 10.2514 22.9634 10.7917 22.0026 11.7526C21.0417 12.7134 20.5014 14.0162 20.5 15.375V16.6562H15.375V15.375C15.3784 12.6576 16.4594 10.0524 18.3809 8.1309C20.3024 6.20939 22.9076 5.12839 25.625 5.125H30.75C33.4685 5.125 36.0756 6.20491 37.9978 8.12716C39.9201 10.0494 41 12.6565 41 15.375C41 18.0935 39.9201 20.7006 37.9978 22.6228C36.0756 24.5451 33.4685 25.625 30.75 25.625V30.75Z'
                  fill='#353CF7'
                />
                <path
                  d='M57.523 53.8994C60.0866 50.8317 61.9664 47.2525 63.037 43.4007C64.1077 39.5489 64.3445 35.513 63.7316 31.5624C63.1188 27.6119 61.6704 23.8374 59.4832 20.491C57.2959 17.1445 54.4201 14.3031 51.0476 12.1562L48.4876 16.5894C51.8292 18.7751 54.5458 21.7913 56.3714 25.3424C58.197 28.8936 59.069 32.858 58.902 36.8475C58.735 40.8369 57.5347 44.7146 55.4187 48.1008C53.3027 51.4869 50.3435 54.2655 46.8309 56.1643C43.3184 58.0631 39.3729 59.0171 35.3808 58.9329C31.3888 58.8486 27.4871 57.729 24.0578 55.6837C20.6284 53.6383 17.7891 50.7374 15.8179 47.265C13.8466 43.7926 12.811 39.8677 12.8125 35.8747H7.68751C7.68363 41.2315 9.20735 46.4784 12.0799 50.9998C14.9525 55.5212 19.0549 59.1298 23.9058 61.4022C28.7567 63.6746 34.155 64.5166 39.4675 63.8295C44.7801 63.1423 49.7865 60.9546 53.8996 57.5227L73.2516 76.8747L76.875 73.2514L57.523 53.8994Z'
                  fill='black'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='47'
                viewBox='0 0 43 47'
                fill='none'
              >
                <path
                  d='M1.99803 3.02532C2.39579 -0.156802 12.6735 4.98824 14.0959 6.03634C16.4746 7.78905 18.2777 10.5945 20.3868 12.7036C23.2244 15.5412 23.5983 19.0065 25.7636 21.7904C27.043 23.4353 26.9452 23.9586 27.6455 26.1994C28.3197 28.3569 30.6827 28.9914 31.0329 31.0923C31.324 32.8394 35.4779 40.0896 36.8398 40.7706'
                  stroke='black'
                  stroke-width='3'
                  stroke-linecap='round'
                />
                <path
                  d='M40.709 30.123C40.709 33.645 41.9674 40.0525 40.2251 43.1887C37.7511 47.642 28.1033 42.209 25.2238 40.7692'
                  stroke='black'
                  stroke-width='3'
                  stroke-linecap='round'
                />
              </svg>
            </SVG_Wrapper>
            <Button_Wrapper>
              <button onClick={() => (handleActiveManual())}>Manual</button>
              <button onClick={()=>{
                handleButtonClick()
                }}>AI</button>
            </Button_Wrapper>
          </Wrapper>
        </PopUp>
      )}
      {!aiActive && !popUpActive && <TopicBuilder />}
      {aiActive && !popUpActive && <AiTopicBuilder />}
    </>
  );
};

export default AddNewTopic;
