import React, { useEffect, useMemo, useState } from 'react';
import {
  Title,
  ChannelVal,
  DateFontStyle,
  EachRow,
  FemaleDataBar,
  GraphBarContainer,
  GraphHeader,
  GraphLabel,
  GraphMainWrapper,
  GraphWrapper,
  MaleDataBar,
  ParagraphBold,
  ParagraphNormal,
  PercentageBar,
  Star,
  SummaryWrapper,
  UnKnownDataBar
} from './PercentageBar.styled';

const Percentage3BarComp = ({ analysisData, compData, title, data, multiplier, summary1 = '', summary2 = '' }) => {
  const [addThisData, setAddThisData] = useState([]);

  // Update addThisData whenever data changes
  useEffect(() => {
    if (data) {
      setAddThisData(data);
    }
  }, [data]);

  // Memoized graph data for gender demographics
  const graphData = useMemo(() => {
    return compData.map(item => ({
      label: item.chatter_topic_title,
      channel: item.channel,
      male: item.ci_report.gender_demographics.male_percentage || 0,
      female: item.ci_report.gender_demographics.female_percentage || 0,
      unknown: item.ci_report.gender_demographics.unknown_percentage || 0,
      positive: item.ci_report.sentiment_metrics?.positive_sentiment || 0,
      negative: item.ci_report.sentiment_metrics?.negative_sentiment || 0,
      neutral: item.ci_report.sentiment_metrics?.neutral_sentiment || 0
    }));
  }, [compData]);

  // Render percentage bars based on title
  const renderPercentageBars = (el) => {
    const isGenderData = title === 'Gender Demographics';
    return (
      <>
        {isGenderData ? (
          <>
            <MaleDataBar width={Math.floor(el.male * multiplier)} background='rgba(27, 175, 246, 1)'>{el.male}%</MaleDataBar>
            <FemaleDataBar width={Math.floor(el.female * multiplier)} background='rgba(234, 170, 189, 1)'>{el.female}%</FemaleDataBar>
            <UnKnownDataBar width={Math.floor(el.unknown * multiplier)} background='rgba(218, 217, 219, 1)'>{el.unknown}%</UnKnownDataBar>
          </>
        ) : (
          <>
            <MaleDataBar width={Math.floor(el.positive * multiplier)} background='rgba(118, 237, 166, 1)'>{el.positive}%</MaleDataBar>
            <UnKnownDataBar width={Math.floor(el.neutral * multiplier)} background='rgba(252, 216, 126, 1)'>{el.neutral}%</UnKnownDataBar>
            <FemaleDataBar width={Math.floor(el.negative * multiplier)} background='rgba(253, 127, 120, 1)'>{el.negative}%</FemaleDataBar>
          </>
        )}
      </>
    );
  };

  // const renderSummary = (data) => {

  //   const isGenderData = title === 'Gender Demographics';
  //   return (
  //     <>
  //       {
  //         isGenderData ?
  //           <>
  //             <ParagraphBold>
  //               {data[0]?.label}: <ParagraphNormal>Popular with males on Twitter.</ParagraphNormal>
  //             </ParagraphBold>
  //             <ParagraphBold>
  //               {data[1]?.label}: <ParagraphNormal>Favored by females on Instagram.</ParagraphNormal>
  //             </ParagraphBold>
  //           </>
  //           :
  //           <>
  //             <ParagraphBold>
  //               {data[0]?.label}: <ParagraphNormal>Popular with males on Twitter.</ParagraphNormal>
  //             </ParagraphBold>
  //             <ParagraphBold>
  //               {data[1]?.label}: <ParagraphNormal>Favored by females on Instagram.</ParagraphNormal>
  //             </ParagraphBold>
  //           </>
  //       }
  //     </>

  //   );
  // }
  return (
    <GraphMainWrapper title={title}  className="pdf-page" style={{
      pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
    }}>
      <GraphWrapper>
        <GraphHeader>
          <Title>{title}</Title>
        </GraphHeader>
        {
          title === 'Gender Demographics' ?
            <GraphLabel>
              <div>
                <span className='male'></span>Male
              </div>
              <div>
                <span className='female'></span>Female
              </div>
              <div>
                <span className='unknown'></span>Unknown
              </div>
            </GraphLabel>
            :
            title === 'Sentiments' &&
            <GraphLabel>
              <div>
                <span className='positive'></span>Positive
              </div>
              <div>
                <span className='negative'></span>Negative
              </div>
              <div>
                <span className='neutral'></span>Neutral
              </div>
            </GraphLabel>
        }
        <GraphBarContainer>
          {graphData.length > 0 && graphData.map((el, index) => (
            <EachRow key={el.label || index}>
              <div>
                {/* <ChannelVal>{el.channel && el.channel == 'twitter' ? ' ' : el?.channel}</ChannelVal> */}
                <Star>{el.label}</Star>
              </div>
              <PercentageBar>
                {renderPercentageBars(el)}
              </PercentageBar>
            </EachRow>
          ))}
        </GraphBarContainer>

        <DateFontStyle style={{marginTop:'15px',marginBottom:'0px'}}>
          This data is observed from {compData?.[0]?.user_start_date || ''} to {compData?.[0]?.user_end_date || ''}
        </DateFontStyle>
      </GraphWrapper>
      {/* {
        title === 'Sentiments' ?

          <SummaryWrapper>
            {renderSummary()}
            <ParagraphBold>
              Positive: <ParagraphNormal>{analysisData?.sentiment?.positive_sentiment || '' }</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold>
              Negative: <ParagraphNormal>{analysisData?.sentiment?.negative_sentiment || ''}</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold>
              Neutral: <ParagraphNormal>{analysisData?.sentiment?.neutral_sentiment || ''}</ParagraphNormal>
            </ParagraphBold>
          </SummaryWrapper>
          :

          <SummaryWrapper>
            {renderSummary()}
            <ParagraphBold>
              {data[0]?.label}: <ParagraphNormal>{summary1}</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold>
              {data[1]?.label}: <ParagraphNormal>{summary2}</ParagraphNormal>
            </ParagraphBold>
          </SummaryWrapper>
      } */}
    </GraphMainWrapper>
  );
};

export default Percentage3BarComp;
