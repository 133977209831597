import React, { useEffect, useRef, useState } from 'react';
import {
    ChildContainer,
    Wrapper2,
    Wrapper2Container,
    Title,
    GraphLabelContainer,
    GraphLabelContainer2,
    GraphLabel,
    LabelContainer,
    LineContainer,
    FilterBtn,
    MainContainer,
    MainComponent2,
    ParagraphBold,
    ToggleButtonWrapper,
    Option,
    ParagraphNormal,
    DropDownWrapper,
    DropDownContentDiv,
    InputDropDownCheckBox,
    InputDivWrapper,
    DateFontStyle,
} from './ViewCompetitive.styled';
import TrendsInUserActivity from './TrendsInUserActivity';
import TrendsInSentiments from './Trends'
import { filter_icon } from '../../Common/SvgIcon/CommonSvgIcons';


const ToggleButton = ({
    options = [],
    onClick = () => { },
    padding = '5px 20px',
    option
}) => {
    const [selectedOption, setSelectedOption] = useState(option || options[0]);
    const handleSelectOption = (option) => {
        setSelectedOption(option);
        onClick(option);
    };

    return (
        <ToggleButtonWrapper>
            {options.map((option) => (
                <Option
                    selected={selectedOption === option}
                    onClick={() => handleSelectOption(option)}
                    padding={padding}
                    key={option}
                >
                    {option}
                </Option>
            ))}
        </ToggleButtonWrapper>
    );
};

const TrendsMainContainer = ({ option = 'Days', compData, trendsDataSet, showGraphLabel, summary1 = '', summary2 = '', analysisData, showsummary, showFilter = true, downloadFeature }) => {
    const [timeSpan, setTimeSpan] = useState(option || 'Days');
    const divRef = useRef(null); // Step 1: Create a ref
    const [openDropDown, setOpenDropDown] = useState(false)
    const [topic1Filter, setTopic1Filter] = useState({
        positive: true,
        negative: true,
        neutral: true
    })
    const [topic2Filter, setTopic2Filter] = useState({
        positive: true,
        negative: true,
        neutral: true
    })
    const handleChangeMainFilter = (e) => {
        const { checked } = e.target;

        setTopic1Filter((prevVal) => ({
            ...prevVal,
            positive: checked,
            negative: checked,
            neutral: checked
        }));
    };
    const handleChangeMainFilter2 = (e) => {
        const { checked } = e.target;

        setTopic2Filter((prevVal) => ({
            ...prevVal,
            positive: checked,
            negative: checked,
            neutral: checked
        }));
    };
    const handleChangeFilter = (e) => {
        const { name, checked } = e.target;

        setTopic1Filter((prevVal) => ({
            ...prevVal,
            [name]: checked
        }));
    };

    const handleChangeFilter2 = (e) => {
        const { name, checked } = e.target;

        setTopic2Filter((prevVal) => ({
            ...prevVal,
            [name]: checked
        }));
    };

    // console.log(topic1Filter, topic2Filter, "topic");
    const [divWidth, setDivWidth] = useState(0); // Step 2: State to store width
    let trendsInUserActivity = {

        Topic1TimeLine: [],
        Topic2TimeLine: [],
        Topic1Count: [],
        Topic2Count: []
    };

    let trendsInSentiments = {

        positive1: [],
        positive2: [],
        negative1: [],
        negative2: [],
        neutral1: [],
        neutral2: [],
        positive1val: [],
        positive2val: [],
        negative1val: [],
        negative2val: [],
        neutral1val: [],
        neutral2val: []

    };

    useEffect(() => {
        // Step 3: Access the width after the component mounts
        if (divRef.current) {
            setDivWidth(divRef.current.offsetWidth); // Get the width
        }

        const handleResize = () => {
            if (divRef.current) {
                setDivWidth(divRef.current.offsetWidth); // Update width on resize
            }
        };

        window.addEventListener('resize', handleResize); // Handle window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup
        };
    }, []);

    if (timeSpan === 'Days') {
        trendsInUserActivity = {
            Topic1TimeLine: compData?.[0]?.ci_report?.user_activity_trends?.days?.timeline || [],
            Topic2TimeLine: compData?.[1]?.ci_report?.user_activity_trends?.days?.timeline || [],
            Topic1Count: compData?.[0]?.ci_report?.user_activity_trends?.days?.count || [],
            Topic2Count: compData?.[1]?.ci_report?.user_activity_trends?.days?.count || []

        }
        trendsInSentiments = {

            positive1: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.days?.timeline || [],
            positive2: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.days?.timeline || [],
            negative1: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.days?.timeline || [],
            negative2: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.days?.timeline || [],
            neutral1: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.days?.timeline || [],
            neutral2: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.days?.timeline || [],


            positive1val: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.days?.count || [],
            positive2val: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.days?.count || [],
            negative1val: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.days?.count || [],
            negative2val: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.days?.count || [],
            neutral1val: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.days?.count || [],
            neutral2val: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.days?.count || []
        }
    } else if (timeSpan === 'Weeks') {
        trendsInUserActivity = {
            Topic1TimeLine: compData?.[0]?.ci_report?.user_activity_trends?.week?.timeline || [],
            Topic2TimeLine: compData?.[1]?.ci_report?.user_activity_trends?.week?.timeline || [],
            Topic1Count: compData?.[0]?.ci_report?.user_activity_trends?.week?.count || [],
            Topic2Count: compData?.[1]?.ci_report?.user_activity_trends?.week?.count || []

        }
        trendsInSentiments = {

            positive1: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.week?.timeline || [],
            positive2: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.week?.timeline || [],
            negative1: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.week?.timeline || [],
            negative2: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.week?.timeline || [],
            neutral1: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.week?.timeline || [],
            neutral2: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.week?.timeline || [],


            positive1val: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.week?.count || [],
            positive2val: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.week?.count || [],
            negative1val: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.week?.count || [],
            negative2val: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.week?.count || [],
            neutral1val: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.week?.count || [],
            neutral2val: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.week?.count || []
        }

        // trendsInUserActivity = compData?.[0]?.ci_report?.user_activity_trends?.week?.timeline || [];
        // trendsInUserActivityTopic2TimeLine = compData?.[1]?.ci_report?.user_activity_trends?.week?.timeline || [];
        // trendsInUserActivityTopic1Count = compData?.[0]?.ci_report?.user_activity_trends?.week?.count || [];
        // trendsInUserActivityTopic2Count = compData?.[1]?.ci_report?.user_activity_trends?.week?.count || [];
    } else if (timeSpan === 'Months') {
        trendsInUserActivity = {
            Topic1TimeLine: compData?.[0]?.ci_report?.user_activity_trends?.month?.timeline || [],
            Topic2TimeLine: compData?.[1]?.ci_report?.user_activity_trends?.month?.timeline || [],
            Topic1Count: compData?.[0]?.ci_report?.user_activity_trends?.month?.count || [],
            Topic2Count: compData?.[1]?.ci_report?.user_activity_trends?.month?.count || []

        }
        trendsInSentiments = {

            positive1: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.month?.timeline || [],
            positive2: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.month?.timeline || [],
            negative1: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.month?.timeline || [],
            negative2: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.month?.timeline || [],
            neutral1: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.month?.timeline || [],
            neutral2: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.month?.timeline || [],


            positive1val: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.month?.count || [],
            positive2val: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.month?.count || [],
            negative1val: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.month?.count || [],
            negative2val: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.month?.count || [],
            neutral1val: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.month?.count || [],
            neutral2val: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.month?.count || []
        }

        // trendsInUserActivity = compData?.[0]?.ci_report?.user_activity_trends?.month?.timeline || [];
        // trendsInUserActivityTopic2TimeLine = compData?.[1]?.ci_report?.user_activity_trends?.month?.timeline || [];
        // trendsInUserActivityTopic1Count = compData?.[0]?.ci_report?.user_activity_trends?.month?.count || [];
        // trendsInUserActivityTopic2Count = compData?.[1]?.ci_report?.user_activity_trends?.month?.count || [];
    }
    else if (timeSpan === 'Years') {
        trendsInUserActivity = {
            Topic1TimeLine: compData?.[0]?.ci_report?.user_activity_trends?.year?.timeline || [],
            Topic2TimeLine: compData?.[1]?.ci_report?.user_activity_trends?.year?.timeline || [],
            Topic1Count: compData?.[0]?.ci_report?.user_activity_trends?.year?.count || [],
            Topic2Count: compData?.[1]?.ci_report?.user_activity_trends?.year?.count || []

        }
        trendsInSentiments = {

            positive1: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.year?.timeline || [],
            positive2: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.year?.timeline || [],
            negative1: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.year?.timeline || [],
            negative2: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.year?.timeline || [],
            neutral1: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.year?.timeline || [],
            neutral2: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.year?.timeline || [],

            positive1val: compData?.[0]?.ci_report?.sentiment_trends?.positive_trends?.year?.count || [],
            positive2val: compData?.[1]?.ci_report?.sentiment_trends?.positive_trends?.year?.count || [],
            negative1val: compData?.[0]?.ci_report?.sentiment_trends?.negative_trends?.year?.count || [],
            negative2val: compData?.[1]?.ci_report?.sentiment_trends?.negative_trends?.year?.count || [],
            neutral1val: compData?.[0]?.ci_report?.sentiment_trends?.neutral_trends?.year?.count || [],
            neutral2val: compData?.[1]?.ci_report?.sentiment_trends?.neutral_trends?.year?.count || []
        }
        // trendsInUserActivity = compData?.[0]?.ci_report?.user_activity_trends?.year?.timeline || []; //post API integration, update with year attribute

        // trendsInUserActivityTopic2TimeLine = compData?.[1]?.ci_report?.user_activity_trends?.year?.timeline || [];
        // trendsInUserActivityTopic1Count = compData?.[0]?.ci_report?.user_activity_trends?.year?.count || [];
        // trendsInUserActivityTopic2Count = compData?.[1]?.ci_report?.user_activity_trends?.year?.count || [];
    }


    const handleTimeSpanClick = (option) => {
        setTimeSpan(option);
    };


    return (

        <ChildContainer className="pdf-page" style={{
            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>

            <Wrapper2>
                <Wrapper2Container>
                    <div>
                        <Title>
                            <p>{!showGraphLabel ? 'Trends in User Activity' : 'Sentiment Trends'}</p>

                        </Title>
                        <ToggleButton
                            options={['Days', 'Weeks', 'Months', 'Years']} //add years once api is available
                            onClick={handleTimeSpanClick}
                            padding='3px 30px'
                            option={option}
                        />
                    </div>
                    {
                        showGraphLabel ?

                            <GraphLabelContainer2 className="pdf-page" style={{
                                pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                            }}>

                                <GraphLabel style={{ marginLeft: '0px', justifyContent: 'end' }}>
                                    <div>
                                        <span className='positive'></span>positive
                                    </div>
                                    <div>
                                        <span className='negitive'></span>negitive
                                    </div>
                                    <div>
                                        <span className='neutralNew'></span>neutral
                                    </div>
                                </GraphLabel>
                                <LabelContainer>

                                    <LineContainer>
                                        <div className="straight-line"></div><div data-testid="topic-1" >{compData?.[1]?.chatter_topic_title || ''}</div>

                                    </LineContainer>

                                    <LineContainer>
                                        <div className="dotted-line"></div><div data-testid="topic-2">{compData?.[0]?.chatter_topic_title || ''}</div>

                                    </LineContainer>

                                </LabelContainer>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    position: 'relative'
                                }}

                                    onMouseOver={() => setOpenDropDown(true)}
                                    onMouseLeave={() => setOpenDropDown(false)}
                                >
                                    {
                                        showFilter &&

                                        <FilterBtn>
                                            Filters
                                            {filter_icon}
                                        </FilterBtn>
                                    }
                                    {
                                        openDropDown === true &&

                                        <DropDownWrapper>

                                            <DropDownContentDiv>
                                                <div>
                                                    <InputDropDownCheckBox type='checkbox' checked={topic1Filter.positive || topic1Filter.negative || topic1Filter.neutral} onChange={(e) => handleChangeMainFilter(e)} /> {compData?.[0]?.chatter_topic_title || ''}

                                                </div>
                                                <InputDivWrapper>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="positive" type='checkbox' checked={topic1Filter.positive} onChange={(e) => handleChangeFilter(e)} /> Positive

                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="negative" type='checkbox' checked={topic1Filter.negative} onChange={(e) => handleChangeFilter(e)} /> Negative

                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="neutral" type='checkbox' checked={topic1Filter.neutral} onChange={(e) => handleChangeFilter(e)} /> Neutral

                                                    </div>

                                                </InputDivWrapper>


                                            </DropDownContentDiv>

                                            <DropDownContentDiv>
                                                <div>
                                                    <InputDropDownCheckBox type='checkbox' checked={topic2Filter.positive || topic2Filter.negative || topic2Filter.neutral} onChange={(e) => handleChangeMainFilter2(e)} /> {compData?.[1]?.chatter_topic_title || ''}

                                                </div>
                                                <InputDivWrapper>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="positive" type='checkbox' checked={topic2Filter.positive} onChange={(e) => handleChangeFilter2(e)} /> Positive

                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="negative" type='checkbox' checked={topic2Filter.negative} onChange={(e) => handleChangeFilter2(e)} /> Negative

                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '10px',

                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <InputDropDownCheckBox name="neutral" type='checkbox' checked={topic2Filter.neutral} onChange={(e) => handleChangeFilter2(e)} /> Neutral

                                                    </div>

                                                </InputDivWrapper>


                                            </DropDownContentDiv>

                                        </DropDownWrapper>

                                    }
                                </div>
                            </GraphLabelContainer2>
                            :

                            <GraphLabelContainer className="pdf-page" style={{
                                pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                            }}>

                                <GraphLabel>
                                    <div>
                                        <span className='topic1'></span>{compData?.[0]?.chatter_topic_title || ''}
                                    </div>
                                    <div>
                                        <span className='topicB'></span>{compData?.[1]?.chatter_topic_title || ''}
                                    </div>
                                </GraphLabel>
                            </GraphLabelContainer>
                    }
                </Wrapper2Container>
                <MainContainer className="pdf-page" style={{
                    pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                }}>
                    {
                        showGraphLabel ?

                            <div ref={divRef} style={{ width: '100%', height: '100%' }} data-testid="trends-in-sentiments" >
                                <TrendsInSentiments
                                    downloadFeature={downloadFeature}
                                    showFilter={showFilter}
                                    width={divWidth}
                                    trendsDataSet={trendsDataSet}
                                    topic1Filter={topic1Filter}
                                    topic2Filter={topic2Filter}
                                    title_2={compData?.[1]?.chatter_topic_title || ''}
                                    title_1={compData?.[0]?.chatter_topic_title || ''}
                                    positive_value_1={trendsInSentiments?.positive1val}
                                    negative_value_1={trendsInSentiments?.negative1val}
                                    neutral_value_1={trendsInSentiments?.neutral1val}
                                    positive_value_2={trendsInSentiments?.positive2val}
                                    negative_value_2={trendsInSentiments?.negative2val}
                                    neutral_value_2={trendsInSentiments?.neutral2val}
                                    positive_date_1={trendsInSentiments?.positive1}
                                    positive_date_2={trendsInSentiments?.positive2}
                                    negative_date_1={trendsInSentiments?.negative1}
                                    negative_date_2={trendsInSentiments?.negative2}
                                    neutral_date_1={trendsInSentiments?.neutral1}
                                    neutral_date_2={trendsInSentiments?.neutral2}
                                    timeSpan={timeSpan}
                                    // topic1_dates={trendsInUserActivity || []}
                                // topic2_dates={trendsInUserActivityTopic2TimeLine || []}
                                // topic1_values={trendsInUserActivity || []}
                                // topic2_values={trendsInUserActivityTopic2Count || []}
                                />
                            </div>
                            :
                            <div style={{ width: '100%', height: '100%' }} data-testid="trends-in-user-activity">
                                <TrendsInUserActivity

                                    downloadFeature={downloadFeature}
                                    title1={compData?.[0]?.chatter_topic_title || ''}
                                    title2={compData?.[1]?.chatter_topic_title || ''}
                                    startDate={compData?.[0]?.start_date || ''}
                                    endDate={compData?.[0]?.end_date || ''}
                                    width={divWidth}
                                    topic1_dates={trendsInUserActivity?.Topic1TimeLine || []}
                                    topic2_dates={trendsInUserActivity?.Topic2TimeLine || []}
                                    topic1_values={trendsInUserActivity?.Topic1Count || []}
                                    topic2_values={trendsInUserActivity?.Topic2Count || []}
                                    trendsDataSet={trendsDataSet}
                                    timeSpan={timeSpan} />
                            </div>

                    }
                    
                          <DateFontStyle style={{ marginTop:'0px',marginBottom:'0px',display: 'flex', alignItems: 'end', justifyContent: 'end',marginRight: '20px', paddingBottom: '10px'}}>
                            This data is observed from {compData?.[0]?.user_start_date || ''} to {compData?.[0]?.user_end_date || ''}
                          </DateFontStyle>
                </MainContainer>
                {
                    showsummary ?
                        <MainComponent2 className="pdf-page" style={{
                            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                        }}>
                            <ParagraphBold  data-testid="topic-1">{compData?.[0]?.chatter_topic_title || ''} Reviews: <ParagraphNormal>{summary1}</ParagraphNormal></ParagraphBold>
                            <ParagraphBold  data-testid="topic-2">

                                {compData?.[1]?.chatter_topic_title || ''} Reviews: <ParagraphNormal>{summary2}</ParagraphNormal>
                            </ParagraphBold>
                            {/* <ParagraphBold>
                        Initial Peak: <ParagraphNormal>Both devices started strong with 300 reviews each on January 1st, before the decline.</ParagraphNormal>

                    </ParagraphBold> */}
                        </MainComponent2>
                        :
                        <MainComponent2 className="pdf-page" style={{
                            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                        }}>
                            <ParagraphBold >
                                {compData?.[0]?.chatter_topic_title || ''} Reviews:


                                <ul>
                                    {
                                        topic1Filter.positive &&
                                        <li data-testid='topic-1'>
                                            Positive Sentiment : <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.positive_trends?.data_1}</span>
                                        </li>
                                    }

                                    {
                                        topic1Filter.negative &&

                                        <li>
                                            Negative Sentiment : <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.negative_trends?.data_1}</span>
                                        </li>
                                    }

                                    {
                                        topic1Filter.neutral &&
                                        <li data-testid='topic-2'>
                                            Neutral Sentiment :  <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.neutral_trends?.data_1}</span>
                                        </li>
                                    }

                                </ul>
                            </ParagraphBold>
                            <ParagraphBold>

                                {compData?.[1]?.chatter_topic_title || ''} Reviews:

                                <ul>
                                    {
                                        topic2Filter.positive &&
                                        <li>
                                            Positive Sentiment : <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.positive_trends?.data_2}</span>
                                        </li>
                                    }

                                    {
                                        topic2Filter.negative &&

                                        <li>
                                            Negative Sentiment : <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.negative_trends?.data_2}</span>
                                        </li>
                                    }

                                    {
                                        topic2Filter.neutral &&

                                        <li >
                                            Neutral Sentiment :  <span style={{ fontSize: '12px', fontWeight: 400 }}>{summary1?.neutral_trends?.data_2}</span>
                                        </li>
                                    }

                                </ul>
                            </ParagraphBold>
                            {/* <ParagraphBold>
                    Initial Peak: <ParagraphNormal>Both devices started strong with 300 reviews each on January 1st, before the decline.</ParagraphNormal>

                </ParagraphBold> */}
                        </MainComponent2>
                }


                {/* <Span>
  This data is observed from {startDate} to {endDate}
</Span> */}
            </Wrapper2>
        </ChildContainer>
    );
};


export default TrendsMainContainer;