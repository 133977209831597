import React from 'react';
import {
  OverallPosts,
  Title,
  RelevantPost,
  MainDivForOverview,
  Logo,
  TextDiv,
  DateFontStyle
} from './ViewCompetitive.styled';


const TopicMentions = ({ data, iconA, iconB }) => {
  return (

    <OverallPosts>
      <Title>{data?.[0]?.channel == 'flipkart' ? 'Chatter Mentions' : 'Topic Mentions'}</Title>
      {/* <div>
        {iconA}
      </div> */}
      <RelevantPost>

        <Logo>
          {iconA}
        </Logo>
        <div>

          <MainDivForOverview>
            {/* <Logo>
              {iconA}
            </Logo> */}
            <TextDiv>
              <h5 style={{ margin: '0px' }}>{data?.[0]?.channel == 'flipkart' ? 'Chatter' : 'Topic'} :</h5>
              <p style={{ margin: '0px' }}>{data?.[0]?.chatter_topic_title || ''} - {data?.[0]?.channel === 'amazon' || data?.[0]?.channel === 'flipkart' ? data?.[0]?.ci_report?.overall_reviews && `${data?.[0]?.ci_report?.overall_reviews} Mentions` : data?.[0]?.ci_report?.topic_mentions && `${data?.[0]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
          </MainDivForOverview>

          <MainDivForOverview>
            {/* <Logo>
              {iconB}
            </Logo> */}
            <TextDiv>
              <h5 style={{ margin: '0px' }}>{data?.[0]?.channel == 'flipkart' ? 'Chatter' : 'Topic'} :</h5>
              <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
          </MainDivForOverview>
        </div>
      </RelevantPost>
      <DateFontStyle style={{ marginTop: '0px', marginBottom: '0px', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
        This data is observed from {data?.[0]?.user_start_date || ''} to {data?.[0]?.user_end_date || ''}
      </DateFontStyle>
    </OverallPosts>
  );
};

export default TopicMentions;