import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { border, borderColor, color, display } from '@mui/system';

// Register the required components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const TrendsInUserActivity = ({ timeSpan, startDate = "10-01-2025", endDate = "2024-03-03", theme = false, downloadFeature = false, title1 = '', title2 = '', width, topic1_dates, topic2_dates, topic1_values, topic2_values }) => {


  function generateDateLabels(timeline1, timeline2, timeSpan) {
    // Step 1: Sort both timelines
    // const sortedTimeline1 = timeline1.sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));
    // const sortedTimeline2 = timeline2.sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));

    // Step 2: Merge both arrays into one
    const mergedTimeline = [...timeline1, ...timeline2];

    let sortedArray = [];
    if(timeSpan === 'Years'){
      function sortYears(years) {
        return years.sort((a, b) => a - b);  // Sort numerically in ascending order
      }
      sortedArray = sortYears(mergedTimeline);
    }else if(timeSpan === 'Months'){
      function sortDates(dates) {
        // Sort the dates in MM/YYYY format and return in the same format
        return dates.sort((a, b) => {
          const [monthA, yearA] = a.split('/'); // Split month and year for the first date
          const [monthB, yearB] = b.split('/'); // Split month and year for the second date
      
          // Create a comparable string in YYYY/MM format for sorting
          const dateA = new Date(`${yearA}-${monthA}-01`); // Create a date with day as 01
          const dateB = new Date(`${yearB}-${monthB}-01`); // Create a date with day as 01
      
          return dateA - dateB; // Compare the dates
        });
      }
      sortedArray = sortDates(mergedTimeline);
    }else{
      sortedArray = mergedTimeline.sort((a, b) => new Date(a.split('/').reverse().join('/')) - new Date(b.split('/').reverse().join('/')));
    }
    // Step 3: Remove duplicates by keeping the first occurrence of each date
    const uniqueTimeline = sortedArray.filter((item, index, self) => self.indexOf(item) === index);

    // Step 4: Assign the unique dates as labels
    const dateLabels = uniqueTimeline;

    // Step 5: Map values for timeline1 and timeline2 based on sorted unique dates
    const result1 = dateLabels.map((date) => {
      const index = timeline1.indexOf(date);
      // Return the corresponding value from topic1_values if the date exists in timeline1, otherwise return null
      return index > -1 ? topic1_values[index] : null;
    });
    // console.log(sortedTimeline2,);

    console.log(result1, "result1");
    const result2 = dateLabels.map((date) => {
      const index = timeline2.indexOf(date);
      // Return the corresponding value from topic2_values if the date exists in timeline2, otherwise return null
      return index > -1 ? topic2_values[index] : null;
    });
    // const result2 = [];

    // Return the sorted and unique date labels along with corresponding values
    return { dateLabels, result1, result2 };
  }

  const { dateLabels, result1, result2 } = generateDateLabels(topic1_dates, topic2_dates, timeSpan);
  console.log(dateLabels, result1, result2, "dateLabels");
  const [viewportHeight, setViewportHeight] = useState(window.innerWidth);

  // const divRef = useRef(null); // Step 1: Create a ref
  // const [divWidth, setDivWidth] = useState(0); // Step 2: State to store width

  // useEffect(() => {
  //   // Step 3: Access the width after the component mounts
  //   if (divRef.current) {
  //     setDivWidth(divRef.current.offsetWidth); // Get the width
  //   }

  //   const handleResize = () => {
  //     if (divRef.current) {
  //       setDivWidth(divRef.current.offsetWidth); // Update width on resize
  //     }
  //   };

  //   window.addEventListener('resize', handleResize); // Handle window resize
  //   return () => {
  //     window.removeEventListener('resize', handleResize); // Cleanup
  //   };
  // }, []);


  // console.log(viewportHeight,divWidth, typeof(divWidth),"viewportHeight");
  // Sample wavelength data (dates)


  // Corresponding energy values (dates)

  // Absorbance data for green and red lines



  // Data and options for the chart
  const data = {
    labels: dateLabels,
    datasets: [
      {
        fill: false,
        data: result1,
        // borderColor:'rgba(0, 213, 208, 1)',
        borderColor: 'rgba(54, 162, 235, 1)',
        // pointBackgroundColor: 'rgba(0, 213, 208, 1)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        // borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
        // pointBackgroundColor: 'green', // Marker color for topic 1
        pointBorderWidth: 2,
        pointRadius: 3, // Size of the markers
        spanGaps: true,
      },
      {
        fill: false,
        data: result2,
        // borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
        // pointBackgroundColor: 'red', // Marker color for topic 2
        borderColor: 'rgba(255, 99, 132, 1)',
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        // borderColor:'rgba(53, 60, 247, 1)',
        // pointBackgroundColor: 'rgba(53, 60, 247, 1)',
        // borderDash: [10, 5], // Dotted line (10px line, 5px gap)
        pointBorderWidth: 2,
        pointRadius: 3, // Size of the markers
        xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,

    // maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        labels: dateLabels, // Main x-axis for wavelength
        grid: {
          display: false, // Disable grid lines
          drawOnChartArea: false, // Prevent drawing any grid lines
          drawTicks: true, // Disable ticks
          drawBorder: true, // Draw only the border
          // borderColor: 'white', // Set border color to white

          borderColor: downloadFeature ? 'white' : '#1f1f1f', // Axis border color
          // color: 'white',
          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#1f1f1f', // Axis border color
          // tickColor:'white',
          tickColor: downloadFeature ? 'white' : 'rgba(54, 162, 235, 1)', // Axis border color
        },
        border: {
          display: true, // Ensure the axis border is shown
          // color: 'white', // Axis border color
          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#1f1f1f', // Axis border color
        },
        // grid: {
        //   display: true, // Display grid lines
        //   drawOnChartArea: false, // Prevent drawing grid lines for all ticks
        //   drawTicks: true, // Draw ticks for all labels
        //   // tickColor: 'black',
        //   tickColor:'white',
        //   drawBorder: true, // Draw the border line
        //   borderColor: 'white', // Set the border color to white

        // color: 'white',
        //   // color: (context) => {
        //   //   return context.tick.value === topic1_dates[topic1_dates.length - 1] ? 'purple' : 'transparent';
        //   // },
        // },
        title: {
          display: downloadFeature ? false : true,
          // text: title1,
          // color: '#1f1f1f'
          // color:'white'
          color: theme == true ? downloadFeature ? 'white' : 'rgba(54, 162, 235, 1)' : 'black', // Axis border color
          // color: 'rgba(54, 162, 235, 1)'
        },
        ticks: {
          // color: 'white',

          color: theme == true ? downloadFeature ? 'white' : 'rgba(54, 162, 235, 1)' : 'black', // Axis border color
          // color: 'rgba(54, 162, 235, 1)' // Alternate colors for tick labels
        },
      },
      topic2_dates: {
        type: 'category',
        position: 'top',
        labels: dateLabels, // Secondary x-axis for energy

        grid: {
          display: false, // Disable grid lines
          drawOnChartArea: false, // Prevent drawing any grid lines
          drawTicks: false, // Disable ticks
          drawBorder: false, // Draw only the border
          // borderColor: 'white', // Set border color to white
          // color: 'white',
          // tickColor:'white',

          borderColor: downloadFeature ? 'white' : '#e50232', // Axis border color
          // color: 'white',
          color: theme == true ? downloadFeature ? 'white' : '#e50232' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#e50232', // Axis border color
          // tickColor:'white',
          tickColor: downloadFeature ? 'white' : '#e50232', // Axis border color
        },
        border: {
          display: true, // Ensure the axis border is shown
          // color: 'white', // Axis border color
          color: theme == true ? downloadFeature ? 'white' : 'rgba(54, 162, 235, 1)' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#1f1f1f', // Axis border color
        },
        // grid: {
        //   display: true, // Display grid lines
        //   drawOnChartArea: false, // Prevent drawing grid lines for all ticks
        //   drawTicks: true, // Draw ticks for all labels
        //   // tickColor: 'red',
        //   tickColor:'white',

        //   drawBorder: true, // Draw the border line
        //   borderColor: 'white', // Set the border color to white
        //   color: 'white',
        //   // color: (context) => {
        //   //   return context.tick.value === topic2_dates[topic2_dates.length - 1] ? 'red' : 'transparent';
        //   // },
        // },
        title: {
          display: false,
          // display: downloadFeature ? false : true,
          // text: title2,
          // color:'white'
          color: theme == true ? downloadFeature ? 'white' : 'rgba(54, 162, 235, 1)' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#e50232', // Axis border color
          // color: '#1f1f1f'
          // color:'#e50232'
        },
        ticks: {
          display: false,
          // display: downloadFeature ? false : true,
          // color:'white',
          color: theme == true ? downloadFeature ? 'white' : '#e50232' : 'black', // Axis border color

          // color: downloadFeature ? 'white' : '#e50232', // Axis border color
          // color: '#e50232' // Alternate colors for tick labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Disable grid lines
          drawTicks: true, // Disable ticks
          drawBorder: true, // Draw only the border
          // borderColor: 'white', // Set border color to white
          // color: 'white', 
          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black', // Axis border color
          // tickColor: downloadFeature ? 'white' : 'black', // Axis border color

          tickColor: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
          // tickColor:'white',
        },
        border: {
          display: true, // Ensure the axis border is shown
          // color: 'white', // Axis border color
          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
        },
        // beginAtZero: true,
        // grid: {
        //   display: true, // Display grid lines
        //   drawTicks: true, // Draw ticks for all labels
        //   // tickColor: 'black',

        // color: 'white',
        //   tickColor:'white',
        //   drawBorder: true, // Draw the border line
        //   borderColor: 'white', // Set the border color to white
        //   // color:'white',
        //   // color: (context) => {
        //   //   const maxValue = Math.max(...topic1_values, ...topic2_values); // Find the maximum value
        //   //   return context.tick.value === maxValue ? 'black' : 'transparent';
        //   // },
        // },
        title: {
          display: true,
          text: 'Reviews',
          // color: 'white',
          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
        },
        ticks: {
          // color:'white',

          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
          // color: 'black' // Default color for Y-axis labels
        },
      },
      rightY: {
        position: 'right', // Position this axis on the right
        grid: {
          display: false, // Disable grid lines for this axis
          drawTicks: false, // Disable ticks
        },
        border: {
          display: true, // Enable border on the right
          // color: 'white', // Border color for the opposite Y-axis

          color: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
        },
        ticks: {
          display: false, // Hide tick labels on this side
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        color: theme == true ? downloadFeature ? 'white' : 'black' : 'black',
      },
      tooltip: {
        // color:'white',
        enabled: true,
      },
    },
  };

  return (
    <div style={{
      width: '100%', height: 400, marginBottom: '10px',
    }}>
      {/* <Line data={data} options={options} /> minWidth:'1163px', */}
      <Line
        // onResize={}
        data={data}
        options={options}
        width={width} // Default behavior for responsive
        height={400} // Set height in pixels
      />
    </div>
  );
};

export default TrendsInUserActivity;

