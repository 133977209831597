import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PostsReport from './PostsReport';
import SentinelHub from './SentinelHub';
import { allMailDataEmpty, allReportDataEmpty } from '../../store/redux/slices/sentinelHubSlice';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
`;

const TabWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 25px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(99, 99, 99, 0.25);
  padding-bottom: 10px;
  margin: auto auto 10px;
  padding: 10px 0px;
  height: 45px;
  align-items: end;
  padding-bottom: 15px;

  & .tabsText {
    margin: 0px 20px 0px 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans';
    cursor: pointer;
  }

  & .active {
    color: rgba(53, 60, 247, 1);
  }
`;

const TabIndicator = styled.div`
  height: 3px;
  background-color: rgb(53 60 247);
  transition: transform 0.3s ease, width 0.3s ease;
  position: absolute;
  bottom: 0;
`;

const SentineHubHome = () => {
  const [showReports, setShowReports] = useState('Reports');
  const [activeTab, setActiveTab] = useState(0);
  const [tabWidths, setTabWidths] = useState([]);
  const tabRefs = useRef([]);
  const dispatch = useDispatch();

  const handleShowTabs = (val, index) => {
    
    dispatch(allMailDataEmpty())
    setActiveTab(index);
    setShowReports(val);
  };

  useEffect(() => {
    const widths = tabRefs.current.map(tab => tab.offsetWidth);
    setTabWidths(widths);
  }, []);
// console.log(tabWidths,"tabWidths");
  return (
    <Wrapper>
      <TabWrapper>
        <h3
          ref={(el) => (tabRefs.current[0] = el)}
          className={showReports === 'Reports' ? 'tabsText active' : 'tabsText'}
          onClick={() => handleShowTabs('Reports', 0)}
        >
          Reports
        </h3>
        <h3
          ref={(el) => (tabRefs.current[1] = el)}
          className={showReports === 'Posts' ? 'tabsText active' : 'tabsText'}
          onClick={() => handleShowTabs('Posts', 1)}
        >
          Posts
        </h3>
        {/* <h3
          ref={(el) => (tabRefs.current[2] = el)}
          className={showReports === 'Competitive_Insights' ? 'tabsText active' : 'tabsText'}
          onClick={() => handleShowTabs('Competitive_Insights', 2)}
        >
          Competitive Insights
        </h3> */}

        {tabWidths.length > 0 && (
          <TabIndicator
            style={{
              width: `${tabWidths[activeTab] + (activeTab === 1 ? 40 : 25)}px`,
              transform: `translateX(${tabWidths
                .slice(0, activeTab)
                .reduce((acc, width) => acc + width + (activeTab === 2 ? 54 : 45), 0)}px)`
            }}
          />
        )}
      </TabWrapper>

      {showReports === 'Reports' && <SentinelHub showci={false} />}
      {showReports === 'Posts' && <PostsReport />}
      {/* {showReports === 'Competitive_Insights' && <SentinelHub showci={true} />} */}
    </Wrapper>
  );
};

export default SentineHubHome;
