import styled from "styled-components";


export const DeletePopupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > p:nth-child(1) {
    color: #636363;
    text-align: left;
    color: #636363;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px #000;
    }
    & > button:nth-child(2) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px solid #000;
      border-radius: 100px;
      padding: 7px 21px;
    }
  }

  & .selectContainer {
    display: flex;
    justify-content: center;
    & > div {
      width: 40%;
    }
  }
`;
export const DivContainer = styled.div`
  display: flex;
  flex-direction:row;
  margin: 20px 10px 20px 0px;
`;
export const RadioContainer = styled.div`
 margin-left:10px;
 margin-top: 5px;
 padding-left: 10px;
 text-align: center;
 color: #636363;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;

 & > input: nth-child(2) {
   margin-left: 80px;
 } 
`;