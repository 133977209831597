import React from 'react';
import { FooterMainDivComp } from '../CompetitiveIntelligence/ViewCompetitive.styled'

const FooterForInsights = ({show,number = '',theme=false}) => {
    return (
        <FooterMainDivComp className={theme ? 'dark-theme':'light-theme'} theme={theme} show={show}>
            <div>All right Reserved | © 2024 Copperpod Digital </div>
            <div style={{textAlign:'center',color:'rgba(89, 89, 89, 1)'}}>Confidential </div>
            <div style={{textAlign:'end'}}>{number}</div>
            
        </FooterMainDivComp>
    );
};


export default FooterForInsights;