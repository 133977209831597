import React from 'react';
import PopUp from '../PopUp/PopUp';
import FilterSelect from '../Select/FilterSelect';
import Button from '../Button/Button';
import { DeletePopupWrapper, DivContainer, RadioContainer } from './FilterPopup.styled';

const FilterPopup = ({
  handleClosePopup,
  setSelectedOption,
  selectedOption,
  filterOption,
  handleFilterChange,
  openDropdown,
  setOpenDropdown,
  view,
  setView,
  handleCancel,
  handleApply,
  handleClear
}) => {
  return (
    <PopUp onClose={handleClosePopup}>
      <DeletePopupWrapper>
        <p>All Filters</p>
        <DivContainer>
          <FilterSelect
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            option={filterOption}
            options={[
              'Bar Graph',
              'Donut Chart',
              'Pie Chart',
              'Funnel Chart',
            ]}
            onChange={(selectedOption) => handleFilterChange(selectedOption)}
            placeholder='Visual View'

                data-testid="fliters"
            dropdownId="fliters"
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
          {
            filterOption == 'Bar Graph' &&
            <RadioContainer>
              <input
                type='radio'
                name='viewType'
                data-testid="horizontal"
                value='horizontal'
                checked={view == 'horizontal' ? true : false}

                // checked={view === 'horizontal'}
                onChange={(e) => setView(e.target.value)}
              />{' '}
              Horizontal View
              <input
                type='radio'
                name='viewType'
                data-testid="vertical"
                value='vertical'
                // checked={view === 'vertical'}
                checked={view == 'vertical' ? true : false}

                onChange={(e) => setView(e.target.value)}
              />{' '}
              Vertical View
            </RadioContainer>
          }

        </DivContainer>
        <div>
          <Button value={'Cancel'} onClick={() => handleCancel()} />
          <Button value={'Clear All'} onClick={handleClear} />
          <Button value={'Apply'} onClick={handleApply} />
        </div>
      </DeletePopupWrapper>
    </PopUp>
  );
};


export default FilterPopup;