import styled from "styled-components";

export const TabHeading = styled.div`
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;

  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;

  & > div {
    color: #353cf7;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;