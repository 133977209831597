import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import ClusterAnalysisGraph from '../../../../../components/ClusterAnalysisGraph/ClusterAnalysisGraph';
import { useParsedEndDate, useParsedStartDate } from '../../../../../utils/ParseDate';
import TitleWithInfo from '../../../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const Container = styled.div`
  height: 600px;
  padding: 25px 20px 25px 50px;

  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  max-width:1200px;
  flex-direction: column;
`;

const Title = styled.div`
  ${'' /* color: #636363; */}

  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin-left: -35px;
  margin-bottom: 45px;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 60px;
  margin-bottom: -18px;
`;

const ClusterAnalysisTab = ({
  data,
  selectedReport,
  startDate,
  endDate,
  filteredData,
  moduleType,
  job_id,
  reset,
  filteredStartDate,
  filteredEndDate,
  
  formatValue = "dd/yyyy/MM",
}) => {
  const sentiment_data = {
    'X-axis': [
      -89.37,

      -121.28,

      21.39,

      21.53,

      58.78,

      -118.94,

      -142.86,

      78.5,

      -58.6,

      78.82,
    ],

    'Y-axis': [
      2.51,

      6.48,

      17.47,

      38.49,

      14.03,

      -18.57,

      -4.3,

      51.37,

      8.11,

      20.62,
    ],

    sentiment: [
      1,

      1,

      -1,

      1,

      -1,

      0,

      1,

      1,

      1,

      1,
    ],
  };

  /* const xAxisValues_sentiments = [
    0, 5, 10, 17, 25, 30, 40, 45, 60, 80, 90, 95, 100,
  ]; */
  const xAxisValues_sentiments = data?.sentiment_data['X-axis'];
  // const yAxisValues_sentiments = [0, 5, 10, 17, 25, 30, 40, 45, 60, 80, 90, 95, 100];
  const yAxisValues_sentiments = [
    0, 13, 10, 17, 55, 60, 80, 95, 98, 105, 110, 115, 120,
  ];
  const sentiment = [-1, 0, 0, 1, -1, -1, 1, 1, 0, 0, 0, -1, 1];

  // const xAxisValues_sentiments = sentiment_data['X-axis'];
  // const yAxisValues_sentiments = sentiment_data['Y-axis'];
  // const sentiment = sentiment_data['sentiment'];
  const sentimentColor = {
    1: '#73EBA4',
    0: '#FBD77D',
    '-1': '#FC6758',
  };
  const sentimentLabel = {
    '-1': 'Negative',
    1: 'Positive',
    0: 'Neutral',
  };

  const reviews_sentiments = [
    'Good Good phone',

    'Good product Good product',

    "PLEASE DONT BUY IPHONES FROM AMAZON IAM GOT SCAMMED I RECEIVED IPHONE 13 ORDERED FROM AMAZON, IN WHICH FRONT CAMERA, REAR CAMERA, TORCH AND FACE ID NOT WORKING, AND I NEED TO REPLACE THE PRODUCT FOR WHICH I'am SOLD WITH A DEFECTIVE IPHONE 13. NO PROPER RESPONSE FROM CUSTOMER SERVICE TEAM FINALLY PRODUCT WAS NOT REPLACED IAM FORCED TO SERVICE THE PHONE. WASTE OF MONEY. PLEASE DONT BUY I PHONES FROM AMAZON",

    'Switched from Android to IOS, loved the experience, but.. The phone is awesome. The smoothness, the camera quality, display and the voice clarity are great. There is way better safety as every app asks to get tracked or not, to send you notifications or you can deny it. The phone in itself is so smooth and it’s so easy to edit videos or reels as no lagging is there. While the experience of switching from android to iOS was great, but there are so many things which are way more complicated in iOS. I can’t use true-caller and have to manually check the number, no headphone jack and airpods are kinda not useful for someone who talks for long hours on calls. (Planning to get the lightning cable wired apple earphones). Also, the fact that it does not have fingerprint is kinda shocking as phones starting from 10k also have that feature now, guess apple should really look in to it. Overall, I feel the pros outweigh the cons. Lastly, the BEST feature is the camera. The crystal clear quality of the camera was one of the reasons why I wanted to get the phone. My father has a Z fold, and personally I like the camera of the iPhone 13 way way better. Could be a personal preference, but I am satisfied.',

    "Extremely expensive doesn't justify the price Doesn't justify the price it is really extremely expensive don't buy iPhone better to buy computer in this price range",

    'Good product Good product battery backup is average',

    'Good product Quality product',

    '🔥 Just wow',

    'Delivery Person Response not good, and not recieved my calls ',

    'Amazing price There is no word to say because I bought this mobile with 12,000.00 discount',
    'Good Good phone',

    'Good product Good product',

    "PLEASE DONT BUY IPHONES FROM AMAZON IAM GOT SCAMMED I RECEIVED IPHONE 13 ORDERED FROM AMAZON, IN WHICH FRONT CAMERA, REAR CAMERA, TORCH AND FACE ID NOT WORKING, AND I NEED TO REPLACE THE PRODUCT FOR WHICH I'am SOLD WITH A DEFECTIVE IPHONE 13. NO PROPER RESPONSE FROM CUSTOMER SERVICE TEAM FINALLY PRODUCT WAS NOT REPLACED IAM FORCED TO SERVICE THE PHONE. WASTE OF MONEY. PLEASE DONT BUY I PHONES FROM AMAZON",

    'Switched from Android to IOS, loved the experience, but.. The phone is awesome. The smoothness, the camera quality, display and the voice clarity are great. There is way better safety as every app asks to get tracked or not, to send you notifications or you can deny it. The phone in itself is so smooth and it’s so easy to edit videos or reels as no lagging is there. While the experience of switching from android to iOS was great, but there are so many things which are way more complicated in iOS. I can’t use true-caller and have to manually check the number, no headphone jack and airpods are kinda not useful for someone who talks for long hours on calls. (Planning to get the lightning cable wired apple earphones). Also, the fact that it does not have fingerprint is kinda shocking as phones starting from 10k also have that feature now, guess apple should really look in to it. Overall, I feel the pros outweigh the cons. Lastly, the BEST feature is the camera. The crystal clear quality of the camera was one of the reasons why I wanted to get the phone. My father has a Z fold, and personally I like the camera of the iPhone 13 way way better. Could be a personal preference, but I am satisfied.',

    "Extremely expensive doesn't justify the price Doesn't justify the price it is really extremely expensive don't buy iPhone better to buy computer in this price range",

    'Good product Good product battery backup is average',

    'Good product Quality product',

    '🔥 Just wow',

    'Delivery Person Response not good, and not recieved my calls ',

    'Amazing price There is no word to say because I bought this mobile with 12,000.00 discount',
  ];

  const emotion_data = {
    'X-axis': [
      -89.37,

      -121.28,

      21.39,

      21.53,

      58.78,

      -118.94,

      -142.86,

      78.5,

      -58.6,

      78.82,
    ],

    'Y-axis': [
      2.51,

      6.48,

      17.47,

      38.49,

      14.03,

      -18.57,

      -4.3,

      51.37,

      8.11,

      20.62,
    ],

    emotion: [
      'fear',

      'fear',

      'neutral',

      'surprise',

      'sadness',

      'neutral',

      'anger',

      'surprise',

      'disgust',

      'joy',
    ],

    reviews: [
      'Good Good phone',

      'Good product Good product',

      "PLEASE DONT BUY IPHONES FROM AMAZON IAM GOT SCAMMED I RECEIVED IPHONE 13 ORDERED FROM AMAZON, IN WHICH FRONT CAMERA, REAR CAMERA, TORCH AND FACE ID NOT WORKING, AND I NEED TO REPLACE THE PRODUCT FOR WHICH I'am SOLD WITH A DEFECTIVE IPHONE 13. NO PROPER RESPONSE FROM CUSTOMER SERVICE TEAM FINALLY PRODUCT WAS NOT REPLACED IAM FORCED TO SERVICE THE PHONE. WASTE OF MONEY. PLEASE DONT BUY I PHONES FROM AMAZON",

      'Switched from Android to IOS, loved the experience, but.. The phone is awesome. The smoothness, the camera quality, display and the voice clarity are great. There is way better safety as every app asks to get tracked or not, to send you notifications or you can deny it. The phone in itself is so smooth and it’s so easy to edit videos or reels as no lagging is there. While the experience of switching from android to iOS was great, but there are so many things which are way more complicated in iOS. I can’t use true-caller and have to manually check the number, no headphone jack and airpods are kinda not useful for someone who talks for long hours on calls. (Planning to get the lightning cable wired apple earphones). Also, the fact that it does not have fingerprint is kinda shocking as phones starting from 10k also have that feature now, guess apple should really look in to it. Overall, I feel the pros outweigh the cons. Lastly, the BEST feature is the camera. The crystal clear quality of the camera was one of the reasons why I wanted to get the phone. My father has a Z fold, and personally I like the camera of the iPhone 13 way way better. Could be a personal preference, but I am satisfied.',

      "Extremely expensive doesn't justify the price Doesn't justify the price it is really extremely expensive don't buy iPhone better to buy computer in this price range",

      'Good product Good product battery backup is average',

      'Good product Quality product',

      '🔥 Just wow',

      'Delivery Person Response not good, and not recieved my calls ',

      'Amazing price There is no word to say because I bought this mobile with 12,000.00 discount',
    ],
  };

  //   const xAxisValues_emotion = emotion_data['X-axis'];
  //   const yAxisValues_emotion = emotion_data['Y-axis'];

  const xAxisValues_emotion = [
    -89.37, -121.28, 21.39, 21.53, 58.78, -118.94, -142.86, 78.5, -58.6, 78.82,
  ];

  const yAxisValues_emotion = [
    2.51, 6.48, 17.47, 38.49, 14.03, -18.57, -4.3, 51.37, 8.11, 20.62,
  ];

  const emotionLabel = {
    fear: 'Fear',
    surprise: 'Surprise',
    neutral: 'Neutral',
    anger: 'Anger',
    disgust: 'Disgust',
    sadness: 'Sadness',
    joy: 'Joy',
  };

  const emotionColor = {
    fear: '#FC9E58',
    surprise: '#B7DC68',
    neutral: '#F5CC61',
    anger: '#F74945',
    disgust: '#9071CE',
    sadness: '#4B9AE9',
    joy: '#FE00D5',
  };

  // const emotions = emotion_data['emotion'];
  // const reviews_emotion = emotion_data['reviews'];
  
  const startDateParsed = useParsedStartDate(reset, filteredStartDate, startDate, formatValue) ;
const endDateParsed = useParsedEndDate(reset, filteredEndDate, endDate, formatValue) ;

  return (
    <Wrapper>
      <Container>
        <Title> <TitleWithInfo
              title={'Sentiments'}
              infoText={`A sentiment analysis on user discussions that identifies common topics. This provides a concise summary of discussions within each cluster`}
           
            />  </Title>
        <ClusterAnalysisGraph
        title={'Sentiments'}
          xAxisValues={
            filteredData
              ? filteredData?.sentiment_data['X-axis']
              : data?.sentiment_data['X-axis'] ||
              selectedReport?.sentiment_data['X-axis']
          }
          yAxisValues={
            filteredData
              ? filteredData?.sentiment_data['Y-axis']
              : data?.sentiment_data['Y-axis'] ||
              selectedReport?.sentiment_data['Y-axis']
          }
          sentimentLabel={sentimentLabel}
          sentimentColor={sentimentColor}
          sentiment={
            data?.sentiment_data.sentiment ||
            selectedReport?.sentiment_data?.sentiment
          }
          reviews={
            filteredData
              ? filteredData?.sentiment_data?.reviews
              : data?.sentiment_data.reviews ||
              selectedReport?.sentiment_data?.reviews
          }
          type={'Sentiment'}
          name={
            filteredData
              ? filteredData?.sentiment_data?.user_name
              : data?.sentiment_data?.user_name ||
              selectedReport?.sentiment_data?.user_name
          }
          date={
            filteredData
              ? filteredData?.sentiment_data?.data
              : data?.sentiment_data?.date ||
              selectedReport?.sentiment_data?.date
          }
          helpful={
            filteredData
              ? filteredData?.sentiment_data?.helpful_cnt
              : data?.sentiment_data?.helpful_cnt ||
              selectedReport?.sentiment_data?.helpful_cnt
          }
          likesCount={
            data?.sentiment_data?.likes_cnt ||
            selectedReport?.sentiment_data?.likes_cnt
          }
          dislikesCount={
            data?.sentiment_data?.dislikes_cnt ||
            selectedReport?.sentiment_data?.likes_cnt
          }
          moduleType={moduleType}

          circle={filteredData ? filteredData?.sentiment_data?.['circle'] : data?.sentiment_data?.['circle'] ||
          selectedReport?.sentiment_data?.['circle']
          }

          job_id={job_id}
        />
        <Footer>
          This data is observed from {startDateParsed} to {endDateParsed}
        </Footer>
      </Container>
      <Container>
        <Title>  <TitleWithInfo
              title={'Emotions'}
              infoText={`A Emotion analysis on user discussions that identifies common topics. This provides a concise summary of discussions within each cluster`}
            
            /></Title>
        <ClusterAnalysisGraph
        title={'Emotions'}
          xAxisValues={
            filteredData
              ? filteredData?.emotion_data['X-axis']
              : data?.emotion_data['X-axis'] ||
              selectedReport?.emotion_data['X-axis']
          }
          yAxisValues={
            filteredData
              ? filteredData?.emotion_data['Y-axis']
              : data?.emotion_data['Y-axis'] ||
              selectedReport?.emotion_data['Y-axis']
          }
          sentimentLabel={emotionLabel}
          sentimentColor={emotionColor}
          sentiment={
            filteredData
              ? filteredData?.emotion_data?.emotion
              : data?.emotion_data.emotion ||
              selectedReport?.emotion_data?.emotion
          }
          reviews={
            filteredData
              ? filteredData?.emotion_data?.reviews
              : data?.emotion_data.reviews ||
              selectedReport?.emotion_data?.reviews
          }
          type={'Emotion'}
          name={
            filteredData
              ? filteredData?.emotion_data?.user_name
              : data?.emotion_data?.user_name ||
              selectedReport?.emotion_data?.user_name
          }
          date={
            filteredData
              ? filteredData?.emotion_data?.data
              : data?.emotion_data?.date || selectedReport?.emotion_data?.date
          }
          helpful={
            filteredData
              ? filteredData?.emotion_data?.helpful_cnt
              : data?.emotion_data?.helpful_cnt ||
              selectedReport?.emotion_data?.helpful_cnt
          }
          dislikesCount={
            data?.emotion_data?.dislikes_cnt ||
            selectedReport?.emotion_data?.dislikes_cnt
          }
          likesCount={
            data?.emotion_data?.likes_cnt ||
            selectedReport?.emotion_data?.likes_cnt
          }
          moduleType={moduleType}

          circle={filteredData ? filteredData?.emotion_data?.['circle'] : data?.emotion_data?.['circle'] ||
            selectedReport?.emotion_data?.['circle']

          }
          job_id={job_id}
        />
        <Footer>
          This data is observed from {startDateParsed} to {endDateParsed}
        </Footer>
      </Container>
    </Wrapper>
  );
};

export default ClusterAnalysisTab;