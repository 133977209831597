import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Options_LI,
  Options_LI_INSTA,
  Options_UL,
  StyledCheckIcon,
} from '../CommonStyledElement/CommonStyledElement';
import {  PostMesgIcon, UserIcon, UserIcon2 } from '../SvgIcon/CommonSvgIcons';
import FlagIcon from '../../assests/images/FlagIcon.png'

const Styled_Options_UL = styled(Options_UL)`
  position: absolute;
  max-height: 250px;
`;

const CustomSelect = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${(props) => props.customStyle?.width};

  & > * {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }

  & label {
    line-height: 28px;
    padding: 0px 10px;
    margin-left: 5px;
  }

  & .select-sub-label {
    font-size: 15px;
    font-weight: 400;
    color: #636363;
    display: inline-block;
    margin-bottom: 6px;
    pointer-events: none;
  }

  & > #selectBox {
    font-size: 16px;
    font-weight: ${(props) =>
    props.customStyle?.placeholderFontWeight || '500'};
    color: ${(props) => props.customStyle?.placeholderColor};
    padding: 15px 18px;
    height: ${(props) =>  props?.selectHeight ?  props?.selectHeight: '18px'};
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid ${(props) => (props.error ? 'red' : props.customStyle?.borderColor ? props.customStyle?.borderColor : '#a2a2a2')};
    position: relative;
    & > span {
      position: absolute;
      right: 10px;
      //z-index: -1;
    }
  }
`;

const ErrorMessage = styled.div`
  padding: 2px 10px;

  color: #d74748;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

const Select = ({
  showSecondModule,
  label,
  placeholder = '',
  subLabel = '',
  option = [],
  style,
  value,
  error = '',
  onChange = () => { },
  checkbox,
  forChannel = '',
  
                    selectHeight = '18px'
}) => {
  const [optionTab, setOptionTab] = useState(false);

  const [selectedOption, setSelectedOption] = useState(placeholder);

  useEffect(() => {
    if (value >= 0) {
      setSelectedOption(option[value]);
    }
    else if (value === -1) {
      setSelectedOption(option[0]);
    }
  }, [value, option]);

  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOptionTab(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const selectedOptionHandler = (op) => {
    setSelectedOption(op);
    setOptionTab(false);
    onChange(op);
  };

  const shouldOpenOptionsUpwards = () => {
    if (selectRef.current) {
      const selectRect = selectRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const distanceFromBottom = windowHeight - selectRect.bottom;

      return distanceFromBottom < selectRect.height;
    }
    return false;
  };

  const optionsStyle = {
    bottom: shouldOpenOptionsUpwards() ? '53%' : 'auto',
  };
  return (
    <CustomSelect selectHeight={selectHeight} customStyle={style} error={error}>
      {label && <label>{label}</label>}
      {subLabel && (
        <>
          <br />
          <label className='select-sub-label'>{subLabel}</label>
        </>
      )}
      <div
        onClick={() => setOptionTab((prev) => !prev)}
        ref={selectRef}
        id='selectBox'
      >
        {selectedOption ? selectedOption : placeholder}
        <span style={{ color: 'gray' }}>
          <KeyboardArrowDownIcon style={{ color: 'gray' }} />
          {/* <KeyboardArrowDownIcon style={{color: showSecondModule ? 'rgb(209, 201, 201)': 'black'}} /> */}
        </span>
      </div>
      {optionTab && (
        <Styled_Options_UL style={optionsStyle}>
          {
            forChannel == 'instagram' ?
              option.map((op) => (
                <Options_LI_INSTA
                style={{height:'30px',margin:'10px'}}
                  key={op}
                  onClick={() => selectedOptionHandler(op)}
                  className={`${selectedOption === op ? 'active-option' : ''}`}
                >
                  {
                    op == 'Account' ?
                      <div style={{ fontSize: '30px', marginRight: '10px' ,height:'30px'}}>
                        {UserIcon2}
                      </div>
                      :
                      <div style={{ fontSize: '30px', marginRight: '10px',width: '27px',textAlign: 'center',color: '#707070'}}>#</div>
                  }
                  {/* {checkbox === true && <StyledCheckIcon active={(selectedOption === op).toString()} />} */}
                  <div style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    // 
                  }}>{op}</div>
                </Options_LI_INSTA>
              )) :
              forChannel == 'facebook' ?
              option.map((op) => (
                <Options_LI_INSTA
                style={{height:'30px',margin:'10px'}}
                  key={op}
                  onClick={() => selectedOptionHandler(op)}
                  className={`${selectedOption === op ? 'active-option' : ''}`}
                >
                  {
                    op == 'Posts' ?
                      <div style={{ fontSize: '30px', marginRight: '10px' ,height:'30px'}}>
                        {PostMesgIcon}
                      </div>
                      :
                      // ,textAlign: 'center',color: '#707070'
                      <div style={{
                        fontSize: '30px',
                        marginRight: '10px',
                        width: '33px',
                        borderRadius: '50%',
                        padding: '2px',
                        background: 'rgba(243, 243, 243, 1)',
                        height: '33px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}><img src={FlagIcon} /></div>
                  }
                  {/* {checkbox === true && <StyledCheckIcon active={(selectedOption === op).toString()} />} */}
                  <div style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    // 
                  }}>{op}</div>
                </Options_LI_INSTA>
              ))
              :
              option.map((op) => (
                <Options_LI
                  key={op}
                  onClick={() => selectedOptionHandler(op)}
                  className={`${selectedOption === op ? 'active-option' : ''}`}
                >
                  {checkbox === true && <StyledCheckIcon active={(selectedOption === op).toString()} />}
                  {op}
                </Options_LI>
              ))
          }

        </Styled_Options_UL>
      )
      }
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CustomSelect >
  );
};

export default Select;
