import React, { useState, useRef, useEffect } from 'react';
import { DownArrowIcon } from '../SvgIcon/CommonSvgIcons';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  display: flex;
  min-width: 120px;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 5px 8px;

  color: #636363;
  font-family: Open Sans;
  font-weight: 400;
  cursor: pointer;

  position: relative;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 0;

  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  width: 100%;
  z-index: 3;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.22);
`;

// ${(props) => props.bgColor && 'background:#6F62FE; color:#fff;'}
const Option = styled.div`
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #6f62fe;
    color: #fff;
  }
`;

const FilterSelect = ({ selectedOption='Bar Graph',setSelectedOption,dropdownId = "", openDropdown, setOpenDropdown, options = [], onChange = () => { }, option }) => {
  


//   useEffect(() => {
//     if (option) {
//       setSelectedOption(option);
//     } else {
//       setSelectedOption(options[0]);
//     }
//   }, [option]);

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  const handleSelectGraph = () => {
      setOpenDropdown(prev => (prev === dropdownId ? null : dropdownId));
   
  };

  return (
    <SelectWrapper onClick={handleSelectGraph}>
      <div>{selectedOption}</div>
      <span>{DownArrowIcon}</span>
      {(openDropdown === dropdownId) && (
        <OptionsWrapper>
          {options.map((option) => (
            <Option
              bgColor={selectedOption === option}
              key={option}
              onClick={() => handleSelectedOption(option)}
            >
              {option}
            </Option>
          ))}
        </OptionsWrapper>
      )}
    </SelectWrapper>
  );
};

export default FilterSelect;
