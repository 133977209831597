import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DonutChart from '../../../../../Common/SentimentCharts/SentimentCharts';
import CorrelationGraph from '../../../../../components/CorrelationGraph/CorrelationGraph';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import WordCloudAnalysis from '../../../../../components/InsightsWordCloud/WordCloudAnalysis';
import GraphHOC from '../../../../../Common/GraphHOC/GraphHOC';
import FunnelGraph from '../../../../../Common/FunnelGraph/FunnelGraph';
import RatingDonutChart from '../../../../../Common/RatingDonutChart/DonutChart';
import {
  emoji_anger,
  emoji_disgust,
  emoji_fear,
  emoji_neutral,
  emoji_sadness,
  emoji_surprise,
  emoji_joy,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../../../Common/BarGraph/VarticalBargraph';
import MapChart from '../../../../../components/Maps/MapsIndia';
import {
  useParsedEndDate,
  useParsedStartDate,
} from '../../../../../utils/ParseDate';
import TitleWithInfo from '../../../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  margin: 40px 25px;
  width: 100%;
  height: auto;
  font-family: Open Sans;

  & > div {
    margin-bottom: 40px;
  }
`;

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 0px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  // min-height: 300px;
  p {
    /* width: 411px;
    margin: 100px 0px 0px 20px; */

    ${'' /* color: #636363; */}
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const HeadingSpan = styled.p`
  display: flex;
  margin: 30px 0px 10px 30px;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  .titleContainer {
    margin: 25px 20px;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }
  & > p:first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
`;

const FilteredGraphContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 30px;
  align-items: center;
`;

const Div = styled.div`
  margin: 20px 0px 0px 30px;
`;
const MapContainer = styled.div`
  /* width: 100%; */
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  & > p:first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 10px;
  margin-bottom: 10px;
`;

function InsightsTab({
  insightTabData,
  plan,
  selectedReport,
  filteredData,
  filterOption,
  view,
  reset,
  filteredStartDate,
  filteredEndDate,

  endDate,
  startDate,
  formatValue = 'dd/yyyy/MM',
}) {
  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = selectedReport?.BrandAstraEssence;
      planSpecificData = selectedReport?.data;
    } else {
      planSpecificData = selectedReport?.data;
      // planSpecificData = selectedReport?.BrandAstraElite;
    }
  } else if (insightTabData) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = insightTabData?.data;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = insightTabData?.BrandAstraEssence;

      planSpecificData = insightTabData?.data;
    } else {
      planSpecificData = insightTabData?.data;
      // planSpecificData = insightTabData?.BrandAstraElite;
    }
  }

  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  const COLOR_RANGE_1 = [
    '#C4C4CD',
    '#87D3F2',
    '#4EBEEB',
    '#35B8E8',
    '#188CE6',
    '#1777CF',
    '#155CB4',
  ];
  const COLOR_RANGE_2 = [
    '#C4C4CD',
    '#57E188',
    '#2DB757',
    '#36C768',
    '#189D3E',
    '#168736',
  ];
  const COLOR_RANGE_3 = [
    '#C4C4CD',
    '#F9FFA1',
    '#FFF060',
    '#F2DB00',
    '#E1B602',
    '#E7A300',
  ];
  const COLOR_RANGE_4 = [
    '#C4C4CD',
    '#FF9A91',
    '#F95D54',
    '#FF4237',
    '#F4362C',
    '#B9251C',
  ];

  const emotionData = [
    {
      name: 'Neutral',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.neutral
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.neutral,
      // fill: '#F5CC61',
      // fill: 'rgb(245, 188, 44)'
      fill: 'rgba(251, 214, 121, 1)',
    },
    {
      name: 'Surprise',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.surprise
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.surprise,
      // fill: '#B7DC68',
      fill: 'rgba(3, 198, 82, 1)',
    },
    {
      name: 'Joy',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.joy
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.joy,
      // fill: '#FE00D5',
      fill: 'rgba(218, 6, 184, 1)',
    },
    {
      name: 'Fear',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.fear
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.fear,
      // fill: '#FC9E58',
      fill: 'rgba(255, 153, 0, 1)',
    },
    {
      name: 'Sadness',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.sadness
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.sadness,
      // fill: '#4B9AE9',
      fill: 'rgba(21, 112, 203, 1)',
    },
    {
      name: 'Anger',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.anger
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.anger,
      // fill: '#F74945',
      fill: 'rgba(238, 19, 7, 1)',
    },
    {
      name: 'Disgust',
      value: filteredData
        ? filteredData?.insights_tab?.emotion_analysis[0]?.disgust
        : planSpecificData?.insights_tab?.emotion_analysis[0]?.disgust,
      // fill: '#9071CE',
      fill: 'rgba(84, 30, 192, 1)',
    },
  ];

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={emotionData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
          emojiList={{
            neutral: emoji_neutral,
            surprise: emoji_surprise,
            joy: emoji_joy,
            fear: emoji_fear,
            sadness: emoji_sadness,
            anger: emoji_anger,
            disgust: emoji_disgust,
            length: 2,
          }}
          title={'Emotional_Analysis'}
          summary={
            filteredData ? filteredData?.insights_tab?.emotion_summary :
              planSpecificData?.insights_tab?.emotion_summary
          }
        />
      ) : (
        <BarGraph
          data={emotionData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
          barHeightHover={'40px'}
          emojiList={{
            neutral: emoji_neutral,
            surprise: emoji_surprise,
            joy: emoji_joy,
            fear: emoji_fear,
            sadness: emoji_sadness,
            anger: emoji_anger,
            disgust: emoji_disgust,
            length: 2,
          }}
          title={'Emotional_Analysis'}
          summary={
            filteredData ? filteredData?.insights_tab?.emotion_summary :
              planSpecificData?.insights_tab?.emotion_summary
          }

        />
      ),
    'Pie Chart': <RatingDonutChart data={emotionData} type='percentage'

      title={'Emotional_Analysis'}
      summary={
        filteredData ? filteredData?.insights_tab?.emotion_summary :
          planSpecificData?.insights_tab?.emotion_summary
      } />,
    'Donut Chart': <DonutChart data={emotionData} type='percentage'
      title={'Emotional_Analysis'} summary={
        filteredData ? filteredData?.insights_tab?.emotion_summary :
          planSpecificData?.insights_tab?.emotion_summary
      } />,
    'Funnel Chart': (
      <FunnelGraph data={emotionData} maxWidth={'80%'} type={'%'}
        title={'Emotional_Analysis'} summary={
          filteredData ? filteredData?.insights_tab?.emotion_summary :
            planSpecificData?.insights_tab?.emotion_summary
        } />
    ),
  };

  // const mapData = filteredData
  //   ? filteredData?.data?.insights_tab?.map_data  || null
  //   : insightTabData?.data?.insights_tab?.map_data?.reviews_by_state ||
  //   selectedReport?.data?.insights_tab?.map_data?.reviews_by_state || null;
  const mapData = filteredData
    ? filteredData?.data?.insights_tab?.map_data || [] // Use empty array if no data
    : insightTabData?.data?.insights_tab?.map_data?.reviews_by_state ||
    selectedReport?.data?.insights_tab?.map_data?.reviews_by_state ||
    [];

  const positiveMapData = filteredData
    ? filteredData?.data?.insights_tab?.map_data || []
    : insightTabData?.data?.insights_tab?.map_data?.positive_reviews_by_state ||
    selectedReport?.data?.insights_tab?.map_data?.positive_reviews_by_state ||
    [];

  // const positiveMapData = filteredData ? filteredData?.data?.insights_tab?.map_data || null
  //   : insightTabData?.data?.insights_tab?.map_data?.positive_reviews_by_state  ||
  //   selectedReport?.data?.insights_tab?.map_data?.positive_reviews_by_state || null;
  const negativeMapData =
    insightTabData?.data?.insights_tab?.map_data?.negative_reviews_by_state ||
    selectedReport?.data?.insights_tab?.map_data?.negative_reviews_by_state ||
    [];

  const neutralMapData =
    insightTabData?.data?.insights_tab?.map_data?.neutral_reviews_by_state ||
    selectedReport?.data?.insights_tab?.map_data?.neutral_reviews_by_state ||
    [];
  let stateWithHighestReviews = '';
  let highestReviews = 0;
  let stateWitPositiveReview = '';
  let stateWithNegativeReview = '';
  let stateWithNeutralReview = '';

  if (
    mapData &&
    typeof mapData === 'object' &&
    Object.keys(mapData)?.length > 0
  ) {
    [stateWithHighestReviews, highestReviews] = Object.entries(mapData).reduce(
      (maxPair, currentPair) => {
        return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
      }
    );
  } else {
    stateWithHighestReviews = null;
    highestReviews = null;
  }
  // if (mapData) {
  //   [stateWithHighestReviews, highestReviews] = Object?.entries(mapData)?.reduce((maxPair, currentPair) => {
  //     return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
  //   });
  // }

  if (
    positiveMapData &&
    typeof positiveMapData === 'object' &&
    Object.keys(positiveMapData)?.length > 0
  ) {
    // if (positiveMapData) {
    [stateWitPositiveReview] = Object?.entries(positiveMapData)?.reduce(
      (maxPair, currentPair) => {
        return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
      }
    );
  } else {
    stateWitPositiveReview = null;
  }

  if (
    negativeMapData &&
    typeof negativeMapData === 'object' &&
    Object.keys(negativeMapData)?.length > 0
  ) {
    // if (negativeMapData) {
    [stateWithNegativeReview] = Object?.entries(negativeMapData)?.reduce(
      (maxPair, currentPair) => {
        return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
      }
    );
  } else {
    stateWithNegativeReview = null;
  }

  if (
    neutralMapData &&
    typeof neutralMapData === 'object' &&
    Object.keys(neutralMapData)?.length > 0
  ) {
    // if (neutralMapData) {
    [stateWithNeutralReview] = Object?.entries(neutralMapData)?.reduce(
      (maxPair, currentPair) => {
        return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
      }
    );
  } else {
    stateWithNeutralReview = null;
  }

  const emotionAnalysisGraph = renderScreen[filterOption];
  // let reviewhelpfullnessData;
  // if (
  //   insightTabData?.channel === 'flipkart' ||
  //   selectedReport?.channel === 'flipkart'
  // ) {
  //   reviewhelpfullnessData = planSpecificData?.insights_tab?.review_liked;
  // } else {
  //   reviewhelpfullnessData = filteredData
  //     ? filteredData?.sentiments?.review_helpfullness
  //     : planSpecificData?.sentiments?.review_helpfullness || [];
  // }
  let reviewhelpfullnessData;

  if (
    insightTabData?.channel === 'amazon' ||
    selectedReport?.channel === 'amazon'
  ) {
    reviewhelpfullnessData = filteredData
      ? filteredData?.insights_tab?.review_helpfullness
      : planSpecificData?.insights_tab?.review_helpfullness || [];
  } else {
    reviewhelpfullnessData = filteredData
      ? filteredData?.insights_tab?.review_liked
      : planSpecificData?.insights_tab?.review_liked || [];
  }
  const ratingColors = ['#D5D5DC', '#C5C6F3', '#ACAFFC', '#898DFB', '#676DFF'];
  const transformedData = reviewhelpfullnessData?.map((item) => ({
    name: `${item.rating} star`,
    fill: ratingColors[item.rating - 1],
    value:
      insightTabData?.channel == 'amazon' ||
        selectedReport?.channel === 'amazon'
        ? item.helpfull_per
        : item.likes_per,
  }));

  const renderReviewScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={transformedData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={transformedData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={transformedData} type='percentage' />,
    'Donut Chart': <DonutChart data={transformedData} type='percentage' />,
    'Funnel Chart': (
      <FunnelGraph data={transformedData} maxWidth={'80%'} type={'%'} />
    ),
  };

  const startDateParsed = useParsedStartDate(
    reset,
    filteredStartDate,
    startDate,
    formatValue
  );
  const endDateParsed = useParsedEndDate(
    reset,
    filteredEndDate,
    endDate,
    formatValue
  );

  return (
    <Wrapper>
      <WordCloudAnalysis
        insightsData={
          filteredData
            ? filteredData.insights_tab
            : planSpecificData?.insights_tab
        }
        startDate={startDateParsed}
        endDate={endDateParsed}
        showKeyWordDropDown={false}
      // startDate={
      //   reset
      //     ? filteredStartDate
      //     : insightTabData?.start_date || selectedReport?.start_date
      // }
      // endDate={
      //   reset
      //     ? filteredEndDate
      //     : insightTabData?.end_date || selectedReport?.end_date
      // }
      />

      {view === 'vertical' ? (
        <PieWrapper>
          <HeadingSpan>
            {insightTabData?.channel === 'flipkart' ||
              selectedReport?.channel === 'flipkart'
              ? 'Review Liked'
              : 'Review Helpfulness'}
          </HeadingSpan>
          <div>
            <VerticalBarGraph
              data={transformedData}
              type={'%'}
              maxWidth={'50%'}
              startDate={startDateParsed}
              endDate={endDateParsed}
            />
          </div>
        </PieWrapper>
      ) : (
        <PieWrapper>
          {/* {insightTabData?.channel === 'flipkart' ||
          selectedReport?.channel === 'flipkart' ? (
            <HeadingSpan>Review Liked</HeadingSpan>
          ) : (
            <HeadingSpan>Review Helpfulness</HeadingSpan>
          )} */}
          <GraphHOC
            title={
              insightTabData?.channel === 'flipkart' ||
                selectedReport?.channel === 'flipkart'
                ? 'Review Liked'
                : 'Review Helpfulness'
            }
            options={options}
            renderScreen={renderReviewScreen}
            graphWidth={'100%'}
            margin={'20px 0px 45px 50px'}
            startDate={startDateParsed}
            endDate={endDateParsed}
            option={filterOption}
            infoText='This chart displays the average number of reviews liked based on different satisfaction levels'
          />

          {/* <BarGraph data={transformedData} type={'%'} maxWidth={'50%'} /> */}
        </PieWrapper>
      )}

      {
        // (plan === 'BrandAstraPro' || plan === 'AstraLite') && (
        <>
          {/* {filterOption ? (
            <EmotionAnalysisWrapper>
              <p>Emotion Analysis</p>
              <FilteredGraphContainer>
                {emotionAnalysisGraph}
              </FilteredGraphContainer>
            </EmotionAnalysisWrapper>
          ) : ( */}
          <EmotionAnalysisWrapper>
            <GraphHOC
              title={'Emotion Analysis'}
              options={options}
              renderScreen={renderScreen}
              graphWidth={'100%'}
              margin={'20px 0px 20px 50px'}
              option={filterOption}
              startDate={startDateParsed}
              endDate={endDateParsed}
              infoText='This chart displays the percentage breakdown of user emotions in the posts, showing the proportions of feelings such as happiness, sadness, fear, anger, and others.'
            />
          </EmotionAnalysisWrapper>
          {/* )} */}
          <div style={{ marginTop: '30px' }}>
            <CorrelationGraph
              RatingLabels={
                insightTabData?.channel === 'amazon' ||
                  selectedReport?.channel === 'amazon'
                  ? [
                    'Rating',
                    'Helpful',
                    'Anger',
                    'Neutral',
                    'Sadness',
                    'Disgust',
                    'Fear',
                    'Joy',
                    'Surprise',
                  ]
                  : [
                    'Rating',
                    'Likes',
                    'Anger',
                    'Neutral',
                    'Sadness',
                    'Disgust',
                    'Fear',
                    'Joy',
                    'Surprise',
                  ]
              }
              correlationAnalysisData={
                filteredData
                  ? filteredData?.insights_tab?.correlation_analysis
                  : planSpecificData?.insights_tab?.correlation_analysis
              }
              startDate={startDateParsed}
              endDate={endDateParsed}
              correlation_summary={filteredData
                ? filteredData?.insights_tab?.correlation_summary
                : planSpecificData?.insights_tab?.correlation_summary}
            />
          </div>
        </>
        // )
      }
      {insightTabData?.channel === 'flipkart' ||
        selectedReport?.channel === 'flipkart' ? (
        <MapContainer>
          <p>
            {' '}
            <TitleWithInfo
              title={'Geographical View of Sentiments'}
              infoText={`This map shows the sentiment distribution of reviews across various states, providing a geographical overview of sentiments.`}
            />{' '}
          </p>
          <Container1>
            <MapChart
              mapData={
                filteredData
                  ? filteredData?.data?.insights_tab?.map_data?.reviews_by_state
                  : selectedReport?.data?.insights_tab?.map_data
                    ?.reviews_by_state ||
                  insightTabData?.data?.insights_tab?.map_data
                    ?.reviews_by_state
              }
              text={`The majority of the engagers were from ${stateWithHighestReviews}`}
              colorRange={COLOR_RANGE_1}
            />
            <MapChart
              mapData={
                filteredData
                  ? filteredData?.data?.insights_tab?.map_data
                    ?.positive_reviews_by_state
                  : selectedReport?.data?.insights_tab?.map_data
                    ?.positive_reviews_by_state ||
                  insightTabData?.data?.insights_tab?.map_data
                    ?.positive_reviews_by_state
              }
              text={`The majority of Positive reviews were from ${stateWitPositiveReview}`}
              colorRange={COLOR_RANGE_2}
              type='positive'
            />
          </Container1>
          <Container1>
            <MapChart
              mapData={
                filteredData
                  ? filteredData?.data?.insights_tab?.map_data
                    ?.neutral_reviews_by_state
                  : selectedReport?.data?.insights_tab?.map_data
                    ?.neutral_reviews_by_state ||
                  insightTabData?.data?.insights_tab?.map_data
                    ?.neutral_reviews_by_state
              }
              text={`The majority of Neutral reviews were from ${stateWithNeutralReview}`}
              colorRange={COLOR_RANGE_3}
              type='neutral'
            />
            <MapChart
              mapData={
                filteredData
                  ? filteredData?.data?.insights_tab?.map_data
                    ?.negative_reviews_by_state
                  : selectedReport?.data?.insights_tab?.map_data
                    ?.negative_reviews_by_state ||
                  insightTabData?.data?.insights_tab?.map_data
                    ?.negative_reviews_by_state
              }
              text={`The majority of Negative reviews were from ${stateWithNegativeReview}`}
              colorRange={COLOR_RANGE_4}
              type='negative'
            />
          </Container1>
        </MapContainer>
      ) : null}
    </Wrapper>
  );
}

export default InsightsTab;
