import React, { useState, useEffect } from 'react';
import {
  Preview,
  Wrapper,
  Text,
  QueryBuilderTitle_with_PreviewButton,
  KeywordWrapper,
  Label,
  SubLabel,
  ErrorMessage,
  LoadingWrapper,
} from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled';
import { DescForTopic, IconDiv, InfoDivWrapper, InfoWrapper } from './ViewCompetitive.styled.js'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DateSlider from '../../Common/DateSlider/dateSlider';
import ConnectChannels from '../TopicBuilder/MainContent/ConnectedChannels/ConnectedChannels';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '../../Common/Select/Select';
import ChannelDropdown from '../ChatterTracker/MainComponent/ChatterTrackerBuilder/ShoppingChannels/ChannelDropdown';
import PopUp from '../../Common/PopUp/PopUp';
import Button from '../../Common/Button/Button';
import { formatDateFilter } from '../../utils';
import { competitiveIntelligenceListChatter, competitiveIntelligenceListTopic, createNewAnalysis } from '../../store/redux/slices/competitiveIntelligenceSlice';
import SelectChannelPage from './SelectChannelPage.jsx';
import { QuestionIcon, SmallQuestionIcon } from '../../Common/SvgIcon/CommonSvgIcons.js';
import { activeTopicList } from '../../store/redux/slices/topicSlice.js';
import { chatterList } from '../../store/redux/slices/chatterSlice.js';

const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    width: 200px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */} 
    padding: 1px 8px;
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;
const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: #222;
  border-radius: 0px;
  width: 75px;
  height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    
  ${(props) =>
    props.selected &&
    `color: #fff;
      background:#353CF7;
      border-radius: ${props.selectedOption == 'Topic' ? '4px 0px 0px 4px !important' : '0px 4px 4px 0px !important'};
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

function CreateComparison({ setAnalysisListTopic, setAnalysisListChatter }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTopicOne, setSelectedTopicOne] = useState(null);
  const [selectedTopicTwo, setselectedTopicTwo] = useState(null);
  const [topicOptions, setTopicOptions] = useState([]);
  const [topicOptionsTwo, setTopicOptionsTwo] = useState([]);
  const [channelType, setChannelType] = useState('Topic');
  const [channel, setChannel] = useState(null);
  const [channelTwo, setChannelTwo] = useState(null);
  const [startDateOne, setStartDateOne] = useState('');
  const [endDateOne, setEndDateOne] = useState('');
  const [startDateTwo, setStartDateTwo] = useState('');
  const [endDateTwo, setEndDateTwo] = useState('');
  const [channelError, setChannelError] = useState('');
  const [showModal, setShowModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [topicOne, setTopicOne] = useState()
  const [topicTwo, setTopicTwo] = useState()
  const { activeTopicListData } = useSelector((state) => state.topicBuilder) || [];
  const { chatterListDate } = useSelector((state) => state.chatterTracker) || [];
  const [topicOneError, setTopicOneError] = useState(false);
  const [topicTwoError, setTopicTwoError] = useState(false);

  const { personalDetailsData } =
    useSelector((state) => state.tenantSetting) || {};
  const handleChannelSelect = (selected) => {
    // console.log(channel, selected, 'selected');

    setTopicTwoError(false)
    setTopicOneError(false)
    setChannelError(null)
    setTopicOne();
    setTopicTwo();
    if (channel === selected) {
      setChannel(null);
    } else {
      setChannel(selected);
    }
    let list = [];
    if (channelType === 'Topic') {
      // list = (activeTopicListData?.filter((topic) => topic.channel === selected && topic.is_active === 0))
      // && topic.is_active === 0
      list = activeTopicListData?.filter((topic) =>
        topic.channel === selected && topic.is_active === 0
      );
    } else {
      list = (chatterListDate?.filter((chatter) => chatter.channel === selected?.toLowerCase() && chatter.is_active === 0))
      // 
    }
    // const opt = list?.map((li) => {
    //   return li.topic_title || li.chatter_title
    // })

    if (list?.length > 0) {

      setShowModal(false)
      setTopicOptions(list)
    } else {
      setShowModal(true)
    }
  };

  // const handleChannelSelectTwo = (selected) => {
  //   // console.log(channel, selected, 'selected');
  //   if (channelTwo === selected) {
  //     setChannelTwo(null);
  //   } else {
  //     setChannelTwo(selected);
  //   }
  //   let list = [];
  //   if (channelType === 'Topic') {
  //     list = (activeTopicListData?.filter((topic) => topic.channel === selected && topic.is_active === 0))

  //   } else {
  //     list = (chatterListDate?.filter((chatter) => chatter.channel === selected?.toLowerCase() && chatter.is_active === 0))

  //   }

  //   // let list = activeTopicListData?.filter((topic) => topic.channel === selected && topic.is_active === 0)
  //   // console.log(list, "list");
  //   const opt = list?.map((li) => {
  //     return li.topic_title || li.chatter_title
  //   })
  //   if (opt?.length > 0) {
  //     setTopicOptionsTwo(opt)
  //   } else {
  //     setShowModal(true)
  //   }
  // };
  const handleButtonClick = (event) => {
    if (!topicOne || !topicTwo || !channel) {
      if (!channel) {
        setChannelError('Select any one channel')
      }
      if (!topicOne) {
        setTopicOneError(true)
      }
      if (!topicTwo) {
        setTopicTwoError(true)
      }
      return;
    }
    setLoader(true)
    const data = {
      job_id_1: topicOne?.job_id,
      start_date_1: endDateOne && formatDateFilter(startDateOne),
      end_date_1: endDateOne && formatDateFilter(endDateOne),
      job_id_2: topicTwo?.job_id,
      start_date_2: endDateOne && formatDateFilter(startDateOne),
      end_date_2: endDateOne && formatDateFilter(endDateOne),
      is_topic: channelType === 'Topic' ? true : false
    }
    dispatch(createNewAnalysis(data))
      .then((res) => {

        dispatch(activeTopicList());
        dispatch(chatterList());
        dispatch(competitiveIntelligenceListTopic()).then(
          (res) => {
            setAnalysisListTopic(res?.payload?.data)
          }
        );
        dispatch(competitiveIntelligenceListChatter()).then(
          (res) => {
            setAnalysisListChatter(res?.payload?.data)
          }
        );
        if (res?.payload?.data?.competitive_intelligence_id) {
          navigate('/competitiveIntelligence/ci_' + res?.payload?.data?.competitive_intelligence_id);

        }
        setLoader(false)
      })

      .catch((err) => {
        setLoader(false)
      });
  };

  const ToggleButton = ({
    options = [],
    onClick = () => { },
    padding = '5px 20px',
    option
  }) => {
    const [selectedOption, setSelectedOption] = useState(option);
    const handleSelectOption = (opt) => {
      if (selectedOption !== opt) {
        onClick(opt);
        setSelectedOption(opt);
      }
    };

    return (
      <ToggleButtonWrapper>
        {options?.map((op) => (
          <Option
            selectedOption={selectedOption}
            selected={selectedOption === op}
            onClick={() => handleSelectOption(op)}
            padding={padding}
            key={op}
          >
            {op}
          </Option>
        ))}
      </ToggleButtonWrapper>
    );
  };

  const handleChannelType = (type) => {
    setChannel(null)
    setChannelTwo(null)
    setChannelType(type)
    setSelectedTopicOne({
      start_date: '',
      end_date: ''
    })
    setselectedTopicTwo({
      start_date: '',
      end_date: ''
    })
  }

  const handleTopicOneChange = (opt) => {
    let selected = []

    if (channelType === 'Topic') {
      selected = activeTopicListData.find((option) => option.topic_title === opt)

    } else {
      selected = chatterListDate.find((option) => option.chatter_title === opt)
    }
    // setSelectedTopicOne(selected)
    // if(selectedTopicOne){
    setStartDateOne(formatDateFilter(selected?.start_date))
    setEndDateOne(formatDateFilter(selected?.end_date))
    // }
  }

  const formatValue =
    personalDetailsData?.app_preference?.date_format === 'dd/mm/yyyy'
      ? 'dd-MM-yyyy'
      : personalDetailsData?.app_preference?.date_format === 'mm/dd/yyyy'
        ? 'MM-dd-yyyy'
        : personalDetailsData?.app_preference?.date_format === 'yyyy/mm/dd'
          ? 'yyyy-MM-dd'
          : 'dd-MM-yyyy';
  const handleTopicTwoChange = (opt) => {
    let selected = []

    if (channelType === 'Topic') {
      selected = activeTopicListData.find((option) => option.topic_title === opt)

    } else {
      selected = chatterListDate.find((option) => option.chatter_title === opt)
    }
    // const selected = activeTopicListData.find((option) => option.topic_title === opt || option.chatter_title === opt)

    setselectedTopicTwo(selected)
    // if(selectedTopicTwo){
    setStartDateTwo(formatDateFilter(selected?.start_date))
    setEndDateTwo(formatDateFilter(selected?.end_date))
    // }
  }

  const dateHandler = (e, count) => {
    const { name, value } = e.target;
    if (name === 'firstPostDate') {
      count == 1 ? setStartDateOne(value) : setStartDateTwo(value);
    } else if (name === 'lastPostDate') {
      count == 1 ? setEndDateOne(value) : setEndDateTwo(value);
    }
  }

  const onDateSliderChange = (e) => {
    // console.log(e.startDate,"e",e);
    if (e.topicCount == 1) {
      setStartDateOne(formatDateFilter(e.startDate))
      setEndDateOne(formatDateFilter(e.endDate))
    } else {
      setStartDateTwo(formatDateFilter(e.startDate))
      setEndDateTwo(formatDateFilter(e.endDate))
    }
  }

  return (
    <Wrapper>
      {
        loader &&
        <LoadingWrapper>
          <div>
            <CircularProgress />
          </div>
        </LoadingWrapper>
      }

      <Text>
        Effortlessly compare data and generate comprehensive competitive
        intelligence reports, delivering actionable insights at your fingertips.
      </Text>
      <QueryBuilderTitle_with_PreviewButton>
        <ToggleButton
          options={['Topic', 'Chatter']}
          onClick={handleChannelType}
          option={channelType}
        />
        <Preview onClick={handleButtonClick}>Compare</Preview>
      </QueryBuilderTitle_with_PreviewButton>
      <SelectChannelPage
        showSecondModule={true}
        chooseFocus={false}
        descVal={channelType == 'Chatter' ? 'Select interested chatter' : 'Select interested topic'}
        subLabelVal={'Select interested channel'}
        handleTopicOneChange={handleTopicOneChange}
        topicOptions={topicOptions}
        selectedTopicOne={selectedTopicOne}
        // startDateOne={startDateOne}
        // endDateOne={endDateOne}
        onDateSliderChange={onDateSliderChange}
        // dateHandler={dateHandler}
        channelType={channelType}
        setTopicTwoError={setTopicTwoError}
        setTopicOneError={setTopicOneError}
        handleChannelSelect={handleChannelSelect}
        channel={channel}
        channelError={channelError}
        showModal={showModal}
        topicCount={1}
        setStartDateOne={setStartDateOne}
        setEndDateOne={setEndDateOne}
        setSelectedTopicOne={setSelectedTopicOne}
        setShowModal={setShowModal}
        formatValue={formatValue}
        setChannel={setChannel}
        topicOne={topicOne}
        topicTwo={topicTwo}
        setTopicOne={setTopicOne}
        setTopicTwo={setTopicTwo}
        topicOneError={topicOneError}
        topicTwoError={topicTwoError}
      />


    </Wrapper >
  );
}

export default CreateComparison;