import React from 'react';
import { Title, ChannelVal, DateFontStyle, EachRow, FemaleDataBar, GraphBarContainer, GraphHeader, GraphLabel, GraphMainWrapper, GraphWrapper, MaleDataBar, ParagraphBold, ParagraphNormal, PercentageBar, Star, SummaryWrapper, UnKnownDataBar, TopicAwithStarDiv, TitleHeaderWrapper } from '../../Pages/CompetitiveIntelligence/ViewCompetitive.styled';
import { StarIcon, X_LOGO } from '../../Common/SvgIcon/CommonSvgIcons';


const PercentageBarComp = ({ title, data, multiplier, compData, summary1 = '', summary2 = '' }) => {
    return (
        <GraphMainWrapper title={title} className="pdf-page" style={{
            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>
            <GraphWrapper title={title}>
                <GraphHeader>
                    {/* Gender Demographics */}
                    {
                        title === 'Emotions' ?
                            <TitleHeaderWrapper >

                                <Title>{title}</Title>
                                <GraphLabel>
                                    <div>
                                        <span className='male'></span> {compData?.[0]?.chatter_topic_title ? `${compData?.[0]?.chatter_topic_title}` : ''}
                                    </div>
                                    <div>
                                    {/* {compData?.[0]?.channel ? compData?.[0]?.channel == 'twitter' ? X_LOGO : compData?.[0]?.channel : ''} */}
                                    {/* {compData?.[1]?.channel ? compData?.[1]?.channel == 'twitter' ? X_LOGO : compData?.[1]?.channel : ''}  */}
                                        <span className='topicB'></span>{compData?.[1]?.chatter_topic_title ? `${compData?.[1]?.chatter_topic_title}` : ''}
                                    </div>
                                </GraphLabel>
                            </TitleHeaderWrapper >
                            :
                            <Title>{title}</Title>

                    }
                </GraphHeader>
                {
                    title === 'Gender Demographics' ?
                        <GraphLabel>
                            <div>
                                <span className='male'></span>Male
                            </div>
                            <div>
                                <span className='female'></span>Female
                            </div>
                            <div>
                                <span className='unknown'></span>Unknown
                            </div>
                        </GraphLabel>
                        : title === 'Average Rating' || title === 'Engagement Metrics' ?
                            <GraphLabel>
                                <div>
                                    <span className='male'></span> {compData?.[0]?.chatter_topic_title ? `${compData?.[0]?.chatter_topic_title}` : ''}
                                </div>
                                <div>
                                    <span className='topicB'></span> {compData?.[1]?.chatter_topic_title ? `${compData?.[1]?.chatter_topic_title}` : ''}
                                </div>
                            </GraphLabel>
// {compData?.[0]?.channel ? compData?.[0]?.channel == 'twitter' ? X_LOGO : compData?.[0]?.channel : ''} {compData?.[1]?.channel ? compData?.[1]?.channel == 'twitter' ? X_LOGO : compData?.[1]?.channel : ''}
                            :
                            title === 'Sentiments' ?
                                <GraphLabel>
                                    <div>
                                        <span className='positive'></span>Positive
                                    </div>
                                    <div>
                                        <span className='negative'></span>Negative
                                    </div>
                                    <div>
                                        <span className='neutral'></span>Neutral
                                    </div>
                                </GraphLabel>
                                :
                                <GraphLabel>
                                </GraphLabel>
                }

                <GraphBarContainer>
                    {data?.map((el) => (
                        <EachRow key={el.label}>
                            {/* {el.label} */}
                            <div>
                                {
                                    title === 'Gender Demographics' || title === 'Sentiments' ?

                                        <ChannelVal>{el.channel}</ChannelVal>
                                        :
                                        <></>
                                }
                                <Star>{el.label}</Star>
                            </div>



                            {
                                title === 'Gender Demographics' ?

                                    <PercentageBar>
                                        <MaleDataBar width={Math.floor(el.male * multiplier)} background='rgb(27, 175, 246)'>{el.male}</MaleDataBar>
                                        <FemaleDataBar width={Math.floor(el.female * multiplier)} background={'rgb(234, 170, 189)'} >{el.female}</FemaleDataBar>
                                        <UnKnownDataBar width={Math.floor(el.unknown * multiplier)} background='rgb(218, 217, 219)'>{el.unknown}</UnKnownDataBar>
                                        {/* <NonVerifiedBar>{el.topicB}</NonVerifiedBar> */}
                                    </PercentageBar>
                                    :
                                    title === 'Sentiments' ?
                                        <PercentageBar>
                                            <MaleDataBar width={Math.floor(el.positive * multiplier)} background='rgba(118, 237, 166, 1)'>{el.positive}%</MaleDataBar>
                                            <FemaleDataBar width={Math.floor(el.negitive * multiplier)} background='rgba(252, 216, 126, 1)'>{el.negitive}%</FemaleDataBar>
                                            <UnKnownDataBar width={Math.floor(el.neutral * multiplier)} background='rgba(253, 127, 120, 1)'>{el.neutral}%</UnKnownDataBar>
                                            {/* <NonVerifiedBar>{el.topicB}</NonVerifiedBar> */}
                                        </PercentageBar>
                                        :
                                        title === 'Emotions' ?

                                            <PercentageBar>
                                                <MaleDataBar width={Math.floor(el.topicA * multiplier)} background='rgba(54, 162, 235, 1)'>{el.topicA}%</MaleDataBar>

                                                <FemaleDataBar width={Math.floor(el.topicB * multiplier)} background='rgba(255, 99, 132, 1)'>{el.topicB}%</FemaleDataBar>
                                                {/* <NonVerifiedBar>{el.topicB}</NonVerifiedBar> */}
                                            </PercentageBar>
                                            :
                                            title === 'Average Rating' ?
                                                <PercentageBar>
                                                    <MaleDataBar width={Math.floor(el.topicA * multiplier)} background='rgba(54, 162, 235, 1)'>
                                                        <TopicAwithStarDiv >
                                                            {el.topicA}{StarIcon}
                                                        </TopicAwithStarDiv>
                                                    </MaleDataBar>
                                                    <FemaleDataBar width={Math.floor(el.topicB * multiplier)} background='rgba(255, 99, 132, 1)'>
                                                        <TopicAwithStarDiv>
                                                            {el.topicB}{StarIcon}
                                                        </TopicAwithStarDiv></FemaleDataBar>
                                                    {/* <NonVerifiedBar>{el.topicB}</NonVerifiedBar> */}
                                                </PercentageBar>
                                                :
                                                title === 'Engagement Metrics' ?
                                                    <PercentageBar>
                                                        <MaleDataBar width={Math.floor(el.topicA * multiplier)} background='rgba(54, 162, 235, 1)'>
                                                            <TopicAwithStarDiv >
                                                                {el.topicA}
                                                            </TopicAwithStarDiv>
                                                        </MaleDataBar>
                                                        <FemaleDataBar width={Math.floor(el.topicB * multiplier)} background='rgba(255, 99, 132, 1)'>
                                                            <TopicAwithStarDiv>
                                                                {el.topicB}
                                                            </TopicAwithStarDiv></FemaleDataBar>
                                                        {/* <NonVerifiedBar>{el.topicB}</NonVerifiedBar> */}
                                                    </PercentageBar>
                                                    :

                                                    <PercentageBar>No Data</ PercentageBar>
                            }

                        </EachRow>
                    ))}
                </GraphBarContainer>


                <DateFontStyle>
                This data is observed from {compData?.[0]?.user_start_date || ''} to {compData?.[0]?.user_end_date || ''}
                </DateFontStyle>
            </GraphWrapper>
            {
                title === 'Emotions' ?

                    <SummaryWrapper>
                        <ParagraphBold>
                            {title === 'Emotions' ? `${compData?.[0]?.chatter_topic_title} :` : data[0]?.label && `${data[0]?.label} :`} <ParagraphNormal>{summary1}</ParagraphNormal>
                        </ParagraphBold>
                        <ParagraphBold>
                            {title === 'Emotions' ? `${compData?.[1]?.chatter_topic_title} :` : data[1]?.label && `${data[1]?.label} :`} <ParagraphNormal>{summary2}</ParagraphNormal>
                        </ParagraphBold>
                    </SummaryWrapper>
                    :
                    <></>
            }
        </GraphMainWrapper>
    );
};


export default PercentageBarComp;