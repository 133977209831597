import React, { useEffect, useMemo, useState } from 'react';
import {
  Title,
  ChannelVal,
  DateFontStyle,
  EachRow,
  FemaleDataBar,
  GraphBarContainer,
  GraphHeader,
  GraphLabel,
  GraphMainWrapper,
  GraphWrapper,
  MaleDataBar,
  ParagraphBold,
  ParagraphNormal,
  PercentageBar,
  Star,
  SummaryWrapper,
  UnKnownDataBar
} from './PercentageBar.styled';

const Percentage3BarComp = ({showTable = true,style={}, theme = false, analysisData, compData, title, data, multiplier, summary1 = '', summary2 = '', desc = '' }) => {
  const [addThisData, setAddThisData] = useState([]);

  // Update addThisData whenever data changes
  useEffect(() => {
    if (data) {
      setAddThisData(data);
    }
  }, [data]);

  // Memoized graph data for gender demographics
  const graphData = useMemo(() => {
    return compData.map(item => ({
      label: item.chatter_topic_title,
      channel: item.channel,
      male: item.ci_report.gender_demographics.male_percentage || 0,
      female: item.ci_report.gender_demographics.female_percentage || 0,
      unknown: item.ci_report.gender_demographics.unknown_percentage || 0,
      positive: item.ci_report.sentiment_metrics?.positive_sentiment || 0,
      negative: item.ci_report.sentiment_metrics?.negative_sentiment || 0,
      neutral: item.ci_report.sentiment_metrics?.neutral_sentiment || 0
    }));
  }, [compData]);

  // Render percentage bars based on title
  const renderPercentageBars = (el) => {
    const isGenderData = title === 'Gender Demographics';
    return (
      <>
        {isGenderData ? (
          <>
            <MaleDataBar theme={theme} width={Math.floor(el.male * multiplier)} background='rgba(13, 135, 246, 1)'>{el.male}%</MaleDataBar>
            <FemaleDataBar theme={theme} width={Math.floor(el.female * multiplier)} background='rgba(246, 79, 129, 1)'>{el.female}%</FemaleDataBar>
            <UnKnownDataBar theme={theme} width={Math.floor(el.unknown * multiplier)} background='rgba(218, 217, 219, 1)'>{el.unknown}%</UnKnownDataBar>
          </>
        ) : (
          <>
            <MaleDataBar theme={theme} width={Math.floor(el.positive * multiplier)} background='rgba(3, 198, 82, 1)'>{el.positive}%</MaleDataBar>
            <FemaleDataBar theme={theme} width={Math.floor(el.negative * multiplier)} background='rgba(238, 19, 7, 1)'>{el.negative}%</FemaleDataBar>
            <UnKnownDataBar theme={theme} width={Math.floor(el.neutral * multiplier)} background='rgba(251, 214, 121, 1)'>{el.neutral}%</UnKnownDataBar>
          </>
        )}
      </>
    );
  };

  // const renderSummary = (data) => {

  //   const isGenderData = title === 'Gender Demographics';
  //   return (
  //     <>
  //       {
  //         isGenderData ?
  //           <>
  //             <ParagraphBold>
  //               {data[0]?.label}: <ParagraphNormal>Popular with males on Twitter.</ParagraphNormal>
  //             </ParagraphBold>
  //             <ParagraphBold>
  //               {data[1]?.label}: <ParagraphNormal>Favored by females on Instagram.</ParagraphNormal>
  //             </ParagraphBold>
  //           </>
  //           :
  //           <>
  //             <ParagraphBold>
  //               {data[0]?.label}: <ParagraphNormal>Popular with males on Twitter.</ParagraphNormal>
  //             </ParagraphBold>
  //             <ParagraphBold>
  //               {data[1]?.label}: <ParagraphNormal>Favored by females on Instagram.</ParagraphNormal>
  //             </ParagraphBold>
  //           </>
  //       }
  //     </>

  //   );
  // }
  return (
    <GraphMainWrapper title={title}

    >
      <GraphWrapper showTable={false}>
        <GraphHeader>
          <Title testid='Gender_Demographics' theme={theme}>{title}</Title>
          <p style={{ color: theme == true ?'white' : 'black', margin: '10px 0px', paddingLeft: '25px', fontSize: '12px' }}>Description : {desc}</p>
        </GraphHeader>
        {
          title === 'Gender Demographics' ?
            <GraphLabel theme={theme}>
              <div>
                <span className='male'></span>Male
              </div>
              <div>
                <span className='female'></span>Female
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '15px 1fr', gap: '10px' }}>
                <span className='unknown'></span>Unknown (Includes organizations, brands, or users with unidentifiable gender attributes)
              </div>
            </GraphLabel>
            :
            title === 'Sentiments' &&
            <GraphLabel theme={theme}>
              <div>
                <span className='positive'></span>Positive
              </div>
              <div>
                <span className='negative'></span>Negative
              </div>
              <div>
                <span className='neutral'></span>Neutral
              </div>
            </GraphLabel>
        }
        <GraphBarContainer>
          {graphData.length > 0 && graphData.map((el, index) =>
          (

            <EachRow key={el.label || index}>
              <div>
                <ChannelVal theme={theme}>{el.channel && el.channel == 'twitter' ? ' ' : el?.channel}</ChannelVal>
                <Star theme={theme}>{el.label}</Star>
              </div>
              <PercentageBar theme={theme}>
                {renderPercentageBars(el)}
              </PercentageBar>
            </EachRow>
          ))}
        </GraphBarContainer>

        {/* <DateFontStyle>
          This data is observed from 22/03/2023 to 22/04/2023
        </DateFontStyle> */}
      </GraphWrapper>
      {/* {
        title === 'Sentiments' ?

          <SummaryWrapper>
           not required  {renderSummary()} analysisData?.sentiment?.neutral_sentiment ||
            <ParagraphBold theme={theme}>
            
              Neutral: <ParagraphNormal theme={theme}>{analysisData?.sentiment?.neutral_sentiment ||''}</ParagraphNormal>
            
              not required  Neutral: <ParagraphNormal theme={theme}>{'The highest neutral sentiment of 57.97% for Burger King due to a mix of promotional content, nostalgic references, product feedback, and casual mentions in memes or general conversations, indicating low emotional intensity.'}</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold theme={theme}>
            Positive: <ParagraphNormal theme={theme}>{ analysisData?.sentiment?.positive_sentiment || ''}</ParagraphNormal>
            
            
            not required   Positive: <ParagraphNormal>{':Positive sentiment for Kredit Bee with a value of 4.43% on twitter'}</ParagraphNormal>
            not required   Positive: <ParagraphNormal theme={theme}>{ 'McDonald’s achieved the highest positive sentiment of 25.4% due to engaging campaigns, relatable content, nostalgic references, and active interaction with diverse audiences.'}</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold theme={theme}>

            not required Negative: <ParagraphNormal>{'Negative sentiment for Kredit Bee with a value of 3.88% on twitter'}</ParagraphNormal>
              
              Negative: <ParagraphNormal theme={theme}>{analysisData?.sentiment?.negative_sentiment || ''}</ParagraphNormal>
              not required  Negative: <ParagraphNormal theme={theme}>{'The highest negative sentiment of 28.7% for McDonald’s due to criticizing its policies, employee practices, and association with controversial events and societal issues.'}</ParagraphNormal>
            </ParagraphBold>

          </SummaryWrapper>
          :

          <SummaryWrapper style={style}>
           not required {renderSummary()}
            <ParagraphBold theme={theme}>
              {data[0]?.label}: <ParagraphNormal theme={theme}>{summary1}</ParagraphNormal>
            </ParagraphBold>
            <ParagraphBold theme={theme}>
              {data[1]?.label}: <ParagraphNormal theme={theme}>{summary2}</ParagraphNormal>
            </ParagraphBold>
          </SummaryWrapper>
      } */}
    </GraphMainWrapper>
  );
};

export default Percentage3BarComp;
