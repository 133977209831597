
import React from 'react';
import {TabHeading } from './TabHeading.styled'

const TabHeadingComp = ({activeTab,handleTabClick}) => {
    return (

        <TabHeading>
            <div
                className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('overViewTab')}
            >
                Overviews
            </div>
            <div
                className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('sentimentsTab')}
            >
                Sentiments
            </div>
            <div
                className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('insightTab')}
            >
                Insights
            </div>
            <div
                className={activeTab === 'clusterAnalysis' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('clusterAnalysis')}
            >
                Cluster Analysis
            </div>
            <div
                className={activeTab === 'customAnalysis' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('customAnalysis')}
            >
                Custom Analysis
            </div>
            <div
                className={activeTab === 'reviewsTab' ? 'activePlanTab' : ''}
                onClick={() => handleTabClick('reviewsTab')}
            >
                Posts
            </div>
        </TabHeading>
    );
};


export default TabHeadingComp;