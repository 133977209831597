import React, { useState } from 'react';
import './SidePanel.css';
import {
  QuestionIcon,
  SidePanelIcon,
} from '../../Common/SvgIcon/CommonSvgIcons';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';
import BarGraph from '../../Common/BarGraph/BarGraph';
import StackedBarChart from '../../Common/StackedBarChart/StackedBarChart';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const CircularLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);

  & > div {
    position: fixed;
    top: 50%;
    left: 80%;
  }

  & svg {
    transform: scale(2);
  }
`;
const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
  position: absolute;
  top: 30px;
  left: -76px;
  min-width: 180px;
  max-width: 300px;
  min-height: 30px;
  background: #d0ccff;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: 35%;
    transform: rotate(90deg);
  }
`;
const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;

  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SidePanel = ({
  isOpen,
  setIsOpen,
  pointGraphData,
  date,
  isLoading,
  startDate,
  endDate,
  channel,
  isChatter,
  chatterName,
}) => {
  const colorData = [
    '#353CF7',
    '#4A50F8',
    '#5D63F9',
    '#7277FA',
    '#868AFA',
    '#999DFA',
    '#AEB1FC',
    '#BEC0FC',
    '#CCCEFD',
    '#E1E2FE',
  ];

  // const colorData = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const dataG = pointGraphData?.top_10_keyword_hashtag
    ? pointGraphData?.top_10_keyword_hashtag
    : [];
  const dataA = pointGraphData?.authoritative_score
    ? pointGraphData?.authoritative_score
    : [];

  const sortedDataG = Object.fromEntries(
    Object.entries(dataG).sort(([, a], [, b]) => b - a)
  );
  const sortedDataA = Object.fromEntries(
    Object.entries(dataA).sort(([, a], [, b]) => b - a)
  );
  const graphData = Object?.keys(sortedDataG)?.map((data, index) => {
    return {
      name: data,
      value: dataG[data],
      fill: colorData[index],
    };
  });
  const graphAuthorativeData = Object?.keys(sortedDataA)?.map((data, index) => {
    return {
      name: data,
      value: dataA[data],
      fill: colorData[index],
    };
  });
  function checkLikes(reviews) {
    return reviews.some((review) => review.likes_per !== 0.0);
  }
  const chatterReviews = pointGraphData?.review_liked
    ? !checkLikes(pointGraphData?.review_liked)
      ? []
      : pointGraphData?.review_liked
    : [];

  const sortedChatterReviews = chatterReviews?.sort(
    (a, b) => b.likes_per - a.likes_per
  );

  const chatterReviewGraphData = sortedChatterReviews?.map((data, index) => {
    return {
      name: data?.rating + ' star',
      value: data?.likes_per,
      fill: colorData[index],
    };
  });

  const topKeyWords = pointGraphData?.top_10_words
    ? Object?.values(pointGraphData?.top_10_words)?.every(
        (value) => value === 0
      )
      ? []
      : pointGraphData?.top_10_words
    : [];
  console.log(JSON.stringify(topKeyWords));
  const sortedWords = Object?.fromEntries(
    Object.entries(topKeyWords).sort((a, b) => b[1] - a[1])
  );
  const chatterTopKeyWords = Object?.keys(sortedWords)?.map((data, index) => {
    return {
      name: data,
      value: topKeyWords[data],
      fill: colorData[index],
    };
  });
  const stackedBarGraphData =
    channel !== 'twitter'
      ? [
          {
            name: 'Demographics',
            Male: pointGraphData?.engegement_insights?.demographics
              ?.male_percentage,
            Female:
              pointGraphData?.engegement_insights?.demographics
                ?.female_percentage,
            Unknown:
              pointGraphData?.engegement_insights?.demographics
                ?.unknown_percentage,
          },
          {
            name: 'Sentiments',
            Positive:
              pointGraphData?.engegement_insights?.sentiments
                ?.positive_sentiment,
            Negative:
              pointGraphData?.engegement_insights?.sentiments
                ?.negative_sentiment,
            Neutral:
              pointGraphData?.engegement_insights?.sentiments
                ?.neutral_sentiment,
          },
        ]
      : [
          {
            name: 'Demographics',
            Male: pointGraphData?.engegement_insights?.demographics
              ?.male_percentage,
            Female:
              pointGraphData?.engegement_insights?.demographics
                ?.female_percentage,
            Unknown:
              pointGraphData?.engegement_insights?.demographics
                ?.unknown_percentage,
          },
          {
            name: 'Sentiments',
            Positive:
              pointGraphData?.engegement_insights?.sentiments
                ?.positive_sentiment,
            Negative:
              pointGraphData?.engegement_insights?.sentiments
                ?.negative_sentiment,
            Neutral:
              pointGraphData?.engegement_insights?.sentiments
                ?.neutral_sentiment,
          },
          {
            name: 'Posts with and without media',
            'Posts With Media':
              pointGraphData?.engegement_insights?.post_with_without_media
                ?.post_with_media,
            'Posts Without Media':
              pointGraphData?.engegement_insights?.post_with_without_media
                ?.post_without_media,
          },
        ];
  const [infoTextEngagement, setInfotextEngagement] = useState(false);
  const [infoTop, setInfotextTop] = useState(false);
  const isEngagementDataValid = (engagementInsights) => {
    if (!engagementInsights || typeof engagementInsights !== 'object') {
      return false; // Return false if data is null, undefined, or not an object
    }

    let totalSum = 0;

    // Iterate through all nested objects
    for (const category in engagementInsights) {
      if (typeof engagementInsights[category] === 'object') {
        for (const key in engagementInsights[category]) {
          const value = engagementInsights[category][key];
          if (value == null || value === undefined) {
            return false; // Return false if any value is null or undefined
          }
          if (typeof value === 'number') {
            totalSum += value;
          }
        }
      }
    }

    return totalSum > 0; // Return false if total sum is 0, otherwise true
  };

  return (
    <div>
      <>
        <div className={`side-panel right ${isOpen ? 'open' : ''}`}>
          {isLoading === true && (
            <CircularLoader>
              <div>
                <CircularProgress />
              </div>
            </CircularLoader>
          )}
          <div className='headerContainer'>
            <button className='close-btn' onClick={() => setIsOpen(false)}>
              {SidePanelIcon}
            </button>
            <div className='headerText'>
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <div>
                  {isChatter ? 'Reviews' : 'Engagements'}: {date}
                </div>
                <div
                  onMouseOver={() => setInfotextEngagement(true)}
                  onMouseLeave={() => setInfotextEngagement(false)}
                >
                  <div className='infoIcon'>
                    i
                    {infoTextEngagement &&
                      (isChatter ? (
                        <InfoWrapper>
                          Analyze consumer activity within the selected time
                          period. Discover top keywords, key engagement
                          insights, and review ratings driving interactions.
                        </InfoWrapper>
                      ) : (
                        <InfoWrapper>
                          Track how your audience engaged in the selected time
                          period from {startDate} to {endDate}. Explore key
                          engagement metrics, audience activity, and the topics
                          driving interaction.
                        </InfoWrapper>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='divider' style={{ paddingTop: '20px' }} />
          <div className='metricBox'>
            <div className='metricTableHead'>
              <div className='tableHeaderText'>Metric</div>
              <div className='tableHeaderText'>Stats</div>
              <div className='tableHeaderText'>
                Share of {isChatter ? 'Reviews' : 'Engagement'}
              </div>
            </div>
            <div className='divider' />
            <div className='tableRows'>
              <div className='tableRowText'>
                {isChatter ? 'Reviews' : 'Engagement'}
              </div>
              <div className='tableRowText'>
                {!isLoading
                  ? isChatter
                    ? pointGraphData?.reviews?.total_reviews_date
                      ? pointGraphData?.reviews?.total_reviews_date + ' '
                      : '0' + ' '
                    : pointGraphData?.engagement?.total_engagement_date
                    ? pointGraphData?.engagement?.total_engagement_date + ' '
                    : '0' + ' '
                  : ''}
                out of{' '}
                {!isLoading
                  ? isChatter
                    ? pointGraphData?.reviews?.total_reviews
                    : pointGraphData?.engagement?.total_engagement
                  : ''}
              </div>
              <div className='tableRowText'>
                {!isLoading
                  ? isChatter
                    ? pointGraphData?.reviews?.shares_of_reviews + '%'
                    : pointGraphData?.engagement?.shares_of_engagement + '%'
                  : ''}
              </div>
            </div>
          </div>
          <div className='graphHeader'>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {isChatter ? (
                <div>User Reviews: Top 10 Discussion Keywords</div>
              ) : (
                <div>Top 10 Engaging Keywords & Hashtags</div>
              )}
              <div
                onMouseOver={() => setInfotextTop(true)}
                onMouseLeave={() => setInfotextTop(false)}
              >
                <div className='infoIcon'>
                  i
                  {infoTop &&
                    (isChatter ? (
                      <InfoWrapper>
                        Discover keywords and phrases which sparked the most
                        conversations and how often they were mentioned.
                      </InfoWrapper>
                    ) : (
                      <InfoWrapper>
                        Discover phrases which sparked the most conversations
                        and how often they were used.
                      </InfoWrapper>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {isChatter ? (
            <div className='graphDesc'>
              {`Key Topics Driving In-Depth ${chatterName} Reviews`}
            </div>
          ) : (
            <div className='graphDesc'>
              Key Topics Driving Higher Engagement
            </div>
          )}
          <div className='graphContainer'>
            <BarGraph
              data={isChatter ? chatterTopKeyWords : graphData}
              type={graphData[0]?.name === 'Likes' ? null : null}
              barHeight={'32px'}
              fontSize={'12px'}
              isSidePanel={true}
            />
          </div>
          <div className='graphHeader'>
            <TitleWithInfo
              width={'200px'}
              title='Engagement Insights'
              infoText={
                isChatter
                  ? 'Explore how various consumer demographics interact with your brand and gain insights into their sentiment.'
                  : 'See how different demographics engage with your brand, track sentiment shifts, and the media usage in user conversations.'
              }
            />
          </div>
          <div className='graphDesc'>
            Breakdown by Demographics, Sentiments{' '}
            {!isChatter && '& Media Presence'}
          </div>
          <div className='graphContainer'>
            {isEngagementDataValid(pointGraphData?.engegement_insights) ===
            false ? (
              <NoDataWrapper>
                {/* // <BarsWrapper> */}
                {/* No Data Available */}Insufficient Data
                {/* // </BarsWrapper> */}
              </NoDataWrapper>
            ) : (
              <StackedBarChart data={stackedBarGraphData} channel={channel} />
            )}
          </div>
          <div className='graphHeader'>
            <TitleWithInfo
              width={'200px'}
              title={isChatter ? 'Review Helpfulness' : 'Authorative Score'}
              infoText={
                isChatter
                  ? 'Explore the distribution of reviews to track how consumers rate your brand.'
                  : 'Ranks the top 5 user accounts based on an authoritative score, calculated by combining the popularity score (Shares + Likes) and the reach score (Followers - Following), to identify the most influential accounts.'
              }
            />
          </div>
          <div className='graphContainer'>
            <BarGraph
              data={isChatter ? chatterReviewGraphData : graphAuthorativeData}
              type={isChatter ? '%' : null}
              barHeight={'32px'}
              fontSize={'12px'}
              isSidePanel={true}
            />
          </div>
          <div style={{ height: '40px' }} />
        </div>
        {isOpen && (
          <>
            <div className='overlay' onClick={() => setIsOpen(false)}></div>
          </>
        )}
      </>
    </div>
  );
};

export default SidePanel;
