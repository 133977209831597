import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';
import CorrelationGraph from '../../../components/CorrelationGraph/CorrelationGraph';
import WordCloudAnalysis from '../../../components/InsightsWordCloud/WordCloudAnalysis';
import {
  emoji_neutral,
  emoji_surprise,
  emoji_joy,
  emoji_fear,
  emoji_sadness,
  emoji_anger,
  emoji_disgust,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import SmallSelect from '../../../Common/Select/SmallSelect';
import { parse, format } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';
import MapChart from '../../../components/Maps/WorldMap';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  & .title {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;

const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  .titleContainer {
    margin: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }

  & > p:first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
`;

const FilteredGraphContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 50px;
  align-items: center;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  margin: 28px 0 20px 0;
  text-align: center;
`;

const FunnelGraphContaining = styled.div`
  width: 80%;
  height: 100%;
  margin: 20px;
`;

const GraphContainer = styled.div`
  margin: 10px 0px 0px 20px;
`;
const Insight = ({
  insights_data = {},
  startDate = '',
  endDate = '',
  filteredData,
  filterOption,
  filteredStartDate,
  filteredEndDate,
  view,
  resetFlag,
  channel,
  hideDropdown,
  is_news = false,
  formatValue = "dd-yyyy-MM",
  search_type = ''
}) => {

  const COLOR_RANGE_1 = [
    '#C4C4CD',
    '#87D3F2',
    '#4EBEEB',
    '#35B8E8',
    '#188CE6',
    '#1777CF',
    '#155CB4',
  ];

  const startDateParsed = useParsedStartDate(
    resetFlag,
    filteredStartDate,
    startDate,
    formatValue
  );
  const endDateParsed = useParsedEndDate(
    resetFlag,
    filteredEndDate,
    endDate,
    formatValue
  );

  if (Object.keys(insights_data).length === 0) {
    return (
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        {/* No data available */}Insufficient Data
      </h2>
    );
  }

  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const emotionAnalysis = [
    {
      name: 'Neutral',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].neutral
          : insights_data?.emotion_analysis[0].neutral,
      // fill: '#F5CC61',
      // fill: 'rgb(245, 188, 44)'
      fill: 'rgba(251, 214, 121, 1)',
    },
    {
      name: 'Surprise',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].surprise
          : insights_data?.emotion_analysis[0].surprise,
      // fill: '#B7DC68',
      fill: 'rgba(3, 198, 82, 1)',
    },
    {
      name: 'Joy',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].joy
          : insights_data?.emotion_analysis[0].joy,
      // fill: '#FE00D5',
      fill: 'rgba(218, 6, 184, 1)',
    },
    {
      name: 'Fear',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].fear
          : insights_data?.emotion_analysis[0].fear,
      // fill: '#FC9E58',
      fill: 'rgba(255, 153, 0, 1)',
    },
    {
      name: 'Sadness',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].sadness
          : insights_data?.emotion_analysis[0].sadness,
      // fill: '#4B9AE9',
      fill: 'rgba(21, 112, 203, 1)',
    },
    {
      name: 'Anger',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].anger
          : insights_data?.emotion_analysis[0].anger,
      // fill: '#F74945',
      fill: 'rgba(238, 19, 7, 1)',
    },
    {
      name: 'Disgust',
      value:
        resetFlag === true && filteredData
          ? filteredData?.emotion_analysis[0].disgust
          : insights_data?.emotion_analysis[0].disgust,
      // fill: '#9071CE',
      fill: 'rgba(84, 30, 192, 1)',
    },
  ];


  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={emotionAnalysis} maxWidth={'70%'} type={'%'} 
        title={'Emotional_Analysis'}
        summary={resetFlag === true && filteredData ? filteredData?.emotion_summary : insights_data?.emotion_summary} />
      ) : (
        <BarGraph
          data={emotionAnalysis}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
          barHeightHover={'40px'}
          emojiList={{
            neutral: emoji_neutral,
            surprise: emoji_surprise,
            joy: emoji_joy,
            fear: emoji_fear,
            sadness: emoji_sadness,
            anger: emoji_anger,
            disgust: emoji_disgust,
            length: 2,
          }}
          title={'Emotional_Analysis'}
          summary={resetFlag === true && filteredData ? filteredData?.emotion_summary : insights_data?.emotion_summary}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart data={emotionAnalysis} width={500} type='percentage' 
      title={'Emotional_Analysis'}
      summary={resetFlag === true && filteredData ? filteredData?.emotion_summary : insights_data?.emotion_summary} />
    ),
    'Donut Chart': <DonutChart data={emotionAnalysis} type='percentage' 
    title={'Emotional_Analysis'}
    summary={resetFlag === true && filteredData ? filteredData?.emotion_summary : insights_data?.emotion_summary} />,
    'Funnel Chart': (
      <FunnelGraph data={emotionAnalysis} maxWidth={'80%'} type={'%'} 
      title={'Emotional_Analysis'}
      summary={resetFlag === true && filteredData ? filteredData?.emotion_summary : insights_data?.emotion_summary} />
    ),
  };

  const ratingColors = ['#676DFF', '#898DFB', '#ACAFFC', '#C5C6F3', '#D5D5DC'];

  function convertObjectToBarGraphData(object = {}) {
    const barGraphData = Object.entries(object).map(([name, value]) => ({
      name,
      value: value || 0,
    }));
    const sortData = barGraphData.sort((a, b) => b.value - a.value);
    const dataWithColors = sortData.map((item, index) => ({
      ...item,
      fill: ratingColors[index],
    }));
    return dataWithColors;
  }

  function checkAndConvert(obj) {
    // Check if there's only one key and that key is an empty string, with a value of 0
    if (
      obj &&
      typeof obj === 'object' &&
      Object.keys(obj)?.length === 1 &&
      (obj?.hasOwnProperty('') || obj?.hasOwnProperty(' ')) &&
      (obj[''] === 0 || obj[' '] === 0)
    ) {
      return []; // Return an empty array
    }
    return convertObjectToBarGraphData(obj); // Otherwise, return the original object
  }
  const mostAuthoritativeData =
    checkAndConvert(
      resetFlag === true && filteredData
        ? filteredData?.most_authoritative
        : insights_data?.most_authoritative
    ) || [];

  const mostMentionedFunc = () => {

    // Determine which object to use based on resetFlag
    let obj = resetFlag === true && filteredData ? filteredData?.most_mentioned : insights_data?.most_mentioned;

    // Add "@" symbol to each key of the object
    const taggedObj = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [`@${key}`, value])
    );

    // Call checkAndConvert function with the modified object
    return checkAndConvert(taggedObj) || [];

  }
  const mostMentionedData = mostMentionedFunc() || [];

  const renderMostAuthoritative = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={mostAuthoritativeData}
          maxWidth={'70%'}
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={mostAuthoritativeData}
          maxWidth={
            mostAuthoritativeData && mostAuthoritativeData.length > 0
              ? '70%'
              : '80%'
          }
          barHeight={'32px'}
        />
      ),

    'Pie Chart': (
      <RatingDonutChart
        data={mostAuthoritativeData}
        width={500}
        maxWidth={
          mostAuthoritativeData && mostAuthoritativeData.length > 0
            ? '100%'
            : '80%'
        }
      />
    ),
    'Donut Chart': (
      <DonutChart
        data={mostAuthoritativeData}
        maxWidth={
          mostAuthoritativeData && mostAuthoritativeData.length > 0
            ? '100%'
            : '80%'
        }
      />
    ),
    'Funnel Chart': (
      <FunnelGraph data={mostAuthoritativeData} maxWidth={'80%'} />
    ),

  };
  const renderMostmentioned = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={mostMentionedData}
          maxWidth={
            mostMentionedData && mostMentionedData.length > 0 ? '70%' : '80%'
          }
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={mostMentionedData}
          maxWidth={
            mostMentionedData && mostMentionedData.length > 0 ? '70%' : '80%'
          }
          barHeight={'32px'}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={mostMentionedData}
        width={500}
        maxWidth={
          mostMentionedData && mostMentionedData.length > 0 ? '100%' : '80%'
        }
      />
    ),
    'Donut Chart': (
      <DonutChart
        data={mostMentionedData}
        maxWidth={
          mostMentionedData && mostMentionedData.length > 0 ? '100%' : '80%'
        }
      />
    ),
    'Funnel Chart': <FunnelGraph data={mostMentionedData} maxWidth={'80%'} />,
  };
  return (
    <Wrapper>
      <WordCloudAnalysis
        showSwitchTab={false}
        insightsData={
          resetFlag === true && filteredData ? filteredData : insights_data
        }
        hideDropdown={hideDropdown}
        startDate={startDateParsed}
        endDate={endDateParsed}
        formatValue={formatValue}
        channel={channel}
        showKeyWordDropDown={false}
      />
      
      {channel == 'news' || search_type == "user" ? '' :
        // channel == 'instagram'  ? ''
        // :
        <EmotionAnalysisWrapper>
          <GraphHOC
            formatValue={formatValue}
            title={'Most Authoritative'}
            options={options}
            renderScreen={renderMostAuthoritative}
            graphWidth={'100%'}
            margin={mostAuthoritativeData && mostAuthoritativeData.length > 0 ? '20px 0px 20px 50px' : '20px 0px 20px 0px'}
            option={filterOption}
            hideDropdown={hideDropdown}
            startDate={startDateParsed}
            endDate={endDateParsed}
            infoText='Ranks the top 5 user accounts based on an authoritative score, calculated by combining the popularity score (Shares + Likes) and the reach score (Followers - Following), to identify the most influential accounts.'

          />
        </EmotionAnalysisWrapper>
      }

      {
        channel == 'news' ? '' :
          <EmotionAnalysisWrapper>
            <GraphHOC
              formatValue={formatValue}
              title={'Most Mentioned'}
              options={options}
              renderScreen={renderMostmentioned}
              graphWidth={'100%'}
              margin={mostMentionedData && mostMentionedData.length > 0 ? '20px 0px 20px 50px' : '20px 0px 20px 0px'}
              option={filterOption}
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              infoText={channel === 'instagram' ?
                'Track the top 5 user handles mentioned across comments, highlighting the most referenced accounts.'
                :
                channel === 'facebook' ?
                  'Track the top 5 user handles mentioned across posts, highlighting the most referenced accounts.'
                  :
                  'Track the top 5 user handles mentioned across posts, highlighting the most referenced accounts.'
              }

            />
          </EmotionAnalysisWrapper>
      }

      <EmotionAnalysisWrapper>
        <GraphHOC
          formatValue={formatValue}
          title={'Emotion Analysis'}
          options={options}
          renderScreen={renderScreen}
          graphWidth={'100%'}
          margin={'20px 0px 20px 50px'}
          startDate={startDateParsed}
          endDate={endDateParsed}
          option={filterOption}
          hideDropdown={hideDropdown}
          infoText={channel === 'instagram' ?
            'The percentage distribution of seven unique user emotions expressed in comments.' :
            channel === 'facebook' ?
              'The percentage distribution of seven unique user emotions expressed in posts.'
              :
              'The percentage distribution of seven unique user emotions expressed in posts.'

          }
        />
      </EmotionAnalysisWrapper>
      {/* )} */}

      <div style={{ marginTop: '30px' }}>
        <CorrelationGraph
          RatingLabels={[
            'Engagement',
            'Anger',
            'Neutral',
            'Sadness',
            'Disgust',
            'Fear',
            'Joy',
            'Surprise',
          ]}
          correlationAnalysisData={
            filteredData && resetFlag === true
              ? filteredData?.correlation_analysis
              : insights_data?.correlation_analysis
          }
          correlation_summary={
            
            filteredData && resetFlag === true
              ? filteredData?.correlation_summary
              : insights_data?.correlation_summary
            }
          hideDropdown={hideDropdown}
          startDate={startDateParsed}
          endDate={endDateParsed}
          formatValue={formatValue}
        />
      </div>
      {(is_news == true || channel === 'twitter') && (
        <div style={{ marginTop: '30px' }}>
          <MapChart
            mapData={
              filteredData ? filteredData?.map_data : insights_data?.map_data
            }
            channel={channel}
            // text={`The majority of the engagers were from ${stateWithHighestReviews}`}
            colorRange={COLOR_RANGE_1}
            startDate={startDateParsed}
            endDate={endDateParsed}
            infoText={
              'Track the total number of posts, likes, replies, views, and reposts across different geographies, offering a global view of user engagement.'
            }
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Insight;
