import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';
import ChatterService from '../services/chatter.services';
import { setChatterPreviewData } from './previewDataSlice';

export const productDetailList = createAsyncThunk(
  'productDetailList',
  async ({ searchQuery, selectedCountry }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.ProductListAmazon(
        searchQuery,
        selectedCountry,
        
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const productDetailFlipkart = createAsyncThunk(
  'productDetailFlipkart',
  async ({ searchQuery, selectedCountry }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.ProductListFlipkart(
        searchQuery,
        selectedCountry,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
      );
      return { data };
    } catch (error) {
      console.log(error,'error');
      
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const chatterPreview = createAsyncThunk(
  'chatterPreview',
  async (
    { productUrl, channelName, chatterTitle, startDate, endDate ,description},
    thunkAPI
  ) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.ChatterPreview(
        productUrl,
        channelName,
        chatterTitle,
        startDate,
        endDate,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        description,
      );
      thunkAPI.dispatch(setChatterPreviewData(data));
      
    
    thunkAPI.dispatch(setMessage('Chatter Preview has been Successfully Created'));
      return { data };
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsForChatterPreview = createAsyncThunk(
  'reviewsForCreateChatter',
  async ({dates,preview_uuid}, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.reviewsForChatterPreview(
        dates,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        preview_uuid
      );
      return { data };
    } catch (error) {
      const message =
      
      (error?.response &&
        error?.response?.data &&
        error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const chatterReviewsForDownload = createAsyncThunk(
  'chatterReviewsForDownload',
  async (api, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.chatterReviewsForDownload(
        brandUser?.tenant_id,
        api.job_id,
        api.email_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
)

export const reviewsForChatter = createAsyncThunk(
  'reviewsForChatter',
  async (job_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.reviewsForChatter(
        brandUser?.tenant_id,
        job_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterChatter = createAsyncThunk(
  'reviewsFilterChatter',
  async ({ dates, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.reviewsFilterChatter(
        brandUser?.tenant_id,
        job_id,
        dates
      );
      return { data };
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.detail?.[0]?.msg) ||

        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterChatterPreview = createAsyncThunk(
  'reviewsFilterChatterPreview',
  async ({ dates, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.reviewsFilterChatterPreview(
        brandUser?.tenant_id,
        job_id,
        dates
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const createChatter = createAsyncThunk(
  'createChatter',
  async (
    { productUrl, channelName, chatterTitle, startDate, endDate },
    thunkAPI
  ) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.CreateChatter(
        productUrl,
        channelName,
        chatterTitle,
        startDate,
        endDate,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      thunkAPI.dispatch(setMessage('Chatter is successfully created.'));
      return { data };
    } catch (error) {
      const message =
      
      (error?.response &&
        error?.response?.data &&
        error?.response?.data?.detail) ||  
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const chatterList = createAsyncThunk(
  'chatterList',
  async (_, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.ChatterList(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      console.log(error,"error");
      
      const message =
      (error?.response &&
        error?.response?.data &&
        error?.response?.data?.detail) ||  

        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getReport = createAsyncThunk(
  'getReport',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await ChatterService.GetReport(brandUser?.tenant_id, jobId);

      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const CustomAnalysis = createAsyncThunk(
  'CustomAnalysis',
  async ({ searchQuery, jobId, uuid, channelName }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.CustomAnalysisService(
        searchQuery,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId,
        uuid,
        channelName
      );
      /* const dat = response; */
      return { response };
    } catch (error) {
      const message =
      
      (error.response &&
        error.response.data &&
        error.response.data.detail?.[0]?.msg) ||  
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const CustomAnalysisResponse = createAsyncThunk(
  'CustomAnalysisResponse',
  async ({ is_table, searchQuery, jobId, uuid, channelName }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.CustomAnalysis(
        is_table,
        searchQuery,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId,
        uuid,
        channelName
      );
      /* const dat = response; */
      return { response };
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.detail?.[0]?.msg) ||   
      (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const ChatterHibernate = createAsyncThunk(
  'ChatterHibernate',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.ChatterHibernate(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      
      thunkAPI.dispatch(setMessage('Chatter hibernated successfully.'));
      return { response };
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error?.response?.data?.detail) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ChatterDelete = createAsyncThunk(
  'ChatterDelete',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.ChatterDelete(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      thunkAPI.dispatch(setMessage('Chatter deleted successfully.'));
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const ChatterActive = createAsyncThunk(
  'ChatterActive',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.ChatterActive(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      
      thunkAPI.dispatch(setMessage('Chatter activated successfully.'));
      return { response };
    } catch (error) {
      const message =
        
      (error.response &&
        error.response.data &&
        error?.response?.data?.detail) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const FilterReportBasedOnDatesChatter = createAsyncThunk(
  'FilterReportBasedOnDates',
  async ({ startDate, endDate, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await ChatterService.GetReportBasedOnDateChatter(
        startDate,
        endDate,
        brandUser?.tenant_id,
        job_id
      );
      return { response };
    } catch (error) {
      console.log(error,"err");
      
      const message =
      (error?.response &&
        error?.response?.data &&
        error?.response?.data.detail?.[0]?.msg) || 

        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  productDetailsData: null,
  productListFlipkart: null,
  chatterPreviewData: null,
  customAnalysisData: null,
  customAnalysisResult: null,
  chatterLoading: false,
  reviewsForChatterData: null,
  createChatterData: null,
  isLoading: false,
  isImageLoading: false,
  chatterListDate: null,
  reportData: null,
  chatterData: null,
  error: null,
  filteredChatterReport: null,
  searchQuery:null,
  selectedDraft:null
};

const ChatterTrackerSlice = createSlice({
  name: 'chatterTracker',
  initialState,
  reducers: {
    updateSearchQuery:(state,action) => {
      state.searchQuery = action.payload
    },
    searchQueryEmpty: (state,action) => {
      state.searchQuery = null
    },
    updateSelectedDraft:(state,action) => {
      state.selectedDraft = action.payload
    },
    selectedDraftEmpty: (state,action) => {
      state.selectedDraft = null
    },
    
    emptyCustomAnalysis : (state,action) =>{
      state.customAnalysisData = null
    },
    emptyCustomAnalysisResult : (state,action) =>{
      state.customAnalysisResult = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(productDetailList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(productDetailList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productDetailsData = action.payload.data;
      })
      .addCase(productDetailList.rejected, (state,action) => {
        state.isLoading = false;
        state.productDetailsData = action.payload;
      })
      .addCase(productDetailFlipkart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(productDetailFlipkart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productListFlipkart = action.payload.data;
      })
      .addCase(productDetailFlipkart.rejected, (state,action) => {
        state.isLoading = false;
        state.productListFlipkart = action.payload;
      })
      .addCase(chatterPreview.pending, (state) => {
        state.chatterLoading = true;
        state.error = null;
      })
      .addCase(chatterPreview.fulfilled, (state, action) => {
        state.chatterLoading = false;
        state.chatterPreviewData = action.payload;
      })
      .addCase(chatterPreview.rejected, (state, action) => {
        state.chatterLoading = false;
        state.chatterPreviewData = action.payload;
      })
      .addCase(reviewsForChatterPreview.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(reviewsForChatterPreview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewsForChatterData = action.payload.data;
      })
      .addCase(reviewsForChatterPreview.rejected, (state) => {
        state.isLoading = false;
        state.reviewsForChatterData = null;
      })
      .addCase(createChatter.fulfilled, (state, action) => {
        state.chatterLoading = false;
        state.createChatterData = action.payload;
        state.chatterData = action.payload.data;
      })
      .addCase(createChatter.rejected, (state, action) => {
        state.chatterLoading = false;
        state.createChatterData = action.payload;
      })
      .addCase(createChatter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(chatterList.fulfilled, (state, action) => {
        state.chatterLoading = false;
        state.chatterListDate = action.payload.data;
      })
      .addCase(chatterList.rejected, (state, action) => {
        state.chatterLoading = false;
        state.chatterListDate = null;
      })
      .addCase(chatterList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportData = action.payload.data;
      })
      .addCase(getReport.rejected, (state, action) => {
        state.isLoading = false;
        state.reportData = null;
      })
      .addCase(getReport.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(CustomAnalysis.pending, (state) => {
        state.isImageLoading = true;
        state.error = null;
      })
      .addCase(CustomAnalysis.fulfilled, (state, action) => {
        state.isImageLoading = false;
        console.log(action.payload,'action.payload');
        
        state.customAnalysisData = action.payload.response;
      })
      .addCase(CustomAnalysis.rejected, (state, action) => {
        state.isImageLoading = false;
        state.customAnalysisData = null;
      })
      
      .addCase(CustomAnalysisResponse.pending, (state) => {
        state.isImageLoading = true;
        state.error = null;
      })
      .addCase(CustomAnalysisResponse.fulfilled, (state, action) => {
        state.isImageLoading = false;
        console.log(action.payload,"action.payload");
        
        state.customAnalysisResult = action.payload.response;
      })
      .addCase(CustomAnalysisResponse.rejected, (state, action) => {
        state.isImageLoading = false;
        state.customAnalysisResult = null;
      })
      .addCase(reviewsForChatter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(reviewsForChatter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewsForChatterData = action.payload.data;
      })
      .addCase(reviewsForChatter.rejected, (state) => {
        state.isLoading = false;
        state.reviewsForChatterData = null;
      })
      .addCase(chatterReviewsForDownload.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(chatterReviewsForDownload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewsForChatterData = action.payload.data;
      })
      .addCase(chatterReviewsForDownload.rejected, (state) => {
        state.isLoading = false;
        state.reviewsForChatterData = null;
      })
      .addCase(reviewsFilterChatter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewsForChatterData = action.payload.data;
      })
      .addCase(reviewsFilterChatter.rejected, (state) => {
        state.isLoading = false;
        state.reviewsForChatterData = null;
      })

      .addCase(reviewsFilterChatterPreview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewsForChatterData = action.payload.data;
      })
      .addCase(reviewsFilterChatterPreview.rejected, (state) => {
        state.isLoading = false;
        state.reviewsForChatterData = null;
      })


      .addCase(FilterReportBasedOnDatesChatter.rejected, (state, action) => {
        state.filteredChatterReport = null;
      })
      .addCase(FilterReportBasedOnDatesChatter.fulfilled, (state, action) => {
        state.filteredChatterReport = action.payload.data;
      });
  },
});

export const {
  updateSelectedDraft,
  selectedDraftEmpty,
  updateSearchQuery,
  searchQueryEmpty,
  emptyCustomAnalysis,
  emptyCustomAnalysisResult
} = ChatterTrackerSlice.actions;

export default ChatterTrackerSlice.reducer;
