import React, { useState } from 'react';
import BarGraph from '../../../../Common/BarGraph/BarGraph';
import GraphHOC from '../ReportDownload/GraphHOC';
import RatingDonutChart from '../../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../../Common/FunnelGraph/FunnelGraph';
import SmallSelect from '../../../../Common/Select/SmallSelect';
import VerticalBarGraph from '../../../../Common/BarGraph/VarticalBargraph';
import {
  Wrapper,
  TopHashtagsWrapper,
  TopRepostWrapper,
  FilteredGraphContainer,
  PostCountWrapper,
  Circle,
  PositiveCircle,
  NeutralCircle,
  NegativeCircle,
  TopPostWrapper,
  SentimentDrilldownWrapper,
  TopPostTopicWrapper,
  TopPostTopicHeading,
  SelectWrapper,
  Footer,
  FilterContainer
} from './Sentiment.styled'
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';

const Sentiments = ({
  sentiments_tab = {},
  startDate = '',
  endDate = '',
  filterOption,
  filteredData,
  filteredStartDate,
  filteredEndDate,
  view,
  resetFlag,
  child,
  hideDropdown,
  is_news = false,
  formatValue
}) => {


  const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
  const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);


  const [topTopicCount, setTopTopicCount] = useState({
    positive: 'Topic 1',
    negative: 'Topic 1',
    neutral: 'Topic 1',
  });
  if (Object.keys(sentiments_tab?.data?.sentiments_tab || {}).length === 0) {
    return (
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        {/* No data available */}Insufficient Data
      </h2>
    );
  }

  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const percentageValue = resetFlag === true && filteredData
    ? filteredData?.sentiment_drilldown?.graph_data
    : sentiments_tab?.data?.sentiments_tab?.sentiment_drilldown;

  const sentimentsData = [
    {
      name: 'Positive',
      value: parseFloat(percentageValue?.positive_sentiment),
      fill: '#A1FCC6',
    },
    {
      name: 'Negative',
      value: parseFloat(percentageValue?.negative_sentiment),
      fill: '#FF8C86',
    },
    {
      name: 'Neutral',
      value: parseFloat(percentageValue?.neutral_sentiment),
      fill: '#FFE092',
    },
  ];
  const ratingColors = ['#676DFF', '#898DFB', '#ACAFFC', '#C5C6F3', '#D5D5DC'];

  function convertObjectToBarGraphData(object = {}) {
    const barGraphData = Object.entries(object).map(([name, value]) => ({
      name,
      value: value,
    }));
    const sortData = barGraphData.sort((a, b) => b.value - a.value);
    const dataWithColors = sortData.map((item, index) => ({
      ...item,
      fill: ratingColors[index],
    }));
    return dataWithColors;
  }
  function checkAndConvert(object = {}) {
    if (is_news) {
      return [{
        name: 'Total Positive Posts',
        value: sentiments_tab?.data?.sentiments_tab?.sentiment_bar_chart?.total_positive_posts || 0,
        fill: 'rgba(92, 227, 144, 1)',
      },
      {
        name: 'Total Negative Posts',
        value: sentiments_tab?.data?.sentiments_tab?.sentiment_bar_chart?.total_negative_posts || 0,
        fill: 'rgba(252, 103, 88, 1)',
      },
      {
        name: 'Total Neutral Posts',
        value: sentiments_tab?.data?.sentiments_tab?.sentiment_bar_chart?.total_neutral_posts || 0,
        fill: 'rgba(245, 166, 35, 1)',
      }]
    } else {

      const hashtaggedObj = (object != null || object != undefined) && Object.fromEntries(
        Object.entries(object)?.map(([key, value]) => [`#${key}`, value])
      );

      if (
        hashtaggedObj &&
        typeof hashtaggedObj === 'object' &&
        Object.keys(hashtaggedObj).length === 1 &&
        hashtaggedObj.hasOwnProperty('') &&
        hashtaggedObj[''] === 0
      ) {
        return []; // Return an empty array
      }
      // Check if there's only one key and that key is an empty string, with a value of 0
      if (hashtaggedObj && typeof hashtaggedObj === 'object') {
        // Check if there's only one key and that key is an empty string, with a value of 0

        return convertObjectToBarGraphData(hashtaggedObj); // Otherwise, return the original object
      }
    }
    // const barGraphData = Object.entries(object).map(([name, value, fill]) => ({
    //   name,
    //   value: value,
    //   fill: '#353CF7',
    // }));

    // return barGraphData;
    // }
  }

  const topHastagsData =
    checkAndConvert(
      resetFlag === true && filteredData
        ? filteredData?.top_five_hashtag
        : sentiments_tab?.data?.sentiments_tab?.top_five_hashtag
    ) || [];

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={topHastagsData} />
      ) : (
        <BarGraph data={topHastagsData} maxWidth={'60%'} />
      ),
    'Pie Chart': <RatingDonutChart data={topHastagsData} width={500} />,
    'Donut Chart': <DonutChart data={topHastagsData} />,
    'Funnel Chart': <FunnelGraph data={topHastagsData} maxWidth={'50%'} />,
  };

  const sentimentsRenderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={sentimentsData} type={'%'} />
      ) : (
        <BarGraph data={sentimentsData} maxWidth={'60%'} type={'%'} />
      ),
    'Pie Chart': (
      <RatingDonutChart data={sentimentsData} width={500} type='percentage' />
    ),
    'Donut Chart': (
      <DonutChart data={sentimentsData} sentiment={true} type='percentage' />
    ),
    'Funnel Chart': (
      <FunnelGraph data={sentimentsData} maxWidth={'50%'} type={'%'} />
    ),
  };

  const renderTopics = (topicStrings, type) => {
    if (!topicStrings || Object.keys(topicStrings).length === 0) {
      return null
    };
    // if (topicData.length === 0) return null;

    const topicData = Object.keys(topicStrings);
    const topicCount = parseInt(topTopicCount[type]);

    // const topicKeys = topicData.slice(0, topicCount);

    const topicKeys = topicData;
    // .filter(
    //   (key, index) => index < topTopicCount[type].split(' ')[1]
    // );

    return topicKeys.map((key, index) => (
      <div key={key}>
        {index + 1}. {topicStrings[key]}
      </div>
    ));
  };

  function formatNumber(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M';
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    } else if (val > 0) {
      return val.toString();
    } else if (val == 0) {
      return 0;
    } else {
      return '';
    }
  }
  

  const filteredValue = child?.length > 0 && child[0]?.child?.reduce((acc, val) => {
    if (val.value === true) {
      acc = val.name;
    }
    return acc;
  }, undefined);

  const filteredHashtagValue = child?.length > 0 && child[1]?.child?.reduce((acc, val) => {
    if (val.value === true) {
      acc = val.name;
    }
    return acc;
  }, undefined);

  
  return (
    <Wrapper>
      <SentimentDrilldownWrapper
        className="pdf-page"
        style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}>
        {filterOption ? (
          <FilterContainer>
            <span>Sentiment Drilldown</span>
            {/* <div style={{ display: 'flex' }}>{filteredSentimentDrillDown}</div> */}
          </FilterContainer>
        ) : (
          child?.length > 0 && child[0].value == true &&
          <GraphHOC
            title={'Sentiment Drilldown'}
            options={filteredValue ? filteredValue : options[0]}
            renderScreen={sentimentsRenderScreen}
            graphWidth={'100%'}
            margin={'20px 0px 20px 50px'}
            option={child[0]?.child?.find(i => i?.value === true)?.name}
            hideDropdown={hideDropdown}
          />
        )}

        <TopPostTopicWrapper
          className="pdf-page"
          style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
        >
          <div>
            <TopPostTopicHeading className='positiveTopic'>
              Positive Sentiments The posts were majorly related to:
            </TopPostTopicHeading>
            
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.data?.sentiments_tab?.topic_analysis?.positive_topics,
                'positive'
              ) || 'Insufficient Data'}
            </div>
          </div>
        </TopPostTopicWrapper>
        <TopPostTopicWrapper className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
        }}>
          <div>
            <TopPostTopicHeading className='neutralTopic'>
              Neutral Sentiments The posts were majorly related to:
            </TopPostTopicHeading>
            
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.data?.sentiments_tab?.topic_analysis
                  ?.neutral_topics,
                'neutral'
              ) || 'Insufficient Data'}
            </div>
          </div>
        </TopPostTopicWrapper>
        <TopPostTopicWrapper
          className="pdf-page"
          style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
        >
          <div>
            <TopPostTopicHeading className='negativeTopic'>
              Negative Sentiments The posts were majorly related to:
            </TopPostTopicHeading>
           
            <div className='topicList'>
              {renderTopics(
                sentiments_tab?.data?.sentiments_tab?.topic_analysis
                  ?.negative_topics,
                'negative'
              ) || 'Insufficient Data'}
            </div>
          </div>
        </TopPostTopicWrapper>
        <Footer>
          This data is observed from {formattedDate} to {endDateParsed}
        </Footer>
      </SentimentDrilldownWrapper>
      {child?.length > 0 && child[1]?.value == true && child[1]?.display == true &&
        <TopHashtagsWrapper className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
        }}>
          <GraphHOC
            title={`Top Hashtags`}
            options={filteredHashtagValue ? filteredHashtagValue : options[0]}
            renderScreen={renderScreen}
            graphWidth={'100%'}
            margin={'20px 0px 20px 50px'}
            startDate={formattedDate}
            endDate={endDateParsed}
            option={child[1]?.child?.find(i => i?.value === true)?.name}
            hideDropdown={hideDropdown}
          />
        </TopHashtagsWrapper>
      }
      {/* // )} */}
      {
        child?.length > 0 && child[2]?.value == true && child[2]?.display == true &&

        <TopRepostWrapper className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
        }}>
          <p className='title'>

            <TitleWithInfo
              title={
                sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel == 'facebook'
                  ? 'Top Comments'
                  : 'Top Reposts'
              }
              infoText={
                sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel == 'facebook' ?
                  `Track the most liked comments based on sentiment—positive, negative, and neutral—providing a snapshot of key audience feedback.`
                  : `Track the most liked posts based on sentiment—positive, negative, and neutral—providing a snapshot of key audience feedback.`
              }
            />

          </p>
          <PostCountWrapper
            style={{
              display:
                sentiments_tab?.channel == 'instagram' || sentiments_tab?.channel == 'facebook' ? 'none' : '',
            }}
          >
            <div >
              <PositiveCircle />
              {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                `Total Positive Posts - ${filteredData
                  ? filteredData?.top_posts?.total_positive_posts
                  : sentiments_tab?.data?.sentiments_tab?.top_posts?.total_positive_posts
                }`
                :

                `Total Positive Reposts - ${filteredData
                  ? filteredData?.top_reposts?.total_positive_posts
                  : sentiments_tab?.data?.sentiments_tab?.top_reposts?.total_positive_posts
                }`
              }
            </div>
            <div>
              <NegativeCircle />
              {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Total Negative Posts -' : 'Total Negative Reposts -'}
              {/* Total Negative Reposts - */}
              {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                sentiments_tab?.data?.sentiments_tab?.top_posts?.total_negative_posts
                : sentiments_tab?.data?.sentiments_tab?.top_reposts?.total_negative_posts
              }
            </div>
            <div>
              <NeutralCircle />
              {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Total Neutral Posts -' : 'Total Neutral Reposts -'}
              {
                sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                  sentiments_tab?.data?.sentiments_tab?.top_posts?.total_neutral_posts
                  :
                  sentiments_tab?.data?.sentiments_tab?.top_reposts?.total_neutral_posts
              }
            </div>
          </PostCountWrapper>
          <TopPostWrapper>
            <div className='positiveReview'>
              <PositiveCircle />
              <div className='reviewText'>
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Top Positive Comment' : 'Top Positive Repost'}
                <br />
                <br />
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook'
                  ? sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments?.comment_text ||( sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments?.post_link ? '' : 'Insufficient Data')
                  : sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts?.post_text || (sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts?.link ? '' : 'Insufficient Data')}

                <br />
                <br />
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                  sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments?.post_link
                  : sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts?.link

                }


              </div>
              {(sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments?.comment_text || sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments?.post_link || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts?.post_text || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts?.link)

                ? <div className='reviewCount'>
                  {
                    sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                      formatNumber(

                        sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_positive_comments
                          ?.likes_count)
                      :
                      formatNumber(sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_positive_posts
                        ?.post_interaction
                      )
                      || ''}
                  <div className='peopleLiked'>
                    People <br /> {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Liked' : 'Reposted'}
                  </div>
                </div>
                :
                <></>
              }
            </div>
            <div className='negativeReview'>
              <NegativeCircle />
              <div className='reviewText'>
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Top Negative Comment' : 'Top Negative Repost'}
                <br />
                <br />
                {
                  sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                    sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments?.comment_text || (sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments?.post_link ? '' : 'Insufficient Data')
                    : sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts?.post_text ||( sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts?.link ? '' : 'Insufficient Data')}

                <br />
                <br />
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                  sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments?.post_link :
                  sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts?.link
                }

              </div>
              {
                sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments?.comment_text || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts?.post_text || sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments?.post_link || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts?.link ?

                  <div className='reviewCount'>
                    {
                      sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                        formatNumber(
                          sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_negative_comments
                            ?.likes_count
                        )
                        :
                        formatNumber(
                          sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_negative_posts
                            ?.post_interaction
                        )
                        || ''}
                    <div className='peopleLiked'>
                      People <br /> {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Liked' : 'Reposted'}
                    </div>
                  </div>
                  :
                  <></>
              }
            </div>
            <div className='neutralReview'>
              <NeutralCircle />
              <div className='reviewText'>
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Top Neutral Comment' : 'Top Neutral Reposts'}

                <br />
                <br />
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                  sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments?.comment_text || (sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments?.post_link ? '' : 'Insufficient Data')
                  : sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts?.post_text || (sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts?.link ? '' : 'Insufficient Data')}


                <br />
                <br />
                {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ?
                  sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments?.post_link
                  :
                  sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts?.link

                }
              </div>
              {
                sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments?.comment_text || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts?.post_text || sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments?.post_link || sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts?.link ?

                  <div className='reviewCount'>
                    {
                      sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook'
                        ?
                        formatNumber(
                          sentiments_tab?.data?.sentiments_tab?.top_liked_comments?.top_neutral_comments
                            ?.likes_count
                        )
                        :
                        formatNumber(
                          sentiments_tab?.data?.sentiments_tab?.top_reposts?.top_neutral_posts
                            ?.post_interaction
                        )
                        || ''}
                    <div className='peopleLiked'>
                      People <br /> {sentiments_tab?.channel === 'instagram' || sentiments_tab?.channel === 'facebook' ? 'Liked' : 'Reposted'}
                    </div>
                  </div>
                  :
                  <></>
              }

            </div>
          </TopPostWrapper>
          <Footer>
            This data is observed from {formattedDate} to {endDateParsed}
          </Footer>
        </TopRepostWrapper>
      }
      {
        child?.length > 0 && child?.[3]?.value == true && child[3]?.display == true &&
        <TopHashtagsWrapper className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
        }}>
          <GraphHOC
            title={`Sentiments Bar Chart`}
            options={filteredHashtagValue ? filteredHashtagValue : options[0]}
            renderScreen={renderScreen}
            graphWidth={'100%'}
            margin={'20px 0px 20px 50px'}
            startDate={formattedDate}
            endDate={endDateParsed}
            option={child?.[3]?.child?.find(i => i?.value === true)?.name}
            hideDropdown={hideDropdown}
          />
        </TopHashtagsWrapper>
      }
    </Wrapper>
  );
};

export default Sentiments;
