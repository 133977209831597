import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PopUp from '../../Common/GenerateAnalysisPopup/GenerateAnalysisPopup';
import Button from '../../Common/Button/Button';
import { useNavigate } from 'react-router-dom';

import { shareReport } from '../../store/redux/slices/sentinelHubSlice';

import { useSelector, useDispatch } from 'react-redux';
import { use } from 'react';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  // padding-bottom: 100px;

  box-sizing: border-box;
  
  display: grid;
  grid-template-rows: 10% 10% 68% 12%;
  grid-template-columns: 1fr;
  padding: 20px 20px 15px;
}
`;

const Title = styled.div`
  text-align: left;
  color: #636363;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  word-break: break-all;
`;

const TabHeading = styled.div`
  border-bottom: 1px solid #d2d2d2;
  // margin-top: 20px;

  display: flex;
  justify-content: space-between;
  width: 65%;
  gap: 5px;

  & > div {
    color: #353cf7;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;

const CheckBoxWrapper = styled.div`
  /* max-height: 320px; */
  padding: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  // height: 400px;
  overflow-y: scroll;
  margin: 10px 0;

  & input[type='checkbox'] {
    width: 18px;
    height: 18px;
  }
`;

const HeadingCheckbox = styled.div`
  display: flex;
  align-items: center;

  & > span {
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;
  }
  & > input[type='checkbox'] {
  }
`;

const MainContainer = styled.div`
  padding-left: 20px;
  padding-top: 15px;
`;

const ChildCheckbox = styled.div`
  display: flex;
  align-items: center;

  & > span {
    color: #636363;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
  }
`;

const RadioWrapper = styled.div`
  padding-left: 20px;
  padding-top: 5px;
  display: flex;
  gap: 24px;

  color: #636363;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;

  & input[type='radio'] {
    // width: 50px;
  }
`;

const ButtonWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GenerateAnalysis = ({
  open,
  onClose,
  moduleType,
  title,
  job_id,
  searchQuery,
  reportData,
  imageResponse,
  tableResponse,
  topicPreviewData = {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('Overviews');
  const [openPopup, setOpenPopup] = useState(open);
  let localVal = localStorage.getItem(
    `local_${job_id !== undefined && job_id !== null && job_id ? job_id : ''}`
  );
  const [selectedData, setSelectedData] = useState({
    Amazon: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1a',
            name: 'Overall Customer Satisfaction',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '1aa',
                name: 'Average Rating',
                type: 'radio',
                value: false,
              },
              {
                id: '1ab',
                name: 'Verified Purchase',
                type: 'radio',
                value: false,
              },
              {
                id: '1ac',
                name: 'Non Verified Purchase',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1b',
            name: 'Customer Rating',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '1ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1c',
            name: 'Gender Demographics',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '1ca',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1cb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1d',
            name: 'Verified and Non Verified Reviews',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1da',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1db',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1dc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1dd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1e',
            name: 'Gender Based Average Rating',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1f',
            name: 'Gender Based Rating',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1fa',
                name: 'Male',
                type: 'radio',
                value: false,
              },
              {
                id: '1fb',
                name: 'Female',
                type: 'radio',
                value: false,
              },
              {
                id: '1fc',
                name: 'Unknown',
                type: 'radio',
                value: false,
              },
              /*  {
                 id: '6ad',
                 name: 'Funnel Chart',
                 type: 'radio',
                 value: false,
               }, */
            ],
          },
          {
            id: '1g',
            name: 'Trends In User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ga',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gaa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1gb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top Helpful Reviews',
            type: 'checkbox',
            display: true,
            value: false,
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Emotion Analysis',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3ca',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3cb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3cc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3cd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Correlation Analysis',
            type: 'checkbox',
            display: true,
            value: false,
          },
          {
            id: '3e',
            name: 'Review Helpfulness',
            type: 'checkbox',
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4a',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4b',
            name: 'Emotions',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      // Reviews: {
      //   id: 6,
      //   name: 'Reviews',
      //   type: 'checkbox',
      //   value: false,
      // }
    },
    Facebook: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1a',
            name: 'Engagement',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1b',
            name: 'Gender Demographics',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1c',
            name: 'Volume',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ca',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1caa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1cb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1cba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1cbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1cbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1cbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },

          {
            id: '1d',
            name: 'Trends In User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1da',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1daa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1db',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1dba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },
          {
            id: '1g',
            name: 'Heat Map',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ga',
                name: 'X-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gaa',
                    name: 'Hour of Day',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'hour',
                  },
                  {
                    id: '1gab',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gac',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gad',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
              {
                id: '1gb',
                name: 'Y-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gba',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gbb',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gbc',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
            ],
          },
          // {
          //   id: '1d',
          //   name: 'Verified and Non-Verified Reviews',
          //   type: 'checkbox',
          //   display: true,
          //   value: false,

          // },
          /*   {
            id: '3a',
            name: 'Word Cloud Analysis',
            type: 'checkbox',
            value: false,
          },
          {
            id: '4a',
            name: 'Word List',
            type: 'checkbox',
            value: false,
          }, */
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top 5 Hashtags',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2c',
            name: 'Top Comments',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Most Authoritative',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Most Mentioned',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3e',
            name: 'Emotion Analysis',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3f',
            name: 'Correlation Analysis',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4aa',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4ab',
            name: 'Emotions',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      // Reviews: {
      //   id: 6,
      //   name: 'Posts',
      //   type: 'checkbox',
      //   value: false,
      // }
    },
    News: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1b',
            name: 'Trends In User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ba',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1baa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1bab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1bac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1bad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1bb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1bba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1bbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1bbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1bbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },
          /*   {
            id: '3a',
            name: 'Word Cloud Analysis',
            type: 'checkbox',
            value: false,
          },
          {
            id: '4a',
            name: 'Word List',
            type: 'checkbox',
            value: false,
          }, */
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top 5 Hashtags',
            type: 'checkbox',
            value: false,
            display: false,
            child: [
              {
                id: '2ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2c',
            name: 'Top Reposts',
            type: 'checkbox',
            value: false,
            display: false,
          },
          {
            id: '2d',
            name: 'Sentiments Bar Chart',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Most Authoritative',
            type: 'checkbox',
            value: false,
            display: false,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Most Mentioned',
            type: 'checkbox',
            value: false,
            display: false,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3e',
            name: 'Emotion Analysis',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3f',
            name: 'Correlation Analysis',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '3g',
            name: 'World Map',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4aa',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4ab',
            name: 'Emotions',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      // Reviews: {
      //   id: 6,
      //   name: 'Posts',
      //   type: 'checkbox',
      //   value: false,
      // }
    },
    Twitter: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1a',
            name: 'Engagement',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '1aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1b',
            name: 'Gender Demographics',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1c',
            name: 'Verified and Non verified Users',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ca',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1cb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1d',
            name: 'Volume',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1da',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1daa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1db',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1dba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1dbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },

          {
            id: '1e',
            name: 'Trends In User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ea',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1eaa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1eab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1eac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1ead',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1eb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1eba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1ebb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1ebc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1ebd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },
          {
            id: '1f',
            name: 'Impressions',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1fa',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1faa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1fab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1fac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1fad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1fb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1fba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1fbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1fbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                  },
                  {
                    id: '1fbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },

          {
            id: '1g',
            name: 'Heat Map',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ga',
                name: 'X-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gaa',
                    name: 'Hour of Day',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'hour',
                  },
                  {
                    id: '1gab',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gac',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gad',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
              {
                id: '1gb',
                name: 'Y-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gba',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gbb',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gbc',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
            ],
          },
          // {
          //   id: '4a',
          //   name: 'Word List',
          //   type: 'checkbox',
          //   value: false,
          // },
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top 5 Hashtags',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2c',
            name: 'Top Reposts',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Most Authoritative',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Most Mentioned',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3e',
            name: 'Emotion Analysis',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3f',
            name: 'Correlation Analysis',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '3g',
            name: 'World Map',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4aa',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4ab',
            name: 'Emotions',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      // Reviews: {
      //   id: 6,
      //   name: 'Posts',
      //   type: 'checkbox',
      //   value: false,
      // }
    },
    Instagram: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1a',
            name: 'Engagement',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1b',
            name: 'Gender Demographics',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1c',
            name: 'Volume',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ca',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1caa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1cb',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1cba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1cbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },

          {
            id: '1d',
            name: 'Trends in User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1da',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1daa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1db',
                name: 'Area Chart',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1dba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1dbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },

          {
            id: '1e',
            name: 'Heat Map',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ga',
                name: 'X-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gaa',
                    name: 'Hour of Day',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'hour',
                  },
                  {
                    id: '1gab',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gac',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gad',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
              {
                id: '1gb',
                name: 'Y-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1gba',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1gbb',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1gbc',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
            ],
          },
          // {
          //   id: '1e',
          //   name: 'Comments',
          //   type: 'checkbox',
          //   display: true,
          //   value: false,
          //   child: [
          //     {
          //       id: '1ea',
          //       name: 'Line Graph',
          //       type: 'radio',
          //       value: false,
          //     },
          //     {
          //       id: '1eb',
          //       name: 'Area Chart',
          //       type: 'radio',
          //       value: false,
          //     },
          //   ],

          // },

          /*   {
            id: '3a',
            name: 'Word Cloud Analysis',
            type: 'checkbox',
            value: false,
          },
          {
            id: '4a',
            name: 'Word List',
            type: 'checkbox',
            value: false,
          }, */
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top 5 Hashtags',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2c',
            name: 'Top Comments',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Most Authoritative',
            type: 'checkbox',
            value: false,
            display:
              topicPreviewData?.channel == 'instagram' &&
              topicPreviewData?.search_type == 'hashtag'
                ? true
                : false,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Most Mentioned',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3e',
            name: 'Emotion Analysis',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3f',
            name: 'Correlation Analysis',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4aa',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4ab',
            name: 'Emotions',
            type: 'checkbox',
            value: false,
            display: true,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      // Reviews: {
      //   id: 6,
      //   name: 'Posts',
      //   type: 'checkbox',
      //   value: false,
      // }
    },
    Flipkart: {
      Overviews: {
        id: 1,
        name: 'Overviews',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '1a',
            name: 'Overall Customer Satisfaction',
            type: 'checkbox',
            value: false,
            display: true,
            // child: [
            //   {
            //     id: '1aa',
            //     name: 'Average Rating',
            //     type: 'radio',
            //     value: false,
            //   },
            //   {
            //     id: '1ab',
            //     name: 'Verified Purchase',
            //     type: 'radio',
            //     value: false,
            //   },
            //   {
            //     id: '1ac',
            //     name: 'Non Verified Purchase',
            //     type: 'radio',
            //     value: false,
            //   },
            // ],
          },
          {
            id: '1b',
            name: 'Customer Rating',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ba',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1bb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1bd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1c',
            name: 'Gender Demographics',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ca',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1cb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1cd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1d',
            name: 'Verified and Non Verified Reviews',
            type: 'checkbox',
            display: false,
            value: false,
            child: [
              {
                id: '1da',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1db',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1dc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1dd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1e',
            name: 'Gender Based Average Rating',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '1eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '1ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '1f',
            name: 'Gender Based Rating',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1fa',
                name: 'Male',
                type: 'radio',
                value: false,
              },
              {
                id: '1fb',
                name: 'Female',
                type: 'radio',
                value: false,
              },
              {
                id: '1fc',
                name: 'Unknown',
                type: 'radio',
                value: false,
              },
              /*  {
                 id: '6ad',
                 name: 'Funnel Chart',
                 type: 'radio',
                 value: false,
               }, */
            ],
          },
          {
            id: '1g',
            name: 'Trends In User Activity',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ga',
                name: 'Line Graph',
                type: 'radio',
                value: false,
                display: true,
                child: [
                  {
                    id: '1gaa',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gab',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gac',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gad',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
              {
                id: '1gb',
                name: 'Area Chart',
                type: 'radio',
                display: true,
                value: false,
                child: [
                  {
                    id: '1gba',
                    name: 'Days',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbb',
                    name: 'Weeks',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbc',
                    name: 'Months',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                  {
                    id: '1gbd',
                    name: 'Years',
                    type: 'radio',
                    value: false,
                    display: true,
                  },
                ],
              },
            ],
          },
          
          {
            id: '1h',
            name: 'Heat Map',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '1ha',
                name: 'X-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1haa',
                    name: 'Hour of Day',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'hour',
                  },
                  {
                    id: '1hab',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1hac',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1had',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
              {
                id: '1hb',
                name: 'Y-axis',
                type: 'radio',
                value: false,
                child: [
                  {
                    id: '1hba',
                    name: 'Day of Week',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'day',
                  },
                  {
                    id: '1hbb',
                    name: 'Quarter of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'quarter',
                  },
                  {
                    id: '1hbc',
                    name: 'Month of Year',
                    type: 'radio',
                    value: false,
                    display: true,
                    showVal: 'month',
                  },
                ],
              },
            ],
          },
        ],
      },
      Sentiments: {
        id: 2,
        name: 'Sentiments',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '2a',
            name: 'Sentiment Drilldown',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '2aa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '2ab',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ac',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '2ad',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '2b',
            name: 'Top Helpful Reviews',
            type: 'checkbox',
            display: true,
            value: false,
          },
        ],
      },
      Insights: {
        id: 3,
        name: 'Insights',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '3a',
            name: 'Word Cloud Analysis - Sentiments',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3aa',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ab',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3ac',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3b',
            name: 'Word Cloud Analysis - Emotions',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3ba',
                name: 'Joy',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bb',
                name: 'Surprise',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bc',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bd',
                name: 'Anger',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3be',
                name: 'Disgust',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bf',
                name: 'Sadness',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3bg',
                name: 'Fear',
                type: 'checkbox',
                value: false,
              },
            ],
          },
          {
            id: '3c',
            name: 'Emotion Analysis',
            type: 'checkbox',
            value: false,
            display: true,
            child: [
              {
                id: '3ca',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3cb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3cc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3cd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3d',
            name: 'Correlation Analysis',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '3e',
            name: 'Review Helpfulness',
            type: 'checkbox',
            display: false,
            value: false,
            child: [
              {
                id: '3ea',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3eb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ec',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3ed',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3f',
            name: 'Review Liked',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3fa',
                name: 'Bar Graph',
                type: 'radio',
                value: false,
              },
              {
                id: '3fb',
                name: 'Pie Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3fc',
                name: 'Donut Chart',
                type: 'radio',
                value: false,
              },
              {
                id: '3fd',
                name: 'Funnel Chart',
                type: 'radio',
                value: false,
              },
            ],
          },
          {
            id: '3g',
            name: 'Geographical View of Sentiments',
            type: 'checkbox',
            display: true,
            value: false,
            child: [
              {
                id: '3ga',
                name: 'Majority Engagers',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3gb',
                name: 'Positive',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3gc',
                name: 'Negative',
                type: 'checkbox',
                value: false,
              },
              {
                id: '3gd',
                name: 'Neutral',
                type: 'checkbox',
                value: false,
              },
            ],
          },
        ],
      },
      Cluster_Analysis: {
        id: 4,
        name: 'Cluster Analysis',
        type: 'checkbox',
        value: false,
        child: [
          {
            id: '4a',
            name: 'Sentiments',
            type: 'checkbox',
            value: false,
            display: true,
          },
          {
            id: '4b',
            name: 'Emotions',
            type: 'checkbox',
            display: true,
            value: false,
          },
        ],
      },
      Custom_Analysis: {
        id: 5,
        name: 'Custom Analysis',
        type: 'checkbox',
        value: false,
        localVal: localVal,
        child: [],
      },
      Reviews: {
        id: 6,
        name: 'Reviews',
        type: 'checkbox',
        value: false,
      },
    },
  });
  const [selectedCheckboxNames, setSelectedCheckboxNames] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (updatedData) => {
    // Do something with updatedData, like sending it to another component
  };

  const newReportData = {
    ...reportData,
    /*   custom_analysis: {
        searchQuery: searchQuery,
        image:imageResponse,
        table: tableResponse
    } */
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    'Overviews',
    'Sentiments',
    'Insights',
    'Cluster_Analysis',
    // 'Reviews'
    'Custom_Analysis',
  ];

  const obj_tabs = [
    {
      name: 'Overviews',
      value: 'Overviews',
    },
    {
      name: 'Sentiments',
      value: 'Sentiments',
    },

    {
      name: 'Insights',
      value: 'Insights',
    },
    {
      name: 'Cluster Analysis',
      value: 'Cluster_Analysis',
    },
    {
      name: 'Custom Analysis',
      value: 'Custom_Analysis',
    },
  ];
  const [selectedX, setSelectedX] = useState();
  const [selectedY, setSelectedY] = useState();
  const handleAxisChange = (axis, value, el_Child) => {
    if (el_Child.name === 'Heat Map') {
      if (axis === 'x') {
        setSelectedX(value);
        if (selectedY === value) {
          setSelectedY(null);
        }
      } else {
        setSelectedY(value);
        if (selectedX === value) {
          setSelectedX(null);
        }
      }
    }
  };
  const create_tab_jsx = (selectedTab) => {
    return (
      <>
        <HeadingCheckbox>
          <input
            id={selectedTab.id}
            type='checkbox'
            checked={selectedTab.value}
            // checked={checkedItems[selectedTab.id] || false}
            label={selectedTab.name}
            /* onChange={() => handleOnChange([selectedTab.name])} */
            onChange={(event) => toggleCheckbox(event, selectedTab, 'parent')}
          />
          <span>{selectedTab.name}</span>
        </HeadingCheckbox>
        {selectedTab.child &&
          selectedTab.child.map(
            (el_Child) =>
              el_Child?.display && (
                <MainContainer key={el_Child.id}>
                  <ChildCheckbox>
                    <input
                      type={el_Child.type}
                      checked={el_Child.value}
                      label={el_Child.name}
                      onChange={(event) =>
                        toggleCheckbox(event, el_Child, 'child')
                      }
                    />
                    <span>{el_Child.name}</span>
                  </ChildCheckbox>
                  {
                    el_Child.name == 'Heat Map' ?
                      <RadioWrapper
                        style={{
                          flexDirection: 'column',
                          alignItems: 'baseline',
                        }}
                      >
                        <div>
                          <label>X-Axis:</label>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            {el_Child.child[0]?.child?.map((option) => (
                              <div key={option.id}>
                                <input
                                  type='radio'
                                  name='x-axis'
                                  value={option.name}
                                  checked={selectedX === option.name && option.value}
                                  disabled={selectedY === option.name}
                                  onChange={(event) => {
                                    handleAxisChange(
                                      'x',
                                      option.name,
                                      el_Child,
                                      selectedTab
                                    );
                                    toggleCheckbox(
                                      event,
                                      option,
                                      'sub_2_Child',
                                      el_Child,
                                      el_Child.child[0],
                                    );
                                  }}
                                />
                                {option.name}
                              </div>
                            ))}
                          </div>
                          <label>Y-Axis:</label>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            {el_Child.child[1]?.child?.map(
                              (option) =>
                                option?.name !== 'Hour of Day' && (
                                  <div key={option.id}>
                                    <input
                                      type='radio'
                                      name='y-axis'
                                      value={option.name}
                                      checked={selectedY === option.name}
                                      disabled={selectedX === option.name}
                                      onChange={(event) => {
                                        handleAxisChange(
                                          'y',
                                          option.name,
                                          el_Child,
                                          selectedTab
                                        );

                                        toggleCheckbox(
                                          // event,
                                          // option,
                                          // 'sub_2_Child',
                                          // el_Child
                                          event,
                                          option,
                                          'sub_2_Child',
                                          el_Child,
                                          el_Child.child[1],
                                        );
                                      }}
                                    />
                                    {option.name}
                                  </div>
                                )
                            )}
                          </div>
                        </div>

                      </RadioWrapper>
                      :

                      <RadioWrapper>
                        {el_Child?.child &&
                          el_Child.child.map((el_Sub_Child) => (
                            <div key={el_Sub_Child.id}>
                              <input
                                type={el_Sub_Child.type}
                                checked={el_Sub_Child.value}
                                // checked={checkedItems[el_Sub_Child.id] || false}
                                label={el_Sub_Child.name}
                                onChange={(event) =>
                                  toggleCheckbox(
                                    // event,
                                    // el_Sub_Child,
                                    // selectedTab.name,
                                    // el_Child.name

                                    event,
                                    el_Sub_Child,
                                    'subChild',
                                    // el_Child.name
                                    el_Child,
                                    el_Sub_Child.name == 'Line Graph' || el_Sub_Child.name == 'Area Chart' ? true : false
                                  )
                                }
                              /*  onChange={() =>
                        handleOnChange([
                          selectedTab.name,
                          el_Child.name,
                          el_Sub_Child.name,
                        ])
                      } */
                              />
                              {el_Sub_Child.name}

                              <RadioWrapper>
                                {el_Sub_Child?.child &&
                                  el_Sub_Child?.child?.map((el_Sub_Sub_Child) => {
                                    return (
                                      <div key={el_Sub_Sub_Child?.id}>
                                        <input
                                          type={el_Sub_Sub_Child?.type}
                                          checked={el_Sub_Sub_Child?.value}
                                          // checked={checkedItems[el_Sub_Child.id] || false}
                                          label={el_Sub_Sub_Child?.name}
                                          onChange={(event) =>
                                            toggleCheckbox(
                                              // event,
                                              // el_Sub_Child,
                                              // selectedTab.name,
                                              // el_Child.name

                                              event,
                                              el_Sub_Sub_Child,
                                              'sub_2_Child',
                                              el_Child,
                                              // el_Child.name
                                              el_Sub_Child
                                            )
                                          }
                                        /*  onChange={() =>
                                  handleOnChange([
                                    selectedTab.name,
                                    el_Child.name,
                                    el_Sub_Child.name,
                                  ])
                                } */
                                        />
                                        {el_Sub_Sub_Child?.name}
                                      </div>
                                    );
                                  })}
                              </RadioWrapper>
                            </div>
                          ))}
                      </RadioWrapper>
                  }
                </MainContainer>
              )
          )}
      </>
    );
  };

  const handleSubChildLevel2RadioChange = (
    parentKey,
    activeTab,
    childKey,
    value,
    subchildKey,
    subChildLevel2Key
  ) => {
    handleChange(
      parentKey,
      activeTab,
      childKey,
      value,
      true,
      subchildKey,
      subChildLevel2Key
    );
  };

  const handleSubChildRadioChange = (
    parentKey,
    activeTab,
    childKey,
    value,
    subchildKey,
    item
  ) => {
    let subChildLevel2Key = item?.child?.[0]?.id;
    handleChange(
      parentKey,
      activeTab,
      childKey,
      value,
      true,
      subchildKey,
      subChildLevel2Key
    );
  };

  const handleSubChildCheckboxChange = (
    parentKey,
    activeTab,
    childKey,
    value,
    subchildKey,
    item
  ) => {
    let subChildLevel2Key = item?.child?.[0]?.id;
    handleChange(
      parentKey,
      activeTab,
      childKey,
      value,
      false,
      subchildKey,
      subChildLevel2Key
    );
  };

  const updateValueRecursively = (
    node,
    targetId,
    value,
    isRadio,
    subchildKey,
    subChildLevel2Key
  ) => {
    if (node.id === targetId) {
      if (node?.child) {
        node.child = node?.child?.map((childNode) => {
          if (childNode.type == 'radio' && node?.name == 'Heat Map') {
            if (childNode.id == subchildKey) {
              childNode.value = value;

              childNode.child = childNode?.child?.map((subChildNode) => {
                if (subChildNode.id === subChildLevel2Key && value) {
                  subChildNode.value = value;
                } else {
                  subChildNode.value = false;
                }

                return subChildNode;
              });
              // childNode.child = childRecursive(childNode,subChildLevel2Key,value)
            }
          } else if (childNode.type == 'radio') {
            if (childNode.id == subchildKey) {
              childNode.value = value;

              // childNode.child = childRecursive(childNode,subChildLevel2Key,value)
            } else {
              childNode.value = false;
            }

            childNode.child = childNode?.child?.map((subChildNode) => {
              if (subChildNode.id === subChildLevel2Key && value) {
                subChildNode.value = value;
              } else {
                subChildNode.value = false;
              }

              return subChildNode;
            });
          } else {
            if (childNode.id == subchildKey) {
              childNode.value = value;
              childNode.child = childNode?.child?.map((subChildNode) => {
                if (subChildNode.id === subChildLevel2Key) {
                  subChildNode.value = value;
                } else {
                  subChildNode.value = false;
                }

                return subChildNode;
              });
            }
          }

          return childNode;
        });

        node.value = node?.child?.some((value) => value.value === true);
      } else {
        node.value = value;
      }

      // Update the child node recursively
      // childNode.child = childNode.child.map(subChildNode => {
      //   if (subChildNode.id === subchildKey) {
      //     subChildNode.value = true;  // Set to true if it matches subchildKey
      //   } else {
      //     subChildNode.value = false; // Set to false if it does not match
      //   }
      //   return subChildNode;
      // });
      // node.child.forEach((childNode, index) => {
      //   if (childNode?.child?.length > 0) {
      //     updateValueRecursively(childNode, childNode.id, value, isRadio)
      //   } else if (index === 0) {
      //     return childNode.value = value
      //   } else {
      //     return false
      //   }

      // })
      // if (isRadio && node.child) {
      //   node.child.forEach(childNode => (childNode.value = false));
      // }
    }
    // else if (node.child) {
    //   node.child = node.child.map(childNode =>
    //     updateValueRecursively(childNode, targetId, value, isRadio)
    //   );
    // }

    return node;
  };

  const handleChange = (
    parentKey,
    activeTab,
    targetId,
    value,
    isRadio = false,
    subchildKey,
    subChildLevel2Key
  ) => {
    setSelectedData((prevState) => {
      const updatedState = { ...prevState };
      updatedState[parentKey][activeTab].child = updatedState[parentKey][
        activeTab
      ].child.map((child) =>
        updateValueRecursively(
          child,
          targetId,
          value,
          isRadio,
          subchildKey,
          subChildLevel2Key
        )
      );
      updatedState[parentKey][activeTab].value = updatedState[parentKey][
        activeTab
      ]?.child?.some((value) => value.value === true);
      return updatedState;
    });
  };

  const handleParentCheckboxChange = (moduleType, activeTab, checked) => {
    setSelectedData((prevState) => {
      const updatedState = { ...prevState };
      const newValue = checked;

      updatedState[moduleType][activeTab].value = newValue;
      if (
        updatedState[moduleType][activeTab]?.child &&
        updatedState[moduleType][activeTab]?.child?.length > 0
      ) {
        updatedState[moduleType][activeTab].child = updatedState[moduleType][
          activeTab
        ].child.map((child) => {
          child.value = newValue;
          if (child.name == 'Heat Map' && child.child) {
            child.child = child.child.map((subChild) => {
              subChild.value = newValue;

              // subChild.value = newValue;
              if (subChild.child) {
                subChild.child = subChild.child.map((subSubChild) => {
                  subSubChild.value =
                    subSubChild.id === subChild.child[0].id &&
                    (subChild.id === child.child[0].id ||
                      subChild.id === child.child[1].id) &&
                    newValue;

                  return subSubChild;
                });
              }
              return subChild;
            });
          } else if (child.child) {
            child.child = child.child.map((subChild) => {
              subChild.value = subChild.id === child.child[0].id && newValue;

              // subChild.value = newValue;
              if (subChild.child) {
                subChild.child = subChild.child.map((subSubChild) => {
                  subSubChild.value =
                    subSubChild.id === subChild.child[0].id &&
                    subChild.id === child.child[0].id &&
                    newValue;

                  return subSubChild;
                });
              }
              return subChild;
            });
          }
          return child;
        });
      }
      
      return updatedState;
    });
  };
  const handleChildCheckboxChangeForHeapMap = (
    parentKey,
    activeTab,
    childKey,
    value,
    item
  ) => {
    
    item?.child.forEach((i) => {
      const childId = i?.id;
      const subChildId = i?.child?.[0]?.id;
      handleChange(
        parentKey,
        activeTab,
        childKey,
        value,
        false,
        childId,
        subChildId
      );
    });
  };

  const handleChildCheckboxChange = (
    parentKey,
    activeTab,
    childKey,
    value,
    item
  ) => {
    let childId = item?.child?.[0]?.id;
    let subChildId = item?.child?.[0]?.child?.[0]?.id;
    handleChange(
      parentKey,
      activeTab,
      childKey,
      value,
      false,
      childId,
      subChildId
    );
  };
  const handleRadioChange = (parentKey, activeTab, childKey, value, item) => {
    let childId = item?.child?.[0]?.id; // This will be the ID of the child
    let subChildId = item?.child?.[0]?.child?.[0]?.id; // This will be the ID of the subchild
    handleChange(
      parentKey,
      activeTab,
      childKey,
      value,
      true, // Since this is a radio button, set `isRadio` to true
      childId,
      subChildId
    );
  };
  const toggleCheckbox = (event, item, parType, el_Child, el_Sub_Sub_Child) => {
    const updatedData = { ...selectedData };
    const { checked } = event.target;

    if (parType == 'parent') {
      if(checked == true){
        setSelectedX('Hour of Day')
        setSelectedY('Day of Week')
      }else{
        setSelectedX('')
        setSelectedY('')
      }
      handleParentCheckboxChange(moduleType, activeTab, checked);
    } else if (parType == 'child' && el_Sub_Sub_Child == true) {
      handleRadioChange(moduleType, activeTab, item.id, checked, item);
    } else if (parType == 'child') {
      
      if (item?.name == 'Heat Map') {
        if(checked == true){
          setSelectedX('Hour of Day')
          setSelectedY('Day of Week')
        }else{
          setSelectedX('')
          setSelectedY('')
        }
        handleChildCheckboxChangeForHeapMap(
          moduleType,
          activeTab,
          item.id,
          checked,
          item
        );
      } else {
        handleChildCheckboxChange(
          moduleType,
          activeTab,
          item.id,
          checked,
          item
        );
      }
    } else if (parType == 'subChild' && item?.type == 'radio') {
      if (el_Child.name == 'Heat Map') {
        let matIds = el_Child?.child?.filter((i) => i.id !== item.id);

        handleSubChildRadioChange(
          moduleType,
          activeTab,
          el_Child.id,
          checked,
          matIds[0]?.id,
          matIds[0]
        );
        handleSubChildRadioChange(
          moduleType,
          activeTab,
          el_Child.id,
          checked,
          item.id,
          item
        );
      } else {
        handleSubChildRadioChange(
          moduleType,
          activeTab,
          el_Child.id,
          checked,
          item.id,
          item
        );
      }
    } else if (parType == 'subChild' && item?.type == 'checkbox') {
      handleSubChildCheckboxChange(
        moduleType,
        activeTab,
        el_Child.id,
        checked,
        item.id,
        item
      );
    } else if (parType == 'sub_2_Child') {
      if (item.name == 'Heat Map') {
        let matVal = el_Child?.child?.some(
          (i) =>
            i.id !== el_Sub_Sub_Child.id && // Check if id is not equal
            i.child?.some((subVal) => subVal.value === true) // Check if any sub-child has value === true
        );

        if (matVal) {
          handleSubChildLevel2RadioChange(
            moduleType,
            activeTab,
            el_Child.id,
            checked,
            el_Sub_Sub_Child.id,
            item.id
          );
        } else {
          let matIds = el_Child?.child?.filter(
            (i) => i.id !== el_Sub_Sub_Child.id
          );

          // handleSubChildLevel2RadioChange(
          //   moduleType,
          //   activeTab,
          //   el_Child.id,
          //   checked,
          //   matIds[0].id,
          //   matIds[0].child[0].id
          // );
          handleSubChildLevel2RadioChange(
            moduleType,
            activeTab,
            el_Child.id,
            checked,
            el_Sub_Sub_Child.id,
            item.id
          );
        }
      } else {
        handleSubChildLevel2RadioChange(
          moduleType,
          activeTab,
          el_Child.id,
          checked,
          el_Sub_Sub_Child.id,
          item.id
        );
      }
    }
    // if (
    //   (item.child && item.child.length > 0 && item.child[0].type === 'radio') ||
    //   (item.child === undefined && item.type === 'checkbox')
    // ) {
    //   // handleChildCheckbox(item);
    //   /* updateHierarchy2(item) */
    // } else if (item.type === 'radio' && item.child === undefined) {
    //   // handleSubchild(item);
    // } else {
    //   // updateHierarchy(item);
    // }
  };

  const renderTabContent = () => {
    const content = selectedData[moduleType][activeTab];
    return content && create_tab_jsx(content);
  };

  const handleButtonClick = (e) => {
    if (e.target.innerText === 'Back') {
      const currentTab = tabs.indexOf(activeTab);
      setActiveTab(tabs[currentTab - 1]);
    } else if (e.target.innerText === 'Cancel') {
    } else if (e.target.innerText === 'Save And Next') {
      const currentTab = tabs.indexOf(activeTab);
      setActiveTab(tabs[currentTab + 1]);
    } else if (e.target.innerText === 'Generate') {
    } else {
      return;
    }
  };

  /* const handleOnChange = (e) => {
    const level = e.length;
    }; */

  const handleOnChange = (path) => {
    setSelectedData((prevTabContent) => {
      // Copy the previous state to avoid mutation
      const updatedTabContent = JSON.parse(JSON.stringify(prevTabContent));

      // Find the target tab based on the path
      let targetTab = updatedTabContent[moduleType][activeTab];
      // If targetTab is found, toggle its value

      /*  for (let i = 0; i <= path.length; i++) {
    } */
      for (let i = 0; i <= path.length; i++) {
        const name = path[i];
        const childIndex = targetTab.child.findIndex(
          (child) => child.name === name
        );
        if (childIndex !== -1) {
          // Toggle the value of the checkbox
          targetTab.child[childIndex].value =
            !targetTab.child[childIndex].value;
          // Update the target tab for the next iteration
          targetTab = targetTab.child[childIndex];
        } else {
          // If a checkbox with the given name is not found, stop traversing
          break;
        }
      }
      return updatedTabContent;
    });
  };

  const handleCancelClick = () => {
    setOpenPopup(!openPopup);
    onClose();
  };

  const handleShareClick = () => {
    // navigate('/sentinelHub');
    let selectedValues;
    if (moduleType == 'News') {
      selectedValues = selectedData.News;
      let reportType = 'news';
      dispatch(
        shareReport({
          selectedData: selectedData.News,
          job_id,
          newReportData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    } else if (moduleType == 'Twitter') {
      selectedValues = selectedData.Twitter;
      let reportType = 'twitter';
      dispatch(
        shareReport({
          selectedData: selectedData.Twitter,
          job_id,
          newReportData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    } else if (moduleType == 'Facebook') {
      selectedValues = selectedData.Facebook;
      let reportType = 'facebook';
      dispatch(
        shareReport({
          selectedData: selectedData.Facebook,
          job_id,
          newReportData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    } else if (moduleType == 'Instagram') {
      selectedValues = selectedData.Instagram;
      let reportType = 'instagram';
      dispatch(
        shareReport({
          selectedData: selectedData.Instagram,
          job_id,
          newReportData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    } else if (moduleType == 'Flipkart') {
      selectedValues = selectedData.Flipkart;
      let repData = newReportData?.data;
      let reportType = 'flipkart';
      dispatch(
        shareReport({
          selectedData: selectedData.Flipkart,
          job_id,
          repData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    } else {
      let repData = newReportData?.data;
      let reportType = 'amazon';
      dispatch(
        shareReport({
          selectedData: selectedData.Amazon,
          job_id,
          repData,
          reportType,
        })
      ).then((res) => {
        onClose();
      });
    }
  };

  return (
    <>
      {openPopup && (
        <PopUp onClose={() => setOpenPopup(!openPopup)}>
          <Wrapper>
            <Title>
              Analysis - {title} - {moduleType} Platform
            </Title>
            <TabHeading>
              {obj_tabs.map((el) => (
                <div
                  className={activeTab === el.value ? 'activePlanTab' : ''}
                  onClick={() => handleTabClick(el?.value)}
                  key={el?.value}
                >
                  {el.name}
                </div>
              ))}
            </TabHeading>
            <CheckBoxWrapper>{renderTabContent()}</CheckBoxWrapper>
            <ButtonWrapper onClick={handleButtonClick}>
              <div>
                {tabs.indexOf(activeTab) !== 0 && (
                  <Button
                    value={'Back'}
                    style={{
                      background: '#fff',
                      color: '#000',
                      width: 'min-content',
                    }}
                  />
                )}
              </div>
              <div>
                <Button
                  value={'Cancel'}
                  style={{ background: '#fff', color: '#000', width: '200px' }}
                  onClick={handleCancelClick}
                />
                <Button
                  value={'Share'}
                  style={{
                    width: '150px',
                    margin: '0px 20px 0px 0px',
                    background: '#fff',
                    color: '#353CF7',
                    borderRadius: '100px',
                    border: '1px solid #353CF7',
                  }}
                  onClick={handleShareClick}
                />
                {tabs.indexOf(activeTab) === tabs.length - 1 ? (
                  <div></div>
                ) : (
                  <Button value={'Save And Next'} style={{ width: '150px' }} />
                )}
              </div>
            </ButtonWrapper>
          </Wrapper>
        </PopUp>
      )}
    </>
  );
};

export default GenerateAnalysis;
