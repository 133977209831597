import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json';
import OverallReview from '../../../components/OverallReview/OverallReview';
import { InstagramLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';
import SidePanel from '../../../components/SidePanel/SidePanel';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import WordCloudComponent from '../../../components/WordCloud/WordCloud';
// import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUserActivity';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';

import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import DynamicCorrelationGraph from '../../../components/DynamicCorrelationGraph/DynamicCorrelationGraph';
import { Bar } from 'recharts';
import { format, parse } from 'date-fns';
import { useMemo } from 'react';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';
import { useDispatch, useSelector } from 'react-redux';
import { DetailedInsightsData } from '../../../store/redux/slices/topicSlice';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  align-items: center;
  box-sizing: border-box;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const Overview = ({
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  preview_uuid,
  filterOption,
  filteredData,
  resetFlag,
  view,
  channel,
  job_id,
  hideDropdown,
  formatValue = 'dd/yyyy/MM',
}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  const [showSidePanel, setShowSidePanel] = useState(false);
  const genderDemographicsData = [
    {
      name: 'Male',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.male_percentage
          : overview_tab?.gender_demographics?.male_percentage,
      // fill: '#6640B8',
      fill: 'rgba(13, 135, 246, 1)',
    },
    {
      name: 'Female',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.female_percentage
          : overview_tab?.gender_demographics?.female_percentage,
      // fill: '#EAAABD',
      fill: 'rgba(246, 79, 129, 1)',
    },
    {
      name: 'Unknown',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.unknown_percentage
          : overview_tab?.gender_demographics?.unknown_percentage,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 217, 219, 1)',
    },
  ];
  const userVerificationData = [
    {
      name: 'Verified',
      value:
        resetFlag === true && filteredData
          ? filteredData?.verified_nonverified?.verified
          : overview_tab?.verified_nonverified?.verified,
      fill: '#353CF780',
    },
    {
      name: 'Non Verified',
      value:
        resetFlag === true && filteredData
          ? filteredData?.verified_nonverified?.nonverified
          : overview_tab?.verified_nonverified?.nonverified,
      fill: '#00D5D0',
    },
  ];
  const engagementData = [
    {
      name: 'Likes',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.likes
          : overview_tab?.engagement?.likes,
      fill: '#353CF780',
    },
    {
      name: 'Replies',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.comments
          : overview_tab?.engagement?.comments,
      fill: '#00D5D0',
    },
    {
      name: 'Views',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.views
          : overview_tab?.engagement?.views,
      fill: '#CE66FF',
    },
    {
      name: 'Reposts',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.reposts
          : overview_tab?.engagement?.reposts,
      fill: '#A2FF82',
    },
  ];

  const totalNoOfPosts =
    resetFlag === true && filteredData
      ? filteredData?.total_count
      : overview_tab?.total_count;

  const renderScreen = (data) => ({
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={data}
          type={data[0]?.name === 'Likes' ? null : '%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={data}
          type={data[0]?.name === 'Likes' ? null : '%'}
          /*  borderRadius={'0px 12px 12px 0px'} */
          barHeight={'32px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={data}
        width={450}
        type={data[0]?.name === 'Likes' ? null : 'percentage'}
      />
    ),
    'Donut Chart': (
      <DonutChart
        data={data}
        type={data[0]?.name === 'Likes' ? null : 'percentage'}
      />
    ),
    'Funnel Chart': (
      <FunnelGraph data={data} type={data[0]?.name === 'Likes' ? '' : '%'} />
    ),
  });

  let genderDemographicsGraph;

  if (filterOption) {
    if (filterOption === 'Bar Graph') {
      genderDemographicsGraph = renderScreen['Bar Graph'];
    } else if (filterOption === 'Pie Chart') {
      genderDemographicsGraph = renderScreen['Pie Chart'];
    } else if (filterOption === 'Donut Chart') {
      genderDemographicsGraph = renderScreen['Donut Chart'];
    } else {
      genderDemographicsGraph = renderScreen['Funnel Chart'];
    }
  }
  const [graphData, setGraphData] = useState();
  const startDateParsed = useParsedStartDate(
    resetFlag,
    filteredStartDate,
    startDate,
    formatValue
  );
  const endDateParsed = useParsedEndDate(
    resetFlag,
    filteredEndDate,
    endDate,
    formatValue
  );
  const dispatch = useDispatch();
  const [weekType, setWeekType] = useState(false);
  const [date, setDate] = useState('');
  function convertDateFormat(dateStr) {
    let fullDateMatch = dateStr?.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (fullDateMatch) {
      let [_, month, day, year] = fullDateMatch;
      return { dateString: `${year}-${day}-${month}`, type: 'day' };
    }

    let monthYearMatch = dateStr?.match(/^(\d{2})\/(\d{4})$/);
    if (monthYearMatch) {
      let [_, month, year] = monthYearMatch;
      return { dateString: `${year}-${month}`, type: 'month' };
    }

    let yearMatch = dateStr?.match(/^(\d{4})$/);
    if (yearMatch) {
      return { dateString: `${yearMatch[1]}`, type: 'year' };
    }

    return 'Invalid date format';
  }
  function convertDateFormatString(dateStr) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let isoDateMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (isoDateMatch) {
      let [_, year, month, day] = isoDateMatch;
      return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
    }

    let yearMonthMatch = dateStr.match(/^(\d{4})-(\d{2})$/);
    if (yearMonthMatch) {
      let [_, year, month] = yearMonthMatch;
      return `${months[parseInt(month) - 1]} ${year}`;
    }

    let monthYearMatch = dateStr.match(/^(\d{2})\/(\d{4})$/);
    if (monthYearMatch) {
      let [_, month, year] = monthYearMatch;
      return `${months[parseInt(month) - 1]} ${year}`;
    }

    let yearMatch = dateStr.match(/^(\d{4})$/);
    if (yearMatch) {
      return yearMatch[1];
    }

    return 'Invalid date format';
  }
  const [isLoading, setIsLoading] = useState(false);
  const handleDataInsightsFetch = (data) => {
    var inputData = convertDateFormat(data?.date);
    var filter_key = [];
    if (data?.likes_count !== undefined) {
      filter_key?.push('likes');
    }
    if (data?.comments_count !== undefined) {
      filter_key.push('comments');
    }
    if (data?.reposts !== undefined) {
      filter_key.push('reposts');
    }
    if (data?.views !== undefined) {
      filter_key.push('views');
    }
    setDate(convertDateFormatString(inputData?.dateString));
    dispatch(
      DetailedInsightsData({
        preview_uuid: preview_uuid,
        job_id: job_id,
        insight_date: inputData?.dateString,
        insight_type: weekType === true ? 'week' : inputData?.type,
        filter_key: filter_key,
      })
    )
      .then((res) => {
        setGraphData(res?.payload?.response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  return (
    <>
      {resetFlag === true && filteredData === undefined ? (
        <div style={{ marginTop: '-80px' }} data-testid='loader'>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Wrapper>
          <SidePanel
            isOpen={showSidePanel}
            setIsOpen={(data) => setShowSidePanel(data)}
            pointGraphData={graphData}
            date={date}
            isLoading={graphData === null ? true : false}
            channel={channel}
            startDate={startDateParsed}
            endDate={endDateParsed}
          />
          <Container>
            <ChildContainer justify='flex-start'>
              <OverallReview
                margin={'15px'}
                backgroundColor='rgba(0, 213, 208, 0.5)'
                icon={X_icon}
                endDateParsed
                inforText='The total number of posts relevant to your query within the specified time period.'
                message={`${totalNoOfPosts} Relevant posts were observed From ${startDateParsed} to ${endDateParsed}`}
                // message= {`${totalNoOfPosts} Relevant posts were observed From ${format(startDateParsed, formatValue)} to ${format(endDateParsed, formatValue)}`}
              />
            </ChildContainer>
            {/* {filterOption ? (
                <FilterContainer>
                  <span>Gender Demographics</span>
                  <div>{genderDemographicsGraph}</div>
                </FilterContainer>
              ) : ( */}
            <ChildContainer>
              <GraphHOC
                title={'Engagement'}
                options={options}
                renderScreen={renderScreen(engagementData)}
                startDate={startDateParsed}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
                formatValue={formatValue}
                infoText={
                  'The total number of likes, replies, views, and reposts on posts relevant to your query within the specified time period.'
                }
              />
            </ChildContainer>
            {/* )} */}
          </Container>
          <Container>
            <ChildContainer>
              <GraphHOC
                title={'Gender Demographics'}
                options={options}
                renderScreen={renderScreen(genderDemographicsData)}
                startDate={startDateParsed}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
                formatValue={formatValue}
                infoText={
                  'The percentage gender distribution of user accounts relevant to your query, with categories for Male, Female, and Unknown when gender data is unavailable.'
                }
              />
            </ChildContainer>
            <ChildContainer>
              <GraphHOC
                title={'Verified and Non verified Users'}
                options={options}
                renderScreen={renderScreen(userVerificationData)}
                startDate={startDateParsed}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
                formatValue={formatValue}
                infoText={
                  'The percentage distribution of verified and unverified users relevant to your query within the specified time period, with verified users confirmed by X as authentic.'
                }
              />
            </ChildContainer>
          </Container>
          <ChildContainer>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:
                  resetFlag === true && filteredData
                    ? filteredData?.volume
                    : overview_tab?.volume,
              }}
              ylabel='Volume'
              title='Volume'
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              formatValue={formatValue}
              infoText={
                'Track the total number of posts over a specified time period.'
              }
            />
          </ChildContainer>
          <ChildContainer>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:
                  resetFlag === true && filteredData
                    ? filteredData?.trends_in_user_activity
                    : overview_tab?.trends_in_user_activity,
              }}
              ylabel='Engagement'
              title='Trends in User Activity'
              hideDropdown={false}
              startDate={startDateParsed}
              typeVal='twitter'
              channel={channel}
              endDate={endDateParsed}
              formatValue={formatValue}
              showFilters={true}
              dataPointClickEnabled={true}
              onEmitTimeSpanClick={setWeekType}
              handleDataPoint={(data) => {
                setIsLoading(true);
                setGraphData(null);
                handleDataInsightsFetch(data?.payload);
                setIsLoading(false);
                setShowSidePanel(true);
              }}
              infoText={
                'Track the total engagement on posts, combining likes, comments, reposts, and views, over a specified time period.'
              }
            />
          </ChildContainer>
          <ChildContainer>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:
                  resetFlag === true && filteredData
                    ? filteredData?.impression_trends
                    : overview_tab?.impression_trends,
              }}
              ylabel='Impressions'
              title='Impressions'
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              formatValue={formatValue}
              infoText={
                'Tracks total impressions, representing an estimate of how many times posts could have been viewed, calculated as the sum of the follower counts of users whose posts are included in the topic during the specified time period.'
              }
            />
          </ChildContainer>
          <DynamicCorrelationGraph
            correlationAnalysisData={overview_tab?.heat_map}
            startDate={startDateParsed}
            endDate={endDateParsed}
          ></DynamicCorrelationGraph>
        </Wrapper>
      )}
    </>
  );
};

export default Overview;
