import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon, CrossIconBig, CrossIconWhite, EmojiHeartIcon, FacebookLogo, InstagramLogo, sort_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import Pagination from '../Pagination/Pagination';
import CollapsibleText from '../ReviewText/collapsibleText';
import { Favorite, ThumbUp, Verified } from '@mui/icons-material';
import { topicEmptyCommentsValue } from '../../store/redux/slices/topicSlice';

// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of other content */
`;

const PopupContainer = styled.div`
  width: ${(props) => props?.popupwidth ? props?.popupwidth : '58%'}; /* Adjust the width as needed */
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: ${(props) => props.topic ? '-10px' : '-70px'};
  /*  overflow-y: scroll; */
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* color: white; */
  padding: 18px 9px;
  border-radius: 0px;
`;

const PopupBoderBottom = styled.div`
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background: rgba(217, 217, 217, 1);
`;

const IconSpan = styled.span`
  //display:flex;
  margin: 10px 0px 0px 25px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(99, 99, 99);
`;

const CloseIcon = styled.span`
  //float:right;
  margin-right: 15px;
display:flex;

  & > svg {
    fill: blue;
    stroke: ${(pop) => (pop.selectedChannel === 'Flipkart' ? 'none' : 'none')};
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height:460px;
  margin-bottom:20px;
  span {
    display: flex;
    // margin-left: 25px;

    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  // margin: 50px 25px;
  height: 100%;
  /* width: 100%; */
  margin:10px;
  .header {
    color: #636363;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;

    & > div:nth-child(2),
    & > div:nth-child(3) {
      color: #636363;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;
const RowWrapper = styled.div`
  & > div {
    width: 100%;
    min-height: 120px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    padding: 20px 0;
    &:hover {
    background-color: rgba(242, 242, 254, 1); /* Change background on hover */
    cursor: pointer; /* Change the cursor to indicate interactivity */
    // transform: scale(1.02); /* Slightly scale up the row */
    // transition: all 0.3s ease; /* Smooth transition for all changes */
    }
    &:hover .collapsible-text {
    background-color: rgba(242, 242, 254, 1) !important;  // Change background to black on hover
    color: white;  // Change text color to white to make it visible
  }
  }
   
`;
const RowData = styled.div`
  display: grid;
  grid-template-columns: 45% 1fr 1fr 1fr;
  gap: 5px;
  text-align: left;
  color: #2a2929;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;

  & .review {
    padding-left: 15px;

    & > div:nth-child(1) {
      color: #2a2929;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  & > div:nth-child(2),
  & > div:nth-child(3) {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }

  & > div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .filterColumn {
    cursor: pointer;
    display: flex;
    align-items: center;

  }

  & .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;

    & :last-child {
    //   color: #636363;
    }
  }
`;

const SpanEmoji = styled.span`
    // border: 1px solid;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
    background: ${(props) => props.color ? props.color : '#4080ff'};
    color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    
    color: white !important;
    margin-left: 2px;
    margin-right: 2px;
`;
const SearchWrapper = styled.div`
  // display: flex;
  // margin-left: -40px;
  // margin-bottom: ;
  // margin-bottom: ${(props) => (props.selectedChannel === 'Amazon' ? '105px' : '0px')};

    display: grid;
    grid-template-columns: 70% 14% 170px;
    gap: 25px;
    margin-top:20px;

    .flexWrap {
      
    display: flex;
    margin-left: -50px;
    }
  span {
    // position: relative;
    // left: 50px;
    // top: 45%;
        position: relative;
    left: 60px;
    // /* top: 35%; */
    height: 48px;
    display: flex;
    justify-content: initial;
    align-items: center;
    // margin-top: 10px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: ${(props) => props.topic ? '135px' : '170px'};
  height: 50px;
  padding: 7px 21px;
  justify-content: center;
  align-items: center;
  // margin: 0px 0px 0px 20px;
  color: white;

    outline: none; /* Remove default outline */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-radius:25px;
  // border-radius: 100px;
  background: ${({ backcolor }) => backcolor || '#353cf7'} ;
  border: none;
`;

const Input = styled.input`
  margin: 0px 0px 0px 30px;
  padding-left: 40px;
  width: 100%;
  height: 45px;
  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
    outline: none; /* Remove default outline */
  font-weight: 500;
  &:focus {
  border: 1px solid #a2a2a2;
    outline: none; /* Remove default outline */
  }
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

// Component
const PoPup = ({ channel, isOpen = false, columns = [],
  PageSize = 8, onClose, selectedChannel = '', searchType = '', setInstaDetails, popupwidth = '58%', topic = false }) => {
  const dispatch = useDispatch();
  const { commentsValue } = useSelector((state) => state.topicBuilder) || [];

  const [indexFilter, setIndexFilter] = useState(-1)
  const [commentsData, setCommentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (commentsValue) {
      setCommentsData(commentsValue);
    }
  }, [commentsValue]);

  // Early return here, so nothing below is rendered when isOpen is false
  if (!isOpen) {
    return null;
  }
  const getCurrentTableData = () => {
    let firstPageIndex = (currentPage - 1) * PageSize;
    let lastPageIndex = firstPageIndex + PageSize;

    return commentsData?.length > 0 ? commentsData?.slice(firstPageIndex, lastPageIndex) : [];
  };
  const handleClose = () => {
    dispatch(topicEmptyCommentsValue())
    onClose()
  }
  function formatNumber(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M'; // Convert to million
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    } else if (val > 0) {
      return val.toString();
    } else if (val == 0) {
      return 0;
    } else {
      return '';
    }
  }
  // if (!isOpen) {
  //   return null;
  // }

  const handleFilter = (index) => {
    const tempReviews = [...commentsData];

    if (indexFilter == index) {
      setIndexFilter(-1)
      if (columns[index] === 'Profile') {
        tempReviews?.sort((a, b) => b.user_name.localeCompare(a.user_name))
      } else if (columns[index] === 'Comment') {
        // let valA = 
        // let valB = 
        tempReviews?.sort((a, b) => (b.comment || b.text).localeCompare(a.comment || a.text))

      } else if (columns[index] == 'Reactions') {
        tempReviews?.sort(
          (a, b) =>
            (a.likes_count) - (b.likes_count)
        );
      } else if (columns[index] == 'Replies') {
        tempReviews?.sort(
          (a, b) =>
            a.replies_count - b.replies_count
        );
      } else {

        tempReviews?.sort(
          (a, b) =>
            a[columns[index].toLowerCase()] - b[columns[index].toLowerCase()]
        );
      }
    } else {
      setIndexFilter(index)
      if (columns[index] === 'Profile') {
        tempReviews?.sort((a, b) => a.user_name.localeCompare(b.user_name))
      } else if (columns[index] === 'Comment') {
        // let valA = 
        // let valB = 
        tempReviews?.sort((a, b) => (a.comment || a.text).localeCompare(b.comment || b.text))

      } else if (columns[index] == 'Reactions') {
        tempReviews?.sort(
          (a, b) =>
            (b.likes_count) - (a.likes_count)
        );
      } else if (columns[index] == 'Replies') {
        tempReviews?.sort(
          (a, b) =>
            b.replies_count - a.replies_count
        );
      } else {

        tempReviews?.sort(
          (a, b) =>
            b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
        );
      }
    }
    /* tempReviews.sort(
      (a, b) =>
        b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
    ); */



    setCommentsData([...tempReviews]);
  };
  const getWeekValue = (val) => {

    const currentDate = new Date();
    const givenDate = new Date(val);

    // Calculate the difference in milliseconds
    const diffInMs = currentDate - givenDate;

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    // console.log(diffInDays,'diffInDays');

    // Convert days to weeks
    // const diffInWeeks = diffInDays / 7;
    const diffInWeeks = Math.round(diffInDays / 7);
    const diffInHours = diffInMs / (1000 * 60 * 60);

    // Set the difference in weeks
    // setWeekDiff(diffInWeeks);
    if (diffInDays < 1) {
      // If less than a day, return hours
      return (Math.round(diffInHours) <= 1) ? `${Math.round(diffInHours)} hr ago` : `${Math.round(diffInHours)} hrs ago`;
    } else if (diffInDays < 7) {
      return (Math.round(diffInDays) == 1 ? `${Math.round(diffInDays)} day ago` : `${Math.round(diffInDays)} days ago`)
    } else {
      return `${diffInWeeks} w`
    }
    // return diffInWeeks
  }
  // useEffect(() => {
  //   if (commentsValue !== commentsData) { // Check if there's a change in data
  //     setCommentsData(commentsValue); // Set the state only when necessary
  //   }
  // }, [commentsValue]);

  // console.log(commentsData,'commemenn');

  return (
    <PopupOverlay>
      <PopupContainer topic={topic} popupwidth={popupwidth}>
        <PopupHeader >
          <IconSpan>
            {channel == 'instagram' ? InstagramLogo : channel == 'facebook' ? FacebookLogo : ''} Comments {commentsData?.length > 0 ? `(${commentsData?.length})` : ''}
          </IconSpan>
          <CloseIcon selectedChannel={selectedChannel} onClick={handleClose}>
            {CrossIconBig}
          </CloseIcon>
        </PopupHeader>
        <PopupBoderBottom>

        </PopupBoderBottom>
        <PopupBody>
          <Wrapper>
            <RowData className='header' >
              {columns.map((column, i) => {
                // if (filterCol.includes(i + 1)) {
                return (
                  <div
                    className='filterColumn'
                    key={i}
                    onClick={() => handleFilter(i)}
                  >
                    {column}&nbsp;
                    {sort_icon}
                  </div>
                );
                // }

                // return <div key={i} style={{ paddingLeft: '15px' }}>{column}</div>;
              })}
            </RowData>
            <RowWrapper>
              {getCurrentTableData()?.length > 0 && getCurrentTableData()?.map((message, index) =>
              (
                <RowData key={index} >
                  <div className='review'>
                    <div>
                      {/* Post on{' '} */}
                      {/* {formatDate(
                    channel === 'twitter'
                      ? message.date
                      : message.date || message['Reviewed on']
                  )} */}
                    </div>
                    <div>
                      <div style={{ minHeight: '60px' }}>
                        <CollapsibleText
                          text={
                            //   channel === 'twitter'
                            message.comment || message.text
                            // : message.posts || message.review_meta || message?.text
                          }
                          link=''

                        />
                      </div>

                      <div style={{ marginBottom: '-10px' }}>
                        {/* {message.time} */}
                        {getWeekValue(message.time || message.created_at)}
                      </div>
                    </div>

                  </div>
                  {/* columns.includes('Profile') && */}
                  {(
                    <div className='profile'>
                      <span >{message.user_name}
                        {message?.verified &&
                          <span className='verified' style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                            <Verified style={{ color: 'rgb(25, 118, 210)' }} />
                          </span>
                        }

                      </span>

                      {/* {channel === 'instagram' && (
                    <span style={{ fontSize: '12px' }}>
                      {formatNumberVal(message['followers_count'] || 0)}
                    </span>
                  )} */}
                    </div>
                  )}
                  <div className='helpful'>

                    {channel == 'facebook' ? '' : formatNumber(message?.likes_count || message?.likes) || 0}
                    {channel == 'facebook' ?
                      <span style={{ marginLeft: '5px', color: 'rgba(222, 13, 13, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SpanEmoji color='#4080ff'>
                          <ThumbUp style={{
                            height: '100%',
                            width: '90%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px'
                          }} />
                        </SpanEmoji>
                        <SpanEmoji color='#ed5168'>
                          <Favorite style={{
                            height: '100%',
                            width: '90%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px'
                          }} />
                        </SpanEmoji>
                        <span style={{ marginLeft: '2px', marginRight: '4px' }}>
                          {EmojiHeartIcon}
                        </span>
                        <span>{formatNumber(message?.likes_count || message?.likes) || 0} </span>
                      </span> : <span style={{ marginLeft: '5px', color: 'rgba(222, 13, 13, 1)' }}><Favorite /></span>}
                  </div>
                  <div>
                    {(formatNumber(message?.replies || message?.replies_count)) || 0}
                  </div>

                </RowData>
              )
              )}
            </RowWrapper>
            <Pagination
              currentPage={currentPage}
              totalCount={commentsData?.length || 0}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              reviewsData={commentsData}
            />
          </Wrapper>


        </PopupBody>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default PoPup;
