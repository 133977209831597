import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';
import { filter_icon } from '../../../../Common/SvgIcon/CommonSvgIcons';

function generateDynamicLinearGradient(value, colorType) {
    if (typeof value !== 'number') {
        return '#fff';
    }

    const colorStops = [
        { value: 1, color: '#008481' },
        { value: 0.9, color: '#008481' },
        { value: 0.8, color: '#1a918e' },
        { value: 0.7, color: '#339d9a' },
        { value: 0.6, color: '#4da9a7' },
        { value: 0.5, color: '#66b5b3' },
        { value: 0.4, color: '#7fc1bf' },
        { value: 0.3, color: '#99cecd' },
        { value: 0.2, color: '#b3dbda' },
        { value: 0.1, color: '#cce6e6' },
        { value: 0, color: '#F3F9F9' },
    ];

    let stop1;

    if (colorType === 'type2') {
        stop1 = colorStops.find((stop) => value.toFixed(1) >= stop.value);
    }

    const background = `${stop1?.color}`;
    return background;
}

const HeatmapCell = styled.div`
  background: ${(props) =>
        generateDynamicLinearGradient(props?.value, props?.colorType)};
  border: 1px solid white;
  height: ${(props) =>
        props?.xAxis === AxisHour
            ? 'unset'
            : props?.xAxis === AxisWeek
                ? '40px'
                : props?.xAxis === AxisQuarter
                    ? '40px'
                    : '35px'};
  width: ${(props) =>
        props?.xAxis === AxisHour
            ? 'unset'
            : props?.xAxis === AxisWeek
                ? '35px'
                : props?.xAxis === AxisQuarter
                    ? '40px'
                    : '35px'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;

  ${(props) => {
        if (props?.value === 'hide') {
            return 'opacity:0';
        }
    }};
`;
const X_AxisLabel = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: ${(props) =>
        props?.currentXAxis === AxisMonth
            ? '5px'
            : props?.currentXAxis === AxisWeek
                ? '5px'
                : '5px'};
  margin-left: 110px;
  font-size: 14px;
  margin-left: ${(props) =>
        props?.currentXAxis === AxisHour
            ? '30px'
            : props?.currentXAxis === AxisMonth
                ? '40px'
                : props?.currentXAxis === AxisWeek
                    ? '40px'
                    : '50px'};
  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;

  font-weight: 400;
`;

const GridContainer = styled.div`
  width: ${(props) =>
        props?.xAxis === AxisHour
            ? '80%'
            : props?.xAxis === AxisMonth
                ? '60%'
                : props?.xAxis === AxisWeek
                    ? '30%'
                    : props?.xAxis === AxisQuarter
                        ? '25%'
                        : 'unset'};
`;
const HeatmapRowContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
        props?.xAxis === AxisHour
            ? 'repeat(24, 25px)'
            : props?.xAxis === AxisMonth
                ? 'repeat(12,  35px)'
                : props?.xAxis === AxisWeek
                    ? 'repeat(7, 35px)'
                    : props?.xAxis === AxisQuarter
                        ? 'repeat(4, 40px)'
                        : 'repeat(10, 70px)'};
`;

const HeatmapRow = ({
    rowData,
    colorType = 'type1',
    min,
    max,
    currentXAxis,
}) => {
    return (
        <HeatmapRowContainer xAxis={currentXAxis}>
            {rowData?.map((value, index) => {
                return (
                    <HeatmapCell
                        key={index}
                        xAxis={currentXAxis}
                        value={(value - min) / (max - min)}
                        colorType={colorType}
                        title={value}
                    ></HeatmapCell>
                );
            })}
        </HeatmapRowContainer>
    );
};

const HeatmapHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
        props?.xAxis === AxisHour
            ? 'repeat(24, 25px)'
            : props?.xAxis === AxisMonth
                ? 'repeat(12,  35px)'
                : props?.xAxis === AxisWeek
                    ? 'repeat(7, 35px)'
                    : props?.xAxis === AxisQuarter
                        ? 'repeat(4,40px)'
                        : 'repeat(10, 70px)'};
`;

const HeatmapRowWrapper = styled.div`
  display: flex;
`;

const YLabelCell = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: end;
  margin-right: 3px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;
`;
const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;
const MainContainer = styled.div`
  margin-top: 45px;
  display: flex;
  gap: 10px;
  justify-content: ${(props) =>
        props?.xAxis === AxisHour || props?.xAxis === AxisMonth
            ? 'space-evenly'
            : 'center'};
  padding-right: 20px;
  margin-left: ${(props) => (props?.xAxis === AxisHour ? '-35px' : 'unset')};
  min-height: 120px;
  align-items: center;
  margin-bottom: 45px;
`;

const HeatmapGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: grid;
    grid-template-columns: ${(props) =>
        props?.xAxis === AxisHour
            ? 'repeat(24, 25px)'
            : props?.xAxis === AxisMonth
                ? 'repeat(12,  35px)'
                : props?.xAxis === AxisWeek
                    ? 'repeat(7, 35px)'
                    : props?.xAxis === AxisQuarter
                        ? 'repeat(4, 40px)'
                        : 'repeat(10, 70px)'};
  }
`;

const ColorRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: ${(props) =>
        props?.xAxis === AxisMonth
            ? '40px'
            : props?.xAxis === AxisHour
                ? '-45px'
                : '80px'};
  & > div {
    width: 20px;
    height: 20px;
    cursor: pointer;

    & .label {
      display: none;
    }
  }

  .colorIndicatorType3 {
    background: linear-gradient(
      180deg,
      #6f62fe 5.28%,
      #75b0f5 20%,
      #ffffff 40%,
      #ffffff 50%,
      #ffa7a9 82%,
      #fc746c 100%
    );
    border: 1px grey solid;
  }

  .colorIndicatorType2 {
    background: linear-gradient(
      180deg,
      #008481 0%,
      /* Deep teal */ #00d5d0 20%,
      /* Bright cyan */ #b4eae9 40%,
      /* Soft aqua */ #f6fdfc 70%,
      /* Near white */ #ffffff 100% /* Pure white */
    );
  }
  .colorIndicatorType1 {
    background: linear-gradient(
      180deg,
      #c20000 5.28%,
      #ec3539 16.22%,
      #e9a9aa 29.5%,
      #fceaea 46.5%,
      #ffe49c 50%,
      #88f8b6 67%,
      #0cbb53 84.5%,
      #008335 100%
    );
    border: 1px grey solid;
  }

  .activeType {
    height: 510px;
    position: relative;

    & > .label {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      right: -40px;
      font-size: 15px;
      font-weight: 400;
      text-align: right;
    }
  }
`;

const Sentiment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 20px 40px;
  line-height: 25px;
  gap: 10px;
  overflow: hidden;
  flex-wrap: wrap;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0px 20px -15px;
`;

const AxisHour = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

const AxisWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

const AxisQuarter = ['Q1', 'Q2', 'Q3', 'Q4'];

const AxisMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
];

// const yAxis = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  height: 35px;
  padding: 5px 20px;
  border-radius: 10px;

  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Options = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #a2a2a2;
  padding-top: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  ${({ apply }) =>
        apply
            ? `
    background-color: #3b82f6;
    color: white;
  `
            : `
    background-color: #f5f5f5;
    color: #333;
  `}

  &:hover {
    opacity: 0.9;
  }
`;
const LabelText = styled.div`
  font-size: 12px;
`;
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 200px;
  right: 0px;
`;

const XLabel = styled.span`
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-left: ${(props) =>
        props?.currentXAxis === AxisQuarter ? '10px' : 'unset'};
`;
const YLabel = styled.span`
  font-size: 20px;
  transform: ${(props) =>
        props?.currentXAxis === AxisHour
            ? 'translateY(-200%) rotate(-90deg)'
            : 'translateY(-100%) rotate(-90deg)'};
  display: inline-block;
  min-width: ${(props) =>
        props?.currentXAxis === AxisHour ? 'unset' : '145px'};
`;

const DynamicCorrelationGraph = ({ xVAl, yVAl, correlationAnalysisData,
    startDate,
    endDate, }) => {
    useEffect(() => { }, [correlationAnalysisData]);
    const [selectedColor, setSelectedColor] = useState('type2');
    const isEmpty = Object.entries(correlationAnalysisData ?? {}).length === 0;
    const [selectedOptions, setSelectedOptions] = useState(['xhour', 'yday']);
    const [xAxis, setXAxis] = useState('Hour of day');
    const [yAxis, setYAxis] = useState('Day of week');

    const transposeMatrix = (matrix) => {
        if (!isEmpty) {
            if (matrix !== null || matrix !== undefined) {
                
                return matrix?.[0]?.map((_, colIndex) =>
                    matrix?.map((row) => row[colIndex])
                );
            } else return [];
        } else return [];
    };
    const cleanedData = [];
    for (const key in correlationAnalysisData) {
        if (!isEmpty) {
            cleanedData[key] = correlationAnalysisData[key];
        }
    }

    const dataMapping = {
        'hour-day': transposeMatrix(cleanedData['hour_day']),
        'hour-quarter': transposeMatrix(cleanedData['hour_quarter']),
        'hour-month': transposeMatrix(cleanedData['hour_month']),
        'day-hour': cleanedData['hour_day'],
        'day-quarter': transposeMatrix(cleanedData['day_quarter']),
        'day-month': transposeMatrix(cleanedData['day_month']),
        'quarter-hour': cleanedData['hour_quarter'],
        'quarter-day': cleanedData['day_quarter'],
        'quarter-month': transposeMatrix(cleanedData['quarter_month']),
        'month-hour': cleanedData['hour_month'],
        'month-day': cleanedData['day_month'],
        'month-quarter': cleanedData['quarter_month'],
    };
    const [currentData, setCurrentData] = useState(
        transposeMatrix(dataMapping?.['day-hour'])
    );
    const [currentXAxis, setCurrentXAxis] = useState(AxisHour);
    const [currentYAxis, setCurrentYAxis] = useState(AxisWeek);
    const [scale, setScale] = useState([]);

    let minVal, maxVal
    if (currentData) {

        const flatArray = currentData?.flat();
        minVal = Math.min(...flatArray);
        maxVal = Math.max(...flatArray);
    }
    const handleFilterChange = (filterArray) => {

        const filter = filterArray?.sort((a, b) => {


            if (a.startsWith('x')) return -1;
            if (b.startsWith('x')) return 1;
            return 0;
        });

        const key = `${filter[0]}-${filter[1]}`;
        switch (filter[0]) {
            case 'hour':
                setCurrentXAxis(AxisHour);
                setXAxis('Hour of day');
                break;
            case 'day':
                setCurrentXAxis(AxisWeek);
                setXAxis('Day of week');
                break;
            case 'month':
                setCurrentXAxis(AxisMonth);
                setXAxis('Month of year');
                break;
            case 'quarter':
                setCurrentXAxis(AxisQuarter);
                setXAxis('Quarter of year');
                break;
            default:
                break;
        }
        switch (filter[1]) {
            case 'hour':
                setCurrentYAxis(AxisHour);
                setYAxis('Hour of day');
                break;
            case 'day':
                setCurrentYAxis(AxisWeek);
                setYAxis('Day of week');

                break;
            case 'month':
                setCurrentYAxis(AxisMonth);
                setYAxis('Month of year');

                break;
            case 'quarter':
                setCurrentYAxis(AxisQuarter);
                setYAxis('Quarter of year');

                break;
            default:
                break;
        }

        setCurrentData(dataMapping[key]);
    };
    useEffect(() => {
        if (xVAl && yVAl) {
            handleFilterChange([xVAl, yVAl]);
        } else {
            console.log("xVAl or yVal is undefined.");
        }
    }, [xVAl, yVAl, correlationAnalysisData]);
    useEffect(() => {
        if (currentData) {

            const flatArray = currentData?.flat();
            const minVal = Math.min(...flatArray);
            const maxVal = Math.max(...flatArray);
            const step = (maxVal - minVal) / 9;
            const intervalArray = Array.from({ length: 10 }, (_, i) =>
                Math.round(minVal + i * step)
            );
            setScale(intervalArray?.sort((a, b) => b - a));
        }
    }, [currentData]);

    
  if (!correlationAnalysisData || isEmpty) {
    return (
      <Sentiment>
        <div>
          <Title>
            <TitleWithInfo
              title={'Heat Map'}
              infoText={
                'Track the correlation coefficients between variables. A correlation of 1 indicates a perfect positive relationship, meaning as one variable increases, the other increases in perfect proportion. A lower value suggests a weaker or no relationship, with values closer to 0 indicating little to no correlation, and negative values representing inverse relationships between the variables.'
              }
            />
          </Title>
        </div>
        <MainContainer>
          <div
            style={{
              color: 'rgb(99, 99, 99)',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Insufficient Data
          </div>
        </MainContainer>
      </Sentiment>
    );
  }
    return (
        <Sentiment id='correlation-graph'>
            <div>
                <Title>
                    <TitleWithInfo
                        title={'Heat Map'}
                        infoText={
                            'Track the correlation coefficients between variables. A correlation of 1 indicates a perfect positive relationship, meaning as one variable increases, the other increases in perfect proportion. A lower value suggests a weaker or no relationship, with values closer to 0 indicating little to no correlation, and negative values representing inverse relationships between the variables.'
                        }
                    />
                </Title>

            </div>

            <MainContainer xAxis={currentXAxis}>
                <YLabel currentXAxis={currentXAxis}>{yAxis}</YLabel>
                <GridContainer xAxis={currentXAxis} data-testid="heatmap-grid">
                    <div>
                        <HeatmapGraphContainer xAxis={currentXAxis}>
                            {currentYAxis
                                ?.slice(0, currentData?.length)
                                ?.map((rowData, rowIndex) => (
                                    <HeatmapRowWrapper key={rowIndex}>
                                        <YLabelCell xAxis={currentXAxis} yAxis={currentYAxis}>
                                            {rowData}
                                        </YLabelCell>
                                        <HeatmapRow
                                            min={minVal}
                                            max={maxVal}
                                            rowData={currentData ? currentData[rowIndex] : []}
                                            currentXAxis={currentXAxis}
                                            colorType={selectedColor}
                                        />
                                    </HeatmapRowWrapper>
                                ))}
                        </HeatmapGraphContainer>
                    </div>
                    <HeatmapHeader xAxis={currentXAxis}>
                        {currentXAxis
                            ?.slice(0, currentData?.[0]?.length)
                            ?.map((label, index) => (
                                <X_AxisLabel
                                    key={index}
                                    yAxis={currentYAxis}
                                    currentXAxis={currentXAxis}
                                >
                                    {label}
                                </X_AxisLabel>
                            ))}
                    </HeatmapHeader>

                    <XLabel currentXAxis={currentXAxis}>{xAxis}</XLabel>
                </GridContainer>
                <ColorRangeWrapper xAxis={currentXAxis} colorType={selectedColor} data-testid="color-range">
                    <div
                        className={`colorIndicatorType2 ${selectedColor === 'type2' ? 'activeType' : ''
                            }`}
                        onClick={() => setSelectedColor('type2')}
                    >
                        <div className='label'>
                            {scale?.map((data, index) => {
                                return (
                                    <span style={{ fontSize: '14px' }} key={index}>
                                        {data}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </ColorRangeWrapper>
            </MainContainer>
            <Span>{` This data is observed from ${startDate} to ${endDate}`}</Span>
        </Sentiment>
    );
};

export default DynamicCorrelationGraph;
