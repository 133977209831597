import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReviewLineChart from './LineChart';
import AreaGraph from './AreaGraph';
import { format } from 'date-fns';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';
import { filter_icon } from '../SvgIcon/CommonSvgIcons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  box-sizing: border-box;
`;
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 200px;
  right: 0px;
`;
const Title = styled.div`
  /* color: #636363; */
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;

  color: #fff;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: #222;
  border-radius: 0px;
  ${(props) =>
    props.selected &&
    `
      color: #fff;
      background:#353CF7;
      border-radius: 4px !important;
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const MainContainer = styled.div`
  flex: 1;
  /* display: flex; */
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  align-items: center;
  margin-top: 10px;
  min-height: 300px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 180px;
  padding-top: 50px;
  z-index: 9;
`;
const StyledButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  height: 35px;
  padding: 5px 20px;
  border-radius: 10px;

  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const FilterOptions = ({
  filterChange,
  cancelFunction,
  selectedOptions,
  setSelectedOptions,
  channel,
}) => {
  const [tempSelectedOptions, setTempSelectedOptions] =
    useState(selectedOptions);
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setTempSelectedOptions((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  const handleClearFunc = () => {
    setShowFilterOptions(false);
    setSelectedOptions(['likes_count', 'comments_count', 'reposts', 'views']);
    cancelFunction();
  };
  return (
    <DropdownContainer>
      <StyledButton onClick={() => setShowFilterOptions(!showFilterOptions)}>
        Filters {filter_icon}
      </StyledButton>
      {showFilterOptions && (
        <DropdownMenu>
          <Options>
            <input
              type='checkbox'
              id='likes_count'
              checked={tempSelectedOptions.includes('likes_count')}
              onChange={handleCheckboxChange}
            />
            Likes
          </Options>
          <Options>
            <input
              type='checkbox'
              id='comments_count'
              checked={tempSelectedOptions.includes('comments_count')}
              onChange={handleCheckboxChange}
            />
            Comments
          </Options>
          {channel === 'twitter' && (
            <>
              <Options>
                <input
                  type='checkbox'
                  id='reposts'
                  checked={tempSelectedOptions.includes('reposts')}
                  onChange={handleCheckboxChange}
                />
                Reposts
              </Options>
              <Options>
                <input
                  type='checkbox'
                  id='views'
                  checked={tempSelectedOptions.includes('views')}
                  onChange={handleCheckboxChange}
                />
                Views
              </Options>
            </>
          )}
          <ButtonContainer>
            <Button
              onClick={() => {
                handleClearFunc();
              }}
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                setSelectedOptions(tempSelectedOptions);
                filterChange(tempSelectedOptions);
                setShowFilterOptions(false);
              }}
              apply
            >
              Apply
            </Button>
          </ButtonContainer>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

const ToggleButton = ({
  options = [],
  onClick = () => {},
  padding = '5px 20px',
  option,
}) => {
  const [selectedOption, setSelectedOption] = useState(option || options[0]);
  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onClick(option);
  };

  return (
    <ToggleButtonWrapper>
      {options.map((option) => (
        <Option
          selected={selectedOption === option}
          onClick={() => handleSelectOption(option)}
          padding={padding}
          key={option}
        >
          {option}
        </Option>
      ))}
    </ToggleButtonWrapper>
  );
};
const Container = styled.div`
  width: 200px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 2px;
`;

const Options = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #a2a2a2;
  padding-top: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  ${({ apply }) =>
    apply
      ? `
    background-color: #3b82f6;
    color: white;
  `
      : `
    background-color: #f5f5f5;
    color: #333;
  `}

  &:hover {
    opacity: 0.9;
  }
`;

const TrendsInUserActivity = ({
  data1,
  title,
  ylabel,
  option,
  hideDropdown,
  startDate,
  endDate,
  formatValue = 'dd-yyyy-MM',
  infoText = '',
  showFilters = false,
  channel,
  typeVal = '',
  handleDataPoint,
  onEmitTimeSpanClick,
  dataPointClickEnabled = false,
}) => {
  const [timeSpan, setTimeSpan] = useState(option || 'Days');
  const [lineGraph, setLineGraph] = useState(true);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showFiltereData, setShowFilteredData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    channel !== 'twitter'
      ? ['likes_count', 'comments_count']
      : ['likes_count', 'comments_count', 'reposts', 'views']
  );

  let trendsInUserActivity;

  if (timeSpan === 'Days') {
    trendsInUserActivity = data1?.trends_in_user_activity?.days || {};
  } else if (timeSpan === 'Weeks') {
    trendsInUserActivity = data1?.trends_in_user_activity?.week || {};
  } else if (timeSpan === 'Months') {
    trendsInUserActivity = data1?.trends_in_user_activity?.month || {};
  } else if (timeSpan === 'Years') {
    trendsInUserActivity = data1?.trends_in_user_activity?.year || {}; //post API integration, update with year attribute
  }

  const timeline = trendsInUserActivity?.timeline || [];
  const reviewsCount =
    title === 'Impressions'
      ? trendsInUserActivity?.impression || []
      : trendsInUserActivity?.count || [];
  const engagementCount = trendsInUserActivity?.engagement_count || [];
  const likesCount = trendsInUserActivity?.likes_count || [];
  const commentsCount = trendsInUserActivity?.comments_count || [];
  const repostsCount = trendsInUserActivity?.reposts || [];
  const viewsCount = trendsInUserActivity?.views || [];

  const userActivityData =
    timeline &&
    (likesCount || engagementCount || commentsCount) &&
    timeline.length > 0 &&
    (reviewsCount.length > 0 ||
      engagementCount.length > 0 ||
      likesCount.length > 0 ||
      commentsCount.length > 0)
      ? timeline
          .map((date, index) => {
            if (date) {
              if (
                typeVal == 'instagram' &&
                title == 'Trends in User Activity'
              ) {
                return {
                  date,
                  engagement_count: engagementCount[index] || 0,
                  likes_count: likesCount[index] || 0,
                  comments_count: commentsCount[index] || 0,
                  // [`${ylabel?.toLowerCase() || 'reviews'}`]: reviewsCount[index] || 0,
                };
              } else if (
                typeVal === 'twitter' &&
                title == 'Trends in User Activity'
              ) {
                {
                  return {
                    date,
                    engagement_count: engagementCount[index] || 0,
                    likes_count: likesCount[index] || 0,
                    comments_count: commentsCount[index] || 0,
                    reposts: repostsCount[index] || 0,
                    views: viewsCount[index] || 0,
                    // [`${ylabel?.toLowerCase() || 'reviews'}`]: reviewsCount[index] || 0,
                  };
                }
              } else {
                return {
                  date,
                  [`${ylabel?.toLowerCase() || 'reviews'}`]:
                    reviewsCount[index] || 0,
                };
              }
            }
            // Return null if the date is invalid, to be filtered later
            return null;
          })
          .filter((item) => item !== null) // Remove any null entries
      : [];

  function filterAndCleanData(data, filterKeys) {
    const keyMap = {
      likes_count: 'likes_count',
      comments_count: 'comments_count',
      reposts: 'reposts',
      views: 'views',
    };

    return data.map((entry) => {
      const newEngagementCount = filterKeys.reduce((sum, key) => {
        const field = keyMap[key];
        return sum + (entry[field] || 0);
      }, 0);

      const filteredEntry = {
        engagement_count: newEngagementCount,
        date: entry.date,
      };

      filterKeys.forEach((key) => {
        const field = keyMap[key];
        if (field && field in entry) {
          filteredEntry[key] = entry[field];
        }
      });

      return filteredEntry;
    });
  }
  const handleActiveFilter = (selectedFilter) => {
    setShowFilteredData(true);
    setSelectedOptions(selectedFilter);
    setFilteredData(filterAndCleanData(userActivityData, selectedFilter));
  };

  useEffect(() => {
    if (showFiltereData) {
      setFilteredData(filterAndCleanData(userActivityData, selectedOptions));
    }
  }, [timeSpan, userActivityData]);

  const handleTimeSpanClick = (option) => {
    if (onEmitTimeSpanClick !== undefined) {
      onEmitTimeSpanClick(option === 'Weeks' ? true : false);
    }
    setTimeSpan(option); // State updates, triggering useEffect
  };

  const handleGraphSelection = (option) => {
    setLineGraph((prev) => !prev);
  };

  return (
    <Wrapper>
      <Title>
        <p>
          <TitleWithInfo title={title} infoText={infoText} />
        </p>

        <div style={{ display: 'flex' }}>
          {showFilters && (
            <FilterOptions
              selectedOptions={selectedOptions}
              channel={channel}
              setSelectedOptions={(data) => {
                setSelectedOptions(data);
              }}
              cancelFunction={() => {
                setShowFilterOptions(false);
                setShowFilteredData(false);
              }}
              filterChange={(data) => {
                handleActiveFilter(data, userActivityData);
                setShowFilterOptions(false);
              }}
            />
          )}
          {hideDropdown !== true && (
            <ToggleButton
              options={['Line Graph', 'Area Graph']}
              onClick={handleGraphSelection}
            />
          )}
        </div>
      </Title>
      {hideDropdown !== true && (
        <ToggleButton
          options={['Days', 'Weeks', 'Months', 'Years']} //add years once api is available
          onClick={handleTimeSpanClick}
          padding='3px 30px'
          option={option}
        />
      )}

      {userActivityData && (
        <MainContainer>
          {lineGraph ? (
            <ReviewLineChart
              height={370}
              data={showFiltereData ? filteredData : userActivityData}
              ylabel={ylabel}
              typeVal={typeVal}
              dataPointClickEnabled={dataPointClickEnabled}
              handleDataPoint={(data) => {
                if (selectedOptions?.length > 0) handleDataPoint(data);
              }}
              showTitle={title}
            />
          ) : (
            <AreaGraph
              height={370}
              data={showFiltereData ? filteredData : userActivityData}
              typeVal={typeVal}
              ylabel={ylabel}
              showTitle={title}
              dataPointClickEnabled={dataPointClickEnabled}
              handleDataPoint={(data) => {
                if (selectedOptions?.length > 0) handleDataPoint(data);
              }}
            />
          )}
        </MainContainer>
      )}
      {(startDate || endDate) && (
        <Span>
          This data is observed from {startDate} to {endDate}
        </Span>
      )}
    </Wrapper>
  );
};

export default TrendsInUserActivity;
