import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import ClusterAnalysisGraph from '../../../components/ClusterAnalysisGraph/ClusterAnalysisGraph';
import { format, parse } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  padding: 30px 40px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Container = styled.div`
  height: 600px;
  padding: 25px 20px 25px 50px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  max-width:1200px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin-left: -20px;
  margin-bottom: 45px;
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 60px;
`;

const sentimentColor = {
  1: '#73EBA4',
  0: '#FBD77D',
  '-1': '#FC6758',
};

const sentimentLabel = {
  '-1': 'Negative',
  1: 'Positive',
  0: 'Neutral',
};

const emotionLabel = {
  fear: 'Fear',
  surprise: 'Surprise',
  neutral: 'Neutral',
  anger: 'Anger',
  disgust: 'Disgust',
  sadness: 'Sadness',
  joy: 'Joy',
};

const emotionColor = {
  fear: '#FC9E58',
  surprise: '#B7DC68',
  neutral: '#F5CC61',
  anger: '#F74945',
  disgust: '#9071CE',
  sadness: '#4B9AE9',
  joy: '#FE00D5',
};

const ClusterAnalysisTab = ({
  cluster_analysis = {},
  startDate = '',
  endDate = '',
  filteredData,
  resetFlag,
  filteredStartDate,
  filteredEndDate,
  channel,
  job_id,
  formatValue = 'dd-MM-yyyy',
}) => {
  // Function to parse dates with date-fns

  const startDateParsed = useParsedStartDate(
    resetFlag,
    filteredStartDate,
    startDate,
    formatValue
  );
  const endDateParsed = useParsedEndDate(
    resetFlag,
    filteredEndDate,
    endDate,
    formatValue
  );

  if (Object.keys(cluster_analysis).length === 0) {
    return (
      <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
        {/* No data available */}Insufficient Data
      </h2>
    );
  }

  return (
    <Wrapper className='pdf-page' style={{ pageBreakInside: 'avoid' }}>
      <Container>
        <Title>
        <TitleWithInfo
              title={'Sentiments'}
              infoText={`Identify patterns and groupings in consumer sentiments to better understand user reactions and emerging trends, based on a minimum of 20 data points.`}
            /></Title>
        <ClusterAnalysisGraph
          title={'Sentiments'}
          job_id={job_id}
          xAxisValues={
            resetFlag && filteredData
              ? filteredData.sentiment_data['X-axis']
              : cluster_analysis.sentiment_data['X-axis']
          }
          yAxisValues={
            resetFlag && filteredData
              ? filteredData.sentiment_data['Y-axis']
              : cluster_analysis.sentiment_data['Y-axis']
          }
          sentimentLabel={sentimentLabel}
          sentimentColor={sentimentColor}
          sentiment={
            resetFlag && filteredData
              ? filteredData.sentiment_data.sentiment
              : cluster_analysis.sentiment_data.sentiment
          }
          reviews={
            resetFlag && filteredData
              ? filteredData.sentiment_data.reviews
              : cluster_analysis.sentiment_data.reviews
          }
          name={
            resetFlag && filteredData
              ? filteredData.sentiment_data.user_name
              : cluster_analysis.sentiment_data.user_name
          }
          date={
            resetFlag && filteredData
              ? filteredData.sentiment_data.date
              : cluster_analysis.sentiment_data.date
          }
          type={'Sentiment'}
          moduleType='Twitter'
          likes={
            channel === 'instagram'
              ? resetFlag && filteredData
                ? filteredData.sentiment_data.likes_count
                : cluster_analysis.sentiment_data.likes_count
              : resetFlag && filteredData
              ? filteredData.sentiment_data.retweet_count
              : cluster_analysis.sentiment_data.retweet_count
          }
          circle={
            resetFlag && filteredData
              ? filteredData.sentiment_data['circle']
              : cluster_analysis.sentiment_data['circle']
          }
        />
        <Footer>{`This data is observed from ${startDateParsed} to ${endDateParsed}`}</Footer>
      </Container>

      <Container>
      <Title>
        <TitleWithInfo
              title={'Emotions'}
              infoText={`Identify patterns and groupings in consumer emotions to better understand user reactions and emerging trends, based on a minimum of 20 data points.`}
            /></Title>
        {/* <Title>Emotions</Title> */}
        <ClusterAnalysisGraph
          title={'Emotions'}
          job_id={job_id}
          xAxisValues={
            resetFlag && filteredData
              ? filteredData.emotion_data['X-axis']
              : cluster_analysis.emotion_data['X-axis']
          }
          yAxisValues={
            resetFlag && filteredData
              ? filteredData.emotion_data['Y-axis']
              : cluster_analysis.emotion_data['Y-axis']
          }
          sentimentLabel={emotionLabel}
          sentimentColor={emotionColor}
          sentiment={
            resetFlag && filteredData
              ? filteredData.emotion_data.emotion
              : cluster_analysis.emotion_data.emotion
          }
          reviews={
            resetFlag && filteredData
              ? filteredData.emotion_data.reviews
              : cluster_analysis.emotion_data.reviews
          }
          name={
            resetFlag && filteredData
              ? filteredData.emotion_data.user_name
              : cluster_analysis.emotion_data.user_name
          }
          date={
            resetFlag && filteredData
              ? filteredData.emotion_data.date
              : cluster_analysis.emotion_data.date
          }
          likes={
            channel === 'instagram'
              ? resetFlag && filteredData
                ? filteredData.emotion_data.likes_count
                : cluster_analysis.emotion_data.likes_count
              : resetFlag && filteredData
              ? filteredData.emotion_data.retweet_count
              : cluster_analysis.emotion_data.retweet_count
          }
          circle={
            resetFlag && filteredData
              ? filteredData.sentiment_data['circle']
              : cluster_analysis.emotion_data['circle']
          }
        />
        <Footer>{`This data is observed from ${startDateParsed} to ${endDateParsed}`}</Footer>
      </Container>
    </Wrapper>
  );
};

export default ClusterAnalysisTab;
