import React from 'react';
import { ChannelsText, CreateTopic, FlexForLogoDiv, H3TitleForInsta, HeaderWrapper, InstagramImgDiv, PTitleforInsta, QueryPara, Span1, Span2, StyledContainer, TwitterText } from './ReportHeader.styled';
import { Icon } from '@mui/material';
import { FacebookLogoSmall, InstagramIconSmall, NewsLogo, StyledAddIcon, X_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import { Verified } from '@mui/icons-material';

const ReportHeaderFile = ({topicPreviewData,handleCreatedTopic,job_id}) => {
    function formatNumber(num) {
        if (num >= 1_000_000_000) {
          // Billion
          return (num / 1_000_000_000).toFixed(1) + 'B';
        } else if (num >= 1_000_000) {
          // Million
          return (num / 1_000_000).toFixed(1) + 'M';
        } else if (num >= 1000) {
          // Thousand
          return (num / 1000).toFixed(1) + 'k';
        } else {
          return num; // Return the number as is if it's less than 1000
        }
      }
    
  const transformText = (inputText) => {
    if (inputText == 'twitter') {
      return ' ';
    }
    const words = inputText?.split(' ');
    const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    return transformedWords?.join(' ')
  };

    return (

        <HeaderWrapper>
            <Span1>{transformText(topicPreviewData?.title)}</Span1>
            <Span2 style={{ width: typeof job_id !== 'number' ? '80%' : '100%' }}>{topicPreviewData?.description}</Span2>
            <StyledContainer>
                <ChannelsText>Channel:</ChannelsText>
                <TwitterText>
                    <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : topicPreviewData?.channel === 'news' ? NewsLogo : topicPreviewData?.channel === 'instagram' ? InstagramIconSmall : topicPreviewData?.channel === 'facebook' ? FacebookLogoSmall : ''}</Icon>
                    {topicPreviewData?.channel == 'twitter' ? '' : transformText(topicPreviewData?.channel)}
                </TwitterText>
            </StyledContainer>
            {
                topicPreviewData?.channel == 'facebook' && topicPreviewData?.facebook_search_type == 'pages' ?
                    <div>
                        <FlexForLogoDiv>
                            <InstagramImgDiv>

                                <img src={topicPreviewData?.profile_pic_url || ''} alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />

                            </InstagramImgDiv>
                            <div>
                                <H3TitleForInsta>{topicPreviewData?.user_name || decodeURIComponent(topicPreviewData?.user_name || '')}
                                    <span style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                                        {/*  */}
                                        {topicPreviewData?.search_type == "user" && topicPreviewData?.verified == true && <Verified />}
                                    </span></H3TitleForInsta>
                                <PTitleforInsta>{topicPreviewData?.full_name || ''}</PTitleforInsta>
                                <PTitleforInsta>

                                    {topicPreviewData?.category || ''} {(`${formatNumber(topicPreviewData?.followers) || 0} followers`)
                                    }
                                </PTitleforInsta>

                                <PTitleforInsta>
                                    {topicPreviewData?.description || topicPreviewData?.page_description}
                                </PTitleforInsta>
                            </div>
                        </FlexForLogoDiv>

                    </div>
                    :
                    topicPreviewData?.channel == 'instagram' && topicPreviewData?.search_type == 'hashtag' ?
                        <div>
                            <FlexForLogoDiv>
                                <InstagramImgDiv>

                                    <div style={{ width: '70px', borderRadius: '50%', fontSize: '50px', textAlign: 'center' }}>#</div>

                                </InstagramImgDiv>
                                <div>

                                    <H3TitleForInsta>{topicPreviewData?.user_name && `#${topicPreviewData?.user_name}` || ''}</H3TitleForInsta>
                                    <PTitleforInsta>{`${formatNumber(topicPreviewData?.engagement)} posts` || ''}</PTitleforInsta>
                                </div>
                            </FlexForLogoDiv>

                        </div>
                        :
                        topicPreviewData?.channel == 'instagram' && topicPreviewData?.search_type == 'user' ?
                            <div>
                                <FlexForLogoDiv>
                                    <InstagramImgDiv>

                                        <img src={topicPreviewData?.profile_pic_url || ''} alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />

                                    </InstagramImgDiv>
                                    <div>
                                        <H3TitleForInsta>{topicPreviewData?.user_name || decodeURIComponent(topicPreviewData?.user_name || '')}
                                            <span style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                                                {/*  */}
                                                {topicPreviewData?.search_type == "user" && topicPreviewData?.verified == true && <Verified />}
                                            </span></H3TitleForInsta>
                                        <PTitleforInsta>{topicPreviewData?.full_name || ''}</PTitleforInsta>
                                        <PTitleforInsta>
                                            {
                                                topicPreviewData?.search_type == "user" ?
                                                    `${formatNumber(topicPreviewData?.engagement)} followers`
                                                    : topicPreviewData?.search_type == "hashtag" ?
                                                        `${formatNumber(topicPreviewData?.engagement)} posts` :
                                                        ''}
                                        </PTitleforInsta>
                                    </div>
                                </FlexForLogoDiv>

                            </div>
                            :
                            <QueryPara>
                                <b>Query Used:&nbsp;</b> <span style={{ cursor: topicPreviewData?.channel == 'instagram' ? 'pointer' : '' }}>{topicPreviewData?.keywords_string}</span>
                                <br />
                                {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}
                            </QueryPara>
            }
            {typeof job_id !== 'number' && (
                <CreateTopic onClick={handleCreatedTopic}>
                    Create Topic
                    {StyledAddIcon}
                </CreateTopic>
            )}
        </HeaderWrapper>
    );
};


export default ReportHeaderFile;