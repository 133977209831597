import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';
import { filter_icon } from '../../Common/SvgIcon/CommonSvgIcons';

function generateDynamicLinearGradient(value, colorType) {
  if (typeof value !== 'number') {
    return '#fff';
  }

  const colorStops = [
    { value: 1, color: '#008481' },
    { value: 0.9, color: '#008481' },
    { value: 0.8, color: '#1a918e' },
    { value: 0.7, color: '#339d9a' },
    { value: 0.6, color: '#4da9a7' },
    { value: 0.5, color: '#66b5b3' },
    { value: 0.4, color: '#7fc1bf' },
    { value: 0.3, color: '#99cecd' },
    { value: 0.2, color: '#b3dbda' },
    { value: 0.1, color: '#cce6e6' },
    { value: 0, color: '#F3F9F9' },
  ];

  let stop1;

  if (colorType === 'type2') {
    stop1 = colorStops.find((stop) => value.toFixed(1) >= stop.value);
  }

  const background = `${stop1?.color}`;
  return background;
}

const HeatmapCell = styled.div`
  background: ${(props) =>
    generateDynamicLinearGradient(props?.value, props?.colorType)};
  border: 1px solid white;
  height: ${(props) =>
    props?.xAxis === AxisHour
      ? 'unset'
      : props?.xAxis === AxisWeek
      ? '40px'
      : props?.xAxis === AxisQuarter
      ? '40px'
      : '35px'};
  width: ${(props) =>
    props?.xAxis === AxisHour
      ? 'unset'
      : props?.xAxis === AxisWeek
      ? '35px'
      : props?.xAxis === AxisQuarter
      ? '40px'
      : '35px'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;

  ${(props) => {
    if (props?.value === 'hide') {
      return 'opacity:0';
    }
  }};
`;
const X_AxisLabel = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: ${(props) =>
    props?.currentXAxis === AxisMonth
      ? '5px'
      : props?.currentXAxis === AxisWeek
      ? '5px'
      : '5px'};
  margin-left: 110px;
  font-size: 14px;
  margin-left: ${(props) =>
    props?.currentXAxis === AxisHour
      ? '30px'
      : props?.currentXAxis === AxisMonth
      ? '40px'
      : props?.currentXAxis === AxisWeek
      ? '40px'
      : '50px'};
  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;

  font-weight: 400;
`;

const GridContainer = styled.div`
  width: ${(props) =>
    props?.xAxis === AxisHour
      ? '80%'
      : props?.xAxis === AxisMonth
      ? '60%'
      : props?.xAxis === AxisWeek
      ? '30%'
      : props?.xAxis === AxisQuarter
      ? '25%'
      : 'unset'};
`;
const HeatmapRowContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props?.xAxis === AxisHour
      ? 'repeat(24, 25px)'
      : props?.xAxis === AxisMonth
      ? 'repeat(12,  35px)'
      : props?.xAxis === AxisWeek
      ? 'repeat(7, 35px)'
      : props?.xAxis === AxisQuarter
      ? 'repeat(4, 40px)'
      : 'repeat(10, 70px)'};
`;

const HeatmapRow = ({
  rowData,
  colorType = 'type1',
  min,
  max,
  currentXAxis,
}) => {
  return (
    <HeatmapRowContainer xAxis={currentXAxis}>
      {rowData?.map((value, index) => {
        return (
          <HeatmapCell
            key={index}
            xAxis={currentXAxis}
            value={(value - min) / (max - min)}
            colorType={colorType}
            title={value}
          ></HeatmapCell>
        );
      })}
    </HeatmapRowContainer>
  );
};

const HeatmapHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props?.xAxis === AxisHour
      ? 'repeat(24, 25px)'
      : props?.xAxis === AxisMonth
      ? 'repeat(12,  35px)'
      : props?.xAxis === AxisWeek
      ? 'repeat(7, 35px)'
      : props?.xAxis === AxisQuarter
      ? 'repeat(4,40px)'
      : 'repeat(10, 70px)'};
`;

const HeatmapRowWrapper = styled.div`
  display: flex;
`;

const YLabelCell = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: end;
  margin-right: 3px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;
`;

const MainContainer = styled.div`
  margin-top: 45px;
  display: flex;
  gap: 10px;
  justify-content: ${(props) =>
    props?.xAxis === AxisHour || props?.xAxis === AxisMonth
      ? 'space-evenly'
      : 'center'};
  padding-right: 20px;
  margin-left: ${(props) => (props?.xAxis === AxisHour ? '-35px' : 'unset')};
  min-height: 120px;
  align-items: center;
  margin-bottom: 45px;
`;

const HeatmapGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: grid;
    grid-template-columns: ${(props) =>
      props?.xAxis === AxisHour
        ? 'repeat(24, 25px)'
        : props?.xAxis === AxisMonth
        ? 'repeat(12,  35px)'
        : props?.xAxis === AxisWeek
        ? 'repeat(7, 35px)'
        : props?.xAxis === AxisQuarter
        ? 'repeat(4, 40px)'
        : 'repeat(10, 70px)'};
  }
`;

const ColorRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: ${(props) =>
    props?.xAxis === AxisMonth
      ? '40px'
      : props?.xAxis === AxisHour
      ? '-45px'
      : '80px'};
  & > div {
    width: 20px;
    height: 20px;
    cursor: pointer;

    & .label {
      display: none;
    }
  }

  .colorIndicatorType3 {
    background: linear-gradient(
      180deg,
      #6f62fe 5.28%,
      #75b0f5 20%,
      #ffffff 40%,
      #ffffff 50%,
      #ffa7a9 82%,
      #fc746c 100%
    );
    border: 1px grey solid;
  }

  .colorIndicatorType2 {
    background: linear-gradient(
      180deg,
      #008481 0%,
      /* Deep teal */ #00d5d0 20%,
      /* Bright cyan */ #b4eae9 40%,
      /* Soft aqua */ #f6fdfc 70%,
      /* Near white */ #ffffff 100% /* Pure white */
    );
  }
  .colorIndicatorType1 {
    background: linear-gradient(
      180deg,
      #c20000 5.28%,
      #ec3539 16.22%,
      #e9a9aa 29.5%,
      #fceaea 46.5%,
      #ffe49c 50%,
      #88f8b6 67%,
      #0cbb53 84.5%,
      #008335 100%
    );
    border: 1px grey solid;
  }

  .activeType {
    height: 510px;
    position: relative;

    & > .label {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      right: -40px;
      font-size: 15px;
      font-weight: 400;
      text-align: right;
    }
  }
`;

const Sentiment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 20px 40px;
  line-height: 25px;
  gap: 10px;
  overflow: hidden;
  flex-wrap: wrap;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0px 20px -15px;
`;

const AxisHour = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

const AxisWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const AxisQuarter = ['Q1', 'Q2', 'Q3', 'Q4'];

const AxisMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// const yAxis = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  height: 35px;
  padding: 5px 20px;
  border-radius: 10px;

  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Options = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  font-size: 12px;
  input[type='radio']:not(:disabled) {
    opacity: 1;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #a2a2a2;
  padding-top: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  ${({ apply }) =>
    apply
      ? `
    background-color: #3b82f6;
    color: white;
  `
      : `
    background-color: #f5f5f5;
    color: #333;
  `}

  &:hover {
    opacity: 0.9;
  }
`;
const LabelText = styled.div`
  font-size: 12px;
`;
const FilterOptions = ({
  handleFilterChange,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleRadioChange = (event) => {
    const { id, name } = event.target;
    setSelectedOptions((prev) => {
      const updatedOptions = prev.filter((item) => !item.startsWith(name[0]));

      return [...updatedOptions, id];
    });
  };
  const isDisabled = (id) => {
    return selectedOptions.some((option) => option.slice(1) === id.slice(1));
  };
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  return (
    <DropdownContainer>
      <StyledButton onClick={() => setShowFilterOptions(!showFilterOptions)}>
        Filters {filter_icon}
      </StyledButton>
      {showFilterOptions && (
        <DropdownMenu>
          <LabelText>X-axis</LabelText>
          <Options>
            <input
              type='radio'
              name='xaxis'
              id='xhour'
              checked={selectedOptions.includes('xhour')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('xhour')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('xhour') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('xhour')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('xhour') && !selectedOptions.includes('xhour')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Hour of Day
          </Options>
          <Options>
            <input
              type='radio'
              name='xaxis'
              id='xday'
              checked={selectedOptions.includes('xday')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('xday')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('xday') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('xday')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('xday') && !selectedOptions.includes('xday')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Day of Week
          </Options>
          <Options>
            <input
              type='radio'
              name='xaxis'
              id='xquarter'
              checked={selectedOptions.includes('xquarter')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('xquarter')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('xquarter') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('xquarter')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('xquarter') &&
                  !selectedOptions.includes('xquarter')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Quarter of Year
          </Options>
          <Options>
            <input
              type='radio'
              name='xaxis'
              id='xmonth'
              checked={selectedOptions.includes('xmonth')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('xmonth')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('xmonth') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('xmonth')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('xmonth') && !selectedOptions.includes('xmonth')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Month of Year
          </Options>

          <LabelText>Y-axis</LabelText>
          {/* <Options>
            <input
              type='radio'
              name='yaxis'
              id='yhour'
              checked={selectedOptions.includes('yhour')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('yhour')}
            />
            Hour of Day
          </Options> */}
          <Options>
            <input
              type='radio'
              name='yaxis'
              id='yday'
              checked={selectedOptions.includes('yday')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('yday')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('yday') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('yday')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('yday') && !selectedOptions.includes('yday')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Day of Week
          </Options>
          <Options>
            <input
              type='radio'
              name='yaxis'
              id='yquarter'
              checked={selectedOptions.includes('yquarter')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('yquarter')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('yquarter') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('yquarter')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('yquarter') &&
                  !selectedOptions.includes('yquarter')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Quarter of Year
          </Options>
          <Options>
            <input
              type='radio'
              name='yaxis'
              id='ymonth'
              checked={selectedOptions.includes('ymonth')}
              onChange={(event) => {
                handleRadioChange(event);
              }}
              disabled={isDisabled('ymonth')}
              style={{
                width: '12px',
                height: '12px',
                border:
                  '1px solid ' +
                  (selectedOptions.includes('ymonth') ? '#007bff' : '#ccc'),
                backgroundColor: selectedOptions.includes('ymonth')
                  ? '#007bff'
                  : '#fff',
                borderRadius: '50%',
                cursor:
                  isDisabled('ymonth') && !selectedOptions.includes('ymonth')
                    ? 'not-allowed'
                    : 'pointer',
                appearance: 'none',
                display: 'inline-block',
                position: 'relative',
                marginTop: '1.5px',
                marginRight: '5px',
              }}
            />
            Month of Year
          </Options>

          <ButtonContainer>
            <Button onClick={() => setShowFilterOptions(false)}>Cancel</Button>
            <Button
              onClick={() => {
                handleFilterChange(selectedOptions);
                setShowFilterOptions(false);
              }}
              apply
            >
              Apply
            </Button>
          </ButtonContainer>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 200px;
  right: 0px;
`;
const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;
const XLabel = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-left: ${(props) =>
    props?.currentXAxis === AxisQuarter ? '10px' : 'unset'};
`;
const YLabel = styled.span`
  font-size: 14px;
  transform: ${(props) =>
    props?.currentXAxis === AxisHour
      ? 'translateY(-200%) rotate(-90deg)'
      : 'translateY(-100%) rotate(-90deg)'};
  display: inline-block;
  min-width: ${(props) =>
    props?.currentXAxis === AxisHour ? 'unset' : '145px'};
`;

const DynamicCorrelationGraph = ({
  correlationAnalysisData,
  startDate,
  endDate,
}) => {
  useEffect(() => {
    handleFilterChange(['xhour', 'yday']);
  }, [correlationAnalysisData]);
  const [selectedColor, setSelectedColor] = useState('type2');
  const isEmpty = Object.entries(correlationAnalysisData ?? {}).length === 0;
  const [selectedOptions, setSelectedOptions] = useState(['xhour', 'yday']);
  const [xAxis, setXAxis] = useState('Hour of day');
  const [yAxis, setYAxis] = useState('Day of week');

  const transposeMatrix = (matrix) => {
    if (!isEmpty) {
      if (matrix !== null || matrix !== undefined) {
        return matrix[0].map((_, colIndex) =>
          matrix.map((row) => row[colIndex])
        );
      } else return [];
    } else return [];
  };
  const cleanedData = [];
  for (const key in correlationAnalysisData) {
    if (!isEmpty) {
      cleanedData[key] = correlationAnalysisData[key];
    }
  }

  const dataMapping = {
    'hour-day': transposeMatrix(cleanedData['hour_day']),
    'hour-quarter': transposeMatrix(cleanedData['hour_quarter']),
    'hour-month': transposeMatrix(cleanedData['hour_month']),
    'day-hour': cleanedData['hour_day'],
    'day-quarter': transposeMatrix(cleanedData['day_quarter']),
    'day-month': transposeMatrix(cleanedData['day_month']),
    'quarter-hour': cleanedData['hour_quarter'],
    'quarter-day': cleanedData['day_quarter'],
    'quarter-month': transposeMatrix(cleanedData['quarter_month']),
    'month-hour': cleanedData['hour_month'],
    'month-day': cleanedData['day_month'],
    'month-quarter': cleanedData['quarter_month'],
  };
  const [currentData, setCurrentData] = useState(
    transposeMatrix(dataMapping?.['day-hour'])
  );
  const [currentXAxis, setCurrentXAxis] = useState(AxisHour);
  const [currentYAxis, setCurrentYAxis] = useState(AxisWeek);
  const [scale, setScale] = useState([]);
  useEffect(() => {
    const flatArray = currentData.flat();
    const minVal = Math.min(...flatArray);
    const maxVal = Math.max(...flatArray);
    const step = (maxVal - minVal) / 9;
    const intervalArray = Array.from({ length: 10 }, (_, i) =>
      Math.round(minVal + i * step)
    );
    setScale(intervalArray?.sort((a, b) => b - a));
  }, [currentData]);

  const flatArray = currentData.flat();
  const minVal = Math.min(...flatArray);
  const maxVal = Math.max(...flatArray);
  const handleFilterChange = (filterArray) => {
    const filter = filterArray?.sort((a, b) => {
      if (a.startsWith('x')) return -1;
      if (b.startsWith('x')) return 1;
      return 0;
    });
    const key = `${filter[0]?.slice(1)}-${filter[1]?.slice(1)}`;
    switch (filter[0]?.slice(1)) {
      case 'hour':
        setCurrentXAxis(AxisHour);
        setXAxis('Hour of day');
        break;
      case 'day':
        setCurrentXAxis(AxisWeek);
        setXAxis('Day of week');
        break;
      case 'month':
        setCurrentXAxis(AxisMonth);
        setXAxis('Month of year');
        break;
      case 'quarter':
        setCurrentXAxis(AxisQuarter);
        setXAxis('Quarter of year');
        break;
      default:
        break;
    }
    switch (filter[1]?.slice(1)) {
      case 'hour':
        setCurrentYAxis(AxisHour);
        setYAxis('Hour of day');
        break;
      case 'day':
        setCurrentYAxis(AxisWeek);
        setYAxis('Day of week');

        break;
      case 'month':
        setCurrentYAxis(AxisMonth);
        setYAxis('Month of year');

        break;
      case 'quarter':
        setCurrentYAxis(AxisQuarter);
        setYAxis('Quarter of year');

        break;
      default:
        break;
    }
    setCurrentData(dataMapping[key]);
  };

  if (!correlationAnalysisData || isEmpty) {
    return (
      <Sentiment test-id='dynamic-correlation-graph'>
        <div>
          <Title>
            <TitleWithInfo
              title={'Heat Map'}
              infoText={
                'Visualize user posting patterns over time. Track engagement trends by hour, day, week, month, or quarter to identify when conversations peak.'
              }
            />
          </Title>
        </div>
        <MainContainer>
          <div
            style={{
              color: 'rgb(99, 99, 99)',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Insufficient Data
          </div>
        </MainContainer>
      </Sentiment>
    );
  }

  return (
    <Sentiment id='correlation-graph'>
      <div>
        <Title>
          <TitleWithInfo
            title={'Heat Map'}
            infoText={
              'Visualize user posting patterns over time. Track engagement trends by hour, day, week, month, or quarter to identify when conversations peak.'
            }
          />
        </Title>

        <div style={{ alignSelf: 'flex-end' }}>
          <FilterOptions
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            handleFilterChange={(data) => {
              handleFilterChange(data);
            }}
          />
        </div>
      </div>

      <MainContainer xAxis={currentXAxis}>
        <YLabel currentXAxis={currentXAxis}>{yAxis}</YLabel>
        <GridContainer xAxis={currentXAxis}>
          <div>
            <HeatmapGraphContainer xAxis={currentXAxis}>
              {currentYAxis
                ?.slice(0, currentData?.length)
                ?.map((rowData, rowIndex) => (
                  <HeatmapRowWrapper key={rowIndex}>
                    <YLabelCell xAxis={currentXAxis} yAxis={currentYAxis}>
                      {rowData}
                    </YLabelCell>
                    <HeatmapRow
                      min={minVal}
                      max={maxVal}
                      rowData={currentData[rowIndex]}
                      currentXAxis={currentXAxis}
                      colorType={selectedColor}
                    />
                  </HeatmapRowWrapper>
                ))}
            </HeatmapGraphContainer>
          </div>
          <HeatmapHeader xAxis={currentXAxis}>
            {currentXAxis
              ?.slice(0, currentData[0]?.length)
              ?.map((label, index) => (
                <X_AxisLabel
                  key={index}
                  yAxis={currentYAxis}
                  currentXAxis={currentXAxis}
                >
                  {label}
                </X_AxisLabel>
              ))}
          </HeatmapHeader>
          <XLabel currentXAxis={currentXAxis}>{xAxis}</XLabel>
        </GridContainer>
        <ColorRangeWrapper xAxis={currentXAxis} colorType={selectedColor}>
          <div
            className={`colorIndicatorType2 ${
              selectedColor === 'type2' ? 'activeType' : ''
            }`}
            onClick={() => setSelectedColor('type2')}
          >
            <div className='label'>
              {scale?.map((data, index) => {
                return (
                  <span style={{ fontSize: '14px' }} key={index}>
                    {data}
                  </span>
                );
              })}
            </div>
          </div>
        </ColorRangeWrapper>
      </MainContainer>
      <Span>{` This data is observed from ${startDate} to ${endDate}`}</Span>
    </Sentiment>
  );
};

export default DynamicCorrelationGraph;
