import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json';
import OverallReview from '../../../components/OverallReview/OverallReview';

import {
  FacebookIcon,
  FacebookLogo,
  Instagram,
  InstagramIconSmall,
  InstagramLogo,
  X_icon,
} from '../../../Common/SvgIcon/CommonSvgIcons';

import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import { format, parse } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';
import DynamicCorrelationGraph from '../../../components/DynamicCorrelationGraph/DynamicCorrelationGraph';
import { useDispatch } from 'react-redux';
import SidePanel from '../../../components/SidePanel/SidePanel';
import { DetailedInsightsData } from '../../../store/redux/slices/topicSlice';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const GraphWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  &>p: first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px 0px 10px;
  }
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer1 = styled(ChildContainer)`
  width: 50%;
  height: max-content;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const OverviewTabFb = ({
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  job_id,
  preview_uuid,
  channel,
  hideDropdown,
  formatValue = 'dd-yyyy-MM',
}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  const engagementData = [
    {
      name: 'Likes',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.likes
          : overview_tab?.engagement?.likes || 0,
      fill: '#6640B8',
    },
    {
      name: 'Comments',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.comments
          : overview_tab?.engagement?.comments || 0,
      fill: '#5FE495',
    },
    // {
    //   name: 'Shares',
    //   // value: 869,
    //   value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
    //   fill: '#6F62FE',
    // },
    // {
    //     name:'Saved',
    //     value:resetFlag === true && filteredData ? filteredData?.engagement.saved : overview_tab?.engagement?.saved || 0,
    //     fill:'#AB71CE'
    // }
  ];

  const genderDemographicsData = [
    {
      name: 'Male',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.male_percentage
          : overview_tab?.gender_demographics?.male_percentage || 0,
      // fill: '#6640B8',
      fill: 'rgba(13, 135, 246, 1)',
    },
    {
      name: 'Female',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.female_percentage
          : overview_tab?.gender_demographics?.female_percentage || 0,
      // fill: '#EAAABD',
      fill: 'rgba(246, 79, 129, 1)',
    },
    {
      name: 'Unknown',
      value:
        resetFlag === true && filteredData
          ? filteredData?.gender_demographics?.unknown_percentage
          : overview_tab?.gender_demographics?.unknown_percentage || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 217, 219, 1)',
    },
  ];
  const reactionsData = [
    {
      name: 'Like',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.like
          : overview_tab?.top_reactions?.like || 0,
      // fill: '#6640B8',
      fill: 'rgba(53, 60, 247, 1)',
    },
    {
      name: 'Love',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.love
          : overview_tab?.top_reactions?.love || 0,
      // fill: '#EAAABD',
      fill: 'rgba(255, 155, 0, 1)',
    },
    {
      name: 'Care',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.care
          : overview_tab?.top_reactions?.care || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(164, 7, 238, 1)',
    },
    {
      name: 'Haha',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.haha
          : overview_tab?.top_reactions?.haha || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 6, 184, 1)',
    },
    {
      name: 'Angry',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.angry
          : overview_tab?.top_reactions?.angry || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(243, 27, 27, 1)',
    },
    {
      name: 'Wow',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.wow
          : overview_tab?.top_reactions?.wow || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(3, 198, 82, 1)',
    },

    {
      name: 'Sad',
      value:
        resetFlag === true && filteredData
          ? filteredData?.top_reactions?.sad
          : overview_tab?.top_reactions?.sad || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 217, 219, 1)',
    },
  ];
  const engagementGraphData = [
    {
      name: 'Reactions',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.likes
          : overview_tab?.engagement?.likes || 0,
      // fill: '#6640B8',
      fill: 'rgba(53, 60, 247, 0.5)',
    },
    {
      name: 'Comments',
      value:
        resetFlag === true && filteredData
          ? filteredData?.engagement?.comments
          : overview_tab?.engagement?.comments || 0,
      // fill: '#EAAABD',
      fill: 'rgba(0, 213, 208, 1)',
    },
    // {
    //     name: 'Shares',
    //     value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
    //     fill: '#E4E4E4',
    // },
  ];

  const totalNoOfPosts =
    resetFlag === true && filteredData
      ? filteredData?.total_count
      : overview_tab?.total_count;

  const renderGenderDemography = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderDemographicsData}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          paddingVal={'20px'}
          data={genderDemographicsData}
          type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={genderDemographicsData}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={genderDemographicsData} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
  };
  const renderReactions = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph data={reactionsData} view={view} />
      ) : (
        <BarGraph
          paddingVal={'20px'}
          data={reactionsData}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart data={reactionsData} width={450} type='percentage' />
    ),
    'Donut Chart': <DonutChart data={reactionsData} type='percentage' />,
    'Funnel Chart': <FunnelGraph data={reactionsData} />,
  };
  const renderEngagement = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={engagementGraphData}
          // type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          paddingVal={'20px'}
          data={engagementGraphData}
          // type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={engagementGraphData}
        width={450}
        // type='percentage'
      />
    ),
    'Donut Chart': <DonutChart data={engagementGraphData} />,
    'Funnel Chart': <FunnelGraph data={engagementGraphData} />,
  };

  const startDateParsed = useParsedStartDate(
    resetFlag,
    filteredStartDate,
    startDate,
    formatValue
  );
  const endDateParsed = useParsedEndDate(
    resetFlag,
    filteredEndDate,
    endDate,
    formatValue
  );

  const [graphData, setGraphData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const dispatch = useDispatch();
  const [weekType, setWeekType] = useState(false);
  const [date, setDate] = useState('');
  function convertDateFormat(dateStr) {
    let fullDateMatch = dateStr?.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (fullDateMatch) {
      let [_, month, day, year] = fullDateMatch;
      return { dateString: `${year}-${day}-${month}`, type: 'day' };
    }

    let monthYearMatch = dateStr?.match(/^(\d{2})\/(\d{4})$/);
    if (monthYearMatch) {
      let [_, month, year] = monthYearMatch;
      return { dateString: `${year}-${month}`, type: 'month' };
    }

    let yearMatch = dateStr?.match(/^(\d{4})$/);
    if (yearMatch) {
      return { dateString: `${yearMatch[1]}`, type: 'year' };
    }

    return 'Invalid date format';
  }
  function convertDateFormatString(dateStr) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let isoDateMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (isoDateMatch) {
      let [_, year, month, day] = isoDateMatch;
      return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
    }

    let yearMonthMatch = dateStr.match(/^(\d{4})-(\d{2})$/);
    if (yearMonthMatch) {
      let [_, year, month] = yearMonthMatch;
      return `${months[parseInt(month) - 1]} ${year}`;
    }

    let monthYearMatch = dateStr.match(/^(\d{2})\/(\d{4})$/);
    if (monthYearMatch) {
      let [_, month, year] = monthYearMatch;
      return `${months[parseInt(month) - 1]} ${year}`;
    }

    let yearMatch = dateStr.match(/^(\d{4})$/);
    if (yearMatch) {
      return yearMatch[1];
    }

    return 'Invalid date format';
  }
  const handleDataInsightsFetch = (data) => {
    
    var inputData = convertDateFormat(data?.date);
    var filter_key = [];
    if (data?.likes_count !== undefined) {
      filter_key?.push('likes');
    }
    if (data?.comments_count !== undefined) {
      filter_key.push('comments');
    }
    if (data?.reposts !== undefined) {
      filter_key.push('reposts');
    }
    if (data?.views !== undefined) {
      filter_key.push('views');
    }
    setDate(convertDateFormatString(inputData?.dateString));
    dispatch(
      DetailedInsightsData({
        preview_uuid: preview_uuid,
        job_id: job_id,
        insight_date: inputData?.dateString,
        insight_type: weekType === true ? 'week' : inputData?.type,
        filter_key: filter_key,
      })
    )
      .then((res) => {
        setGraphData(res?.payload?.response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      {resetFlag === true && filteredData === undefined ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Wrapper>
          <SidePanel
            isOpen={showSidePanel}
            setIsOpen={(data) => setShowSidePanel(data)}
            pointGraphData={graphData}
            channel={channel}
            date={date}
            isLoading={graphData === null ? true : false}
            startDate={startDateParsed}
            endDate={endDateParsed}
          />
          <Container>
            <ChildContainer justify='flex-start'>
              <OverallReview
                backgroundColor='rgba(0, 213, 208, 0.5)'
                icon={FacebookLogo}
                message={`${totalNoOfPosts} Relevant posts were observed From ${startDateParsed} to ${endDateParsed}`}
                inforText={
                  'The total number of posts relevant to your query within the specified time period.'
                }
              />
            </ChildContainer>
            
            <ChildContainer>
              <GraphHOC
                graphWidth={'100%'}
                margin={'20px 0px 20px 0px'}
                title={'Engagement'}
                options={options}
                renderScreen={renderEngagement}
                startDate={startDateParsed}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
                formatValue={formatValue}
                infoText={
                  'The total number of likes and comments on posts relevant to your query within the specified time period.'
                }
              />
            </ChildContainer>
            {/* )} */}
          </Container>
          <ChildContainer>
            <GraphHOC
              title={'Top  Reactions'}
              options={options}
              graphWidth={'100%'}
              margin={'20px 0px 20px 0px'}
              renderScreen={renderReactions}
              startDate={resetFlag ? filteredStartDate : startDate}
              endDate={resetFlag ? filteredEndDate : endDate}
              option={filterOption}
              hideDropdown={hideDropdown}
              formatValue={formatValue}
              infoText={
                'View the top reactions on posts, including like, love, and other user responses, to analyze user engagement and interaction trends.'
              }
            />
          </ChildContainer>
          <ChildContainer>
            <GraphHOC
              title={'Gender Demographics'}
              options={options}
              graphWidth={'100%'}
              margin={'20px 0px 20px 0px'}
              renderScreen={renderGenderDemography}
              startDate={resetFlag ? filteredStartDate : startDate}
              endDate={resetFlag ? filteredEndDate : endDate}
              option={filterOption}
              hideDropdown={hideDropdown}
              formatValue={formatValue}
              infoText={
                'The percentage gender distribution of user accounts relevant to your query, with categories for Male, Female, and Unknown when gender data is unavailable.'
              }
            />
          </ChildContainer>
          <ChildContainer>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:
                  resetFlag === true && filteredData
                    ? filteredData?.volume
                    : overview_tab?.volume,
              }}
              title={`Volume`}
              ylabel={'Volume'}
              // countName = {'engagement_count'}
              // option='Weeks'
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              formatValue={formatValue}
              infoText={
                'Track the total number of posts over a specified time period.'
              }
            />
          </ChildContainer>

          <ChildContainer data-testid='trends-in-user-activity'>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:
                  resetFlag === true && filteredData
                    ? filteredData?.trends_in_user_activity
                    : overview_tab?.trends_in_user_activity,
              }}
              title={`Trends in User Activity`}
              ylabel={'Engagement'}
              // countName = {'engagement_count'}
              // option='Weeks'
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              showFilters={true}
              channel={channel}
              onEmitTimeSpanClick={setWeekType}
              dataPointClickEnabled={true}
              handleDataPoint={(data) => {
                setIsLoading(true);
                setGraphData(null);
                handleDataInsightsFetch(data?.payload);
                setIsLoading(false);
                setShowSidePanel(true);
              }}
              formatValue={formatValue}
              infoText={
                'Track the total engagement on posts, combining likes and comments, over a specified time period.'
              }
              typeVal='instagram'
            />
          </ChildContainer>
          <DynamicCorrelationGraph
            correlationAnalysisData={overview_tab?.heat_map}
            startDate={startDateParsed}
            endDate={endDateParsed}
            data-testid='dynamic-correlation-graph'
          ></DynamicCorrelationGraph>
        </Wrapper>
      )}
    </>
  );
};

export default OverviewTabFb;
