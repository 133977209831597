import styled from "styled-components";

export const HeaderWrapper = styled.div`
  padding: 20px 0 20px 25px;
  display: flex;
  flex-direction: column;

  position: relative;
`;
export const Span1 = styled.span`
  color: #636363;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
`;
export const Span2 = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px 0;
    word-break: break-word;
`;

export const StyledContainer = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;
export const ChannelsText = styled.div`
  display: flex;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
`;

export const TwitterText = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  color: rgba(0, 0, 0, 0.84);
  margin: 0;

  & svg {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }
`;
export const Icon = styled.div``;

export const FlexForLogoDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
`;
export const InstagramImgDiv = styled.div`
width: ${(props) => props?.whithVal ? '60px' : '70px'};
height: ${(props) => props?.whithVal ? '60px' : '70px'};
border-radius: 50%;
// border:${(props) =>props.searchType == 'user' ? 'none' : '1px solid gray'};

`;
export const H3TitleForInsta = styled.div`
    margin: 0px;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
`;

export const PTitleforInsta = styled.div`
margin: 0px;
    margin-left: 20px;
    color: rgba(139, 139, 139, 1);
    font-size: 15px;
    font-weight: 400;
`;
export const QueryPara = styled.div`
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  margin-top: 10px;
`;

export const CreateTopic = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  padding: 10px 20px;
  border-radius: 20px;

  border: 0px;

  &:active {
    transform: scale(0.98);
  }
`;