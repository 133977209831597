




import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import './SentimentCharts.css'
import { useState } from 'react';
import PopUp from '../PopUp/PopUp';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  // max-width: ${(props) => props.maxWidth};

  max-width: ${(props) => props.showData ? props.maxWidth : '100%'};
  width: ${(props) => props.showData ? props.maxWidth : '100%'};
  `;

const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

width:100%;
  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 90px;
  overflow: scroll;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
`;
const StatusValWrapper = styled.div`
  text-align: justify;
  display: flex;
  align-items: center;

  & :first-child {
    height: 12px;
    width: 12px;
    content: " ";
    display: grid;
    background: ${(props) => props?.summaryVal?.fill ? props?.summaryVal?.fill : ''};
    border-radius: 50%;
    margin-bottom: 0px;
  }

  & :nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;
const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const StyledPieChartWrapper = styled.div`
  /* Prevent focus and hover styles on the PieChart container */
  .recharts-pie-sector:focus,
  .recharts-pie-sector:hover {
    outline: none !important;  /* Remove any focus outline */
    border: none !important;   /* Remove any border */
  }
path.recharts-sector:focus{
    outline: none !important;
}
  /* Ensure there's no stroke (border) around the pie sectors */
  .recharts-pie-sector {
    stroke: none !important; /* Remove stroke (border) */
  }

  /* Optionally, target the focus on the container */
  :focus {
    outline: none !important;
  }
`;
const COLORS = ['#57E188', '#F95D54', '#FFCA63', '#6F62FE', '#6F62FE'];

// const renderCustomizedLabel =
//   (data, type) =>
//     ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//       if (percent <= 0.01) {
//         return null;
//       }
//       const radius = innerRadius + (outerRadius - innerRadius) * 2;
//       const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
//       const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

//       const angle = midAngle > 180 ? midAngle - 360 : midAngle;

//       let textAnchor;
//       if (angle > 0) {
//         textAnchor = 'start';
//       } else {
//         textAnchor = 'end';
//       }

//       return (
//         <>
//           <text
//             x={x}
//             y={y}
//             fill='black'
//             textAnchor={x > cx ? 'start' : 'end'}
//             dominantBaseline='central'
//             fontSize='14px'
//           >
//             {`${data[index].name} - ${data[index].value}${type === 'percentage' ? '%' : ''
//               }`}
//           </text>
//         </>
//       );
//     };

const DonutChart = ({ data, align = "right", sentiment, type, maxWidth = '100%', summary = {}, title = '' }) => {
  // let finalData = data.filter(row => row.value > 0);
  let finalData = data
    .map(row => ({ ...row, value: parseFloat(row.value.toFixed(2)) }))
    .filter(row => row.value >= 0);
  const sortedData = finalData.sort((a, b) => b.value - a.value);

  const tooltipFormatter = (value) => {
    return ` ${typeof value === 'number' && value !== 0 && value}`;
  };

  const [summaryVal, setSummaryVal] = useState('')
  const [showPopup, setShowPopup] = useState(false);



  const CustomLegend = ({ payload }) => {
    return (
      <div style={{ width: '230px', marginLeft: '15px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{
            display: 'flex', alignItems: 'center',
            marginBottom: '15px'
          }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: entry.color, marginRight: '10px' }} />
            <span style={{
              wordBreak: 'break-word',
              width: '220px'
            }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
  const handleOpenSummary = (rating) => {

    if (rating.value > 0 && title == 'Emotional_Analysis') {
      setSummaryVal(rating)
      setShowPopup(true)
    }
  }
  return (
    <Wrapper className="pdf-page" style={{ pageBreakInside: 'avoid' }} maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100} showData={sortedData && sortedData?.length ? true : false}>
      {
        sortedData && sortedData?.length ?

          <StyledPieChartWrapper>
            <PieChart width={450} height={250}>
              <Pie
                data={sortedData}
                dataKey='value'
                cx='50%'
                cy='50%'
                startAngle={90}
                endAngle={-270}
                innerRadius={80} // Adjust the size of the hole
                outerRadius={100}
              //  label={renderCustomizedLabel(sortedData, type)}
              >
                {sortedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} onClick={() => handleOpenSummary(entry)}
                  />
                ))}
              </Pie>
              <Legend content={<CustomLegend />} layout='vertical' verticalAlign='middle' align='right' />

              {/* <Legend layout='vertical' verticalAlign='middle' align="right" /> */}
              <Tooltip formatter={tooltipFormatter} />
            </PieChart>
          </StyledPieChartWrapper>

          :

          <NoDataWrapper>
            {/* No Data Available */}Insufficient Data
          </NoDataWrapper>
      }
      {/*  <div style={{display:'inline'}}>
    <SquareWrapper/>
    <span>Positive</span>    
    </div>  */}
      {
        showPopup && summary[summaryVal?.name && summaryVal?.name?.toLowerCase()] && <PopUp minWidth={'700px'} height={'280px'} maxWidth={'700px'} onClose={() => setShowPopup(false)}>
          {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>

            <div>
              <TitleWithInfo
                title={'Summary '}
                infoText={``}
              />
            </div>
            <br />
            <Message>{summary[summaryVal?.name && summaryVal?.name?.toLowerCase()]}</Message>
            <br />
            <StatusValWrapper summaryVal={summaryVal}>
              <span></span> <span>{`${summaryVal?.name}- ${summaryVal?.value ? summaryVal?.value : 0}%`}</span>
            </StatusValWrapper>
            <CancelButton onClick={() => setShowPopup(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      }
    </Wrapper>
  );
};

export default DonutChart;



