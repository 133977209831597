import React, { useEffect, useState } from 'react';
import { Wrapper } from './ActiveTopic.styled';
import { useSelector, useDispatch } from 'react-redux';
import Sentiments from './Sentiments/SentimentsTab';
import Insights from './Insights/InsightsTab';
import ReviewsTab from './ReviewsTab/ReviewsTab';
import Overview from './OverViewTab/OverView';
import ClusterAnalysisTab from './ClusterAnalysisTab/ClusterAnalysisTab';
import CustomAnalysisTab from './CustomAnalysisTab/CustomAnalysisTab';
import GenerateAnalysis from '../../components/Generate Analysis/GenerateAnalysis';
import {
  fetchCreatedTopic,
  createNewTopic,
  activeTopicList,
} from '../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';
import OverviewTabInsta from './OverViewTab/OverviewTabInsta';
import OverviewTabFb from './OverViewTab/OverviewTabFb';
import OverviewNews from './OverViewTab/OverviewNews';
import FilterPopup from '../../Common/FilterPopup/FilterPopup';
import ReportHeaderFile from '../../components/HeaderFileReport/ReportHeaderFile';
import TabHeadingComp from '../../Common/TabHeading/TabHeading';
import FilterWithDate from '../../Common/FilterWithDate/FilterWithDate';
import useTopicData from './CustomHook/ActiveTopicHook';

const ActiveTopic = ({ job_id }) => {
  const dispatch = useDispatch();
  const [shareOption, setShareOption] = useState(false);
  const navigate = useNavigate();
  // const { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
  const { personalDetailsData } =
    useSelector((state) => state.tenantSetting) || {};
  const {
    activeTab,
    searchQuery,
    topicPreviewData,
    filterFlag,
    filterOption,
    selectedOption,
    filteredData,
    resetFlag,
    applyFilter,
    orgFilterOption,
    orgView,
    startDate,
    endDate,
    showSave,
    openDropdown,
    fetchedData,
    view,
    setView,
    setFilterOption,
    setSelectedOption,
    setOpenDropdown,
    handleTabClick,
    handleFilter,
    handleClosePopup,
    handleApply,
    handleFilterChange,
    handleCancel,
    handleReset,
    dateHandler,
    dateSubmit,
    updateSearchQuery,
    clearFilters,
  } = useTopicData(job_id); // Using the custom hook
console.log(resetFlag,"resetFlag");

  const overView_tab = fetchedData?.overview_tab || {};
  const sentiments_tab = fetchedData?.sentiments_tab || {};
  const insights_tab = fetchedData?.insights_tab || {};
  const clusterAnalysis_tab = fetchedData?.cluster_analysis_tab || {};
  const handleShareClick = () => {
    setShareOption(!shareOption);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return topicPreviewData?.channel === 'news' ? (
          <OverviewNews
            overview_tab={overView_tab}
            startDate={startDate}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            endDate={endDate}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.overview_tab}
            resetFlag={resetFlag}
            view={orgView}
            formatValue={formatValue}
          />
        ) : topicPreviewData?.channel === 'twitter' ? (
          <Overview
            overview_tab={overView_tab}
            startDate={startDate}
            filteredStartDate={startDate}
            job_id={job_id}
            preview_uuid={topicPreviewData?.preview_uuid}
            filteredEndDate={endDate}
            endDate={endDate}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.overview_tab}
            resetFlag={resetFlag}
            view={orgView}
            channel={topicPreviewData?.channel}
            formatValue={formatValue}
          />
        ) : topicPreviewData?.channel === 'facebook' ? (
          <OverviewTabFb
            overview_tab={overView_tab}
            startDate={startDate}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            endDate={endDate}
            filterOption={orgFilterOption}
            job_id={job_id}
            channel={topicPreviewData?.channel}
            preview_uuid={topicPreviewData?.preview_uuid}
            filteredData={filteredData?.data?.overview_tab}
            resetFlag={resetFlag}
            view={orgView}
            formatValue={formatValue}
          />
        ) : (
          <OverviewTabInsta
            overview_tab={overView_tab}
            startDate={startDate}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            endDate={endDate}
            job_id={job_id}
            preview_uuid={topicPreviewData?.preview_uuid}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.overview_tab}
            resetFlag={resetFlag}
            view={orgView}
            formatValue={formatValue}
            channel={topicPreviewData?.channel}
          />
        );
      case 'sentimentsTab':
        return (
          <Sentiments
            sentiments_tab={sentiments_tab}
            startDate={startDate}
            endDate={endDate}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            resetFlag={resetFlag}
            filteredData={filteredData?.data?.sentiments_tab}
            view={orgView}
            channel={topicPreviewData?.channel}
            formatValue={formatValue}
            is_news={topicPreviewData?.channel === 'news' ? true : false}
          />
        );
      case 'insightTab':
        return (
          <Insights
            insights_data={insights_tab}
            startDate={startDate}
            endDate={endDate}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.insights_tab}
            search_type={topicPreviewData?.search_type || ''}
            view={orgView}
            resetFlag={resetFlag}
            channel={topicPreviewData?.channel}
            formatValue={formatValue}
            is_news={topicPreviewData?.channel === 'news' ? true : false}
          />
        );
      case 'clusterAnalysis':
        return (
          <ClusterAnalysisTab
            cluster_analysis={clusterAnalysis_tab}
            startDate={startDate}
            endDate={endDate}
            filteredData={filteredData?.data?.cluster_analysis}
            resetFlag={resetFlag}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            channel={topicPreviewData?.channel}
            job_id={job_id}
            formatValue={formatValue}
          />
        );
      case 'customAnalysis':
        return (
          <CustomAnalysisTab
            job_id={job_id}
            preview_uuid={topicPreviewData?.preview_uuid}
            onUpdateSearchQuery={updateSearchQuery}
            channel={topicPreviewData?.channel}
          />
        );
      case 'reviewsTab':
        return (
          <ReviewsTab
          resetFlag={resetFlag}
            min={startDate}
            max={endDate}
            startDate={startDate}
            search_type={topicPreviewData?.search_type || ''}
            endDate={endDate || endDate}
            preview_uuid={topicPreviewData?.preview_uuid}
            job_id={topicPreviewData?.job_id}
            filteredReviews={filteredData?.data?.overview_tab}
            channel={topicPreviewData?.channel}
            formatValue={formatValue}
          />
        );
      default:
        return null;
    }
  };
  const handleClear = () => {
    clearFilters();
  };
  const handleCreatedTopic = () => {
    dispatch(createNewTopic(topicPreviewData?.inputData))
      .then((res) => {
        dispatch(activeTopicList());
        if (res.payload?.data?.job_id) {
          dispatch(fetchCreatedTopic(res.payload?.data?.job_id)).then((res) => {
            // fetchedData = res?.payload?.data?.data;
          });

          navigate('/topic/job_' + res?.payload?.data?.job_id);
        } else {
          // navigate('/topic/home');
        }
      })
      .catch((err) => console.log(err));
  };

  const formatValue =
    personalDetailsData?.app_preference?.date_format === 'dd/mm/yyyy'
      ? 'dd-MM-yyyy'
      : personalDetailsData?.app_preference?.date_format === 'mm/dd/yyyy'
      ? 'MM-dd-yyyy'
      : personalDetailsData?.app_preference?.date_format === 'yyyy/mm/dd'
      ? 'yyyy-MM-dd'
      : 'dd-MM-yyyy';

  return (
    <Wrapper>
      {shareOption && (
        <GenerateAnalysis
          open={shareOption}
          onClose={() => setShareOption(false)}
          moduleType={
            topicPreviewData?.channel == 'news'
              ? 'News'
              : topicPreviewData?.channel == 'facebook'
              ? 'Facebook'
              : topicPreviewData?.channel == 'instagram'
              ? 'Instagram'
              : 'Twitter'
          }
          title={topicPreviewData?.title}
          job_id={job_id}
          reportData={fetchedData}
          topicPreviewData={topicPreviewData}
        />
      )}
      {filterFlag && (
        <FilterPopup
          handleClosePopup={handleClosePopup}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          filterOption={filterOption}
          handleFilterChange={handleFilterChange}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          view={view}
          setView={setView}
          handleCancel={handleCancel}
          handleApply={handleApply}
          handleClear={handleClear}
        />
      )}
      <ReportHeaderFile
        job_id={job_id}
        topicPreviewData={topicPreviewData}
        handleCreatedTopic={handleCreatedTopic}
      />
      <FilterWithDate
        job_id={job_id}
        startDate={startDate}
        initialStartDate={startDate}
        dateHandler={dateHandler}
        formatValue={formatValue}
        endDate={endDate}
        initialEndDate={endDate}
        showSave={showSave}
        dateSubmit={dateSubmit}
        handleReset={handleReset}
        handleFilter={handleFilter}
        handleShareClick={handleShareClick}
      />
      <TabHeadingComp activeTab={activeTab} handleTabClick={handleTabClick} />
      {renderTabContent()}
    </Wrapper>
  );
};

export default ActiveTopic;
