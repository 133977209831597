import React from 'react';
import MyDatePicker from '../../components/DatePicker/DatePicker';
import { DateWrapper, FilterWrapper } from './FilterWithDate.styled';
import { filter_icon, reset_icon, share_icon } from '../SvgIcon/CommonSvgIcons';

const FilterWithDate = ({
  job_id,
  startDate,
  initialStartDate,
  dateHandler,
  formatValue,
  endDate,
  initialEndDate,
  showSave,
  dateSubmit,
  handleReset,
  handleFilter,
  handleShareClick,
}) => {
  return (
    <FilterWrapper>
      <DateWrapper>
        <div>
          <div>First Post Date:</div>

          <MyDatePicker

            width='110px'
            name='firstPostDate'
            value={startDate || initialStartDate}
            dateHandler={dateHandler}
            format={
              formatValue
            }
            min={initialStartDate}
            max={initialEndDate}
            readOnlyvalue={!job_id ? true : false}
          />
        </div>
        <div>
          <div>Last Post Date:</div>

          <MyDatePicker

            width='110px'
            name='lastPostDate'
            value={endDate || initialEndDate}
            dateHandler={dateHandler}
            readOnlyvalue={!job_id ? true : false}
            format={
              formatValue
            }
            min={startDate}
            max={initialEndDate}
          />
        </div>
        {typeof job_id === 'number' && (showSave ?
          (
            <button className='button' onClick={dateSubmit}>
              Save
            </button>
          )
          :
          (
            <button className='button' onClick={handleReset}>
              Reset
              {reset_icon}
            </button>
          )
        )
        }
      </DateWrapper>
      <div>
        
      </div>
      <div className='buttonContainer'>
        <button className='button' onClick={handleFilter}>
          Filters
          {filter_icon}
        </button>
        {typeof job_id === 'number' && (
          <button className='button' onClick={handleShareClick}>
            Share
            {share_icon}
          </button>
        )}
      </div>
    </FilterWrapper>
  );
};


export default FilterWithDate;