import React from 'react';
import {
  TopicDiv,
  NewTopic,
  NewButton,
  ActiveTopic,
  NewTopicDesc,
  Desc,
  Wrapper,
  Heading,
  ActiveTopicDiv,
  NewTopicSpan,
} from './HomePage.styled';

import { useDispatch, useSelector } from 'react-redux';
import { activeTopicList, resetFlagFunc, setPopUpActive } from '../../../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeTopicListData } = useSelector((state) => state.topicBuilder) || [];
  if (activeTopicListData === null) {
    dispatch(activeTopicList());
  }

  const handleTopicClick = (job_id) => {
    navigate('/topic/job_'+job_id)

          dispatch(resetFlagFunc(false))
  };

  const handleChangePage = () => {
    
    dispatch(setPopUpActive(true))
    navigate('/topic/create')
  }
  const activeTopics = activeTopicListData?.filter((topic) => topic.is_active === 0)

  return (
    <Wrapper>
      <TopicDiv>Topic Listening</TopicDiv>
      <Desc>Discover brand insights, craft custom reports</Desc>
      <NewTopic>
        <NewTopicSpan>The Advanced Topic Composer</NewTopicSpan>
        <NewTopicDesc>
          Our advanced query builder allows users to create targeted topic
          requests with ease. Seamlessly explore a wide array of filtering
          options, including keywords, hashtags, user mentions, exclusions, and
          more, to fulfil your diverse data needs.
        </NewTopicDesc>
        <NewButton onClick={() => handleChangePage()}>
          New Topic
        </NewButton>
      </NewTopic>
      <ActiveTopic>
        <Heading>Active Topics</Heading>
        <div className='topicWrapper'>
          {activeTopics?.map((el) => (
            <ActiveTopicDiv
              key={el.job_id}
              onClick={() => handleTopicClick(el.job_id)}
            ><p>
            {el.topic_title}
            </p>
            </ActiveTopicDiv>
          ))}
        </div>
      </ActiveTopic>
    </Wrapper>
  );
}

export default HomePage;
