import React, { useState, useEffect } from 'react';
import {
  Preview,
  Wrapper,
  Text,
  ColumnWrapper,
  MainContainer,
  QueryBuilderTitle_with_PreviewButton,
  TopicText,
  KeywordWrapper,
  Label,
  SubLabel,
  ErrorMessage,
  LoadingWrapper,
  LabelStyle,
  LabelParaStyle,
  FlexAlertWrapper,
  ShowAlertWrapper,
  InstaWrapper,
  InstaLogoWrapper,
  InstaTopicTitle,
  FlexForLogoDiv,
  InstagramImgDiv,
  H3TitleForInsta,
  PTitleforInsta,
  InstagramCrossWrapper,
  SubLabelDiv,
  LabelDiv,
} from './TopicBuilder.styled';
import Input from '../../../SettingPage/Input';
import Description from '../../../../Common/DescriptionInput/Description';
import Select from '../../../../Common/Select/Select';
import MultipleCountriesSelect from '../../../../Common/MultiSelect/MultipleSelect';
import LocationOrigin from '../Location';
import QueryString from '../QueryString/QueryString';
import ItemList from '../ToggleList/ToggleList';
import ConnectedChannels from '../ConnectedChannels/ConnectedChannels';

import { useDispatch, useSelector } from 'react-redux';
import { resetFlagFunc, topicPreview } from '../../../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import { languages } from '../../../../utils/languageList';
import { countries } from '../../../../utils/countryList';
// import { citiesList } from '../../../../utils/CitiesList';

import citiesList from '../../../../utils/CitiesList';

import { setMessage } from '../../../../store/redux/slices/alertMessageSlice';
import PopUp from '../../../../Common/PopUp/PopUp';
import { DeletePopupWrapper } from '../../../ActiveTopic/ActiveTopic.styled';
import Button from '../../../../Common/Button/Button';
import PoPup from './PopupForUser';
import {
  CrossIcon,
  CrossIconWithOutBorder,
  FacebookLogo,
  InstagramLogo,
  StaticProfileImg,
} from '../../../../Common/SvgIcon/CommonSvgIcons';
import { Verified } from '@mui/icons-material';
import HashtagImg from '../../../../assests/images/HashtagImg.png';
import LocationTwitter from '../LocationTwitter';
import locationX from '../../../../utils/xSupportedLocation';
import CustomDropdown from '../../../../Common/Select/ReactSelect';
import VirtualizedSelect from '../../../../Common/Select/DebounceSearch';

const toggle = [
  // { id: 1, name: 'Include Sensitive Contents ', isActive: false },
  { id: 2, name: 'Only Posts with Media', isActive: false },
  { id: 3, name: 'Fetch Reposts', isActive: false },
  { id: 4, name: 'Only Posts from Verified Users', isActive: false },
];

const timeframe = [
  'None',
  'Last 1 month',
  'Last 3 month',
  'Last 6 month',
  'Last 12 month',
];

const StyledSwitch = styled(Switch)(() => ({
  width: '66px !important',
  height: '33px !important',
  padding: 6,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateY(6px) translateX(6px)',
    '&.Mui-checked': {
      color: 'red',
      transform: 'translateY(6px) translateX(45px)',
      // transform: 'translateX(40px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#B4B7FC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#353CF7',
  },
}));

function TopicBuilder() {
  const [inputValue, setInputValue] = useState('');
  const [desc, setDesc] = useState('');
  const [channel, setChannel] = useState('');
  const [queryArrays, setQueryArrays] = useState([
    {
      group: [{ value: '', keywordType: 'Word' }],
      combinator: 'AND',
      combinatorWithNextGroup: 'AND',
    },
  ]);
  const [queryExclusionArrays, setQueryExclucsionArrays] = useState([
    {
      group: [{ value: '', keywordType: 'Word' }],
      combinator: 'OR',
      combinatorWithNextGroup: 'OR',
    },
  ]);
  const [keywordType, setKeywordType] = useState([
    'Word',
    'Phrase',
    'Hashtags',
    'Mentions',
    'From User',
    'To User',
  ]);

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: 'English',
    value: 'en',
  });
  const [selectedNotifyMethod, setSelectedNotifyMethod] = useState([
    { label: "What's up", value: 'whatsup' },
  ]);
  const [perForAlert, setPerForAlert] = useState([
    { label: 'Mention Frequency', value: 'Mention Frequency' },
  ]);
  const [scanFreq, setScanFreq] = useState([
    { label: 'Daily', value: 'Daily' },
  ]);
  const [showAlert, setShowAlert] = useState(false);
  const lang = true;
  const [searchType, setSearchType] = useState('');
  const [searchTypeFb, setSearchTypeFb] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [exclusion, setExclusion] = useState('');
  const [timeFrame, setTimeframe] = useState('');
  const [userCountry, setUsercountry] = useState([]);
  const [postCountry, setPostcountry] = useState([]);
  const [location, setLocation] = useState(null);
  const [locationRadius, setLocationRadius] = useState('10');
  const [titleError, setTitleError] = useState('');
  const [keywordError, setKeyWordError] = useState('');
  const [execKeywordError, setExecKeyWordError] = useState('');
  const [SearchTypeError, setSearchTypeError] = useState('');
  const [SearchTypeErrorInsta, setSearchTypeErrorInsta] = useState('');
  const [channelError, setChannelError] = useState('');
  const [instaDetails, setInstaDetails] = useState([]);
  const [toggleData, setToggleData] = useState(toggle);
  const [debounceValue, setDebounceValue] = useState(null);
  const [topicPreviewDataLoading, setTopicPreviewDataLoading] = useState(false);
  const [citySelected, setCitySelected] = useState();
  const { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};

  useEffect(() => {
    // const loadingFlag = JSON.parse(localStorage.getItem('topicPreviewLoading'));
    // if (loadingFlag) {
    //   setTopicPreviewDataLoading(true);
    // } else if (topicPreviewDataLoading && !loadingFlag) {
    //   setTopicPreviewDataLoading(false);
    // }
    if (topicPreviewData != null && topicPreviewData?.data == 'Has Failed') {
      dispatch(
        setMessage({
          message:
            'Unable to create a preview. Please attempt your action again.',
          timer: 'off',
          type: 'rejected',
        })
      );
    } else if (
      topicPreviewData != null &&
      (topicPreviewData?.data != 'No data' ||
        topicPreviewData?.data != 'Has Failed')
    ) {
      setTimeout(() => {
        navigate('/topic/view');
      }, 2000);
    }
  }, [topicPreviewData]);

  const navigate = useNavigate();

  // if (topicPreviewData !== null) {
  //   navigate('/topic/view');
  // }

  const dispatch = useDispatch();

  const characterLimit = 50;
  const descLimit = 250;

  const PerameterForAlert = [
    {
      label: 'User Engagement (Likes & Reshares)',
      value: 'User Engagement (Likes & Reshares)',
    },
    { label: 'Mention Frequency', value: 'Mention Frequency' },
    { label: 'Positive Sentiment Shift', value: 'Positive Sentiment Shift' },
    { label: 'Negative Sentiment Shift', value: 'Negative Sentiment Shift' },
  ];

  const ScanFreqValue = [
    { label: 'Hourly', value: 'Hourly' },
    { label: 'Daily', value: 'Daily' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Quaterly', value: 'Quaterly' },
    { label: 'Yearly', value: 'Yearly' },
  ];

  const notifyMethod = [
    { label: "What's up", value: 'whatsup' },
    { label: 'Sms', value: 'sms' },
  ];

  /* const handleConnectChannelsToggle = (selected) => {
    if (channel.includes(selected)) {
      let index = channel.indexOf(selected);
      setChannel([...channel.slice(0, index), ...channel.slice(index + 1)]);
    } else {
      channel.push(selected);
      setChannel([...channel]);
    }

    if (selected.length === 0) {
      setChannelError('Select at least one channel');
    } else {
      setChannelError('');
    }
  }; */

  const handleConnectChannelsToggle = (selected) => {
    setTitleError('')
    setKeyWordError('')
    setSearchTypeErrorInsta('')
    setSearchTypeError('');
    setQueryArrays([
      {
        group: [{ value: '', keywordType: 'Word' }],
        combinator: 'AND',
        combinatorWithNextGroup: 'AND',
      },
    ])
    if (channel === selected) {
      setChannel(null); // Deselect if the same channel is clicked again
      setKeywordType([
        'Word',
        'Phrase',
        'Hashtags',
        'Mentions',
        'From User',
        'To User',
      ]);
    } else {
      setChannel(selected); // Select the new channel
      if (selected == 'facebook') {
        setQueryArrays((prevQueryArrays) =>
          prevQueryArrays.map((item) => ({
            ...item,
            group: item.group.map((groupItem) => ({
              ...groupItem,
              value: groupItem.value
                .replace(/^['"]|['"]$/g, '')
                .replace(/['"]/g, '')
                .replace(/[@#]/g, '')
                .trim(),
              keywordType: 'Word',
            })),
          }))
        );
      } else {
        setQueryArrays((prevQueryArrays) =>
          prevQueryArrays.map((item) => ({
            ...item,
            group: item.group.map((groupItem) => ({
              ...groupItem,
              value: groupItem.value
                .replace(/^['"]|['"]$/g, '')
                .replace(/['"]/g, '')
                .replace(/[@#]/g, '')
                .trim(),
              keywordType: 'Word',
            })),
          }))
        );
      }

      setKeywordType((prev) => {
        if (selected === 'twitter') {
          // changeKeywordType('Word', index)
          return [
            'Word',
            'Phrase',
            'Hashtags',
            'Mentions',
            'From User',
            'To User',
          ];
        } else if (selected === 'instagram') {
          // changeKeywordType('Word', index)
          return ['Word', 'Hashtags', 'Mentions'];
        } else if (selected === 'facebook') {
          // changeKeywordType('Word', index)
          return ['Word'];
        } else if (selected === 'news') {
          // changeKeywordType('Word', index)
          return ['Word'];
        } else {
          // changeKeywordType('Word', index)
          return [
            'Word',
            'Phrase',
            'Hashtags',
            'Mentions',
            'From User',
            'To User',
          ];
        }
      });
    }

    if (!selected) {
      setChannelError('Select at least one channel');
    } else {
      setChannelError('');
    }
  };

  const handleSelectChange = (selectedLanguage) => {
    const langValue = selectedLanguage.map((lan) => lan.value);
    setSelectedLanguage(langValue);
  };

  function exclusionQuery(exclude) {
    const str = exclude();
    setExclusion(str);
  }

  // const handlePostCountry = (postCountrySelected) => {

  //   // const postCountryValue = postCountrySelected.map(
  //   //   (country) => country.value
  //   // );
  //   // setPostcountry(postCountryValue);

  // };

  const handlePostCountry = (selectedOption) => {
    // setSelectedOption(selectedOption);
    setDebounceValue(selectedOption);
  };

  const handleLocationChange = (locationSelected) => {
    setLocation(locationSelected);
  };

  const handleLocationRadiusChange = (radius) => {
    setLocationRadius(radius);
  };
  const handleCityChange = (citySelected) => {
    setCitySelected(citySelected);
  };

  const handleButtonClick = (event) => {
    //query builder
    event.preventDefault();
    let activeError = false;

    if (inputValue === '') {
      activeError = true;
      setTitleError('Topic Title is required');
    }

    if (channel?.length === 0 || channel == null) {
      activeError = true;
      setChannelError('Please select atleast one channel');
    }
    if (channel == 'instagram' && (instaDetails?.length == 0 || searchType == '')) {
      activeError = true;
      setSearchTypeErrorInsta('Please select any one search type');
    } else {
      setSearchTypeErrorInsta('');
    }
    
    // if (channel == 'instagram' && ) {
    //   activeError = true;
    //   setSearchTypeErrorInsta('Please select any one search type');
    // } else {
    //   setSearchTypeErrorInsta('');
    // }
    if (channel == 'facebook' && searchTypeFb == '') {
      activeError = true;
      setSearchTypeError('Please select any one search type');
    } else {
      setSearchTypeError('');
    }
    
    if (
      ((channel == 'twitter') && (queryString.length === '' || queryString === false)) ||
      ((channel == 'facebook') && (searchTypeFb == 'Posts') && (queryString.length === '' || queryString === false))
    ) {
      activeError = true;
      setKeyWordError('Please specify Keywords');
    } else {
      setKeyWordError('');
    }
    if (channel === 'twitter') {
      if (citySelected === null || citySelected === undefined) {
      } else {
        if (locationRadius < 10) {
          activeError = true;
          let anchor = document.querySelector('#location-twitter');
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
    // console.log("inputData",activeError);
    if (activeError) {
      return false;
    }
    const inputData = {
      title: inputValue,
      channels: channel,
      keywords: queryString,
      userCountry: userCountry,
    };
    if(channel == 'facebook' && searchTypeFb== "Posts"){
      
      inputData.search_type = 'post';
    }

    if (instaDetails && instaDetails?.length > 0 && channel == 'instagram') {
      inputData.keywords_string = instaDetails?.[0]?.url || '';
      inputData.search_type = instaDetails?.[0]?.search_type || '';
      inputData.user_name = instaDetails?.[0]?.user_name || '';
      inputData.full_name = instaDetails?.[0]?.full_name || '';
      inputData.verified = instaDetails?.[0]?.verified || false;
      if (instaDetails?.[0]?.search_type == 'user') {
        inputData.engagement = instaDetails?.[0]?.followers_count || 0;
      } else {
        inputData.engagement = instaDetails?.[0]?.posts_count || 0;
      }
      inputData.profile_pic_url = instaDetails?.[0]?.profilepic_url || '';
    }
    if (instaDetails && instaDetails?.length > 0 && channel == 'facebook' && searchTypeFb == 'Pages') {
      inputData.keywords_string = instaDetails?.[0]?.page_url || '';
      inputData.search_type = 'pages';
      inputData.user_name = instaDetails?.[0]?.user_name || '';
      inputData.verified = instaDetails?.[0]?.verified || false;
      inputData.followers = instaDetails?.[0]?.followers || 0;
      inputData.category = instaDetails?.[0]?.category || 0;
      inputData.page_description = instaDetails?.[0]?.page_description || '';
      inputData.profile_pic_url = instaDetails?.[0]?.profilepic_url || '';
    }
    if (desc) {
      inputData.description = desc;
    }
    if (selectedLanguage) {
      
      inputData.languages = 'lang:' + selectedLanguage?.value || '';
    }

    if (exclusion) {
      inputData.exclusions = exclusion;
    }

    if (timeFrame) {
      inputData.timeFrame = timeFrame;
    }

    if (userCountry) {
      inputData.userCountry = userCountry;
    }

    if (postCountry) {
      inputData.postCountry = postCountry;
    }

    if (location || debounceValue) {
      if (channel === 'twitter') {
        if (citySelected === null || citySelected === undefined) {
          inputData.location = `geocode:${location}km`;
        } else {
          inputData.location = `geocode:${citySelected},${locationRadius}km`;
        }
      } else {
        inputData.location = location || debounceValue;
        if (locationRadius) {
          inputData.locationRadius = locationRadius;
        }
      }
    }

    if (toggleData[0]?.isActive) {
      inputData.only_posts_with_media = 'filter:media';
    } else {
      inputData.only_posts_with_media = null;
    }
    if (toggleData[1]?.isActive) {
      inputData.fetch_reposts = 'is:retweet';
      // inputData.fetch_reposts = 'min_retweets:1';
    } else {
      inputData.fetch_reposts = null;
    }
    if (toggleData[2]?.isActive) {
      inputData.only_posts_from_verified_users = 'filter:verified';
    } else {
      inputData.only_posts_from_verified_users = null;
    }
    // console.log(inputData,"inputData");
    
    dispatch(topicPreview(inputData)).then((res) => {
      setTopicPreviewDataLoading(false);
    });
    setTopicPreviewDataLoading(true);
    // if (inputValue && channel && queryString) {
    // }
  };

  // const dataTemp = {
  //   title: 'Iphone 14',
  //   channels: ['Twitter', 'Instagram'],
  //   keywords: '(  aa  )',
  //   userCountry: ['India'],
  //   description: 'some des',
  //   language: ['en', 'es'],
  //   exclusion: "(  '--aa'  )",
  //   timeFrame: 'Last 1 month',
  //   postCountry: ['United States', 'India'],
  //   location: {
  //     label: 'Amsterdam, Netherlands',
  //     value: 'Amsterdam',
  //   },
  //   locationRadius: '1000',
  //   include_sensitive_content: true,
  //   only_posts_with_media: true,
  //   fetch_reposts: true,
  //   only_posts_from_verified_users: true,
  // };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue != '') {
      setTitleError('');
    }
    setInputValue(newValue);
  };
  function returnQueryString(formQuery) {
    let result = formQuery();
    
    setQueryString(result);
  }
  const option = ['Hashtag', 'Account'];
  const optionforFb = ['Posts','Pages'];
  // 
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const searchUserFunc = (e) => {
    setShowPopup(true);
    setSearchType(e);

  };

  const searchPostsFbFunc = (e) => {
    setSearchTypeError('')
    if (e == 'Pages') {

      setShowPopup(true);
    }
    setSearchTypeFb(e);

  };

  const removeInstaFunc = () => {
    setInstaDetails([]);
  };

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      // Billion
      return (num / 1_000_000_000).toFixed(1) + 'B';
    } else if (num >= 1_000_000) {
      // Million
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      // Thousand
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num; // Return the number as is if it's less than 1000
    }
  }

  return (
    <Wrapper>
      {topicPreviewDataLoading && (
        <LoadingWrapper>
          <div>
            <CircularProgress />
          </div>
        </LoadingWrapper>
      )}
      <Text>
        Effortlessly construct custom queries and generate comprehensive
        reports, delivering actionable intelligence at your fingertips
      </Text>
      <QueryBuilderTitle_with_PreviewButton>
        <TopicText>Query Composer</TopicText>
        <Preview onClick={handleButtonClick}>Preview</Preview>
      </QueryBuilderTitle_with_PreviewButton>

      <MainContainer>
        <ColumnWrapper>
          <KeywordWrapper>
            <Label>
              Channels <span>*</span>
            </Label>
            <br />
            <SubLabel>Select interested channels for topic creation</SubLabel>
            <ConnectedChannels
              handleConnectChannelsToggle={handleConnectChannelsToggle}
              channel={channel}
            />
            {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
          </KeywordWrapper>

          {/*  <KeywordWrapper>
            <Label>Timeframe</Label>
            <br />
            <SubLabel>
              Specify the timeframe for integrating historical data
            </SubLabel>
            <Select
              style={{
                placeholderFontWeight: '400',
                placeholderColor: '#636363',
              }}
              placeholder='Select desired timeframe  '
              value={timeFrame}
              option={timeframe}
              onChange={(selectTimeframe) => {
                setTimeframe(selectTimeframe);
              }}
            />
          </KeywordWrapper> */}
          {/* {
            channel == 'instagram' ? */}
          {channel && (
            <Description
              label={'Description'}
              placeholder='Enter a brief description specifying the topic’s objective...'
              value={desc}
              characterLimit={descLimit}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              changeHeight={
                channel == 'instagram' || channel == 'facebook' ? true : false
              }
            />
          )}
          {channel === 'twitter' && (
            <KeywordWrapper>
              <Label>Language</Label>
              <br />
              <SubLabel>Select language to monitor topics</SubLabel>
              <MultipleCountriesSelect
                placeholder='Select preferred language'
                singleSelect={true}
                language={lang ? languages : countries}
                value={selectedLanguage}
                setValue={setSelectedLanguage}
              />
            </KeywordWrapper>
          )}
          {channel == 'facebook' && (
            <KeywordWrapper>
              {/* <Label showMargin={true}>Post Origin</Label>
              <br />
              <SubLabel showMargin={true}>
                Filter content based on the country of origin; default is global
              </SubLabel> */}

              <Label>Content Origin </Label>
              <br />
              <SubLabel>View posts by country; default is global</SubLabel>

              <VirtualizedSelect
                showMargin={true}
                options={citiesList}
                placeholder='Select country'
                onChange={handlePostCountry}
                value={debounceValue}
                setValue={setDebounceValue}
              />
            </KeywordWrapper>
          )}

          {
            (channel == 'facebook' && searchTypeFb == 'Pages' && instaDetails.length > 0) ? (
              <InstaWrapper>
                <InstagramCrossWrapper>
                  <InstaLogoWrapper>
                    {FacebookLogo}{' '}
                    {'Page'}
                  </InstaLogoWrapper>
                  <div onClick={() => removeInstaFunc()}>
                    {CrossIconWithOutBorder}
                  </div>
                </InstagramCrossWrapper>
                <InstaTopicTitle>
                  Topic :{' '}
                  {decodeURIComponent(instaDetails?.[0]?.user_name)}
                </InstaTopicTitle>
                <FlexForLogoDiv style={{
                  borderTop: '1px solid'
                }}>
                  <InstagramImgDiv
                    whithVal={true}
                    searchType={instaDetails?.[0]?.search_type}
                  >
                    {
                      // <image src="" alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                      instaDetails?.[0]?.profilepic_url ? (
                        <img
                          src={instaDetails?.[0]?.profilepic_url}
                          style={{
                            height: '60px',
                            width: '60px',
                            borderRadius: '50%',
                          }}
                        />
                      ) : (
                        StaticProfileImg
                      )


                      // <div style={{ width: '70px', borderRadius: '50%', fontSize: '50px', textAlign: 'center' }}>#</div>
                    }
                  </InstagramImgDiv>
                  <div>
                    <H3TitleForInsta>
                      {decodeURIComponent(instaDetails?.[0]?.user_name)}
                      <span
                        style={{
                          color: 'rgb(25, 118, 210)',
                          height: '23px',
                          marginLeft: '8px',
                        }}
                      >
                        {/*  */}
                        {instaDetails?.[0]?.search_type == 'user' &&
                          instaDetails?.[0]?.verified == true && <Verified />}
                      </span>
                    </H3TitleForInsta>
                    <PTitleforInsta>
                      {instaDetails?.[0]?.category || ''} {(`${formatNumber(instaDetails?.[0]?.followers) || 0} followers`)
                      }
                    </PTitleforInsta>
                    <PTitleforInsta>
                      {instaDetails?.[0]?.description || instaDetails?.[0]?.page_description}
                    </PTitleforInsta>
                  </div>
                </FlexForLogoDiv>
              </InstaWrapper>
            )
              :
              (channel == 'instagram' && instaDetails.length > 0) && (
                <InstaWrapper>
                  <InstagramCrossWrapper>
                    <InstaLogoWrapper>
                      {InstagramLogo}{' '}
                      {instaDetails?.[0]?.search_type == 'user'
                        ? 'Account'
                        : 'Hashtag'}
                    </InstaLogoWrapper>
                    <div onClick={() => removeInstaFunc()}>
                      {CrossIconWithOutBorder}
                    </div>
                  </InstagramCrossWrapper>
                  <InstaTopicTitle>
                    Topic :{' '}
                    {instaDetails?.[0]?.name ||
                      decodeURIComponent(instaDetails?.[0]?.user_name)}
                  </InstaTopicTitle>
                  <FlexForLogoDiv>
                    <InstagramImgDiv
                      whithVal={true}
                      searchType={instaDetails?.[0]?.search_type}
                    >
                      {
                        instaDetails?.[0]?.search_type == 'user' ? (
                          // <image src="" alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                          instaDetails?.[0]?.profilepic_url ? (
                            <img
                              src={instaDetails?.[0]?.profilepic_url}
                              style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                              }}
                            />
                          ) : (
                            StaticProfileImg
                          )
                        ) : (
                          <img
                            src={HashtagImg}
                            style={{
                              height: '62px',
                              width: '65px',
                              borderRadius: '50%',
                              border: 'none',
                            }}
                          />
                        )

                        // <div style={{ width: '70px', borderRadius: '50%', fontSize: '50px', textAlign: 'center' }}>#</div>
                      }
                    </InstagramImgDiv>
                    <div>
                      <H3TitleForInsta>
                        {instaDetails?.[0]?.name ||
                          decodeURIComponent(instaDetails?.[0]?.user_name)}
                        <span
                          style={{
                            color: 'rgb(25, 118, 210)',
                            height: '23px',
                            marginLeft: '8px',
                          }}
                        >
                          {/*  */}
                          {instaDetails?.[0]?.search_type == 'user' &&
                            instaDetails?.[0]?.verified == true && <Verified />}
                        </span>
                      </H3TitleForInsta>
                      <PTitleforInsta>
                        {instaDetails?.[0]?.full_name}
                      </PTitleforInsta>
                      <PTitleforInsta>
                        {(instaDetails?.[0]?.posts_count &&
                          `${formatNumber(
                            instaDetails?.[0]?.posts_count
                          )} posts`) ||
                          (instaDetails?.[0]?.followers_count &&
                            `${formatNumber(
                              instaDetails?.[0]?.followers_count
                            )} followers`)}
                      </PTitleforInsta>
                    </div>
                  </FlexForLogoDiv>
                </InstaWrapper>
              )
          }

          {channel === 'twitter' && (
            <KeywordWrapper>
              <Label>Location Origin </Label>
              <br />
              <SubLabel id='location-twitter'>
                Filter content based on country
              </SubLabel>

              <LocationTwitter
                option={locationX}
                placeholder='Search...'
                // hideRadius={true}
                onCityChange={handleCityChange}
                value={location}
                onChange={handleLocationChange}
                onChangeRadius={handleLocationRadiusChange}
              />
            </KeywordWrapper>
          )}

          {/*  <KeywordWrapper>
            <Label>User Country</Label>
            <br />
            <SubLabel>
              Filter content based on user's home country; default is global
            </SubLabel>
            <MultipleCountriesSelect
              placeholder='Select country'
              onChange={handleSelectedCountriesChange}
            />
          </KeywordWrapper> */}
        </ColumnWrapper>
        {/* {
          channel == 'instagram' ? */}
        <ColumnWrapper>
          {channel && (
            <div
              style={{ height: '113px', display: 'flex', alignItems: 'end' }}
            >
              <Input
                require={true}
                name='Topic'
                placeholder='Enter topic title'
                label={'Topic Title '}
                customStyle={{
                  width: '95%',
                  border: 'none',
                  marginTop: '0px',
                }}
                onChange={handleInputChange}
                characterLimit={characterLimit}
                value={inputValue}
                error={titleError}
                limit={characterLimit}
                maxLength={characterLimit}
                marginLeft={'-6px'}
                TextDivStyle={{
                  border: '1px solid rgb(162, 162, 162)',
                  borderRadius: '15px',
                  marginTop: '10px',
                }}
              />
            </div>
          )}

          {
            channel == 'facebook' &&
            <KeywordWrapper>
              <LabelDiv>
                Search Type <span>*</span>
              </LabelDiv>
              {/* <br /> */}
              <SubLabelDiv>
                Customize your search by selecting specific posts or pages
              </SubLabelDiv>
              {/* <ConnectedChannels
                handleConnectChannelsToggle={handleConnectChannelsToggle}
                channel={channel}
              /> */}
              <Select
                option={optionforFb}
                forChannel={channel}
                // key={pref.id}
                placeholder={'Select search type'}
                // subLabel={pref.subLabel}
                // option={pref.option}
                // style={{ marginTop: '6px' }}
                // value={pref.option.indexOf(pref.valueIndex)}
                onChange={(e) => searchPostsFbFunc(e)}
                selectHeight='22px'
              />
              {SearchTypeError && (
                <ErrorMessage>{SearchTypeError}</ErrorMessage>
              )}
            </KeywordWrapper>

          }

          {/* <KeywordWrapper></KeywordWrapper> */}

          {channel == 'instagram' ? (
            <KeywordWrapper>
              <LabelDiv>
                Search Type <span>*</span>
              </LabelDiv>
              {/* <br /> */}
              <SubLabelDiv>
                Customize your search by selecting specific hashtags or accounts
              </SubLabelDiv>
              {/* <ConnectedChannels
                handleConnectChannelsToggle={handleConnectChannelsToggle}
                channel={channel}
              /> */}
              <Select
                option={option}
                forChannel={channel}
                // key={pref.id}
                placeholder={'Select search type'}
                // subLabel={pref.subLabel}
                // option={pref.option}
                // style={{ marginTop: '6px' }}
                // value={pref.option.indexOf(pref.valueIndex)}
                onChange={(e) => searchUserFunc(e)}
                selectHeight='22px'
              />
              
              {
              // instaDetails && instaDetails?.length == 0 && channel == 'instagram' &&
              SearchTypeErrorInsta && (
                <ErrorMessage>{SearchTypeErrorInsta}</ErrorMessage>
              )}
            </KeywordWrapper>
          ) :
            channel == 'facebook' && searchTypeFb == 'Posts' ? (
              <KeywordWrapper>
                <Label>
                  Keywords <span>*</span>
                </Label>
                <br />
                <SubLabel>
                  Customize topic search by including specific keywords
                </SubLabel>
                <QueryString
                    setKeyWordError={setKeyWordError}
                  keywordType={keywordType}
                  placeholder='Enter keywords'
                  returnQueryString={returnQueryString}
                  setQueryArrays={setQueryArrays}
                  queryArrays={queryArrays}
                  channel={channel}
                //keyword = {queryString}
                />
                {keywordError && <ErrorMessage>{keywordError}</ErrorMessage>}
              </KeywordWrapper>
            ) :
              channel == 'facebook' && searchTypeFb != 'Posts' ? <></>
                :

                channel ? (
                  <KeywordWrapper>
                    <Label>
                      Keywords <span>*</span>
                    </Label>
                    <br />
                    <SubLabel>
                      Customize topic search by including specific keywords
                    </SubLabel>
                    <QueryString
                    setKeyWordError={setKeyWordError}
                      keywordType={keywordType}
                      placeholder='Enter keywords'
                      returnQueryString={returnQueryString}
                      setQueryArrays={setQueryArrays}
                      queryArrays={queryArrays}
                      channel={channel}
                    //keyword = {queryString}
                    />
                    {keywordError && <ErrorMessage>{keywordError}</ErrorMessage>}
                  </KeywordWrapper>
                ) : (
                  ''
                )}
          <PoPup
            topic={true}
            isOpen={showPopup}
            onClose={handleClosePopup}
            searchType={searchType}
            searchTypeFb={searchTypeFb}
            setInstaDetails={setInstaDetails}
            setSearchTypeErrorInsta={setSearchTypeErrorInsta}
            popupwidth='45%'
            channel={channel}
          />

          {/* </ColumnWrapper>

            // :
              <ColumnWrapper> */}
          {/* <Description
                  label={'Description'}
                  placeholder='Enter a brief description specifying the topic’s objective...'
                  value={desc}
                  characterLimit={descLimit}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                /> */}
          {channel === 'twitter' && (
            <KeywordWrapper>
              {/* <Label>Exclusions</Label>
                <br />
                <SubLabel>
                  Customize topic search by excluding specific keywords
                </SubLabel>
                {/* <QueryString
                  placeholder='Enter keywords'
                  returnQueryString={exclusionQuery}
                  setQueryArrays={setQueryExclucsionArrays}
                  queryArrays={queryExclusionArrays}
                  channel={channel}
                  exclude
                  //keyword = {queryString}
                /> */}
              <Label>Exclusions</Label>
              <br />
              <SubLabel>
                Exclude specific words, phrases, and hashtags when monitoring
                content{' '}
              </SubLabel>
              <QueryString
              setKeyWordError={setExecKeyWordError}
                keywordType={keywordType?.slice(0, 3)}
                placeholder='Enter exclusions'
                returnQueryString={exclusionQuery}
                setQueryArrays={setQueryExclucsionArrays}
                queryArrays={queryExclusionArrays}
                channel={channel}
                exclude
              //keyword = {queryString}
              />
            </KeywordWrapper>
          )}

          {/* )} */}
          {channel === 'twitter' && (
            <ItemList
              items={toggleData}
              exclusion={false}
              setData={setToggleData}
            />
          )}

          {/*  <KeywordWrapper>
              <Label>Post Country</Label>
              <br />
              <SubLabel>
                Filter content based on the country of origin; default is global
              </SubLabel>
              <MultipleCountriesSelect
                placeholder='Select country'
                onChange={handlePostCountry}
              />
            </KeywordWrapper> */}
        </ColumnWrapper>
        {/* } */}
      </MainContainer>
    </Wrapper>
  );
}

export default TopicBuilder;
