import React, { useState } from 'react';
import styled from 'styled-components';
import GraphDropdown from '../../Common/GraphDropDown/GraphDropdown';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';
import { format } from 'date-fns';
import { FaEye } from 'react-icons/fa';
import PopUp from '../../Common/PopUp/PopUp';

function generateDynamicLinearGradient(value, colorType) {
  if (typeof value !== 'number') {
    return '#fff';
  }

  // Define color stops and corresponding values
  // const colorStops = [
  //   { value: 1, color: '#4c3f69' },
  //   { value: 0.9, color: '#514370' },
  //   { value: 0.7, color: '#5d4d80' },
  //   { value: 0.5, color: '#68568f' },
  //   { value: 0.3, color: '#74609f' },
  //   { value: 0.1, color: '#8270a9' },
  //   { value: 0, color: '#9080b3' },
  //   { value: -0.1, color: '#9e8fbc' },
  //   { value: -0.3, color: '#ab9fc6' },
  //   { value: -0.5, color: '#b9afcf' },
  //   { value: -0.7, color: '#c7bfd9' },
  //   { value: -0.9, color: '#d5cfe2' },
  //   { value: -1, color: '#e3dfec' },
  // ];
  const colorStops = [
    { value: 1, color: '#008481' },
    // 0a9592
    // 22a6a3
    // 59aaa8
    // 8ec7c6
    { value: 0.9, color: '#1a918e' },
    { value: 0.7, color: '#22a6a3' },
    { value: 0.5, color: '#339d9a' },
    { value: 0.3, color: '#4da9a7' },
    { value: 0.1, color: '#59aaa8' },
    { value: 0, color: '#66b5b3' },
    { value: -0.1, color: '#7fc1bf' },
    { value: -0.3, color: '#8ec7c6' },
    { value: -0.5, color: '#99cecd' },
    // { value: -0.7, color: '#F3F9F9' },
    { value: -0.7, color: '#b3dbda' },
    { value: 0.9, color: '#cce6e6' },
    { value: -1, color: '#ddeded' },
  ];

  const colorStops2 = [
    { value: 1, color: '#0CBB53' },
    { value: 0.9, color: '#66ffa4' },
    { value: 0.7, color: '#1aff76' },
    { value: 0.5, color: '#00cc53' },
    { value: 0.3, color: '#008034' },
    { value: 0.1, color: '#cc9400' },
    { value: 0, color: '#ffc01a' },
    { value: -0.1, color: '#f48f91 ' },
    { value: -0.3, color: '#ef575b' },
    { value: -0.5, color: '#ea1f24' },
    { value: -0.7, color: '#ba1215' },
    { value: -0.9, color: '#830c0f ' },
    { value: -1, color: '#4b0708' },
  ];

  const colorStops3 = [
    { value: 1, color: '#5c4dfe' },
    { value: 0.9, color: '#6f62fe' },
    { value: 0.7, color: '#7367fe' },
    { value: 0.5, color: '#8b80fe' },
    { value: 0.3, color: '#a29afe' },
    { value: 0.1, color: '#b9b3ff' },
    { value: 0, color: '#ffffff' },
    { value: -0.1, color: '#fecfcd' },
    { value: -0.3, color: '#fdb8b4' },
    { value: -0.5, color: '#fda09b' },
    { value: -0.7, color: '#fc8882 ' },
    { value: -0.9, color: '#fc746c' },
    { value: -1, color: '#fc7069' },
  ];

  // Find the two closest color stops
  let stop1;
  if (colorType === 'type1') {
    stop1 = colorStops2.find((stop) => value.toFixed(1) >= stop.value);
  } else if (colorType === 'type2') {
    // stop1 = colorStops.find((stop) => value.toFixed(1) >= stop.value);
    stop1 = colorStops.find((stop) => value.toFixed(1) >= stop.value);

    if (!stop1) {
      return '#F3F9F9'; // Return a default color if no color stop is found
    }
  } else {
    stop1 = colorStops3.find((stop) => value.toFixed(1) >= stop.value);
  }
  const background = `${stop1.color}`;
  return background;
}

const HeatmapCell = styled.div`
  background: ${(props) =>
    generateDynamicLinearGradient(props?.value, props?.colorType)};
  border: 1px solid #000;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  // color: #fff;
  color: ${(props) => props?.colorType === 'type3' && '#222'};
  // color:black;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;

  ${(props) => {
    if (props?.value === 'hide') {
      return 'opacity:0';
    }
  }};
`;
const X_AxisLabel = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: 110px;

  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const HeatmapRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 70px);
`;

const HeatmapRow = ({ rowData, colorType = 'type1' }) => {
  return (
    <HeatmapRowContainer>
      {rowData.map((value, index) => (
        <HeatmapCell title={value} key={index} value={value} colorType={colorType}>
          {/* {value} */}
        </HeatmapCell>
      ))}
    </HeatmapRowContainer>
  );
};

const HeatmapHeader = styled.div``;

const HeatmapRowWrapper = styled.div`
  display: flex;
`;

const YLabelCell = styled.div`

  display: flex; 
  justify-content: end;

  align-items: center;
  margin-right: 18px;

  color: rgba(0, 0, 0, 0.81);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
`;

const MainContainer = styled.div`
  display: flex;
  gap: 10px;

  min-height: 120px;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
`;

const HeatmapGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: grid;
    grid-template-columns: repeat(10, 70px);
  }
`;

const ColorRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > div {
    width: 20px;
    height: 20px;
    cursor: pointer;

    & .label {
      display: none;
    }
  }

  .colorIndicatorType3 {
    background: linear-gradient(
      180deg,
      #6f62fe 5.28%,
      #75b0f5 20%,
      #ffffff 40%,
      #ffffff 50%,
      #ffa7a9 82%,
      #fc746c 100%
    );
    border: 1px grey solid;
  }

  .colorIndicatorType2 {
    // background: linear-gradient(
    //   180deg,
    //   #4c3f69 -14.86%,
    //   #574877 -3.5%,
    //   #615086 20.44%,
    //   #7661a5 42.07%,
    //   #8d74c3 68.97%,
    //   #9c82d6 89.22%,
    //   #fdfcff 106.17%
    // );
     background: linear-gradient(
      180deg,
      rgb(0, 132, 129) 0%,
      rgb(40 187 184) 20%,
      rgb(102, 181, 179) 40%,
      rgb(153, 206, 205) 70%,
      rgb(221, 237, 237) 100%
      // #008481 0%,
      // /* Deep teal */ #00d5d0 20%,
      // /* Bright cyan */ #b4eae9 40%,
      // /* Soft aqua */ #f6fdfc 70%,
      // /* Near white */ #ffffff 100% /* Pure white */
    );
  }
  .colorIndicatorType1 {
    background: linear-gradient(
      180deg,
      #c20000 5.28%,
      #ec3539 16.22%,
      #e9a9aa 29.5%,
      #fceaea 46.5%,
      #ffe49c 50%,
      #88f8b6 67%,
      #0cbb53 84.5%,
      #008335 100%
    );
    border: 1px grey solid;
  }

  .activeType {
    height: 510px;
    position: relative;

    & > .label {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      right: -40px;
      font-size: 15px;
      font-weight: 400;
      text-align: right;
    }
  }
`;

const Sentiment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 20px 40px;
  line-height: 25px;
  gap: 10px;
  flex-wrap: wrap;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0px 20px -15px;
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;
const SummaryButton = styled.button`

  height: 40px;
  width: 185px;
  font-size: 15px;
  font-weight: 500;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 2px;
  background: rgba(53, 60, 247, 1);
  border: none;
  border-radius: 10px;
  color: white;

 & > svg {
  width:18px;
  height:18px;
 }
`;

const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 250px;
  overflow: scroll;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
`;
const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const CorrelationGraph = ({
  correlationAnalysisData,
  RatingLabels = [],
  hideDropdown,
  startDate,
  endDate,
  formatValue = 'dd-yyyy-MM',
  correlation_summary=''
}) => {
  const [showSummary, setShowSummary] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Full');
  const [selectedColor, setSelectedColor] = useState('type2');
  const isEmpty = Object.entries(correlationAnalysisData ?? {}).length === 0;

  // const isEmpty = Object.entries(correlationAnalysisData).length === 0;
  // console.log(correlationAnalysisData, "correlationAnalysisData", isEmpty);
  if (!correlationAnalysisData || isEmpty) {
    // Handle the case where correlationAnalysisData is not available
    return (
      <Sentiment>
        <div>
          <Title>
            <TitleWithInfo
              title={'Correlation Analysis'}
              infoText={
                'Track the correlation coefficients between variables. A correlation of 1 indicates a perfect positive relationship, meaning as one variable increases, the other increases in perfect proportion. A lower value suggests a weaker or no relationship, with values closer to 0 indicating little to no correlation, and negative values representing inverse relationships between the variables.'
              }
            />
          </Title>
        </div>
        <MainContainer>
          <div style={{
            color: 'rgb(99, 99, 99)',
            fontSize: '16px',
            fontWeight: '600'
          }}>
            {/* No Data Available */}Insufficient Data
          </div>
        </MainContainer>
      </Sentiment>
    );
  }

  const keys = Object.keys(correlationAnalysisData);
  const data = keys.map((rowKey) =>
    keys.map((colKey) => correlationAnalysisData[rowKey][colKey])
  );

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };

  const getDisplayedData = () => {
    switch (selectedOption) {
      case 'Upper':
        return data.map((row, rowIndex) =>
          row.map((col, colIndex) => (colIndex >= rowIndex ? col : 'hide'))
        );
      case 'Lower':
        return data.map((row, rowIndex) =>
          row.map((col, colIndex) => (colIndex <= rowIndex ? col : 'hide'))
        );
      default:
        return data;
    }
  };
  const showSummaryFunc = () => {
    setShowSummary(true)
  }

  const message = `Posts with a neutral or slightly disgusted tone tend to get a bit more likes, possibly because they feel more balanced or thought-provoking. On the other hand, fear and surprise seem to lower likes, which could be because people might find these emotions unsettling or less engaging.

When looking at emotions themselves, fear and neutrality are strongly opposite—if a post is fearful, it’s usually not neutral. Similarly, joy and sadness don’t mix well, meaning a post that expresses joy is less likely to show sadness. Anger and neutrality also don’t go together, as angry posts tend to lack a neutral tone.`;

  // Split the message into sentences based on periods.
  const sentences = message.split('.');
  return (
    <Sentiment id='correlation-graph'>
      <div>
        <Title>
          <TitleWithInfo
            title={'Correlation Analysis'}
            infoText={
              'Track the correlation coefficients between variables. A correlation of 1 indicates a perfect positive relationship, meaning as one variable increases, the other increases in perfect proportion. A lower value suggests a weaker or no relationship, with values closer to 0 indicating little to no correlation, and negative values representing inverse relationships between the variables.'
            }
          />
          {/* <SummaryWrapper> */}
          {
            correlation_summary && <SummaryButton onClick={() => showSummaryFunc()}>View Summary <FaEye /></SummaryButton>
          
          }
          {/* </SummaryWrapper> */}
        </Title>
        {!hideDropdown && (
          <GraphDropdown
            options={['Full', 'Upper', 'Lower']}
            onSelectGraph={handleDropdownSelect}
            placement='right'
            defaultOption={selectedOption}
          />
        )}
      </div>
      <MainContainer>
        <HeatmapGraphContainer>
          {getDisplayedData().map((rowData, rowIndex) => (
            <HeatmapRowWrapper key={rowIndex}>
              <YLabelCell>{RatingLabels[rowIndex]}</YLabelCell>
              <HeatmapRow rowData={rowData} colorType={selectedColor} />
            </HeatmapRowWrapper>
          ))}
          <HeatmapHeader>
            {RatingLabels.map((label, index) => (
              <X_AxisLabel key={index}>{label}</X_AxisLabel>
            ))}
          </HeatmapHeader>
        </HeatmapGraphContainer>
        <ColorRangeWrapper colorType={selectedColor}>
          <div
            className={`colorIndicatorType1 ${selectedColor === 'type1' ? 'activeType' : ''
              }`}
            onClick={() => setSelectedColor('type1')}
          >
            <div className='label'>
              <span>1.0</span>
              <span>0.8</span>
              <span>0.6</span>
              <span>0.4</span>
              <span>0.2</span>
              <span>0.0</span>
              <span>-0.2</span>
              <span>-0.4</span>
              <span>-0.6</span>
              <span>-0.8</span>
              <span>-1.0</span>
            </div>
          </div>
          <div
            className={`colorIndicatorType3 ${selectedColor === 'type3' ? 'activeType' : ''
              }`}
            onClick={() => setSelectedColor('type3')}
          >
            <div className='label'>
              <span>1.0</span>
              <span>0.8</span>
              <span>0.6</span>
              <span>0.4</span>
              <span>0.2</span>
              <span>0.0</span>
              <span>-0.2</span>
              <span>-0.4</span>
              <span>-0.6</span>
              <span>-0.8</span>
              <span>-1.0</span>
            </div>
          </div>
          <div
            className={`colorIndicatorType2 ${selectedColor === 'type2' ? 'activeType' : ''
              }`}
            onClick={() => setSelectedColor('type2')}
          >
            <div className='label'>
              <span>1.0</span>
              <span>0.8</span>
              <span>0.6</span>
              <span>0.4</span>
              <span>0.2</span>
              <span>0.0</span>
              <span>-0.2</span>
              <span>-0.4</span>
              <span>-0.6</span>
              <span>-0.8</span>
              <span>-1.0</span>
            </div>
          </div>
        </ColorRangeWrapper>
      </MainContainer>
      {
        showSummary && <PopUp minWidth={'780px'} height={'400px'} maxWidth={'780px'} onClose={() => setShowSummary(false)}>
          {/* <span>cross</span>
        <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>

            <div>
              <TitleWithInfo
                title={'Summary '}
                infoText={``}
              />
            </div>
            <br />
            <Message>{correlation_summary}</Message>
            <br />
            <CancelButton onClick={() => setShowSummary(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      }
      <Span>{` This data is observed from ${startDate} to ${endDate}`}</Span>
    </Sentiment>
  );
};

export default CorrelationGraph;
