import React, { useState, useEffect } from 'react';
import {
  Preview,
  Wrapper,
  Text,
  QueryBuilderTitle_with_PreviewButton,
  TopicText,
  KeywordWrapper,
  Label,
  SubLabel,
  ErrorMessage,
  LoadingWrapper,
} from './TopicBuilder.styled';
import Input from '../../../SettingPage/Input';
import Description from '../../../../Common/DescriptionInput/Description';
import ConnectedChannels from '../ConnectedChannels/ConnectedChannels';
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { QueryPromptApi, resetFlagFunc, setQueryPromt, topicAIPreview, topicPreview } from '../../../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import styled, { keyframes } from 'styled-components';
import { Close } from '@mui/icons-material';
import { setMessage } from '../../../../store/redux/slices/alertMessageSlice';
import TextArea from '../../../SettingPage/TextArea';


const StyledKeywordWrapper = styled(KeywordWrapper)`
  width: 52%;
`;

const shadowChange = keyframes`
    0%   { box-shadow: rgb(240 11 11 / 84%) 0px 0px 15px rgba(255, 0, 0, 0.5); } /* Start with red shadow */
    25%  { box-shadow: rgb(18 244 18 / 96%) 0px 0px 15px; } /* Change to green shadow */
    50%  { box-shadow: 0px 0px 15px rgb(16 16 236 / 90%); } /* Change to blue shadow */
    75%  { box-shadow: 0px 0px 15px rgb(238 238 13 / 91%); } /* Change to yellow shadow */
    100% { box-shadow: 0px 0px 15px rgb(19 209 242); } /* Change to purple shadow */
`;


const QueryInputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  & > .inputContainer {
    // width: 70%;
    width:90%;
    position: relative;
    border:1px solid rgb(162, 162, 162);
    border-radius:15px;
    gap:11px;
    display:flex;
    align-items:center;
  }
  & .svgIcon {
    // position: absolute;
    right: 10px;
    top: 51%;
    cursor: pointer;
    margin-right: 10px;

  }
  & .svgIcon_mic {
    
    // position: absolute;
    right: 50px;
    top: 53%;
    cursor: pointer;
  }

  & > .svgMicIcon {
    margin-bottom: 8px;
    display: flex;
    gap: 5px;
    cursor: pointer;
  }

  & > .svgResetIcon {
    
    display: flex;
    height: 58px;
    cursor: pointer;
    align-items: center;
  }

  & .mic_box_shadow {
    width: 20px;
    height: 20px;
    margin: 0px;
    padding: 3px;
    border-radius:50%;
    // border:1px solid;
    animation: ${shadowChange} 5s infinite alternate; /* Apply the animation */
  }
`;

const MetaDataWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 90%;
  & > * {
    flex: 1;
  }
`;

const QueryPromptWrapper = styled.div`

padding: 15px;
border: 1px solid rgb(126 124 124);
border-radius: 15px;
margin-top: 25px;
    width: 87%;
`;

const QueryPromptFlexWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: ${(props) => props?.justifyGap ? 'space-between' : ''};
gap : ${(props) => props?.justifyGap ? '' : '20px'};
width: 100%;
overflow: hidden;
margin-top: 17px;
word-break: break-word;
`;

const QueryPromptDraftWrapper = styled.div`

width: 30.5%;
padding: 10px;
border: 1px solid rgba(53, 60, 247, 1);
border-radius: 15px;
cursor:pointer;

`;
const QuerySelectedPromptDraftWrapper = styled.div`

width: 30.5%;
padding: 10px;
border: 1px solid rgba(53, 60, 247, 1);
border-radius: 15px;
background: rgb(53, 60, 347);
color: white;
cursor:pointer;

`;




function AiTopicBuilder() {
  const [queryPlaceHolder, setQueryPlaceHolder] = useState('Enter Query Prompt')
  const [loadingVal, setLoadingVal] = useState(false)
  const [startRec, setStartRec] = useState(true)
  const [queryData, setQueryData] = useState()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [inputValue, setInputValue] = useState('');
  const [queryPromptValue, setQueryPromptValue] = useState('');
  const [queryPromptError, setQueryPromptError] = useState('');
  const [showPrompt, setShowPrompt] = useState(false)
  const [desc, setDesc] = useState('');
  const [channel, setChannel] = useState('');
  const [titleError, setTitleError] = useState('');
  const [channelError, setChannelError] = useState('');

  const [topicPreviewDataLoading, setTopicPreviewDataLoading] = useState(false);

  const { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};

  const { queryValue } = useSelector((state) => state.topicBuilder) || [];
console.log(queryValue,"queryValue");

  const navigate = useNavigate();



  const dispatch = useDispatch();

  const characterLimit = 50;
  const descLimit = 250;

  useEffect(() => {
    // const loadingFlag = JSON.parse(localStorage.getItem('topicPreviewLoading'));
    // if (loadingFlag) {
    //   setTopicPreviewDataLoading(true);
    // } else if (topicPreviewDataLoading && !loadingFlag) {
    //   setTopicPreviewDataLoading(false);
    // }
    if (topicPreviewData != null && topicPreviewData?.data == "Has Failed") {
      dispatch(
        setMessage({
          message:
          'Unable to create a preview. Please attempt your action again.',
          timer: 'off',
          type: 'rejected',
        })
      );
    }
    else if (topicPreviewData != null && (topicPreviewData?.data != 'No data' || topicPreviewData?.data != "Has Failed")) {

      setTimeout(() => {
        navigate('/topic/view')
      }, 2000);
    }


  }, [topicPreviewData]);




  const handleConnectChannelsToggle = (selected) => {
    if (channel === selected) {
      setChannel(null); // Deselect if the same channel is clicked again
    } else {
      setChannel(selected); // Select the new channel
    }

    if (!selected) {
      setChannelError('Select at least one channel');
    } else {
      setChannelError('');
    }
  };

  const handleButtonClick = (event) => {
    event.preventDefault();

    let activeError = false;

    if (inputValue === '' || inputValue.trim() === '' || inputValue.length < 5) {
      activeError = true;
      setTitleError('Topic Title is required');
    }

    if (queryPromptValue === '' || queryPromptValue.trim() === '') {
      activeError = true;
      setQueryPromptError('Query Prompt is required');
    }

    if (!channel) {
      activeError = true;
      setChannelError('Please select atleast one channel');
    }

    if (activeError) {
      return false;
    }



    const inputData = {
      title: inputValue,
      channel_name: channel,
      description: desc,
      prompt: queryPromptValue,
      draft: queryData?.draft,
      // keywords_string: `(\"puma shoes\" OR #Puma) -@PUMA has:mentions`
      keywords_string: queryData?.query,
    
  ...(channel === 'facebook' && { facebook_search_type: 'post' })

    }

    dispatch(topicAIPreview(inputData)).then((res) => {
      setTopicPreviewDataLoading(false);

    });
    setTopicPreviewDataLoading(true);
    dispatch(resetFlagFunc(false))
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue != '') {
      setTitleError('');
    }
    setInputValue(newValue);
  };

  const handleQueryPromptChange = (e) => {
    const newValue = e.target.value;

    if (newValue != '') {
      setQueryPromptError('');
    }
    setQueryPromptValue(newValue);
  };

  const handleSubmitQuery = () => {
    if (!channel) {
      setChannelError('Please select atleast one channel');
    } else {
      
    setSelectedIndex(-1)
    setInputValue('');
    setDesc('');
    dispatch(setQueryPromt(false))
    setQueryData()

      setLoadingVal(true)
      dispatch(QueryPromptApi({ prompt: queryPromptValue, channel_name: channel }))
        .then((i) => {
          setShowPrompt(true)
          setLoadingVal(false)
        })
        .catch((err) => console.log(err, "seee"))

    }


  }

  const handleResetQuery = () => {
    setQueryPlaceHolder('Enter Query Prompt')
    setQueryPromptValue('')
    setDesc('')
    setQueryData('')
    setInputValue('');
    dispatch(setQueryPromt(false))
  }

  const handleSetValue = (query, index) => {
    setQueryData(query)
    setSelectedIndex(index)
    setDesc(query?.description)
    setInputValue(query?.topic)
  }


  const handleStartMic = () => {
    setQueryPlaceHolder('Enter Query Prompt')
    setStartRec(!startRec)
  }


  function startVoiceInput() {
    setQueryPromptValue('')
    setStartRec(!startRec);
    setQueryPlaceHolder('Listening...');
    // let inactivityTimer;
    const recognition = new window.webkitSpeechRecognition();
    // const recognition = new webkitSpeechRecognition(); // Create a SpeechRecognition object
    recognition.lang = 'en-US'; // Set language to English (United States)

    // recognition.continuous = true; // Enable continuous listening
    recognition.interimResults = false; // Optional: only return final results

    // clearTimeout(inactivityTimer);
    // Start speech recognition
    recognition.start();

    // inactivityTimer = setTimeout(() => {
    //   console.log('Speech recognition closed due to inactivity.1');
    //   console.log('Toggling startRec state from:', startRec, 'to:', !startRec);
    //   setStartRec((prev) => !prev); // Toggle recording state
    //   setQueryPlaceHolder((prev)=>'Enter Query Prompt')
    //   recognition.stop(); // Stop recognition after timeout
    // }, 6000); // 7 seconds timeout
    // Event listener for when speech recognition results are available
    recognition.onresult = function (event) {
      // setQueryPlaceHolder('Loading...')
      if (event?.results.length > 0) {
        const transcript = event.results[0][0].transcript; // Get the transcribed text

        setQueryPromptValue(transcript)
        setStartRec(true)
        setQueryPlaceHolder('Enter Query Prompt')


        // clearTimeout(inactivityTimer);
        // inactivityTimer = setTimeout(() => {
        //   console.log('Speech recognition closed due to inactivity.2');
        //   setStartRec((prev) => !prev); // Toggle recording state
        //   setQueryPlaceHolder('Enter Query Prompt')
        //   recognition.stop();
          
        // }, 6000); // Reset to 7 seconds
      }

    };
    recognition.onend = function () {
      console.log('Recognition stopped. Restarting...');
      // recognition.start(); // Restart the recognition
    };

    // Event listener for errors
    recognition.onerror = function (event) {
      console.error('Speech recognition error:', event.error);
      // clearTimeout(inactivityTimer);
    };
  }

  const handleCloseDraft = () => {
    setShowPrompt(false);
    setInputValue('');
    setDesc('');
    dispatch(setQueryPromt(false))
    setQueryData()
    console.log(queryData,"queryValue");
  }
  return (
    <Wrapper>
      {topicPreviewDataLoading && (
        <LoadingWrapper>
          <div>
            <CircularProgress />
          </div>
        </LoadingWrapper>
      )}

      {/* <VoiceToText /> */}
      <Text>
        Effortlessly construct custom queries and generate comprehensive
        reports, delivering actionable intelligence at your fingertips
      </Text>
      <QueryBuilderTitle_with_PreviewButton>
        <TopicText>Query Composer</TopicText>
        <Preview onClick={handleButtonClick}>Preview</Preview>
      </QueryBuilderTitle_with_PreviewButton>

      <div>
        <StyledKeywordWrapper>
          <Label>
            Channels <span>*</span>
          </Label>
          <br />
          <SubLabel>Select interested channels for topic creation</SubLabel>
          <ConnectedChannels
            handleConnectChannelsToggle={handleConnectChannelsToggle}
            channel={channel}
            isai={true}
          />
          {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
        </StyledKeywordWrapper>
        <div style={{ marginTop: '20px' }}>
          <Label>
            Query Prompt <span>*</span>
          </Label>
          <QueryInputWrapper>
            <div className='inputContainer' >
              <TextArea
                setChange={true}
                require={true}
                name='QueryPrompt'
                placeholder={queryPlaceHolder}
                // label={'Query Prompt'}
                onChange={handleQueryPromptChange}
                value={queryPromptValue}
                error={queryPromptError}
                marginTop="0px"
                Border="none"
              />
              <div className='svgIcon_mic'>
                {
                  !startRec ?
                    <div className='mic_box_shadow'>
                      {/* FaMicrophoneSlash */}
                      <FaMicrophone style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%'

                      }} onClick={() => handleStartMic()} />
                      {/*  */}
                    </div>

                    :

                    <FaMicrophoneSlash style={{
                      width: '20px',
                      height: '20px',
                      margin: '0px',
                      padding: '3px',
                      // marginTop: '3px'
                    }} onClick={() => startVoiceInput()} />
                }
              </div>
              <div className='svgIcon' onClick={() => handleSubmitQuery()}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='32'
                  viewBox='0 0 28 32'
                  fill='none'

                >
                  <path
                    d='M4.66406 24.6654V17.7934L11.4371 15.9987L4.66406 14.204V7.33203L22.5327 15.9987L4.66406 24.6654Z'
                    fill='black'
                  />
                </svg>
              </div>

            </div>
            <div className='svgResetIcon' onClick={() => handleResetQuery()}>



              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='31'
                height='31'
                viewBox='0 0 29 29'
                fill='none'
              >
                <path
                  d='M14.5009 22.0396C10.3249 22.0396 6.96094 18.6756 6.96094 14.4996C6.96094 12.6436 7.65694 10.9036 8.87494 9.51158L9.74494 10.2656C8.70094 11.4256 8.12094 12.9336 8.12094 14.4996C8.12094 18.0376 10.9629 20.8796 14.5009 20.8796C15.4289 20.8796 16.2989 20.7056 17.1689 20.2996L17.6329 21.3436C16.6469 21.8076 15.6029 22.0396 14.5009 22.0396ZM20.1269 19.5456L19.2569 18.7916C20.3009 17.6316 20.8809 16.1236 20.8809 14.5576C20.8809 11.0196 18.0389 8.17758 14.5009 8.17758C13.5729 8.17758 12.7029 8.35158 11.8329 8.75758L11.3689 7.71358C12.3549 7.24958 13.3989 7.01758 14.5009 7.01758C18.6769 7.01758 22.0409 10.3816 22.0409 14.5576C22.0409 16.3556 21.3449 18.1536 20.1269 19.5456Z'
                  fill='black'
                />
                <path
                  d='M10.4408 13.9193H9.28078V10.4393H5.80078V9.2793H10.4408V13.9193ZM23.2008 19.7193H18.5608V15.0793H19.7208V18.5593H23.2008V19.7193Z'
                  fill='black'
                />
              </svg>
            </div>
          </QueryInputWrapper>
        </div>
        {
          loadingVal ?
            <LoadingWrapper>
              <div>
                <CircularProgress />
              </div>
            </LoadingWrapper>
            :

            queryValue?.length > 0 && showPrompt === true &&
            <QueryPromptWrapper>
              <div
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>
                  <h5 style={{ margin: '0px', fontSize: '16px' }}>Did you mean this ?</h5>
                  <p style={{ margin: '0px', fontSize: '14px', marginTop: ' 2px', }}>Please select one draft to get query which you are looking for</p>

                </div>
                <div>
                  <Close style={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    padding: '2px'
                  }}
                    onClick={() => handleCloseDraft()}
                  />
                </div>

              </div>
              <QueryPromptFlexWrapper justifyGap={queryValue?.length > 0 && queryValue?.length >= 2 ? true : false}>
                {
                  queryValue?.length > 0 && queryValue?.map((query, index) => (
                    <>{
                      selectedIndex == index ?
                        <QuerySelectedPromptDraftWrapper onClick={() => handleSetValue(query, index)} key={index}>
                          <h4 style={{ fontSize: '16px' }}>Draft {index + 1}</h4>
                          <p style={{ fontSize: '16px' }}>{query?.draft}</p>

                        </QuerySelectedPromptDraftWrapper>
                        :
                        <QueryPromptDraftWrapper onClick={() => handleSetValue(query, index)} key={index}>
                          <h4 style={{ fontSize: '16px' }}>Draft {index + 1}</h4>
                          <p style={{ fontSize: '16px' }}>{query?.draft}</p>

                        </QueryPromptDraftWrapper>
                    }
                    </>
                  )
                  )
                }
              </QueryPromptFlexWrapper>
            </QueryPromptWrapper>
        }
        <div style={{ width: '52%', marginTop: '20px' }}>
          <Input
            require={true}
            name='Topic'
            placeholder='Enter topic title'
            label={'Topic Title '}
            customStyle={{
              width: '95%',
              border: 'none', marginTop: '0px'
            }}
            TextDivStyle={{
              border: '1px solid rgb(162, 162, 162)',
              borderRadius: '15px',
              marginTop: '10px',

            }}
            marginLeft={'-6px'}
            onChange={handleInputChange}
            characterLimit={characterLimit}
            value={inputValue}
            error={titleError}
            limit={characterLimit}
            maxLength={characterLimit}
            showAi={true}
          />

        </div>

        <MetaDataWrapper>
          <Description
            label={'Description'}
            placeholder='Enter a brief description specifying the topic’s objective...'
            value={desc}
            characterLimit={descLimit}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
            showAi={true}
          />





        </MetaDataWrapper>
        <div style={{marginTop:'20px',width:'99%'}}>

        {
          queryData?.query &&
          <TextArea
            paddingText='7px 21px'
            showBorder={true}
            marginTopVal={true}
            require={true}
            name='Query'
            // placeholder='Enter topic title'
            label={'Query'}
            customStyle={{ width: '95%' }}
            // onChange={handleInputChange}
            // characterLimit={characterLimit}
            setChange={false}
            value={queryData?.query}
          // error={titleError}
          // limit={characterLimit}
          // maxLength={characterLimit}
          />
        }
        </div>
      </div>
    </Wrapper>
  );
}

export default AiTopicBuilder;
