import React from 'react';
import { HelpIcon } from '../SvgIcon/CommonSvgIcons';
import styled from 'styled-components';

const InfoWrapperDiv = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;
const InfoWrapperVal = styled.div`
  /* display: none; */
  color: #636363;
    position: relative;
    // left: -70px;
    // top: 100%;
    min-width: 200px;
    max-width: 300px;
    min-height: 30px;
    background: #d0ccff;
    padding: 10px;
    border-radius: 8px;
    z-index: 100;
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;


    display: flex;
    align-items: center;




  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {

    
    content: "";
    position: absolute;
    top: 15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
        left: -3%;
    transform: rotate(0deg);
  }
`;

const InfoWrapper = ({setInfotext,infoText,val=''}) => {
    return (
            <InfoWrapperDiv data-testid="info-wrapper" onMouseOver={() => setInfotext(true)} onMouseLeave={() => setInfotext(false)}>
            {HelpIcon}
            {infoText && (
              <InfoWrapperVal>
                {val}
              </InfoWrapperVal>
            )}
          </InfoWrapperDiv>
    );
};


export default InfoWrapper;