import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  CrossIcon,
  HorizontalArrowIcon,
  QuestionIcon,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import {
  CustomAnalysis,
  CustomAnalysisResponse,
  emptyCustomAnalysis,
  emptyCustomAnalysisResult,
  searchQueryEmpty,
  updateSearchQuery,
  updateSelectedDraft,
} from '../../../store/redux/slices/chatterSlice';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 50px;
  padding: 40px 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 5%;
  gap: 12px;
  align-items: center;

  & > div:first-of-type {
    border: 1px solid #a2a2a2;
    position: relative;
    border-radius: 15px;
    gap: 14px;
    align-items: center;
    display: flex;
    height: 50px;
    padding-left: 15px;
    padding-right: 7px;
  }

  & > div:last-of-type {
    position: relative;
    display: flex;
    cursor: pointer;
    // margin-top: 10px;
    // margin-left: 10px
  }
`;
const InputTextArea = styled.input`
  height: 40px;
  width: 100%;
  resize: none;
  padding-left: 15px;

  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  border: none;
`;
const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 15px;

  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin-top: 50px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  margin-top: 30px;
`;

const Table = styled.table`
  width: 90%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  text-align: center;
`;

const TableRow = styled.tr`
  border: 1px solid #ddd;
  height: auto;
  margin: 20px;
`;

const TableCell = styled.td`
  padding: 8px; /* Cell padding */
  border-right: 1px solid #ddd;
`;

const TableHead = styled.th`
  height: 50px;
  padding: 10px;
  background: #ededed;
  border-right: 1px solid #ddd;
`;

const ContentWrapper = styled.div`
  width: 75%;
`;

const DraftWrapper = styled.ul`
  width: 89%;
  /* padding-left: 2px; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid #a2a2a2;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
`;

const DraftItem = styled.li`
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  /*  background-color: #fff; */
  &:last-child {
    border-bottom: none;
  }

  color: #000;

  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
`;

const Icon = styled.span`
  // position: absolute;
  // right: 100px;
  // top: 50%;
  cursor: pointer;
  // transform: translate(0%, -50%);
  transform: translate(0%, 11%);
`;

const ArrowIcon = styled.span`
  // position: absolute;
  // right: 60px;
  // top: 50%;
  cursor: pointer;
  // transform: translate(0%, -50%);
  transform: translate(0%, 11%);
  margin-right: 7px;
`;

const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
  position: absolute;
  left: -70px;
  top: 120%;
  min-width: 200px;
  max-width: 300px;
  min-height: 30px;
  background: #d0ccff;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: 35%;
    transform: rotate(90deg);
  }
`;

const CustomAnalysisTab = ({
  job_id,
  preview_uuid,
  onUpdateSearchQuery,
  channel,
}) => {
  const {searchQuery,selectedDraft,customAnalysisData,customAnalysisResult} = useSelector((state) => state.chatterTracker);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchQuery, setSearchQuery] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [tableData, setTableData] = useState();
  const [showDraft, setshowDraft] = useState([]);
  // const [selectedDraft, setSelectedDraft] = useState('');
  const [infoText, setInfotext] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.chatterTracker.isImageLoading);

  /* useEffect(() => {
    const storedSearchQuery = localStorage.getItem('searchQuery');
    const storedImage = localStorage.getItem('imageResponse_chatter');
    const storedTableData = localStorage.getItem('tableData_chatter');

    setSearchQuery(storedSearchQuery || '');
    setImageUrl(storedImage || '');
    setTableData(storedTableData ? JSON.parse(storedTableData) : []);
  }, []);
 */
  /* if (imageUrl === null) {
    onUpdateImage(tableData);
  } else {
    onUpdateTable(imageUrl);
  } */

  const handleSearch = async () => {
    const channelName = channel;
    const uuid = preview_uuid;
    const jobId =
      job_id !== undefined && job_id !== null && job_id
        ? job_id.toString()
        : null;
    /* localStorage.setItem('searchQuery', searchQuery);
    if (imageUrl !== '' && !tableData) {
      localStorage.setItem('imageResponse_chatter', imageUrl);
      localStorage.setItem('tableData_chatter', tableData);
    } */

    onUpdateSearchQuery(searchQuery);
    try {
      const data =
        jobId !== undefined && jobId !== null && jobId
          ? await dispatch(CustomAnalysis({ searchQuery, channelName, jobId }))
          : await dispatch(CustomAnalysis({ searchQuery, channelName, uuid }));
      // setshowDraft(data?.payload?.response);

      /*   if (data?.payload?.response?.type === 'image') {
       
        const imageUrl = data?.payload?.response?.image_url;
        setImageUrl(imageUrl);
        localStorage.setItem('imageResponse_chatter', imageUrl); // Store table data in localStorage
      } else if (data?.payload?.response?.type === 'table') {
        const tableData = data?.payload?.response?.data;
        setTableData(tableData);
        localStorage.setItem('tableData_chatter', JSON.stringify(tableData));
      } */
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDraftClick = async (suggestion, is_table) => {
    const channelName = channel;
    const uuid = preview_uuid;
    const jobId = job_id ? job_id.toString() : null;
    setImageUrl(null);
    // setTableData(null);
    
    dispatch(emptyCustomAnalysisResult());
    dispatch(emptyCustomAnalysis());
    dispatch(updateSelectedDraft(suggestion));
    try {
      /* const data = await dispatch(CustomAnalysisResponse({ searchQuery: suggestion, channelName: channelName, jobId:job_id.toString() })); */
      const data =
        jobId !== undefined && jobId !== null && jobId
          ? await dispatch(
              CustomAnalysisResponse({
                is_table: is_table,
                searchQuery: suggestion,
                channelName,
                jobId,
              })
            )
          : await dispatch(
              CustomAnalysisResponse({
                is_table: is_table,
                searchQuery: suggestion,
                channelName,
                uuid,
              })
            );
      localStorage.setItem(
        `local_${
          jobId !== undefined && jobId !== null && jobId
            ? jobId
            : uuid
            ? uuid
            : ''
        }`,
        JSON.stringify({
          query: suggestion,
          is_table: is_table,
          response: data?.payload?.response,
        })
      );
      // if (data?.payload?.response?.type === 'image') {
      //   const imageUrl = data.payload.response.image_url;
      //   setImageUrl(imageUrl);
      //   /* localStorage.setItem('imageResponse_chatter', imageUrl); */
      // } else if (data?.payload?.response?.type === 'table') {
      //   const tableData = data.payload.response.data;
      //   setTableData(tableData);
      //   /*  localStorage.setItem('tableData_chatter', JSON.stringify(tableData)); */
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderResult = () => {
    if (customAnalysisResult?.type == 'image') {
      return <Image src={customAnalysisResult?.image_url} />;
    } else if (Array.isArray(customAnalysisResult?.data) && customAnalysisResult?.data.length > 0) {
      const keys = Object.keys(customAnalysisResult?.data[0]); // Get the keys from the first object
      return (
        <Table>
          <thead>
            <TableRow>
              {keys.map((key, index) => (
                <TableHead key={index}>{key}</TableHead>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {customAnalysisResult?.data?.map((item, index) => (
              <TableRow key={index}>
                {keys.map((key, idx) => (
                  <TableCell key={idx}>{item[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      );
    } else {
      return null;
    }
  };

  const handleDeleteQuery = () => {
    // dispatch(searchQueryEmpty())
    dispatch(updateSearchQuery(''));
    dispatch(emptyCustomAnalysis());
    dispatch(emptyCustomAnalysisResult());
    // setImageUrl(null);
    // setTableData(null);
    dispatch(updateSelectedDraft(null));
    
  };

  const handleOnchange = (e) => {
    dispatch(updateSearchQuery(e.target.value))
    // setSearchQuery(e.target.value);
    dispatch(emptyCustomAnalysis());
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <InputWrapper>
          <div>
            <InputTextArea
              type='text'
              value={searchQuery}
              onChange={(e) => handleOnchange(e)}
            ></InputTextArea>
            {searchQuery && (
              <Icon onClick={handleDeleteQuery}>{CrossIcon}</Icon>
            )}
            <ArrowIcon onClick={handleSearch}> {HorizontalArrowIcon}</ArrowIcon>
          </div>
          <div
            onMouseOver={() => setInfotext(true)}
            onMouseLeave={() => setInfotext(false)}
          >
            {QuestionIcon}
            {infoText && (
              <InfoWrapper>
                Users may ask report-specific questions and access relevant
                insights from the collected data to facilitate informed
                decision-making.
              </InfoWrapper>
            )}
          </div>
        </InputWrapper>

        {customAnalysisData && customAnalysisData?.length > 0 && (
          <DraftWrapper>
            {customAnalysisData?.map((item, index) => (
              <DraftItem
                onClick={() => handleDraftClick(item.suggestion, item.is_table)}
                key={index}
              >
                {item.suggestion}
              </DraftItem>
            ))}
          </DraftWrapper>
        )}
        {selectedDraft && (
          <div style={{ marginTop: '10px' }}>
            <b>Selected Draft:</b> {selectedDraft}
          </div>
        )}
      </ContentWrapper>
      {loading ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Container>{renderResult()}</Container>
      )}
    </Wrapper>
  );
};

export default CustomAnalysisTab;
