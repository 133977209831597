import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowRight } from '../../../../assests/images/LandingAssets/ArrowRight.svg';
import { ReactComponent as ArrowLeft } from '../../../../assests/images/LandingAssets/ArrowLeft.svg';
import { ReactComponent as ProfileImage } from '../../../../assests/images/LandingAssets/ProfileImage.svg';
import { ReactComponent as SophiaProfile } from '../../../../assests/images/LandingAssets/SophiaProfile.svg';
import { ReactComponent as DanielProfile } from '../../../../assests/images/LandingAssets/DanielProfile.svg';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '1800px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  @media only screen and ${device.tablet} {
    padding-left: 24px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding-left: 120px;
  margin-top: 34px;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  @media only screen and ${device.tablet} {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scrollbar-height: none;
    padding-left: 0px;
  }
  @media only screen and ${device.desktop} {
    padding-left: none;
    justify-content: center;
  }
`;

const Card = styled.div`
  flex: 0 0 574px;
  border-radius: 20px;
  border: 5px solid #391c99;
  padding: 50px;
  background-color: white;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  @media only screen and ${device.tablet} {
    flex: 0 0 230px;
    padding: 30px 20px 30px 20px;
  }
`;

const CardTitle = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  @media only screen and ${device.tablet} {
    font-size: 24px;
    min-height: 88px;
  }
`;

const CardText = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  min-width: 524px;
  @media only screen and ${device.tablet} {
    min-width: 230px;
    font-size: 14px;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 80px;
  justify-content: start;
  display: flex;
  margin-bottom: 20px;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 120px;
  @media only screen and ${device.tablet} {
    display: none;
  }
  @media only screen and ${device.desktop} {
    display: none;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
const Rotate = styled.div`
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: -7px;
`;
const CarousalCards = () => {
  const containerRef = useRef(null);

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -3000 : 3000;
      containerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const cards = [
    {
      title: "The Founder's Insight",
      text: 'Liam Thomson, a startup founder, uncovered unexpected demand for his eco-friendly products in southern India by analyzing emotional engagement data, allowing him to pivot marketing strategies and boost sales in that region.',
      ProfileImage: <ProfileImage />,
    },
    {
      title: 'The Agency Consultant’s Perspective',
      text: 'Sophia Martinez, a marketing consultant, discovered that high engagement rates didn’t always correlate with positive emotions, leading her to redesign client strategies to address negative sentiments and enhance customer loyalty.',
      ProfileImage: <SophiaProfile />,
    },
    {
      title: "The Author's Takeaway",
      text: 'Daniel Green, an experienced author, emphasizes the importance of emotional sentiment analysis in uncovering hidden opportunities that significantly impact brand growth and customer relationships.',
      ProfileImage: <DanielProfile />,
    },
  ];
  const [activeScroll, setActiveScroll] = useState('left');
  return (
    <Wrapper>
      <ArrowContainer>
        <ArrowButton
          onClick={() => {
            scroll('left');
            setActiveScroll('left');
          }}
        >
          <Rotate>
            <ArrowRight opacity={activeScroll === 'left' ? 0.5 : 1} />
          </Rotate>
        </ArrowButton>
        <ArrowButton
          onClick={() => {
            scroll('right');
            setActiveScroll('right');
          }}
        >
          <ArrowRight opacity={activeScroll === 'right' ? 0.5 : 1} />
        </ArrowButton>
      </ArrowContainer>

      <Container ref={containerRef}>
        {cards.map((card, index) => (
          <Card key={index}>
            <CardTitle>{card.title}</CardTitle>
            <ImageWrapper>{card?.ProfileImage}</ImageWrapper>
            <CardText>{card.text}</CardText>
          </Card>
        ))}
      </Container>
    </Wrapper>
  );
};

export default CarousalCards;