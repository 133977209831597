import styled from "styled-components";

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-top: 1px solid #d2d2d2;
  padding: 30px;
  & .button {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    background: #353cf7;
    color: #fff;

    height: 35px;
    padding: 5px 20px;
    border-radius: 10px;

    border: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      margin-left: 10px;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  & .buttonContainer {
    display: flex;
    gap: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    width: 150px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */} 
    padding: 1px 8px;
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;