import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const StackedBarChart = ({ data, channel }) => {
  const COLORS = [
    '#0288D1',
    '#F06292',
    '#D3D3D3',
    '#66E7A0',
    '#EE1307',
    '#F5CC61',
    '#7FE9E7',
    '#6DA5A4',
    '#7FE9E7',
  ];
  const keys = [
    ...new Set(
      data.flatMap((obj) => Object.keys(obj).filter((key) => key !== 'name'))
    ),
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className='custom-tooltip'
          style={{
            background: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
          }}
        >
          {payload.map((entry, index) => (
            <p
              key={index}
              style={{ color: entry.color, fontSize: '11px',fontWeight:'600' }}
            >{`${entry.name}: ${entry.value}%`}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer
      width='100%'
      height={channel === 'twitter' ? 200 : 100}
    >
      <BarChart
        layout='vertical'
        data={data}
        barSize={32}
        barGap={10}
        style={{ fontSize: '10px', fontFamily: 'Montserrat' }}
        margin={{ top: 0, right: 0, left: 30, bottom: 5 }}
      >
        <XAxis type='number' hide />
        <YAxis
          dataKey='name'
          type='category'
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#666', fontSize: 11 }}
        />
        <Tooltip cursor={false} content={<CustomTooltip />} />
        {keys?.map((data, index) => {
          return (
            <Bar
              key={data}
              dataKey={data}
              stackId='a'
              activeBar={false}
              fill={COLORS[index]}
              data-testid="bar"
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
