import React, { useState } from 'react';
import styled from 'styled-components';
import GenderBasedRating from '../../Common/GenderBasedRating/GenderBasedRatingGraph';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 97%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  margin: 20px 0px 0px 20px;

  &>div: first-child {
    display: flex;
    flex-direction: row;
  }
`;

const Label = styled.span`
  /* color: #636363; */

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin: 30px 0px 0px 20px;
`;

const OptionDiv = styled.div`
  flex-direction: row;
  margin: 25px 0px 0px 100px;
`;

const GenderSpan = styled.span`
  cursor: pointer;
  height: 21px;
  padding: 5px 20px;
  border: 1px solid #e4e4e4;
  border-radius: ${(props) => (props.selected ? '4px 0px 0px 4px' : '1px')};
  background-color: ${(props) => (props.selected ? '#776BFE' : 'transparent')};
  color: ${(props) => (props.selected ? '#FFF' : 'black')};

  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-right: 0px;
`;

const Span = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-end;
  text-align: right;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const GenderRating = ({ data, startDate, endDate,hideDropdown }) => {
  const [selectedGender, setSelectedGender] = useState('female');
  let genderRatingData;
  if (selectedGender === 'female') {
    genderRatingData = {
      rating: [
        parseFloat(data?.gender_based_rating?.female.five_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.female.four_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.female.three_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.female.two_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.female.one_star_ratings)  || 0,
      ],
    };
  } else if (selectedGender === 'male') {
    genderRatingData = {
      rating: [
        parseFloat(data?.gender_based_rating?.male.five_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.male.four_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.male.three_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.male.two_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.male.one_star_ratings)  || 0,
      ],
    };
  } else {
    genderRatingData = {
      rating: [
        parseFloat(data?.gender_based_rating?.unknown.five_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.unknown.four_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.unknown.three_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.unknown.two_star_ratings)  || 0,
        parseFloat(data?.gender_based_rating?.unknown.one_star_ratings)  || 0,
      ],
    };
  }

  const handlleGenderClick = (option) => {
    setSelectedGender(option);
  };

  return (
    <FlexWrapper>
      <div><Label><TitleWithInfo
                                title={'Gender Based Rating'}
                                infoText={`A correlation matrix is a table showing correlation coefficients
                              between variables. Each cell in the table shows the correlation
                              between two variables`}
                              /></Label>
        {/* <Label>Gender Based Rating</Label> */}
        <OptionDiv style={{
          display: hideDropdown === true ? 'none' : 'flex'
        }}>
          <GenderSpan
            selected={selectedGender === 'female'}
            onClick={() => handlleGenderClick('female')}
          >
            Female
          </GenderSpan>
          <GenderSpan
            selected={selectedGender === 'male'}
            onClick={() => handlleGenderClick('male')}
          >
            Male
          </GenderSpan>
          <GenderSpan
            selected={selectedGender === 'unknown'}
            onClick={() => handlleGenderClick('unknown')}
          >
            Unknown
          </GenderSpan>
        </OptionDiv>
      </div>
      <GenderBasedRating gender={selectedGender} data={genderRatingData} />
      <Span>
        This data is observed from {startDate} to {endDate}
      </Span>
    </FlexWrapper>
  );
};

export default GenderRating;
