import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import Loader from '../../assests/animation/Loader2.json';
import {
  AmazonIconSmall,
  CrossIcon,
  FacebookLogo,
  FacebookLogoSmall,
  FlipkartIconSmall,
  InstagramIconSmall,
  NewsLogo,
  RedCrossIcon,
  StyledAddIcon,
  X_icon,
} from '../../Common/SvgIcon/CommonSvgIcons';
import styled from 'styled-components';
import PopUp from '../../Common/PopUp/PopUp';
import Button from '../../Common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ChatterActive,
  ChatterDelete,
  ChatterHibernate,
  chatterList,
  emptyCustomAnalysis,
  emptyCustomAnalysisResult,
  updateSearchQuery,
  updateSelectedDraft,
} from '../../store/redux/slices/chatterSlice';
import {
  TopicActive,
  TopicDelete,
  TopicHibernate,
  activeTopicList,
  createTopicSideBar,
  resetFlagFunc,
  searchTextEmpty,
  setPopUpActive,
  topicPreviewEmpty,
} from '../../store/redux/slices/topicSlice';
import Lottie from 'lottie-react/build';
import { setMessage } from '../../store/redux/slices/alertMessageSlice';
import { competitiveIntelligenceDelete, competitiveIntelligenceListChatter, competitiveIntelligenceListTopic } from '../../store/redux/slices/competitiveIntelligenceSlice';

const TabWrapper = styled.div`
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  color: #222;
  width: 100%;

  //common style for Type1Wrapper and Type2Wrapper
  & > div {
    display: flex;
    justify-content: space-between;
    margin: 14px auto;
    width: 85%;
    padding: 3px 0px;
  }

  //style for arrow button
  & > div svg {
    color: #353cf7;
    padding: 2px 8px;
    border: 0.5px solid #353cf7;
    border-radius: 5px;
    ${({ setActive }) =>
    setActive.activeTab === setActive.id &&
    `color: #fff; background:#353cf7;`};
  }

  &:hover {
    color: #0e01fe;
  }

  // Active setting tab will have background white
  ${({ setActive }) =>
    setActive.activeTab === setActive.id && `color: #353cf7;font-weight:600;`};
`;

const Type2Wrapper = styled.div`

      padding: 10px !important; 
      border-radius: 10px;
      background: rgba(174, 177, 252, 0.16);   

  flex-direction: column;
  & > div  svg{
    border:none;  
  }
  ${({ setActive }) =>
    setActive.activeTab === setActive.id &&
    `
      // height: 100px; 
      padding: 10px !important; 
      border-radius: 10px;
      background: rgba(174, 177, 252, 0.16);   
      
      & > div  svg{
        background: transparent;
        color: #353cf7;
        border:none;  
      }
    `};
`;

const Header = styled.div`
  display: flex;
  text-align: left;
  margin: 5px 0px 10px 5px;
  justify-content: space-between;
`;
const Type2Tabs = styled.div`
  padding: 8px 0;
  margin: 14px 10px;
  position: relative;
  height: auto;

  display: flex;
  /* justify-content: space-between; */

  border-radius: 10px;

  border: 1px solid #353cf7;
  padding: 10px 0px 10px 10px;

  font-size: 16px;
  &:hover {
    background-color: #353cf7;
    width: 75%;
    color: #fff;
  }

  .crossButton {
    position: absolute;
    top: 50%;
    right: -17%;
    transform: translateY(-50%);
    opacity: 0; /* Initially hide the cross button */
    opacity: 0;  /* Initially hide the cross button */
    visibility: hidden;  /* Ensure the element is not taking space */
    transition: opacity 0.3s ease; /* Add transition for smooth visibility change */
  }

  &:hover .crossButton {
    visibility: visible;  /* Ensure it's visible when hovered */
    opacity: 1; /* Show cross button on hover */
  }

  ${({ setSubTabActive }) =>
    setSubTabActive.active === setSubTabActive.id &&
    `color: #fff;background: #353cf7;
      & svg{
        color: #fff !important;
      }
  `};
`;

const DeletePopupWrapper = styled.div`
  line-height: 1.3rem;

  & > svg {
    width: 60px;
    height: 60px;
  }

  & > p:nth-child(1) {
    color: #fc6758;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: min-content;
      margin-right: 30px;
    }
  }

  & .selectContainer {
    display: flex;
    justify-content: center;
    & > div {
      width: 40%;
    }
  }
`;

const SearchBar = styled.input`
  /*  width: 100%; */
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  background: #fff;
  border: none;
`;

const Tab3Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  /*  overflow-y: scroll; */

  ${({ setActive }) =>
    setActive.scrollable && `overflow-y: scroll;max-height:200px;`};
`;

const Line = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

const Icon = styled.span`
  & > svg {
    height: 24px;
    width: min-content;
  }
`;

const NewChatterTab = styled.div`
  margin: 8px 0px;
  height: 25px;
  width: 90%;
  display: flex;
  justify-content: space-between;

  border-radius: 8px;

  border: 1px solid #353cf7;
  padding: 10px 0px 10px 10px;

  font-size: 16px;
  ${({ isClicked }) =>
    isClicked === true &&
    `color: #fff;background: #353cf7;
    & svg{
      color: #fff !important;
    }
`};
`;

const CrossButton = ({ onClick }) => (
  <div className='crossButton' onClick={onClick}>
    {CrossIcon}
  </div>
);

const Tabs = ({ active, value, name, buttonTitle }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isHovered, setIsHovered] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupId, setPopupId] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { val, id, subTabs, path } = value;
  let activeTab = active;
  if (typeof active === 'object') {
    activeTab = active.tab;
  }

  // console.log(active,"active");
  let setActiveState = { activeTab, id };
  if (subTabs && subTabs.length > 3) {
    setActiveState.scrollable = true; // Add scrollable flag
  }
  const handleScreen = (subTabId,id) => {
    
  if(id != active.subTab){

      dispatch(updateSearchQuery(''));
      dispatch(emptyCustomAnalysis());
      dispatch(emptyCustomAnalysisResult());
      dispatch(updateSelectedDraft(null));
      dispatch(searchTextEmpty())
      dispatch(topicPreviewEmpty())
          dispatch(resetFlagFunc(false))
    }
    if (subTabs) {
      setClicked(false);
      navigate(subTabs[subTabId].path);
    } else if (buttonTitle === 'Compare') {
      setClicked(false);
      navigate('/competitiveIntelligence/create')
    } else {
      setClicked(false);
      navigate(path);
    }
  };

  const handleNewChatter = () => {

    if (name === 'Chatter Tracker') {
      navigate('/chatter/create');
    } else if (name === 'Topic Listening') {

      dispatch(setPopUpActive(true))

      navigate('/topic/create');
    }
    setClicked(true);
  };

  const filteredSubTabs = subTabs
    ? subTabs.filter((tab) =>
      tab.val.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  useEffect(() => {
    // console.log(value, 'tabs')
  }, [value])

  const handleDeleteSubTab = (id) => {
    setPopup(!popup);
    setPopupId(id);
  };

  const handleClosePopup = () => {
    setPopup(false);
  };

  const handleRemove = () => {
    setLoading(true)
    const jobId = popupId;
    // if (name === 'Chatter Tracker') {
    //   dispatch(ChatterDelete(jobId));
    // } else {
    //   dispatch(TopicDelete(jobId));
    // }



    const action = name === 'Chatter Tracker'
      ? ChatterDelete(jobId)
      : name === 'Competitive Analysis' ? competitiveIntelligenceDelete(jobId)
        : TopicDelete(jobId);

    dispatch(action).then(() => {
      if (name === 'Competitive Analysis') {

        dispatch(competitiveIntelligenceListTopic())
        dispatch(competitiveIntelligenceListChatter())
      }
      dispatch(chatterList());
      dispatch(activeTopicList());
      setTimeout(() => {
        setLoading(false);

        setPopup(false);
        if (name === 'Chatter Tracker') {
          navigate('/chatter/home');
        } else if (name === 'Competitive Analysis') {
          navigate('/competitiveIntelligence/home');
        } else {
          navigate('/topic/home');
        }


        // window.location.reload();
      }, 2000);
    })
      .catch((error) => {
        // Handle any error case here
        console.error('Error dispatching action:', error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message))
      });
  };

  const handleHibernate = (subtabId) => {
    // Extract the ID of the subtab
    setLoading(true)
    const jobId = popupId;

    const action = name === 'Chatter Tracker'
      ? (val === 'Hibernated' ? ChatterActive(jobId) : ChatterHibernate(jobId))
      : (val === 'Hibernated' ? TopicActive(jobId) : TopicHibernate(jobId));

    dispatch(action).then(() => {
      // Handle the success case here setLoading(true);
      dispatch(chatterList());
      dispatch(activeTopicList());
      setTimeout(() => {
        setLoading(false);
        // window.location.reload();
        setPopup(false);
      }, 2000);
    }).catch((error) => {
      // Handle any error case here
      console.error('Error dispatching action:', error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(setMessage(message))
    });

  };

  const transformText = (inputText) => {
    const words = inputText?.split(' ');
    const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    return transformedWords?.join(' ')
  };
  // console.log(activeTab === id,"activeTab === id",activeTab ,id);
  return (
    <>
      {popup && (
        <PopUp onClose={handleClosePopup}>
          {loading === true ? (
            <div style={{ marginTop: '-80px' }}>
              <Lottie
                data-testid='loader'
                animationData={Loader}
                loop={true}
                width='100px'
                height='100px'
              />
            </div>
          )
            :
            <DeletePopupWrapper>
              <p>{RedCrossIcon}</p>
              <p>Remove Product</p>
              <p>Are you sure you want to remove the Product ?</p>
              <span> This action cannot be undone</span>
              <div>
                <Button onClick={handleClosePopup} value={'Cancel'} />
                {
                  value?.showHibernate == true ? '' : <Button
                    data-testid="handleHibrate"
                    onClick={() => handleHibernate()}
                    value={val === 'Hibernated' ? 'Active' : 'Hibernate'}
                  />
                }

                <Button
                  data-testid="handleRemove"
                  onClick={() => handleRemove()} value={'Delete'} />
              </div>
            </DeletePopupWrapper>


          }


        </PopUp>
      )}
      {
        // val !== 'Hibernated' &&

        <TabWrapper setActive={{ activeTab, id }}>
          {!subTabs && (
            <div onClick={() => handleScreen()}>
              {val}
              {activeTab !== id && <KeyboardArrowDownIcon />}
              {activeTab === id && <KeyboardArrowRightTwoToneIcon />}
            </div>
          )}
          {subTabs && (
            <Type2Wrapper setActive={{ activeTab, id }}>
              {val === 'Active' && (
                <NewChatterTab
                  key='newChatter'
                  onClick={handleNewChatter}
                  isClicked={isClicked}
                >
                  {buttonTitle}
                  {StyledAddIcon}
                </NewChatterTab>
              )}

              <Header onClick={() => handleScreen(0)}>
                {val}
                {/* {activeTab !== id && <KeyboardArrowDownIcon />}
            {activeTab === id && <KeyboardArrowRightTwoToneIcon />} */}
              </Header>

              <Tab3Wrapper setActive={setActiveState}>
                {subTabs && (
                  <SearchBar
                    type='text'
                    placeholder='Search...'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                )}
                <Line />
                {/* activeTab === id && */}
                {(
                  <div>
                    {filteredSubTabs.map(({ id, val, type, channel }, index) => (
                      <Type2Tabs
                        key={id}
                        onMouseEnter={() => setIsHovered(id)}
                        // onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => handleScreen(index,id)}
                        setSubTabActive={{ active: active.subTab, id }}
                      >
                        {channel && (name === 'Chatter Tracker' ? (
                          channel == 'amazon' ? (
                            <Icon>{AmazonIconSmall} </Icon>
                          ) : (
                            <Icon>{FlipkartIconSmall} </Icon>
                          )
                        ) : channel === 'twitter' ? (
                          <Icon>{X_icon}</Icon>
                        )
                          : channel === 'news' ?

                            <Icon>{NewsLogo}</Icon>
                            : channel === 'facebook' ?

                              <Icon>{FacebookLogo}</Icon>
                              : (
                                <Icon>{InstagramIconSmall}</Icon>
                              ))}
                        <span>{transformText(val)}</span>

                        {type === 'add' && StyledAddIcon}
                        {/*  {type !== 'add' && active.subTab !== id && (
                      <KeyboardArrowDownIcon />
                    )} */}
                        {type !== 'add' && active.subTab === id && (
                          <KeyboardArrowRightTwoToneIcon />
                        )}
                        {
                          // isHovered &&
                          isHovered && isHovered === id &&
                          (
                            <CrossButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteSubTab(id);
                              }}
                            />
                          )}
                      </Type2Tabs>
                    ))}
                  </div>
                )}
              </Tab3Wrapper>
            </Type2Wrapper>
          )}
        </TabWrapper>
      }


    </>
  );
};

export default Tabs;
