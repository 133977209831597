import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../../../assests/animation/Loader2.json';
import {
  DownArrowIcon,
  FilledStar,
  HalfStar,
  StaticProfileImg,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import { Verified } from '@mui/icons-material';
import HashtagImg from '../../../../../assests/images/HashtagImg.png'

const Wrapper = styled.div`
      min-height: ${(props) => props.showFb ? '345px' : ''};
      background: ${(props) => props.showFb ? '#f2f4f7' : ''};
      margin-left: ${(props) => props.showFb ? '25px' : ''};
      width: ${(props) => props.showFb ? '95%' : '100%'};
  // height: 100vh;
  overflow-y: ${(props) => props.showVal ? '' : 'scroll'};


    // background: #f2f4f7;
    // margin-left: 25px;
    // width: 93%;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  gap:${(props) => props?.showVal ? '5px' : '10px'};
`;

const ProdItem = styled.div`
  cursor:pointer;
  display: flex;
  height: 190px;
  /* border: 1px solid #ddd; */
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
`;

const Image = styled.img`
  border: 1px solid #ddd;
  width: 150px;
  height: 190px;
`;

const DetailsWrapper = styled.div`
  margin: 0px 0px 0px 30px;
  text-align: left;

  h3 {
    height: 25px;
    margin-left: 25px;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 25px;
`;

const RatingSpan = styled.span`
 gap:6px;
 margin-top:40px;
`;

const Price = styled.p`
  height: 25px;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  margin-right: 10px;
  font-weight: 500;
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom:5px;
  height: 1px;
  width:100%;
  background: #dddddd;
`;

const RealPrice = styled.p`
  margin-top: 25px;
  color: #565959;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through !important;
`;


function ProductDetails({ productList, onClose, onProductClick, onLoading, showInsta = false, showFb = false, account = '' }) {
  const [prodList, setProdList] = useState([]);

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      // Billion
      return (num / 1_000_000_000).toFixed(1) + 'B';
    } else if (num >= 1_000_000) {
      // Million
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      // Thousand
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num; // Return the number as is if it's less than 1000
    }
  }
  useEffect(() => {
    /*  const filteredProducts = productList.filter((product) => {
        return (
          product.title !== null &&
          product.price !== null &&
          product.rating !== null &&
          product.rating_count !== null &&
          product.thumbnail_url !== null 
        );
      });
  
   */
    if (productList.length > 0) {
      setProdList(productList);
    } else {
      setProdList([])
    }
  }, [productList]);

  const handleProductClick = (product) => {
    onProductClick(product);
    setProdList([]);
    onClose();
  };

  return (
    <Wrapper showVal={true} showFb={showFb}>
      {onLoading ? (
        <div  >
          <Lottie
            animationData={Loader}
            loop={true}
            // width='5'
            // height='5'
            style={{ minHeight: '300px' }}
          />
        </div>
      ) : (
        <ProductList showVal={true}>

          {prodList && !Array.isArray(prodList) && <p style={{ color: 'red' }}>{prodList}</p>}
          {/* {showInsta == true && account == 'Account' && prodList.length > 0 &&  <p style={{marginLeft:'30px',fontWeight:'500',fontSize:'18px'}}>{'Accounts'}</p>}
          {showInsta == true && account == 'Hashtag' && prodList.length > 0 &&  <p style={{marginLeft:'30px',fontWeight:'500',fontSize:'18px'}}>{'Hashtag'}</p>} */}
          {
            showInsta == true ? prodList && Array.isArray(prodList) && prodList?.map((product) => (
              <>
                <ProdItem key={product.asin} style={{ alignItems: 'center', height: account == 'Hashtag' ? '50px' : '70px', marginLeft: '20px', padding: '0px' }}>
                  {
                    account == 'Hashtag' ?
                      <Image src={HashtagImg} style={{ height: '62px', width: '65px', borderRadius: '50%', border: 'none' }}></Image>

                      // <div style={{width:'80px',borderRadius:'50%',fontSize:'75px'}}>#</div>
                      :
                      // <Image src={`https://garmentdesign.s3.ap-south-1.amazonaws.com/genimages/b140d7b7-8e40-476a-8231-00fe7c3bf2b4.png`} />
                      product.profilepic_url ?
                        <Image src={product.profilepic_url} style={{ height: '60px', width: '60px', borderRadius: '50%' }}></Image>
                        :
                        StaticProfileImg
                  }
                  <DetailsWrapper onClick={() => handleProductClick(product)} style={{ margin: '0px 0px 0px 5px' }}>
                    <h3 style={{ margin: '0px', marginLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '16px' }}>
                      {account == "Hashtag" && '#'}{product.name || decodeURIComponent(product?.user_name)} <span style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                        {/*  */}
                        {account == "Account" && product.verified == true && <Verified />}
                      </span>
                    </h3>
                    <p style={{ margin: '0px', marginLeft: '15px', color: 'rgba(139, 139, 139, 1)', fontSize: '13px' }}>{product?.full_name}</p>
                    <RatingSpan style={{ margin: '0px', marginLeft: '15px', color: 'rgba(139, 139, 139, 1)', fontSize: '13px' }}>
                      {/* <span style={{ margin: '0px', color: 'rgba(139, 139, 139, 1)' }}> */}
                      {account == 'Hashtag' ? `${formatNumber(product?.posts_count)} posts` : `${formatNumber(product?.followers_count)} followers`}
                      {/* </span> */}
                    </RatingSpan>
                    {/* <Description>
                  7.00 cm (6.7-inch) Super Retina XDR display featuring
                  Always-On and ProMotion Dynamic Island, a magical new way to
                  interact with iPhone{' '}
                </Description> */}
                  </DetailsWrapper>
                </ProdItem>
                <Line style={{ marginTop: '3px', marginBottom: '0px', background: 'none' }} />
              </>
            ))
              :
              showFb == true ? prodList && Array.isArray(prodList) && prodList?.map((product,index) => (
                <>
                  <ProdItem key={product.asin} style={{ alignItems: 'center', minHeight: '80px',height:'100%', margin: '10px auto auto',marginBottom: index == prodList.length - 1 ? '10px' : '0px', padding: '0px' ,width:'96%',borderRadius:'6px'}}>
                    <Image src={product?.profilepic_url} style={{ height: '65px', width: '65px',marginLeft:'5px', borderRadius: '50%' }}></Image>
                    <DetailsWrapper onClick={() => handleProductClick(product)} style={{ margin: '0px 0px 0px 5px' ,marginBottom:'5px',marginTop:'5px'}}>
                      <h3 style={{ margin: '0px', marginLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '16px' }}>
                        {decodeURIComponent(product?.user_name)} <span style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                          {/*  */}
                          {product.verified == true && <Verified />}
                        </span>
                      </h3>
                      <RatingSpan style={{ margin: '0px', marginLeft: '15px', color: 'rgba(139, 139, 139, 1)', fontSize: '13px' }}>
                        {/* <span style={{ margin: '0px', color: 'rgba(139, 139, 139, 1)' }}> */}
                        {product?.category || ''} {`${formatNumber(product?.followers || 0)} followers`}
                        {/* </span> */}
                      </RatingSpan>
                      <p style={{ margin: '0px', marginLeft: '15px', color: 'rgba(139, 139, 139, 1)', fontSize: '13px' }}>{product?.page_description || ''}</p>

                    </DetailsWrapper>
                  </ProdItem>

                </>
              ))

                :

                prodList && Array.isArray(prodList) && prodList?.map((product) => (
                  <>
                    <ProdItem key={product.asin}>
                      <Image src={product.thumbnail_url}></Image>
                      <DetailsWrapper onClick={() => handleProductClick(product)} >
                        <h3>
                          {product.title}
                        </h3>
                        <RatingSpan>{product.rating && (
                          <>
                            {product.rating} {FilledStar} {DownArrowIcon}
                          </>
                        )}
                          {/* {product.rating && `${product.rating} ${FilledStar} ${DownArrowIcon}`} */}
                          <span style={{ margin: '0px', color: '#007185' }}>
                            {product.rating_count}
                          </span>
                        </RatingSpan>
                        <PriceWrapper>
                          <Price style={{ color: '#B12704' }}> {product.price}</Price>
                          <RealPrice>
                            {product.real_price ? product.real_price : ''}
                          </RealPrice>
                        </PriceWrapper>
                        {/* <Description>
                  7.00 cm (6.7-inch) Super Retina XDR display featuring
                  Always-On and ProMotion Dynamic Island, a magical new way to
                  interact with iPhone{' '}
                </Description> */}
                      </DetailsWrapper>
                    </ProdItem>
                    <Line />
                  </>
                ))}
        </ProductList>
      )}
    </Wrapper>
  );
}

export default ProductDetails;
