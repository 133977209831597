import React, { useCallback, useEffect } from 'react';
import { ErrorMessage, KeywordWrapper, Label, SubLabel, TextDiv, TextDiv2, TextDiv3, TextDivSelected, TextFlexWrapper } from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled';
import ConnectChannels from '../TopicBuilder/MainContent/ConnectedChannels/ConnectedChannels';
import ChannelDropdown from '../ChatterTracker/MainComponent/ChatterTrackerBuilder/ShoppingChannels/ChannelDropdown';
import { DateWrapperComp, DeletePopupWrapper, DescForTopic, IconDiv, InfoDivWrapper, InfoWrapper, PopupWrapper, TextWrapper } from './ViewCompetitive.styled';
import Select from '../../Common/Select/Select';
import DateSlider from '../../Common/DateSlider/dateSlider';
import { CrossCompIcon, CrossIcon, RedCrossIcon, SmallQuestionIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useState } from 'react';
import PopUp from '../../Common/PopUp/PopUp';
import { useNavigate } from 'react-router-dom';
import Button from '../../Common/Button/Button';
import styled from 'styled-components';
import { useParsedStartDate } from '../../utils/ParseDate';
import MyDatePicker from '../../components/DatePicker/DatePicker';
import { formatDateFilter } from '../../utils';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';
import TitleWithQue from '../../Common/TitleWithInfo/TitlewithQue';
import { minWidth } from '@mui/system';
const CancelButton = styled.span`
  cursor: pointer;
  ${'' /* float: right; */}
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
const SelectChannelPage = ({
    topicOneError = false,
    topicTwoError = false,
    showSecondModule,
    topicCount,
    chooseFocus,
    subLabelVal,
    descVal,
    handleTopicOneChange, topicOptions,
    selectedTopicOne, setSelectedTopicOne,
    startDateOne,
    endDateOne,
    onDateSliderChange,
    dateHandler,
    setStartDateOne,
    setEndDateOne,
    setShowModal,
    setChannel,
    channelType,
    handleChannelSelect,
    channel,
    channelError,
    showModal,
    error = '',
    formatValue = 'dd/yyyy/MM',
    topicOne,
    topicTwo,
    setTopicOne,
    setTopicTwo, setTopicTwoError, setTopicOneError
}) => {
    const [showPopup, setShowPopup] = useState(false)
    const navigate = useNavigate();
    const [showCross, setShowCross] = useState(false)
    const [showCross2, setShowCross2] = useState(false)
    const [infoText, setInfotext] = useState(false)
    const [topicOneChange, setTopicOneChange] = useState('')
    function parseDate(dateString) {
        const parts = dateString?.split(/[-/]/);
        if (parts?.length === 3) {
            const day = parts?.length === 3 && parts[0]?.length === 2 ? parts[0] : parts[1];
            const month = parts?.length === 3 && parts[0]?.length === 2 ? parts[1] : parts[0];
            const year = parts[parts?.length - 1];
            return new Date(year, month - 1, day);
        }
        return null;
    }

    function getIntersection(start1, end1, start2, end2) {
        // Convert date strings to Date objects
        const startDate1 = parseDate(start1);
        const endDate1 = parseDate(end1);
        const startDate2 = parseDate(start2);
        const endDate2 = parseDate(end2);

        if (!startDate1 || !endDate1 || !startDate2 || !endDate2) {
            return "Invalid date format.";
        }

        // Find the later start date
        const intersectionStart = new Date(Math.max(startDate1.getTime(), startDate2.getTime()));

        // Find the earlier end date
        const intersectionEnd = new Date(Math.min(endDate1.getTime(), endDate2.getTime()));

        // Check if there is an intersection
        if (intersectionStart <= intersectionEnd) {

            return {
                start: intersectionStart.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
                end: intersectionEnd.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
              };
        } else {
            return "No intersection"; // No overlap
        }
    }

    const handleSelectTopic = (val) => {
        setTopicTwoError(false)
        setTopicOneError(false)
        if (val.job_id != topicOne?.job_id && val.job_id != topicTwo?.job_id) {

            if (!topicOne) {
                setTopicOne(val)
                if (topicTwo) {
                    let common = getIntersection(val?.start_date, val?.end_date, topicTwo?.start_date, topicTwo?.end_date);
                    console.log(common, "common");

                    if (common?.start && common?.end) {
                        setSelectedTopicOne({
                            start_date: common.start,
                            end_date: common.end,
                        });
                    } else {
                        // Handle the case where there is no intersection
                        setSelectedTopicOne(null); // Or set a default value
                    }
                }

            } else {
                setTopicTwo(val)
                let common = getIntersection(topicOne?.start_date, topicOne?.end_date, val?.start_date, val?.end_date);
                console.log(common, "common");
                // setSelectedTopicOne({
                //         start_date: common?.start,
                //         end_date: common?.end,

                //     })
                if (common?.start && common?.end) {

                    setSelectedTopicOne({
                        start_date: common.start,
                        end_date: common.end,
                    });
                } else {
                    // Handle the case where there is no intersection

                    setSelectedTopicOne(null); // Or set a default value
                }

            }
            handleTopicOneChange(val.topic_title)
        }
        return;

    }
    useEffect(() => {
        console.log("selectedTopicOne updated:", selectedTopicOne);
    }, [selectedTopicOne]);

    const useFormatStartDate = (val) => {
        let startDateParsed = useParsedStartDate(false, val, val, formatValue);

        return startDateParsed;
    };

    const dateRangeFunc = (jobStartDate, jobEndDate, actualStartDate, actualEndDate) => {
        if (jobStartDate != null && jobEndDate != null && actualStartDate != null && actualEndDate != null) {

            const actualStart = new Date(actualStartDate);
            const actualEnd = new Date(actualEndDate);
            const start2 = new Date(jobStartDate);
            const end2 = new Date(jobEndDate);
            const isInActualRange =
                (start2 >= actualStart && start2 <= actualEnd) || (end2 >= actualStart && end2 <= actualEnd);

            // Check if the actual start or end date is within the larger date range (startDate_2 and endDate_2)
            const isActualInRange = actualStart >= start2 && actualEnd <= end2;

            // If either condition is true, return true
            return isInActualRange || isActualInRange;
        } else {
            return false;
        }
    };
    const handleRemovePopup = (val) => {
        if (val == 'topic1') {
            setTopicOneChange('topic1')
        } else {
            setTopicOneChange('topic2')
        }
        setShowPopup(true)
    }
    const handleClosePopup = () => {
        setShowPopup(false)
    }
    const handleRemove = () => {
        if (topicOneChange == 'topic1') {
            setTopicOne()
        } else {
            setTopicTwo()
        }
        setShowPopup(false)
        setSelectedTopicOne(null)
    }
    const handleMouseEnter = useCallback(() => {
        setShowCross(true);
    }, []); // Empty dependency array to keep the function stable across renders

    const handleMouseLeave = useCallback(() => {
        setShowCross(false);
    }, []);

    const handleMouseEnter2 = useCallback(() => {
        setShowCross2(true);
    }, []); // Empty dependency array to keep the function stable across renders

    const handleMouseLeave2 = useCallback(() => {
        setShowCross2(false);
    }, []);

    return (

        <div>
            <KeywordWrapper>
                <div style={{ marginLeft: '8px', marginBottom: '10px' }}>
                    <TitleWithQue
                        fontSize={'18px'}
                        title={'Select your source'}
                        infoText={`coming soon`}
                    />
                </div>

                <Label style={{
                    color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                }}>
                    Channels <span>*</span>
                </Label>
                <br />
                <SubLabel style={{
                    color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                }}>{subLabelVal}</SubLabel>
                <div style={{ width: '40%' }}>
                    {channelType === 'Topic' ? <ConnectChannels

                        showSecondModule={!showSecondModule}
                        handleConnectChannelsToggle={handleChannelSelect}
                        channel={channel}
                    /> :
                        <ChannelDropdown
                            showSecondModule={!showSecondModule} onOptionSelect={handleChannelSelect} />
                    }
                </div>
                {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
            </KeywordWrapper>
            {
                channel !== null && showModal !== true &&
                <div>
                    {/* <SubLabel style={{marginTop: 40}}>Select topics to generate comprehensive competitive intelligence reports</SubLabel> */}
                    <div>
                        <div style={{ borderRadius: 6, border: '1px solid, #A2A2A2', padding: 20, marginTop: '30px' }}>
                            {
                                channelType == 'Chatter' ?
                                    <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Chatter <span>*</span></Label>
                                    :
                                    <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic <span>*</span></Label>
                            }
                            {/* <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic</Label> */}
                            <DescForTopic style={{
                                color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                            }}>{descVal}</DescForTopic>
                            <TextFlexWrapper>
                                {
                                    topicOptions?.length > 0 && topicOptions?.map((i) => (
                                        (i?.job_id == topicOne?.job_id || i?.job_id == topicTwo?.job_id) ?
                                            <TextDiv onClick={() => handleSelectTopic(i)}>
                                                <p>
                                                    {i.topic_title || i.chatter_title}
                                                </p>
                                            </TextDiv>
                                            :
                                            dateRangeFunc(formatDateFilter(topicOne?.start_date), formatDateFilter(topicOne?.end_date), formatDateFilter(i.start_date), formatDateFilter(i.end_date)) ?
                                                <TextDiv2 onClick={() => handleSelectTopic(i)}>
                                                    <p>
                                                        {i.topic_title || i.chatter_title}
                                                    </p>
                                                </TextDiv2>
                                                :
                                                !topicOne && (dateRangeFunc(formatDateFilter(topicTwo?.start_date), formatDateFilter(topicTwo?.end_date), formatDateFilter(i.start_date), formatDateFilter(i.end_date))) ?
                                                    <TextDiv2 onClick={() => handleSelectTopic(i)}>
                                                        <p>
                                                            {i.topic_title || i.chatter_title}
                                                        </p>
                                                    </TextDiv2>
                                                    :
                                                    (topicOne && !dateRangeFunc(formatDateFilter(topicOne?.start_date), formatDateFilter(topicOne?.end_date), formatDateFilter(i.start_date), formatDateFilter(i.end_date))) || (topicTwo && !(dateRangeFunc(formatDateFilter(topicTwo?.start_date), formatDateFilter(topicTwo?.end_date), formatDateFilter(i.start_date), formatDateFilter(i.end_date)))) ?

                                                        <TextDiv3 >
                                                            <p>
                                                                {i.topic_title || i.chatter_title}
                                                            </p>
                                                        </TextDiv3>
                                                        :

                                                        <TextDiv2 onClick={() => handleSelectTopic(i)} >
                                                            <p>
                                                                {i.topic_title || i.chatter_title}
                                                            </p>
                                                        </TextDiv2>
                                    ))
                                }
                            </TextFlexWrapper>

                        </div>
                        <div style={{ marginTop: '30px', display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div>
                                {
                                    channelType == 'Chatter' ?
                                        <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Chatter <span>*</span></Label>
                                        :
                                        <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic <span>*</span></Label>
                                }
                                {/* <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic <span>*</span></Label> */}

                                <div style={{ borderRadius: 6, border: '1px solid, #A2A2A2', padding: 20, marginTop: '10px', display: 'flex', gap: '15px', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                    <div
                                        style={{ position: 'relative' }}
                                        // Use an anonymous function to correctly set the state
                                        // onMouseEnter={handleMouseEnter}
                                        // onMouseLeave={handleMouseLeave}
                                    >
                                        <TextDivSelected showTrue={topicOne ? true : false}>
                                            <p>{topicOne?.topic_title || topicOne?.chatter_title}</p>
                                        </TextDivSelected>
                                        {
                                            topicOne && <div style={{ position: 'absolute', top: '-6px', right: '-6px' }} onClick={() => handleRemovePopup('topic1')}>{CrossCompIcon}</div>

                                        }
                                    </div>
                                    <DateWrapperComp>
                                        <div>
                                            <div style={{
                                                textAlign: 'left',
                                                marginBottom: '12px',
                                                marginLeft: '0px',
                                                marginTop: '0px'
                                            }}>Start Date:</div>

                                            <MyDatePicker
                                                name="firstPostDate"
                                                value={formatDateFilter(topicOne?.start_date)}
                                                dateHandler={dateHandler}
                                                format={
                                                    formatValue
                                                }
                                                readOnlyvalue={true}
                                                min={formatDateFilter(topicOne?.start_date)}
                                                max={formatDateFilter(topicOne?.end_date)}
                                            />
                                        </div>
                                        <div>
                                            <div style={{
                                                textAlign: 'left',
                                                marginBottom: '12px',
                                                marginLeft: '0px',
                                                marginTop: '0px'
                                            }}>End Date:</div>
                                            <MyDatePicker
                                                name="lastPostDate"
                                                value={formatDateFilter(topicOne?.end_date)}
                                                dateHandler={dateHandler}
                                                format={
                                                    formatValue
                                                }
                                                readOnlyvalue={true}
                                                min={formatDateFilter(topicOne?.start_date)}
                                                max={formatDateFilter(topicOne?.end_date)}
                                            />
                                        </div>
                                    </DateWrapperComp>
                                </div>
                                {
                                    topicOneError && <ErrorMessage style={{ marginTop: '5px' }}>{`${channelType == 'Chatter' ? 'Select Any One Chatter' : 'Select Any One Topic'}`}</ErrorMessage>

                                }

                            </div>

                            <div>
                                {
                                    channelType == 'Chatter' ?
                                        <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Chatter <span>*</span></Label>
                                        :
                                        <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic <span>*</span></Label>
                                }


                                <div style={{ borderRadius: 6, border: '1px solid, #A2A2A2', padding: 20, marginTop: '10px', display: 'flex', gap: '15px', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                    {/* <TextDivSelected showTrue={topicTwo ? true : false}>
                                        {topicTwo?.topic_title || topicTwo?.chatter_title}
                                    </TextDivSelected> */}
                                    <div
                                        style={{ position: 'relative' }}
                                        // Use an anonymous function to correctly set the state
                                        // onMouseEnter={handleMouseEnter2}
                                        // onMouseLeave={handleMouseLeave2}
                                    >
                                        <TextDivSelected showTrue={topicTwo ? true : false}>
                                            <p>{topicTwo?.topic_title || topicTwo?.chatter_title}</p>
                                        </TextDivSelected>
                                        {
                                            topicTwo  && <div style={{ position: 'absolute', top: '-6px', right: '-6px' }} onClick={() => handleRemovePopup('topic2')}>{CrossCompIcon}</div>

                                        }
                                    </div>
                                    <DateWrapperComp>
                                        <div>
                                            <div style={{
                                                textAlign: 'left',
                                                marginBottom: '12px',
                                                marginLeft: '0px',
                                                marginTop: '0px'
                                            }}>Start Date:</div>

                                            <MyDatePicker
                                                name="firstPostDate"
                                                value={formatDateFilter(topicTwo?.start_date)}
                                                dateHandler={dateHandler}
                                                format={
                                                    formatValue
                                                }
                                                min={formatDateFilter(topicTwo?.start_date)}
                                                max={formatDateFilter(topicTwo?.end_date)}
                                                readOnlyvalue={true}
                                            />
                                        </div>
                                        <div>
                                            <div style={{
                                                textAlign: 'left',
                                                marginBottom: '12px',
                                                marginLeft: '0px',
                                                marginTop: '0px'
                                            }}>End Date:</div>
                                            <MyDatePicker
                                                name="lastPostDate"
                                                value={formatDateFilter(topicTwo?.end_date)}
                                                dateHandler={dateHandler}
                                                format={
                                                    formatValue
                                                }
                                                min={formatDateFilter(topicTwo?.start_date)}
                                                max={formatDateFilter(topicTwo?.end_date)}
                                                readOnlyvalue={true}
                                            />
                                        </div>
                                    </DateWrapperComp>
                                </div>

                                {
                                    topicTwoError && <ErrorMessage style={{ marginTop: '5px' }}>Select Any One Topic</ErrorMessage>

                                }
                            </div>
                        </div>
                        {
                            topicOne && topicTwo && selectedTopicOne !== null &&

                            <DateSlider
                                startDate={selectedTopicOne?.start_date}
                                endDate={selectedTopicOne?.end_date}
                                // progress={{ start: 20, end: 60 }}
                                onChange={onDateSliderChange}
                                topicCount={topicCount}
                                setStartDateOne={setStartDateOne}
                                setEndDateOne={setEndDateOne}
                            />
                        }
                    </div>
                </div>
            }
            {
                showPopup &&

                <PopUp onClose={() => handleClosePopup()}>
                    <PopupWrapper>

                        <DeletePopupWrapper>
                            <p>{RedCrossIcon}</p>
                            <p style={{ color: 'red', fontSize: '16px', fontWeight: '600' }}>Remove Topic</p>
                            <p>Are you sure you want to remove Topic ?</p>
                            <div style={{ width: '550px', marginTop: '70px', justifyContent: 'center' }}>
                                <Button
                                    style={{ minWidth: '160px' }}
                                    onClick={handleClosePopup}
                                    value={'Cancel'} />
                                <Button
                                    data-testid="handleRemove"
                                    onClick={() => handleRemove()}
                                    value={'Remove'} />
                            </div>
                        </DeletePopupWrapper>
                    </PopupWrapper>
                </PopUp>
            }
        </div>
    );
};
export default SelectChannelPage;