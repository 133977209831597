import styled from "styled-components";


export const IconDiv = styled.div`
display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 3px;

`;

export const InfoDivWrapper = styled.div`
    display: flex;
    position: relative;
    gap: 10px;
    margin-left: 8px;
    align-items: flex-start;
    margin-bottom: 17px;
`;

export const InfoWrapper = styled.div`
  
  color: rgb(99, 99, 99);
    position: absolute;
    left: 185px;
    top: -50%;
    min-width: 200px;
    max-width: 300px;
    min-height: 30px;
    background: rgb(208, 204, 255);
    padding: 10px;
    border-radius: 8px;
    z-index: 100;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;

  &:before { 
    content: "";
    position: absolute;
    top: 12px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: -3%;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  text-align: left;
  font-family: Open Sans;
  // background:black;
`;
export const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 9px 0px 0px 0px;
  box-sizing: border-box;
`;
export const HeaderWrapper = styled.div`
  padding: 20px 0 20px 25px;
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const Span1 = styled.span`
  
    font-family: Montserrat;
    text-align: justify;
    font-size: 15px;
    color: black;
    font-weight: 400;
    margin: 0px;
`;

export const Span2 = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  word-spacing: 1px;
  margin: 0px;
`;

export const ChannelsText = styled.div`
  display: flex;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
`;

export const TwitterText = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  color: rgba(0, 0, 0, 0.84);
  margin: 0;

  & svg {
    width: 20px;
    height: 20px;
    // padding-top: 5px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-top: 1px solid #d2d2d2;
  padding: 30px;
  & .button {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    background: #353cf7;
    color: #fff;

    height: 35px;
    padding: 5px 20px;
    border-radius: 10px;

    border: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      margin-left: 10px;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  & .buttonContainer {
    display: flex;
    gap: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    width: 135px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */} 
    padding: 1px 8px;
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;
export const CreateTopic = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  padding: 10px 20px;
  border-radius: 20px;

  border: 0px;

  &:active {
    transform: scale(0.98);
  }
`;

export const Icon = styled.div``;

export const StyledContainer = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const QueryPara = styled.div`
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  margin-top: 10px;
`;
export const GraphBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left:20px;
  
    justify-content: center;
`;

export const PercentageBar = styled.div`
  font-size: 15px;
  font-weight: 400;
  flex: 1;

  & > div {
    width: 100%;
    border-radius: 2px;
    display: flex;

    &:before {
      content: '';
      border-radius: 2px;
      margin-right: 5px;
      min-width: 2px;
    }
  }
`;

export const MaleDataBar = styled.div`
color:${(props) => props.theme == true ? 'white' :'black'};
  &:before {
    width: ${props => props.width}%;
    background:  ${props => props.background || 'rgba(27, 175, 246, 1)'}; 
    
    
    height: 25px;
    margin-bottom: 5px;
  }


`;

export const FemaleDataBar = styled.div`
color:${(props) => props.theme == true ? 'white' :'black'};
  margin-top: 2px;
  &:before {
    width: ${props => props.width}%;
    background:  ${props => props.background || 'rgba(234, 170, 189, 1)'}; 
    
    // background: rgba(234, 170, 189, 1);
    
    height: 25px;
    margin-bottom: 5px;
  }
`;

export const UnKnownDataBar = styled.div`
  margin-top: 2px;
  &:before {
    width: ${props => props.width}%;
    // background:  rgba(218, 217, 219, 1);
background:  ${props => props.background || 'rgba(218, 217, 219, 1)'}; 
    
    
    height: 25px;
    margin-bottom: 5px;
  }
`;

export const EachRow = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  justify-items: center;
  gap: 10px;
  font-family: Open Sans;
`;
export const ChannelVal = styled.div`

      width: 80px;
    color: rgb(99, 99, 99);
    color:white;
    font-size: 14px;
    font-weight: 400;
    text-align: end;
`;

export const Star = styled.div`

      width:  ${props => props.title == 'Emotions' ? '120px' :'80px' };
    color: rgb(99, 99, 99);
    color:${(props) =>props.theme == true ? 'white' : 'black'};
    font-size: 14px;
    font-weight: 400;
    text-align: end;

    display:  ${props => props.title == 'Emotions' ? 'flex' :'' };
    align-items:   ${props => props.title == 'Emotions' ? 'center' :'' };
    justify-content:   ${props => props.title == 'Emotions' ? 'unset' :'' };
    gap:   ${props => props.title == 'Emotions' ? '10px' :'' };
`;

export const Container = styled.div`
  
  min-height: 380px;
  padding: 30px;
  display: flex;
  gap: 30px;
`;
export const EmotionContainer = styled.div`
  
  min-height: 380px;
  padding: 30px;
`;
export const Container2 = styled.div`

padding: 30px;
display: flex;
gap: 30px;
`

export const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  // display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const MainDivForOverview = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    display: grid;
    grid-template-columns:${(props) => props?.showTab == true ? '' : '60px 1fr'} ;
    padding: 10px;


    -webkit-box-align: center;
    -webkit-box-pack: start;
    // margin-left: ;
    margin-left:${(props) => props?.showTab == true ? '' : '-35px'} ;
    justify-content :${(props) => props?.showTab == true ? 'center' : 'flex-start'} ;
`;


export const TextDiv = styled.div`
text-align: justify;
display: flex;
flex-direction: column;
word-break: break-all;
min-height: 60px;
justify-content: space-evenly;
width:${(props)=>props.width ? props.width : ''};
& >p {
  
  word-break: break-word;
  text-align: left;
}
`;

export const ChildContainerForCustom = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  // border: 1px solid #d9d9d9;

  // display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const GraphMainWrapper = styled.div`

box-sizing: border-box;
border-radius: 15px 15px 0px 0px;
/* border: 1px solid rgb(217, 217, 217); */
/* padding: 20px 20px 10px; */
/* margin-left: 15px; */
/* display: flex; */
flex-direction: column;
/* -webkit-box-pack: justify; */
/* justify-content: space-between; */
height: 100%;
display: grid;
grid-template-rows: ${(props) => props.title === 'Emotions' ? '430 1fr' : '310 1fr'};
width:  ${(props) => props.title === 'Emotions' ? '100%' : '50%'};

}
`;


export const EmotionMainWrapper = styled.div`

box-sizing: border-box;
border-radius: 15px 15px 0px 0px;
flex-direction: column;
height: 100%;
display: grid;
grid-template-rows: 620px 1fr;
}
`;


export const GraphWrapper = styled.div`
  
// width: 50%;
//   box-sizing: border-box;
//   border-radius: 15px;
//   border: 1px solid #d9d9d9;

//   padding: 20px;
//   padding-bottom: 10px;
//   margin-left: 15px;

//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;



  box-sizing: border-box;
  // border-radius: 15px 15px 0px 0px;
  
  border-radius:${(props) =>props?.title == 'Emotions' ?   '15px 15px 0px 0px' : '15px 15px'} ;
  border: 1px solid rgb(217, 217, 217);
  padding: ${(props) => (props.title === 'Engagement Metrics' ) || (props.title === 'Average Rating' ) ? '20px 20px 21px' : '20px 20px 10px'};
  /* margin-left: 15px; */
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  // border-bottom: none;
  
    // display: grid;
    // grid-template-rows: 30px 30px 1fr 35px;
        gap: 20px;
    padding-bottom: 25px;
        grid-template-columns: 1fr;
    gap: 10px;
    
    display : ${(props) => (props.title === 'Engagement Metrics' ||  props.title ===  'Sentiments' || props.title ===  'Average Rating') ? 'grid' : 'flex'};
    grid-template-rows : ${(props) => (props.title === 'Engagement Metrics' ||  props.title ===  'Sentiments' ||  props.title ===  'Average Rating') ? '70px 30px 216px' : ''};
`;

export const OverallPosts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px;
  box-sizing: border-box;
`;
export const TitleHeaderWrapper = styled.div`
display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

`;
export const GraphHeader = styled.div`
  display: flex;
  justify-content:${(props) => (props.title === 'Engagement Metrics' ||  props.title ===  'Average Rating' ) ?  "space-between" : ''};
  width:100%;
  flex-direction:column;
  & > div:last-child {
    // color: #636363;
    color:white;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;

    padding: 2px;
  }
`;
export const Title = styled.div`
// color:white;
color:${(props)=>props?.theme == true ? 'white' : 'black'};
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left:25px;
`;

export const LogoOneChannel = styled.div`
  position: absolute;
z-index: 10;
  // top: 40%;
  top:210px;
  left: 7%;

  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
  & svg {
    width: 30px;
  }
`;


export const Logo = styled.div`
  // position: absolute;
  top: -7%;
  left: -7%;

  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
  & svg {
    width: 30px;
  }
`;
export const RelevantPost = styled.div`
     position: relative;
    /* display: flex; */
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    // width: 145%;
    // padding: 5%;
    height: 100%;
    border-radius: ${(props) =>props.border ? props.border :'5px 5px'};
    // background: rgba(111, 98, 254, 0.22);
    background:rgb(0 106 104);
    margin-top: 0px;
    margin-left: 0px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: "Open Sans";
    font-size: 17px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: auto;


`;
export const RelevantPost2 = styled.div`
     position: relative;
    /* display: flex; */
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    // width: 145%;
    // padding: 5%;
    height: 100%;
    border-radius: ${(props) =>props.border ? props.border :'5px 5px'};
    // background: rgba(111, 98, 254, 0.22);
    background:rgb(26 30 123);
    margin-top: 0px;
    margin-left: 0px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: "Open Sans";
    font-size: 17px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 20px;
    margin-top:${(props)=>props.showTab == true ? '' :'20px' };
    margin-bottom: auto;


`;
export const LabelContainer = styled.div`

display: flex;
align-items: center;
gap: 15px;
justify-content: space-between;
 

& .straight-line {
  width: 100%;
  height: 2px;
  // background-color: black;
  margin: 7px 0;
  
  width: 30px;
  margin-right: 10px;
  background-color:${(props)=>props.theme == true ? 'white' :'black' };
}


& .dotted-line {
  
  width: 100%;
  height: 2px;
  // border-top: 2px dotted black; /* Creates a dotted line */
  margin: 7px 0;
  
  width: 30px;
  // background-color:white;
  
  border-top:${(props)=>props.theme == true ? '2px dotted white' :'2px dotted black' };
  // border-top: ;
  margin-right: 10px;
}


`;

export const LineContainer = styled.div`

display: flex;
align-items: center;
// color:white;

  color:${(props)=>props.theme == true ? 'white' :'black'};
`;

export const DropDownContentDiv = styled.div`
    padding: 7px;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    flex-direction:column;
`;
export const InputDivWrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    margin-left: 15px;
`

export const InputDropDownCheckBox = styled.input`
  height: 16px;
  margin: 0px;
  margin-right: 10px;
  width: 16px;
`;

export const DropDownWrapper = styled.div`
background: white;
position: absolute;
top:40px;
box-shadow: 4px 3px 7px 2px #989898;
padding: 11px;
text-align:left;
// margin-top: 2vh;
min-width: 125px;
// top: 103%;
// transform: screenId == 2 ? 'translateX(50%)' : 'translateX(5%)', // Center horizontally
 // top: '103%';

`;
export const FilterBtn = styled.button`
height: 39px;
    width: 115px;
    background: rgb(53, 60, 247);
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

`;
export const GraphLabel = styled.div`
  display: flex;
  // justify-content: end;
  gap: 10px;
  margin-left: 25px;
  // color:white;
  color:${(props)=>props.theme == true ? 'white' :'black'};
  min-width:120px;
  & > div {
    // color: #636363;
    // color: white;
    
  color:${(props)=>props.theme == true ? 'white' :'black'};
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    & > span {
      display: inline-block;
      width: 20px;
      // width: 15px;
      height: 15px;
      background: red;
      margin-right: 5px;
      border-radius: 0px 0px 0px 0px;
      // border-radius: 2px 0px 0px 2px;
    }
      & .emotion1 {
      
      background: rgba(0, 213, 208, 0.5);
      }
      & .emotion2 {
      
      background: rgba(53, 60, 247, 0.5);
      }
    & .male {
      background: rgba(27, 175, 246, 1);

    }
    & .engA {
    
      background: rgba(0, 213, 208, 0.5);
    }
    & .engB {
    
      background: rgba(53, 60, 247, 0.5);
    }

    & .topicB {
      background: rgba(255, 99, 132, 1);

    }

    & .female {
      background: rgba(234, 170, 189, 1);

    }

    & .unknown {
      background: rgba(218, 217, 219, 1);

    }
    & .positive {
      // background: rgba(118, 237, 166, 1);
      background :rgba(3, 198, 82, 1);
    }

    & .negative {
      // background: rgba(252, 216, 126, 1);
      background:rgba(238, 19, 7, 1);
    }

    & .neutral {
    background : rgba(255, 232, 175, 1);
      // background: rgba(253, 127, 120, 1);

    }

    & .topic1 {
    background : rgba(0, 213, 208, 1);
      // background : rgba(54, 162, 235, 1);
    }
    
    & .topic2 {
    background : rgba(53, 60, 247, 1);
      // background : #e50232;
    }
  }
`;
export const DescForTopic = styled.p`
  margin-top: 7px;
  margin-bottom: 10px;
  font-size: 13px;
  color: gray;
  font-weight: 400;
`;


export const GraphLabelContainer2 = styled.div`
display: flex;
flex-direction: column;
gap: 9px;
padding-right: 22px;
padding-top: 10px;

`;

export const Wrapper2Container = styled.div`

display: flex;
align-items: end;
justify-content: space-between;
gap:20px;
`;

export const GraphLabelContainer = styled.div`
  padding-right: 20px;
  margin-bottom: 5px;
`;

export const BarWrapper = styled.div`
display:flex;
flex-direction: row;
font-family: Open Sans;
width: 80%;
/* height: 40%; */


//change this to your own
margin: 30px 0px 0px 0px; 
`;

const Label = styled.span`
  width: 50px;
  margin: 10px 0px 0px 25px;
  color: #636363;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const BarVal = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 30px;
  width:100%;
`;
export const TopicAwithStarDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 5px;
gap:2px;
color:${(props) => props.theme ==true ? 'white' : 'black'};
`;

export const DateFontStyle = styled.span`
color: rgba(0, 0, 0, 0.87);
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 400;
    text-align: end;
    // margin-right: 10px;
    margin-bottom: -4px;
margin-top:20px;

`;

export const AvgTopicA = styled.div`
  /* flex: ${(props) => props.percentage}; */
  height: 15px; 
  background-color: #6F62FE;
  // background-color: ${(props) => props.backgroundColor || '#6F62FE'};
  /* border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'}; */
  margin: 8px 0px 0px 0px;
`;


export const SummaryWrapper = styled.div`
    border-radius: 0px 0px 15px 15px;
    border: 1px solid rgb(217, 217, 217);
    padding: 0px 20px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;

`;
export const MainContainer = styled.div`
  flex: 1;
  /* display: flex; */
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  align-items: center;
  margin-top: 10px;
  min-height: 300px;
  margin-left:25px;
`;

export const ParagraphBold = styled.p`
    font-size: 12px;
    font-weight: 600;
    color:${(props)=>props.theme == true ? 'white' :'black'};
          

`;

export const ParagraphNormal = styled.span`
    font-size: 12px;
    font-weight: 400;
    color:${(props)=>props.theme == true ? 'white' :'black'};

`;

export const MainComponent2 = styled.div`

    padding-left: 25px;
    padding-right: 25px;
    border-top: 1px solid rgb(217, 217, 217);

`;

export const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
margin-left:25px;
  color: #fff;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

export const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  // color: #222;
  color:white;
  border-radius: 0px;
  ${(props) =>
    props.selected &&
    `
      color: #fff;
      background:#353CF7;
      border-radius: 4px !important;
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

export const MainComponent = styled.div`
  border-bottom: 1px solid rgb(210, 210, 210);
  margin-right: auto;
  width: 98%;
  padding-bottom: 15px
`
export const SubMainComponent = styled.div`
display: grid;
grid-template-columns: 48% 48%;
gap: 10px;
`;

export const SubComponent1 = styled.div`
padding-right:10px;
display: flex;
border-right: 1px solid;
flex-direction: column;
gap: 8px;
text-align: justify
`;

export const SubComponent2 = styled.div`

display: flex;
flex-direction: column;
gap: 8px;
text-align: justify;
`;

export const TopicBDiv = styled.div`

display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
margin-top: 8px;
`;

export const TopicADiv = styled.div`
display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                margin-top: 8px
`;
export const TabWrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;

`;

export const DivGridForDownload = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom:10px;
    gap: 0px;
    background:black;
    & .label {
    
    font-size: 18px;
    font-weight: 500;
    color:white;
    }
     & .value {
    
    font-size: 18px;
    font-weight: 400;
    color:white;
    }
`;

export const TableHeaderContent = styled.div`

display: flex;
justify-content: space-between;
padding: 15px 10px;
border-bottom: 1px solid white;
    background:black;
    align-items:baseline;
& .title {

font-size: 30px;
font-weight: 500;
    color:white;
}
 & .value {
    
    font-size: 22px;
    font-weight: 500;
    color:white;
    }
`;

export const TableHeaderContentBody = styled.div`

display: flex;
justify-content: space-between;
padding: 15px 10px;
    background:black;
    align-items:baseline;

& .title {

    font-size: 22px;
font-weight: 400;
    color:white;
}
 & .value {
    
    font-size: 22px;
    font-weight: 400;
    color:white;
    }
`;

export const TabHeading = styled.div`
  border-bottom: 1px solid rgb(210, 210, 210);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 20%;
    gap: 5px;

  & > div {
    color: #353cf7;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;

export const ButtonDiv = styled.div`

display: flex;
justify-content: end;
padding-right: 30px;
`;

export const ButtonStyle = styled.button`
  width: 115px;
  height: 38px;
  border-radius: 8px;
  border: none;
  background: rgba(53, 60, 247, 1);
  color: white;
  font-size: 15px;
  font-weight: 600;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    & .cancel {
    background: #fff !important;
    color: #353cf7 !important;
    border: 1px solid #353cf7  !important;
  }
`;

export const CancelButtonStyle = styled.button`
  width: 115px;
  height: 38px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #fff !important;
  color: #353cf7 !important;
  border: 1px solid #353cf7  !important;  
`;
