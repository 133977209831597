import React, { useState } from 'react';
import styled from 'styled-components';
import { AistarIcon, StarIcon } from '../SvgIcon/CommonSvgIcons';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';
import PopUp from '../PopUp/PopUp';

const Wrapper = styled.div`
  font-family: Open Sans;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
`;

const BarsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;

  padding-top: ${(props) => (props?.paddingVal ? props?.paddingVal : '5px')};
  box-sizing: border-box;

  color: #636363;
  font-size: ${(props) =>
    props?.fontSize !== undefined ? props?.fontSize : '14px'};
  font-weight: 400;
  white-space: nowrap;
  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 10;
    opacity: 0.9;
  }
  // text styling
  & > :nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: right;
    word-break: break-all;
  }

  & .barLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    padding-left: 50px;
  }

  .barLabelSidePanel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75px;
    display: block;
    text-align: center;
    margin: 5px 0;
    position: relative;
  }

  & .emoji {
    width: 28px;
    height: 28px;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;

  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 90px;
  overflow: scroll;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
`;

const StatusValWrapper = styled.div`
  text-align: justify;
  display: flex;
  align-items: center;

  & :first-child {
    height: 12px;
    width: 12px;
    content: ' ';
    display: grid;
    background: ${(props) =>
      props?.summaryVal?.fill ? props?.summaryVal?.fill : ''};
    border-radius: 50%;
    margin-bottom: 0px;
  }

  & :nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;

const Bar = styled.div`
  font-size: 0;
  height: 40px;
  line-height: 40px;
  display: inline-block;
`;

const FilledBar = styled.div`
  display: inline-block;
  width: 100px;
  text-align: center;
  vertical-align: super;
  font-size: 16px; /* Reset font size */
  height: ${(props) => props.barHeight};
  background: ${(props) => props.color};
  width: ${(props) => props.width};
  min-width: 0%;
  border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'};
  // margin: 8px 0px 0px 0px;
  box-shadow: ${(props) =>
    props.isHovered ? '0px 4px 12px 5px rgba(0, 0, 0, 0.2)' : 'none'};
  position: relative; /* To position tooltip */
  ${(props) => {
    if (props.borderRadius) {
      return `border-radius: ${props.borderRadius}`;
    }
  }}
`;
const Tooltip = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 8px;
  white-space: nowrap;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out;
  background: rgb(208, 204, 255);
  z-index: 100;
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  color: rgba(0, 0, 0, 0.81);
  width: 100px;
  height: 10px;
  display: flex;
  align-items: anchor-center;
  justify-content: center;
  gap: 5px;
  color: #636363;
  &:before {
    content: '';
    position: absolute;
    top: 23px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: 43%;

    transform: rotate(-90deg);
  }
`;
const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
  position: absolute;
  left: -70px;
  top: 120%;
  min-width: 200px;
  max-width: 300px;
  min-height: 30px;
  background: #d0ccff;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: 35%;
    transform: rotate(90deg);
  }
`;
const BarGraph = ({
  paddingVal = '5px',
  data,
  maxWidth = '100%',
  type = '',
  borderRadius = '',
  barHeight = '27px',
  emojiList = {},
  starRating = false,
  barHeightHover = '40px',
  title = '',
  summary = {},
  isSidePanel = false,
  fontSize,
}) => {
  const [summaryVal, setSummaryVal] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [infoText, setInfotext] = useState(null);
  const sortedData = data.sort((a, b) => b.value - a.value);
  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 100 / maxValue;

  const handleOpenSummary = (rating) => {
    if (rating.value > 0) {
      setSummaryVal(rating);
      setShowPopup(true);
    }
  };

  return (
    <Wrapper
      maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100}
      className='pdf-page'
      style={{ pageBreakInside: 'avoid', width: isSidePanel ? '75%' : '90%' }}
    >
      {sortedData && sortedData.length > 0 ? (
        sortedData?.map((rating, index) => (
          <BarsWrapper key={index} paddingVal={paddingVal} fontSize={fontSize}>
            {emojiList.length > 0 ? (
              <div className={'barLabel'}>
                <div data-testid='emoji' className='emoji'>
                  {emojiList[rating.name?.toLowerCase()]}
                </div>
                {rating.name}
              </div>
            ) : (
              <div
                className={
                  isSidePanel === true ? 'barLabelSidePanel' : undefined
                }
                title={rating?.name}
              >
                {rating.name}
              </div>
            )}
            {/* {emojiList.length > 0 ? (
            <div className='barLabel'>
              <div className='emoji'>{emojiList[index]}</div>
              {rating.name}
            </div>
          ) : (
            <div>{rating.name}</div>
          )} */}
            <Bar>
              {title == 'Emotional_Analysis' ? (
                <FilledBar
                  width={Math.floor(rating.value * multiplier) + 1 + '%'}
                  actualWidth={rating.value}
                  color={rating.fill || '#353cf7'}
                  borderRadius={borderRadius}
                  barHeight={infoText === index ? barHeightHover : barHeight}
                  isHovered={infoText === index} // Pass isHovered prop
                  onMouseOver={() => setInfotext(index)} // Set the hovered index
                  onMouseLeave={() => setInfotext(null)}
                  onClick={() => handleOpenSummary(rating)}
                  // onMouseOver={() => setInfotext(true)}
                  // onMouseLeave={() => setInfotext(false)}
                >
                  <Tooltip show={infoText === index}>
                    {/* <Tooltip show={true}>/ */}
                    Explain with AI {AistarIcon}
                  </Tooltip>
                </FilledBar>
              ) : (
                <FilledBar
                  width={Math.floor(rating.value * multiplier) + 1 + '%'}
                  actualWidth={rating.value}
                  color={rating.fill || '#353cf7'}
                  borderRadius={borderRadius}
                  barHeight={barHeight}

                  // onMouseOver={() => setInfotext(true)}
                  // onMouseLeave={() => setInfotext(false)}
                ></FilledBar>
              )}

              {/* {(
              <InfoWrapper>
                Explain with AI
              </InfoWrapper>
            )} */}
              <div
                style={{
                  marginLeft: '5px',
                  display: 'inline-block',
                  fontSize: '16px',
                  verticalAlign: 'text-bottom',
                }}
              >
                {`${typeof rating.value === 'number' && rating.value}`}
                {starRating === true ? <span>{StarIcon}</span> : type}
              </div>
            </Bar>
          </BarsWrapper>
        ))
      ) : (
        <NoDataWrapper>
          {/* // <BarsWrapper> */}
          {/* No Data Available */}Insufficient Data
          {/* // </BarsWrapper> */}
        </NoDataWrapper>
      )}
      {showPopup &&
        summary[summaryVal?.name && summaryVal?.name?.toLowerCase()] && (
          <PopUp
            minWidth={'700px'}
            height={'280px'}
            maxWidth={'700px'}
            onClose={() => setShowPopup(false)}
          >
            {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
            <TextWrapper>
              <div>
                <TitleWithInfo title={'Summary '} infoText={``} />
              </div>
              <br />
              <Message>
                {summary[summaryVal?.name && summaryVal?.name?.toLowerCase()]}
              </Message>
              <br />
              <StatusValWrapper summaryVal={summaryVal}>
                <span></span>{' '}
                <span>{`${summaryVal?.name}- ${
                  summaryVal?.value ? summaryVal?.value : 0
                }%`}</span>
              </StatusValWrapper>
              <CancelButton onClick={() => setShowPopup(false)}>
                Close
              </CancelButton>
            </TextWrapper>
          </PopUp>
        )}
    </Wrapper>
  );
};

export default BarGraph;
