import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';

import TopicService from '../services/topic.service';
import {
  channelManipulation,
  channelReviewManipulation,
} from '../utils/channelCreation';

export const topicPreview = createAsyncThunk(
  'topicPreview',
  async (body, thunkAPI) => {
    try {
      const topic_post_data = {
        topic_title: body?.title,
        description: body?.description || '',
        languages: body?.languages || null,
        time_frame: body?.timeFrame || 'last 1 month',
        keywords_string: body?.keywords || body?.keywords_string || '',
        exclusions_string: body?.exclusions || '',
        exclusions_noise: {
          select_all: false,
          sweep_stakes: false,
          sales_listings: false,
          coupons: false,
          supplemental_income: false,
          cryptocurrency: false,
        },
        channel_name: body?.channels || [],
        location_origin: body?.location || null,
        location_origin_radius: body?.locationRadius || null,
        post_country: body?.postCountry || [],
        user_country: '',
        include_sensitive_content: body?.include_sensitive_content || false,
        only_posts_with_media: body?.only_posts_with_media || null,
        fetch_reposts: body?.fetch_reposts || null,
        only_posts_from_verified_users:
          body?.only_posts_from_verified_users || null,
        // keywords_string:,

        // ...(body?.channels === 'instagram' && {
        //   search_type: body?.search_type || '',

        // }),
        ...(((body?.channels === 'instagram') ? 
        {
          search_type: body?.search_type || '',
          user_name: body?.user_name || '',
          full_name: body?.full_name || '',
          verified: body?.verified || false,
          engagement: body?.engagement || 0,
          profile_pic_url: body?.profile_pic_url || '',
          // facebook_search_type: body?.search_type || '',
        }
        :
        // && body?.search_type == 'pages'
          (body?.channels == 'facebook' && body?.search_type == 'pages')  ? {
            // search_type: body?.search_type || '',
            user_name: body?.user_name || '',
            full_name: body?.full_name || '',
            verified: body?.verified || false,
            engagement: body?.engagement || 0,
            profile_pic_url: body?.profile_pic_url || '',
            facebook_search_type: body?.search_type || '',
            page_description: body?.page_description || '',
            category:body?.category || '',
            followers:body?.followers || '',
          }
        : (body?.channels == 'facebook') && {
          
          // search_type: body?.search_type || '',
          user_name: body?.user_name || '',
          full_name: body?.full_name || '',
          verified: body?.verified || false,
          engagement: body?.engagement || 0,
          profile_pic_url: body?.profile_pic_url || '',
          facebook_search_type: body?.search_type || '',
        }
        )),
      };

      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const fetch_uuid_for_topicPreview =
        await TopicService.fetch_uuid_for_topicPreview(
          topic_post_data,
          brandUser?.tenant_id,
          brandUser?.user_details.user_id
        );
      localStorage.setItem(
        'fetch_uuid_for_topicPreview',
        JSON.stringify(fetch_uuid_for_topicPreview)
      );
      localStorage.setItem('topicPreviewLoading', 'true');

      let actualResponse;
      let counter = 0;
      const refreshPreviewData = async () => {
        // if (counter === 30) {
        //   localStorage.removeItem('fetch_uuid_for_topicPreview');
        //   localStorage.removeItem('topicPreviewLoading');
        //   return { data: 'No data' };
        // }
        // counter += 1;
        actualResponse = await TopicService.topicPreview(
          fetch_uuid_for_topicPreview?.preview_uuid,
          // counter,
          brandUser?.tenant_id,
          brandUser?.user_details.user_id
        );
        if (typeof actualResponse === 'object') {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          actualResponse.preview_uuid =
            fetch_uuid_for_topicPreview?.preview_uuid;
          actualResponse.inputData = topic_post_data;
          thunkAPI.dispatch(resetFlagFunc(false))
          thunkAPI.dispatch(
            setMessage('Topic Preview has been Successfully Created')
          );

          return actualResponse;
        } else {
          let status = actualResponse.split(' ');

          status.shift();

          // Join the remaining words back into a string
          let resultString = status.join(' ');

          if (resultString && resultString == 'still in progress') {
            await new Promise((resolve) => setTimeout(resolve, 8000));
            return refreshPreviewData();
          } else if (resultString && resultString == 'has failed') {
            localStorage.removeItem('fetch_uuid_for_topicPreview');
            localStorage.removeItem('topicPreviewLoading');

            return { data: 'Has Failed' };
          }
        }
      };
      return await refreshPreviewData();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCommentsListPreview = createAsyncThunk(
  'fetch_Comments_Details_preview',
  async ({ channel, value, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const body = {
        channel: channel,

        post_url: value,
      };
      // { query: value, search_type: searchType };
      const data = await TopicService.fetch_Comments_Details_preview(
        body,
        brandUser?.tenant_id,
        job_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCommentsList = createAsyncThunk(
  'fetch_Comments_Details',
  async ({ channel, value, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const body = {
        channel: channel,

        post_url: value,
      };
      // { query: value, search_type: searchType };
      const data = await TopicService.fetch_Comments_Details(
        body,
        brandUser?.tenant_id,
        job_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getFbSearchList = createAsyncThunk(
  'fetch_FB_Search_Details',
  async ({ searchType, value }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const body = { query: value, search_type: searchType };
      // { query: value, search_type: searchType };
      const data = await TopicService.fetch_FB_Search_Details(
        body,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getinstaSearchList = createAsyncThunk(
  'productDetailList',
  async ({ searchType, value }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const body = { query: value, search_type: searchType };

      const data = await TopicService.fetch_Insta_Search_Details(
        body,
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reviewsForTopic = createAsyncThunk(
  'reviewsForTopic',
  async (preview_uuid, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForTopic(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        preview_uuid
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterTopic = createAsyncThunk(
  'reviewsFilterTopic',
  async ({ dates, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsFilterTopic(
        brandUser?.tenant_id,
        job_id,
        dates
      );
      return { data };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsFilterTopicPreview = createAsyncThunk(
  'reviewsFilterTopicPreview',
  async ({ dates, preview_uuid }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsFilterTopicPreview(
        brandUser?.tenant_id,
        preview_uuid,
        dates
      );
      return { data };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsForCreatedTopic = createAsyncThunk(
  'reviewsForCreatedTopic',
  async (job_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForCreatedTopic(
        brandUser?.tenant_id,
        job_id
      );
      let dataObj = channelReviewManipulation(data);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reviewsForDownload = createAsyncThunk(
  'reviewsForDownload',
  async (apiVal, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.reviewsForDownload(
        brandUser?.tenant_id,
        apiVal?.job_id,
        apiVal?.email_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activeTopicList = createAsyncThunk(
  'activeTopicList',
  async (_, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.activeTopicList(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchCreatedTopic = createAsyncThunk(
  'fetchCreatedTopic',
  async (job_id, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.fetchCreatedTopic(
        brandUser?.tenant_id,
        job_id
      );

      // const dataObj = channelManipulation(data);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createNewTopic = createAsyncThunk(
  'createNewTopic',
  async (inputData, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.createNewTopic(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        inputData
      );

      thunkAPI.dispatch(setMessage('Topic is successfully created.'));
      return { data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.detail) ||
        (error.response &&
          error.response.data &&
          error.response.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const TopicHibernate = createAsyncThunk(
  'TopicHibernate',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicHibernate(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      thunkAPI.dispatch(setMessage('Topic hibernated successfully.'));
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error?.response?.data?.detail) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const TopicDelete = createAsyncThunk(
  'TopicDelete',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicDelete(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );
      thunkAPI.dispatch(setMessage('Topic deleted successfully.'));
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const TopicActive = createAsyncThunk(
  'TopicActive',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.TopicActive(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
      );

      thunkAPI.dispatch(setMessage('Topic activated successfully.'));
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error?.response?.data?.detail) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const FilterReportBasedOnDates = createAsyncThunk(
  'FilterReportBasedOnDates',
  async ({ startDate, endDate, job_id }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.GetReportBasedOnDate(
        startDate,
        endDate,
        brandUser?.tenant_id,
        job_id
      );
      // let resObj = channelManipulation(response)
      return {
        response: response,
        // reviews: response?.reviews
      };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const DetailedInsightsData = createAsyncThunk(
  'DetailedInsightsData',
  async (
    { preview_uuid, job_id, insight_date, insight_type, filter_key },
    thunkAPI
  ) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.DetailedInsights(
        preview_uuid,
        job_id,
        insight_date,
        insight_type,
        brandUser?.tenant_id,
        filter_key
      );
      return {
        response: response,
      };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const QueryPromptApi = createAsyncThunk(
  'QueryPromptApi',
  async ({ prompt, channel_name }, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await TopicService.GetQueryPromptData(
        prompt,
        channel_name
      );
      return { response };
    } catch (error) {

      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const topicAIPreview = createAsyncThunk(
  'topicAIPreview',
  async (body, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const fetch_uuid_for_topicPreview = await TopicService.PreviewAiData(
        body
      );

      localStorage.setItem(
        'fetch_uuid_for_topicPreview',
        JSON.stringify(fetch_uuid_for_topicPreview)
      );
      localStorage.setItem('topicPreviewLoading', 'true');

      const topic_post_data = {
        topic_title: body?.title || '',
        description: body?.description || '',
        keywords_string: body?.keywords_string || '',
        channel_name: body?.channel_name || [],
        is_ai: true,
        prompt: body?.prompt || '',
        draft: body?.draft || '',
        facebook_search_type:body?.facebook_search_type || null
      };
      let actualResponse;
      let counter = 0;
      const refreshPreviewData = async () => {
        if (counter === 30) {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          return { data: 'No data' };
        }
        counter += 1;
        actualResponse = await TopicService.topicPreview(
          fetch_uuid_for_topicPreview?.preview_uuid,
          // counter,
          fetch_uuid_for_topicPreview?.tenant_id,
          fetch_uuid_for_topicPreview?.user_id
        );
        if (
          actualResponse != null &&
          actualResponse != undefined &&
          typeof actualResponse !== 'object'
        ) {
          const firstSpaceIndex = actualResponse?.indexOf(' ');
          const firstWord = actualResponse?.substring(0, firstSpaceIndex);
          const restOfString = actualResponse?.substring(firstSpaceIndex + 1);

          if (restOfString == 'still in progress') {
            await new Promise((resolve) => setTimeout(resolve, 12000));
            return refreshPreviewData();
          } else if (restOfString == 'has failed') {
            // thunkAPI.dispatch(setMessage(message));
            return { data: 'Has Failed' };
          }
        } else if (typeof actualResponse === 'object') {
          localStorage.removeItem('fetch_uuid_for_topicPreview');
          localStorage.removeItem('topicPreviewLoading');
          // actualResponse.preview_uuid =
          //   fetch_uuid_for_topicPreview?.preview_uuid;
          actualResponse.inputData = topic_post_data;

          thunkAPI.dispatch(resetFlagFunc(false))
          thunkAPI.dispatch(
            setMessage('Topic Preview has been Successfully Created')
          );

          return actualResponse;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 12000));
          return refreshPreviewData();
        }
      };
      return await refreshPreviewData();
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const searchPost = createAsyncThunk(
  'search_Post',
  async ({ start_date, end_date, keyword, job_id }, thunkAPI) => {
    try {
      // const body ={  "start_date": startDate,  "end_date": endDate,  "keyword": value }
      // const brandUser = JSON.parse(localStorage.getItem('brandUser'));

      const body = {
        start_date: start_date,
        end_date: end_date,
        keyword: keyword,
      };

      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.search_Post(
        // brandUser?.tenant_id,
        // preview_uuid,
        brandUser?.tenant_id,
        // brandUser?.user_details.user_id,
        job_id,
        body
      );
      return { data };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const searchPostPreview = createAsyncThunk(
  'searchPostPreview',
  async ({ start_date, end_date, keyword, job_id }, thunkAPI) => {
    try {
      // const body ={  "start_date": startDate,  "end_date": endDate,  "keyword": value }
      // const brandUser = JSON.parse(localStorage.getItem('brandUser'));

      const body = {
        start_date: start_date,
        end_date: end_date,
        keyword: keyword,
      };

      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await TopicService.search_Post_Preview(
        // brandUser?.tenant_id,
        // preview_uuid,
        brandUser?.tenant_id,
        // brandUser?.user_details.user_id,
        job_id,
        body
      );
      return { data };
    } catch (error) {
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.detail?.[0]?.msg) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createTopicSideBar = async (body) => {
  try {
    console.log(body, 'body');
  } catch (error) {
    return error.message;
  }
};

const initialState = {
  topicPreviewData: null,
  reviewsForTopicData: null,
  activeTopicListData: null,
  createdTopicData: [],
  filteredReport: null,
  queryValue: [],
  topicAIPreviewData: [],
  popUpActive: false,
  searchInstaValue: [],
  isLoadingVal: false,
  searchFbValue: [],
  commentsValue: [],
  searchVal: [],
  searchText:'',
  resetFlag:false
};

const topicSlice = createSlice({
  name: 'topicBuilder',
  initialState,
  reducers: {
    setPopUpActive: (state, action) => {
      state.popUpActive = action.payload;
    },
    setFilteredReport: (state, action) => {
      state.filteredReport = null;
    },

    setQueryPromt: (state, action) => {
      state.queryValue = action.payload;
    },
    topicPreviewEmpty: (state, action) => {
      state.topicPreviewData = null;
    },
    topicEmptysearchInstaValue: (state, action) => {
      state.searchInstaValue = [];
    },
    topicEmptyFbValue: (state, action) => {
      state.searchFbValue = [];
    },
    topicEmptyCommentsValue: (state, action) => {
      state.commentsValue = [];
    },
    searchTextFunc : (state,action) => {
      state.searchText = action.payload
    },
    searchTextEmpty: (state,action) => {
      state.searchText =''
    },
    resetFlagFunc: (state,action) => {
      
      state.resetFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(topicPreview.fulfilled, (state, action) => {
        state.topicPreviewData = action.payload;
      })
      .addCase(topicPreview.rejected, (state, action) => {
        state.topicPreviewData = null;
      })
      .addCase(getinstaSearchList.pending, (state, action) => {
        state.isLoadingVal = true;
        // state.searchInstaValue = action.payload?.data;
      })
      .addCase(getinstaSearchList.fulfilled, (state, action) => {
        state.isLoadingVal = false;
        state.searchInstaValue = action.payload?.data;
      })
      .addCase(getinstaSearchList.rejected, (state, action) => {
        state.isLoadingVal = false;
        state.searchInstaValue = [];
      })
      .addCase(getCommentsListPreview.pending, (state, action) => {
        // state.searchInstaValue = action.payload?.data;
      })
      .addCase(getCommentsListPreview.fulfilled, (state, action) => {

        state.commentsValue = action.payload?.data?.comments_data;
      })
      .addCase(getCommentsListPreview.rejected, (state, action) => {
        state.commentsValue = [];
      })
      .addCase(getCommentsList.pending, (state, action) => {
        // state.searchInstaValue = action.payload?.data;
      })
      .addCase(getCommentsList.fulfilled, (state, action) => {

        state.commentsValue = action.payload?.data?.comments_data;
      })
      .addCase(getCommentsList.rejected, (state, action) => {
        state.commentsValue = [];
      })
      .addCase(getFbSearchList.pending, (state, action) => {
        state.isLoadingVal = true;
        // state.searchInstaValue = action.payload?.data;
      })
      .addCase(getFbSearchList.fulfilled, (state, action) => {
        state.isLoadingVal = false;
        state.searchFbValue = action.payload?.data;
      })
      .addCase(getFbSearchList.rejected, (state, action) => {
        state.isLoadingVal = false;
        state.searchFbValue = [];
        
      })
      // .addCase(getinstaSearchList.rejected, (state, action) => {
      //   state.searchInstaValue = [];
      // })

      .addCase(reviewsFilterTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsFilterTopic.rejected, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })

      .addCase(searchPostPreview.fulfilled, (state, action) => {
        state.searchVal = action.payload?.data;
      })
      .addCase(searchPostPreview.rejected, (state, action) => {
        state.searchVal = action.payload;
      })
      .addCase(searchPost.fulfilled, (state, action) => {
        state.searchVal = action.payload?.data;
      })
      .addCase(searchPost.rejected, (state, action) => {
        state.searchVal = action.payload;
      })
      .addCase(reviewsFilterTopicPreview.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsFilterTopicPreview.rejected, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForTopic.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(reviewsForDownload.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForDownload.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(reviewsForCreatedTopic.fulfilled, (state, action) => {
        state.reviewsForTopicData = action.payload.data.reviews;
      })
      .addCase(reviewsForCreatedTopic.rejected, (state, action) => {
        state.reviewsForTopicData = null;
      })
      .addCase(activeTopicList.fulfilled, (state, action) => {
        state.activeTopicListData = action.payload.data;
      })
      .addCase(activeTopicList.rejected, (state, action) => {
        state.activeTopicListData = null;
      })

      .addCase(fetchCreatedTopic.fulfilled, (state, action) => {
        state.createdTopicData = [action.payload.data];
        // state.status = 'succeeded';
      })

      .addCase(fetchCreatedTopic.rejected, (state, action) => {
        console.log('Please Try Again');
      })
      .addCase(createNewTopic.fulfilled, (state, action) => {
        state.createdTopicData = [action.payload.data];
      })
      .addCase(createNewTopic.rejected, (state, action) => {
        console.log('Please Try Again');
      })
      .addCase(FilterReportBasedOnDates.rejected, (state, action) => {
        state.filteredReport = null;
      })
      .addCase(FilterReportBasedOnDates.fulfilled, (state, action) => {
        state.filteredReport = action.payload.data;
      })
      .addCase(QueryPromptApi.rejected, (state, action) => {
        state.queryValue = null;
      })
      .addCase(QueryPromptApi.fulfilled, (state, action) => {
        state.queryValue = action.payload.response;
      })
      .addCase(topicAIPreview.rejected, (state, action) => {
        state.topicPreviewData = null;
      })
      .addCase(topicAIPreview.fulfilled, (state, action) => {
        state.topicPreviewData = action.payload;
      });
  },
});

export const {
  setPopUpActive,
  setQueryPromt,
  topicPreviewEmpty,
  topicEmptysearchInstaValue,
  topicEmptyFbValue,
  topicEmptyCommentsValue,
  searchTextFunc,
  searchTextEmpty,
  resetFlagFunc
} = topicSlice.actions;

export default topicSlice.reducer;
