import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateFilter } from '../../../utils';
import { fetchCreatedTopic, FilterReportBasedOnDates, resetFlagFunc } from '../../../store/redux/slices/topicSlice';
import { getPersonalDetails } from '../../../store/redux/slices/tenantSettingSlice';
 // Replace with actual utils

const useTopicData = (job_id) => {
  const dispatch = useDispatch();
  let topicPreviewData = useSelector((state) => state.topicBuilder.topicPreviewData) || {};
  let { createdTopicData } = useSelector((state) => state.topicBuilder);
  let { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};
  const {resetFlag} = useSelector((state) => state.topicBuilder) || false;
  const [activeTab, setActiveTab] = useState('overViewTab');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterOption, setFilterOption] = useState('Bar Graph');
  const [selectedOption, setSelectedOption] = useState('Bar Graph');
  const [filteredData, setFilteredData] = useState(null);
  // const [resetFlag, setResetFlag] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [orgFilterOption, setOrgFilterOption] = useState('Bar Graph');
  const [orgView, setOrgView] = useState('horizontal');
  const [view, setView] = useState('horizontal');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showSave, setShowSave] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  let fetchedData;

  let selectedTopic = createdTopicData?.reduce((foundTopic, topic) => {
    if (topic.job_id === job_id) {
      return topic;
    }
    return foundTopic;
  }, null);

  if (typeof job_id === 'number' && selectedTopic) {
    topicPreviewData = selectedTopic;
    fetchedData = selectedTopic?.data;
  } else {
    fetchedData = topicPreviewData?.data;
  }
  const initialStartDate = selectedTopic ? formatDateFilter(selectedTopic?.start_date) : topicPreviewData ? formatDateFilter(topicPreviewData?.start_date) : '';
  const initialEndDate = selectedTopic ? formatDateFilter(selectedTopic?.end_date) : topicPreviewData ? formatDateFilter(topicPreviewData?.end_date) : '';


  useEffect(() => {
    if (personalDetailsData === null) {
      dispatch(getPersonalDetails());
    }
    if (typeof job_id === 'number' && !selectedTopic) {
      dispatch(fetchCreatedTopic(job_id)).then((res) => {
        fetchedData = res?.payload?.data?.data;
      });
    }
    if (selectedTopic || topicPreviewData) {
      setStartDate((prevStartDate) => {
        const newStartDate = selectedTopic
          ? formatDateFilter(selectedTopic?.start_date)
          : topicPreviewData
          ? formatDateFilter(topicPreviewData?.start_date)
          : '2024-01-01';
        return prevStartDate !== newStartDate ? newStartDate : prevStartDate;
      });
      setEndDate((prevEndDate) => {
        const newEndDate = selectedTopic
          ? formatDateFilter(selectedTopic?.end_date)
          : topicPreviewData
          ? formatDateFilter(topicPreviewData?.end_date)
          : '01-01-2024';
        return prevEndDate !== newEndDate ? newEndDate : prevEndDate;
      });
    }
    setOrgFilterOption('Bar Graph');
    setOrgView('');
  }, [job_id, selectedTopic, topicPreviewData, dispatch]);

  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilter = () => {
    setApplyFilter(false);
    setFilterFlag(!filterFlag);
  };

  const handleClosePopup = () => {
    setFilterFlag(false);
  };

  
  const clearFilters = () => {
    setOrgFilterOption('Bar Graph');
    setOrgView('horizontal');
    setView('horizontal');
  };


  const handleApply = () => {
    setApplyFilter(true);
    setFilterFlag(!filterFlag);
    setOrgFilterOption(filterOption);
    setOrgView(view);
  };

  const handleFilterChange = (selectedOption) => {
    setFilterOption(selectedOption);
    if (selectedOption === 'Bar Graph') {
      setView('horizontal');
    } else {
      setView('');
    }
  };

  const handleCancel = () => {
    setView(orgView);
    setFilterFlag(false);
    setFilterOption(orgFilterOption);
    setSelectedOption(orgFilterOption);
  };

  const handleReset = () => {
    setStartDate(formatDateFilter(selectedTopic?.initial_start_date || topicPreviewData?.initial_start_date) || '2024-01-01');
    setEndDate(formatDateFilter(selectedTopic?.initial_end_date || topicPreviewData?.initial_end_date) || '01-01-2024');
    // setResetFlag(false);
    dispatch(resetFlagFunc(false))
    setFilteredData(null);
  };

  const dateHandler = (e) => {
    const { name, value } = e;
    setShowSave(true);
    const formattedDate = new Date(value).toISOString().split('T')[0];

    if (name === 'firstPostDate') {
      setStartDate(formattedDate);
    } else if (name === 'lastPostDate') {
      setEndDate(formattedDate);
    }
  };

  const dateSubmit = () => {
    setFilteredData(null);
    dispatch(resetFlagFunc(true))
    // setResetFlag(true);
    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date.getTime());
      };
    
      const validateDate = (date) => {
        
          // Convert date strings to Date objects
        const start = new Date(initialStartDate); // Explicitly parse the initialStartDate
        const end = new Date(initialEndDate);     // Explicitly parse the initialEndDate
        const datedate = new Date(date);          // Explicitly parse the date to be validated
  
        if (!isValidDate(start) || !isValidDate(end) || !isValidDate(datedate)) return false;
  
        const isAfterMin = datedate >= start;
        const isBeforeMax = datedate <= end;
  
        // console.log("Start Date Range:", start);
        // console.log("End Date Range:", end);
        // console.log("Date Being Validated:", datedate);
  
        return isAfterMin && isBeforeMax;
      };

    if (validateDate(startDate) && validateDate(endDate)) {
      dispatch(FilterReportBasedOnDates({ startDate, endDate, job_id })).then((res) => {
        setFilteredData(res.payload?.response);
        setShowSave(false);
        // dispatch(resetFlagFunc(false))
      }).catch((error) => {
        console.error('Error:', error);
      });
    } else {
      alert(`Enter valid Input between ${selectedTopic?.start_date || topicPreviewData?.start_date} and ${selectedTopic?.end_date || topicPreviewData?.end_date}`);
    }
  };

  return {
    activeTab,
    searchQuery,
    filterFlag,
    filterOption,
    selectedOption,
    filteredData,
    resetFlag,
    applyFilter,
    orgFilterOption,
    orgView,
    startDate,
    endDate,
    showSave,
    openDropdown,
    fetchedData,
    view,
    topicPreviewData,
    setView,
    setFilterOption,
    setOpenDropdown,
    setSelectedOption,
    handleTabClick,
    handleFilter,
    handleClosePopup,
    handleApply,
    handleFilterChange,
    handleCancel,
    handleReset,
    dateHandler,
    dateSubmit,
    updateSearchQuery,
    clearFilters
  };
};

export default useTopicData;
