import React, { useState } from 'react';
import styled from 'styled-components';
import { StarIcon } from '../SvgIcon/CommonSvgIcons';
import PopUp from '../PopUp/PopUp';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';

const BarGraphContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 30px;
  height: 200px; /* Adjust height as needed */
  padding: 10px; /* Adjust padding */
  ${'' /* margin-left: 50px; */}
  justify-content: center;
`;

const Bar = styled.div`
  width: 50px; /* Adjust width of each bar */
  display: flex;
  flex-direction: column-reverse; /* Reverse the column direction to make bars grow from bottom to top */
  align-items: center;
  position: relative;

  &>div: first-child {
    border-radius: 3px 0 0 3px;
  }
`;

const BarValue = styled.span`
  position: absolute;
  top: -20px; /* Adjust top position */
  font-size: 14px; /* Adjust font size */
  color: #636363;
  font-family: Montserrat;
`;

// const FilledBar = styled.div`
//   height: ${(props) => props.barHeight};
//   width: 100%;
//   background: red;
// `;

const BarLabel = styled.span`
  position: absolute;
  bottom: -30px; /* Adjust bottom position */
  font-size: 14px; /* Adjust font size */
  white-space: nowrap; /* Prevent label text from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis;
  width: 60px;
  text-align:center;

  color: #636363;
  font-family: Montserrat;
`;

const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 90px;
  overflow: scroll;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
`;
const StatusValWrapper = styled.div`
  text-align: justify;
  display: flex;
  align-items: center;

  & :first-child {
    height: 12px;
    width: 12px;
    content: " ";
    display: grid;
    background: ${(props) => props?.summaryVal?.fill ? props?.summaryVal?.fill : ''};
    border-radius: 50%;
    margin-bottom: 0px;
  }

  & :nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;
const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const VerticalBarGraph = ({ data, type = '', starRating,summary={},title='' }) => {
  // Sample data for the bars

  const [summaryVal, setSummaryVal] = useState('')
  const [showPopup, setShowPopup] = useState(false);
  const sortedData = data.sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 180 / maxValue;
  const handleOpenSummary = (rating) => {
    
    if(rating.value > 0 && title == 'Emotional_Analysis') {
      setSummaryVal(rating)
      setShowPopup(true)
    }
  }
  return (
    <BarGraphContainer className="pdf-page" style={{ pageBreakInside: 'avoid',width:(data && data?.length > 0) ? '':'100%' }}>
      {(data && data?.length > 0) ? data.map((item, index) => (
        <Bar key={index}  onClick={() => handleOpenSummary(item)}>
          <div
            style={{
              height: `${Math.floor(item.value * multiplier) + 2}px`,
              backgroundColor: item.fill || '#353cf7',
              width: '100%',
            }}
          ></div>
          <BarValue>{item.value}<span>{type}</span> </BarValue>
          <BarLabel>{item.name}</BarLabel>
        </Bar>
      ))
        :

        <NoDataWrapper>
          {/* No Data Available  */}Insufficient Data
        </NoDataWrapper>

      }
       {
        showPopup && summary[summaryVal?.name && summaryVal?.name?.toLowerCase()] && <PopUp minWidth={'700px'} height={'280px'} maxWidth={'700px'} onClose={() => setShowPopup(false)}>
          {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>

            <div>
              <TitleWithInfo
                title={'Summary '}
                infoText={``}
              />
            </div>
            <br />
            <Message>{summary[summaryVal?.name && summaryVal?.name?.toLowerCase()]}</Message>
            <br />
            <StatusValWrapper summaryVal={summaryVal}>
              <span></span> <span>{`${summaryVal?.name}- ${summaryVal?.value ? summaryVal?.value : 0}%`}</span>
            </StatusValWrapper>
            <CancelButton onClick={() => setShowPopup(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      }
    </BarGraphContainer>
  );
};

export default VerticalBarGraph;
