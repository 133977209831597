import { margin } from '@mui/system';
import * as React from 'react';
import { PieChart, Pie, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';
import './DonutChart.css'
import PopUp from '../PopUp/PopUp';
import { useState } from 'react';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
//  margin-left: 6%;
//  ${(props) => props.maxWidth};
max-width: ${(props) => props.showData ? props.maxWidth : '100%'};
width: ${(props) => props.showData ? props.maxWidth : '100%'};
`;

const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;
  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 90px;
  overflow: scroll;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
`;
const StatusValWrapper = styled.div`
  text-align: justify;
  display: flex;
  align-items: center;

  & :first-child {
    height: 12px;
    width: 12px;
    content: " ";
    display: grid;
    background: ${(props) => props?.summaryVal?.fill ? props?.summaryVal?.fill : ''};
    border-radius: 50%;
    margin-bottom: 0px;
  }

  & :nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;
const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const StyledPieChartWrapper = styled.div`
  /* Prevent focus and hover styles on the PieChart container */
  .recharts-pie-sector:focus,
  .recharts-pie-sector:hover {
    outline: none !important;  /* Remove any focus outline */
    border: none !important;   /* Remove any border */
  }
path.recharts-sector:focus{
    outline: none !important;
}
  /* Ensure there's no stroke (border) around the pie sectors */
  .recharts-pie-sector {
    stroke: none !important; /* Remove stroke (border) */
  }

  /* Optionally, target the focus on the container */
  :focus {
    outline: none !important;
  }
`;

const RatingDonutChart = ({ data, width = 450, type, summary = {}, title = '',
  maxWidth = '100%', }) => {
  // Sample data
  /* const data = [
    { name: 'Geeksforgeeks', students: 400 },
    { name: 'Technical scripter', students: 700 },
    { name: 'Geek-i-knack', students: 200 },
    { name: 'Geek-o-mania', students: 1000 },
  ]; */
  // const finalData = data.filter(row => row.value !== 0);
  let finalData = data
    .map(row => ({ ...row, value: parseFloat(row.value) }))
    .filter(row => row.value >= 0)
  const sortedData = finalData.sort((a, b) => b.value - a.value);

  const [summaryVal, setSummaryVal] = useState('')
  const [showPopup, setShowPopup] = useState(false);

  // const renderCustomLabel = (type) => ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }) => {
  //   if (percent <= 0.001) {
  //     return null;
  //   }
  //   const radius = innerRadius + (outerRadius - innerRadius) * 1.25;
  //   const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  //   const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="black"
  //       textAnchor={x > cx ? 'start' : 'end'}
  //       dominantBaseline="central"
  //       fontSize="14px"
  //     >
  //       {`${sortedData[index].name}- ${typeof sortedData[index]?.value === 'number' && sortedData[index]?.value
  //         }${type === 'percentage' ? '%' : ''
  //         }`}
  //     </text>
  //   );
  // };


  const tooltipFormatter = (value, name, props) => {



    // Assuming 'name' is your star rating (like '5star') and 'value' is the corresponding value
    return ` ${typeof value === 'number' && value !== 0 && value}`;
  };


  const CustomLegend = ({ payload }) => {
    return (
      <div style={{ width: '230px', marginLeft: '15px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{
            display: 'flex', alignItems: 'center',
            marginBottom: '15px'
          }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: entry.color, marginRight: '10px' }} />
            <span style={{
              wordBreak: 'break-word',
              width: '220px'
            }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const handleClickSegment = (rating) => {
    let val = {
      value: rating?.value || 0,
      name: rating?.name || '',
      fill: rating?.fill || '',
    }
    if (rating.value > 0 && title == 'Emotional_Analysis') {
      setSummaryVal(val)
      setShowPopup(true)
    }
  }
  // console.log(sortedData,"sortedData");

  return (
    <Wrapper maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100} showData={sortedData && sortedData?.length ? true : false}>
      {/* <PieChart width={width} height={280} viewBox={`93 0 ${width} 280`}
      > */}
      {
        sortedData && sortedData?.length ?
          <StyledPieChartWrapper>
            <PieChart width={width} height={280}>
              <Pie
                data={sortedData}
                dataKey="value"
                outerRadius={100}
                // Ensure viewBox covers the necessary area

                /* fill='#6F62FE' */
                /*  label={({ name, value }) => {
                if (value !== 0) {
                  return `${name}: ${value}%`;
                  return <text x={100} y={50} dy={100} textAnchor="middle" fill="black">
                    {`${name}: ${value}%`}
                  </text>
                } else {
                  return null; // Return null to hide the label
                }
              }} */
                //  label={renderCustomLabel(type)}
                fill={(entry) => entry.fill}
                stroke="none"
                border="none"
                activeShape={null} // Disable the activeShape highlight
                onClick={handleClickSegment}
              />

              <Legend content={<CustomLegend />} layout='vertical' verticalAlign='middle' align='right' />
              {/* <Legend contentStyle={{ color: 'blue' }} layout='vertical' verticalAlign='middle' align='right'  /> */}
              <Tooltip formatter={tooltipFormatter} />
            </PieChart>
          </StyledPieChartWrapper>

          :
          <NoDataWrapper>
            {/* No Data Available */}Insufficient Data
          </NoDataWrapper>
      }
      {
        showPopup && summary[summaryVal?.name && summaryVal?.name?.toLowerCase()] && <PopUp minWidth={'700px'} height={'280px'} maxWidth={'700px'} onClose={() => setShowPopup(false)}>
          {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>

            <div>
              <TitleWithInfo
                title={'Summary '}
                infoText={``}
              />
            </div>
            <br />
            <Message>{summary[summaryVal?.name && summaryVal?.name?.toLowerCase()]}</Message>
            <br />
            <StatusValWrapper summaryVal={summaryVal}>
              <span></span> <span>{`${summaryVal?.name}- ${summaryVal?.value ? summaryVal?.value : 0}%`}</span>
            </StatusValWrapper>
            <CancelButton onClick={() => setShowPopup(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      }
    </Wrapper>
  );
};

export default RatingDonutChart;
