import React from 'react';
import styled from 'styled-components';
import PopUp from '../PopUp/PopUp';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';
import { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props?.padding ? props?.padding : '10px'};
  box-sizing: border-box;
  font-family: Open Sans;
`;
const EachRow = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
`;

const Star = styled.div`
  font-size: 14px; /* Adjust font size */
  color: #636363;
  text-align: end;
  width: 100%;
  font-family: Montserrat;
  word-break:break-all;
`;

const PercentageBar = styled.div`
  display: flex;
  border-radius: 2px;
  background: ${(props) => props.color};

  width: ${(props) => props.width};
  height: 30px;
  padding: 5px 25px;
  justify-content: center;
  box-sizing: border-box;

  color: #000;
  font-weight: 400;
  position: relative;

  ${(props) => {
    if (parseInt(props.actualWidth) < 0) {
      return `&:after {
            content:'${props.actualWidth}${props.type}';
            position: absolute;
            left:110%;
            color:#222;
          }`;
    }
  }}
`;


const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  // text-align: left;
  // overflow:scroll !important;
  & > * {
    // float: left;
    clear: both;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  span {
    font: Open Sans;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
const Message = styled.div`
  color: #2a2929;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 90px;
  overflow: scroll;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
`;
const StatusValWrapper = styled.div`
  text-align: justify;
  display: flex;
  align-items: center;

  & :first-child {
    height: 12px;
    width: 12px;
    content: " ";
    display: grid;
    background: ${(props) => props?.summaryVal?.fill ? props?.summaryVal?.fill : ''};
    border-radius: 50%;
    margin-bottom: 0px;
  }

  & :nth-child(2) {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;
const CancelButton = styled.span`
  cursor: pointer;
  float: right;
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const FunnelGraph = ({ data, maxWidth = '100%', type = '', summary = {}, title = '', }) => {

  const [summaryVal, setSummaryVal] = useState('')
  const [showPopup, setShowPopup] = useState(false);
  const sortedData = data
    .filter((el) => el.value > 0)
    .map((e1) => {
      return {
        name: e1.name,
        value: parseFloat(e1.value),
        fill: e1.fill
      }
    })
    .sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 100 / maxValue;



  const tooltipFormatter = (value) => {
    return ` ${typeof value === 'number' && value !== 0 && value}`;
  };


  const handleOpenSummary = (rating) => {
    if (rating.value > 0 && title == 'Emotional_Analysis') {
      setSummaryVal(rating)
      setShowPopup(true)
    }
  }
  return (
    <Wrapper padding={sortedData && sortedData.length > 0 ? '10px' : '0'} maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100} className="pdf-page" style={{ pageBreakInside: 'avoid' }}>
      {
        sortedData && sortedData?.length ?
          sortedData.map((value, name) => (
            <EachRow key={name}
              onClick={() => handleOpenSummary(value)}>
              <Star>{value.name}</Star>
              <PercentageBar
                width={Math.floor(value.value * multiplier) + '%'}
                actualWidth={value.value}
                type={type}
                color={value.fill || '#353cf7'}
              >
                {value.value > 0 && tooltipFormatter(value.value) + type}
              </PercentageBar>
            </EachRow>
          ))
          :

          <NoDataWrapper>
            {/* No Data Available */}Insufficient Data
          </NoDataWrapper>

      }
      {
        showPopup && summary[summaryVal?.name && summaryVal?.name?.toLowerCase()] && <PopUp minWidth={'700px'} height={'280px'} maxWidth={'700px'} onClose={() => setShowPopup(false)}>
          {/* <span>cross</span>
          <div>{reviews[reviewMessage]}</div> */}
          <TextWrapper>

            <div>
              <TitleWithInfo
                title={'Summary '}
                infoText={``}
              />
            </div>
            <br />
            <Message>{summary[summaryVal?.name && summaryVal?.name?.toLowerCase()]}</Message>
            <br />
            <StatusValWrapper summaryVal={summaryVal}>
              <span></span> <span>{`${summaryVal?.name}- ${summaryVal?.value ? summaryVal?.value : 0}%`}</span>
            </StatusValWrapper>
            <CancelButton onClick={() => setShowPopup(false)}>
              Close
            </CancelButton>
          </TextWrapper>
        </PopUp>
      }
    </Wrapper>
  );
};

export default FunnelGraph;
