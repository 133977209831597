import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { DateWrapper } from "../../Pages/CompetitiveIntelligence/ViewCompetitive.styled";


const parseDateVal = (dateStr) => {
  // const [month, day, year] = dateStr.split('/');
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day);  // months are 0-indexed in JavaScript's Date object
};
function parseDate(dateString) {
  const parts = dateString?.split(/[-/]/);
  if (parts?.length === 3) {
    const day = parts?.length === 3 && parts[0]?.length === 2 ? parts[0] : parts[1];
    const month = parts?.length === 3 && parts[0]?.length === 2 ? parts[1] : parts[0];
    const year = parts[parts?.length - 1];
    return new Date(year, month - 1, day);
  }
  return null;
}
function parseDateReverse(dateString) {
  const parts = dateString?.split(/[-/]/).reverse();
  if (parts?.length === 3) {
    const day = parts?.length === 3 && parts[0]?.length === 2 ? parts[0] : parts[1];
    const month = parts?.length === 3 && parts[0]?.length === 2 ? parts[1] : parts[0];
    const year = parts[parts?.length - 1];
    return new Date(year, month - 1, day);
  }
  return null;
}


function formatDateToDDMMYYYY(dateString) {
  // console.log(dateString, "vdateString");
  const parts = dateString?.split(/[-/]/);
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

function formatDateToYYYYMMDD(date) {
  const pad = (n) => (n < 10 ? '0' + n : n);
  return `${date?.getFullYear()}-${pad(date?.getMonth() + 1)}-${pad(date?.getDate())}`;
}

export default function RangeSlider({ startDate, endDate, setStartDateOne, setEndDateOne }) {

  const [value, setValue] = useState([0, 1]);
  const [max, setMax] = useState(0);
  // const [formattedDates, setFormattedDates] = useState(["", ""]);
  const [labels, setLabels] = useState([]);
  const [startInput, setStartInput] = useState("");
  const [endInput, setEndInput] = useState("");
  const [sliderBool, setSliderBool] = useState(false)
  const [sliderDisabled, setSliderDisabled] = useState(false);
  useEffect(() => {
    const startDateOne = parseDateVal(startDate);
    const endDateOne = parseDateVal(endDate);

    if (!startDateOne || !endDateOne) {
      console.error("Invalid date format");
      setStartInput('')
      setEndInput('')
      setMax('')
      setValue([0, 1]);
      setLabels([])
      return;
    }

    // Set input values in the correct format
    setStartInput(formatDateToDDMMYYYY(startDate));
    setEndInput(formatDateToDDMMYYYY(endDate));

    const differenceInMilliseconds = endDateOne - startDateOne;
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    if (differenceInDays === 0) {
      setMax(1);
      setValue([0, 1]);
      setSliderDisabled(true);
    } else {

      setMax(differenceInDays);
      setValue([0, differenceInDays]);
      setSliderDisabled(false);
    }
    let newLabels = [];

    if (differenceInDays > 8) {
      // Always include the start date in the labels
      newLabels.push(startDateOne.toLocaleString("en-GB", { day: '2-digit', month: "short", year: "numeric" }));

      // Add intermediate labels
      newLabels = [
        ...newLabels,
        ...Array.from({ length: 5 }, (_, index) => {
          const date = new Date(startDateOne);
          // Adjust to get intermediate dates
          date.setDate(startDateOne.getDate() + Math.round((differenceInDays * (index + 1)) / 6)); // Adjust for the 5 intermediate labels
          return date.toLocaleString("en-GB", { day: '2-digit', month: "short", year: "numeric" });
        })
      ];

      // Always include the end date in the labels
      newLabels.push(endDateOne.toLocaleString("en-GB", { day: '2-digit', month: "short", year: "numeric" }));

    } else {
      newLabels = Array.from({ length: differenceInDays + 1 }, (_, index) => {
        const date = new Date(startDateOne);
        date.setDate(startDateOne.getDate() + Math.round((differenceInDays * index) / differenceInDays));
        return date.toLocaleString("en-GB", { day: '2-digit', month: "short", year: "numeric" });
      });
    }
    setLabels(newLabels);
  }, [startDate, endDate]);

  useEffect(() => {


    if (sliderBool === true) {
      const startDateOne = parseDateReverse(startInput);
      const endDateOne = parseDateReverse(endInput);
      if (!startDateOne || !endDateOne) return;
      // setFormattedDates([
      //     startDateOne.toLocaleDateString("en-GB"),
      //     endDateOne.toLocaleDateString("en-GB"),
      // ]);

      setStartDateOne(startDateOne.toLocaleDateString("en-GB"))
      setEndDateOne(endDateOne.toLocaleDateString("en-GB"))
      setSliderBool(false)
    } else {

      const startDateOne = parseDateVal(startDate);
      const endDateOne = parseDateVal(endDate);
      if (!startDateOne || !endDateOne) return;

      const differenceInMilliseconds = endDateOne - startDateOne;
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      let startLabel = new Date(startDateOne.getTime() + value[0] * 24 * 60 * 60 * 1000);
      let endLabel = new Date(startDateOne.getTime() + value[1] * 24 * 60 * 60 * 1000);

      if (differenceInDays === 0) {

        startLabel = new Date(startDateOne.getTime() + value[0] * 24 * 60 * 60 * 1000);
        endLabel = new Date(startDateOne.getTime() + value[0] * 24 * 60 * 60 * 1000);

      } else {

        startLabel = new Date(startDateOne.getTime() + value[0] * 24 * 60 * 60 * 1000);
        endLabel = new Date(startDateOne.getTime() + value[1] * 24 * 60 * 60 * 1000);

      }
      // console.log(startDateOne.toLocaleDateString("en-GB"), endDateOne, ',', startLabel, ',', endLabel, ',', startLabel.toLocaleDateString("en-GB"));

      // setFormattedDates([
      //     startLabel.toLocaleDateString("en-GB"),
      //     endLabel.toLocaleDateString("en-GB"),
      // ]);
      setStartDateOne(startLabel.toLocaleDateString("en-GB"))
      setEndDateOne(endLabel.toLocaleDateString("en-GB"))
    }

    // setStartInput(formatDateToDDMMYYYY(startLabel.toLocaleDateString("en-GB")));
    // setEndInput(formatDateToDDMMYYYY(endLabel.toLocaleDateString("en-GB")));
  }, [value, startInput, endInput]);

  const handleSliderChange = (event, newValue) => {

    setValue(newValue);

    const startDateOne = parseDateVal(startDate);
    if (startDateOne) {
      const newStartDate = new Date(startDateOne.getTime() + newValue[0] * 24 * 60 * 60 * 1000);
      const newEndDate = new Date(startDateOne.getTime() + newValue[1] * 24 * 60 * 60 * 1000);
      // console.log(startDateOne, formatDateToYYYYMMDD(newStartDate), "ssssssssssssssssssssssssssssssssssssssssss");
      // Update the input states
      setStartInput(formatDateToYYYYMMDD(newStartDate));
      setEndInput(formatDateToYYYYMMDD(newEndDate));
    }
  };

  const handleStartInputChange = (event) => {
    const newStartDate = event.target.value;
    setStartInput(newStartDate);
    const change = parseDateReverse(newStartDate);
    const endDateOne = parseDateVal(startDate);

    // console.log(newStartDate, change, endDateOne, "newStartDate");
    if (change && endDateOne) {
      const differenceInMilliseconds = change - endDateOne;
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      setValue([differenceInDays, value[1]]);

    }


    setSliderBool(true)
  };

  const handleEndInputChange = (event) => {
    const newEndDate = event.target.value;
    setEndInput(newEndDate);
    const change = parseDateReverse(newEndDate);
    const startDateOne = parseDateVal(startDate);

    if (change && startDateOne) {
      const differenceInMilliseconds = change - startDateOne;
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      // console.log(value, differenceInDays);
      setValue([value[0], differenceInDays]);
    }
    setSliderBool(true)
  };
  return (
    <Box
      sx={{
        // width: '90%',
        // padding: '20px',
        margin: '0 auto',
        textAlign: 'center',
      }}>
      <p style={{ textAlign: 'justify', marginTop: '35px', marginBottom: '0px', fontSize: '16px', fontWeight: '500' }}>Shared Interval</p>

      <div style={{ marginTop: 10, borderRadius: 4, border: '1px solid #d9d9d9' }}>
        <Box
          sx={{
            width: '90%',
            padding: '20px',
            margin: '0 auto',
            textAlign: 'center',
          }}>
          <Slider
            disabled={sliderDisabled}
            getAriaLabel={() => "Date range"}
            value={value}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) => {
              const startDateOne = parseDateVal(startDate);
              const newDate = new Date(startDateOne.getTime() + val * 24 * 60 * 60 * 1000);
              // console.log(,"newDate");
              // let newVAL = formatDateToYYYYMMDD(newDate);
              let newVal = newDate.toLocaleString("en-GB", { day: '2-digit', month: "short", year: "numeric" })

              return newVal;
              // formatDateToDDMMYYYY(newDate); // Display date instead of number
            }}
            max={max}
            marks={labels.map((label, index) => ({
              value: Math.round((max * index) / (labels?.length - 1)),
              label,
            }))}

            sx={{
              "& .MuiSlider-track": {
                bgcolor: 'rgba(174, 255, 253, 1)',
                height: "20px",
              },
              "& .MuiSlider-thumb": {
                bgcolor: "white",
                border: "2px solid #A2A2A2",
                height: "30px",
                width: "30px",
              },
              "& .MuiSlider-rail": {
                bgcolor: "#c9c4c4",
                height: "20px",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                borderRadius: '0px'
              },
              "& .MuiSlider-markLabel": {
                color: "#555",
                fontSize: "14px",
                marginTop: "10px",
              },
              "& .MuiSlider-mark": {
                display: "none",
              },
            }}
          />
        </Box>


      </div>

      {/* <DateWrapper>
        <div>
          <div style={{
            textAlign: 'left',
            marginBottom: '7px',
            marginLeft: '8px',
            marginTop: '20px'
          }}>Start Date:</div>
          <input
            type="date"
            name="firstPostDate"
            value={startInput}
            onChange={handleStartInputChange}
            style={{
              width: "150px",
              height: "40px",
              borderRadius: "8px",
              border: "1px solid rgb(217, 217, 217)",
              background: "rgb(255, 255, 255)",
              boxShadow: "rgba(162, 162, 162, 0.24) 0px 0px 8px 0px",
              padding: "1px 8px",
              color: "rgb(99, 99, 99)",
              fontFamily: "Open Sans",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "400",
            }}

            min={formatDateToDDMMYYYY(startDate)}
            max={formatDateToDDMMYYYY(endDate)}
          />
        </div>
        <div>
          <div style={{
            textAlign: 'left',
            marginBottom: '7px',
            marginLeft: '8px',
            marginTop: '20px'
          }}>End Date:</div>
          <input
            type="date"
            name="endPostDate"
            value={endInput}
            onChange={handleEndInputChange}
            style={{
              width: "150px",
              height: "40px",
              borderRadius: "8px",
              border: "1px solid rgb(217, 217, 217)",
              background: "rgb(255, 255, 255)",
              boxShadow: "rgba(162, 162, 162, 0.24) 0px 0px 8px 0px",
              padding: "1px 8px",
              color: "rgb(99, 99, 99)",
              fontFamily: "Open Sans",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
            min={formatDateToDDMMYYYY(startDate)}
            max={formatDateToDDMMYYYY(endDate)}
          />
        </div>
      </DateWrapper> */}
    </Box>
  );
}
